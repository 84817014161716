<template>
  <b-card
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Rank Chart
        </b-card-title>
        <b-card-sub-title>총판/유저 수익기여순위</b-card-sub-title>
      </div>
    </b-card-header>
    <!--/ title and subtitle -->

    <b-card-body style="height: 458px">
      <div class="row">
        <div class="col-6">
          <div
            class="text-center"
            style="font-style: italic; padding-left: 1px"
          >
            BRANCH
          </div>
          <hr style="margin-top: 6px">
        </div>
        <div class="col-6">
          <div
            class="text-center"
            style="font-style: italic; padding-left: 1px"
          >
            USER
          </div>
          <hr style="margin-top: 6px">
        </div>
        <div class="col-6">
          <!-- 첫 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[0].role"
                  :text="avatarText(transactionBranchData[0].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(0) }"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[0].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[0].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[0].cash.toLocaleString() }}
            </div>
          </div>
          <!-- 두 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[1].role"
                  :text="avatarText(transactionBranchData[1].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(1)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[1].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[1].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[1].cash.toLocaleString() }}
            </div>
          </div>
          <!-- 세 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[2].role"
                  :text="avatarText(transactionBranchData[2].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(2)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[2].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[2].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[2].cash.toLocaleString() }}
            </div>
          </div>
          <!-- 네 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[3].role"
                  :text="avatarText(transactionBranchData[3].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(3)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[3].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[3].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[3].cash.toLocaleString() }}
            </div>
          </div>
          <!-- 다섯 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[4].role"
                  :text="avatarText(transactionBranchData[4].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(4)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[4].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[4].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[4].cash.toLocaleString() }}
            </div>
          </div>
          <!-- 여섯 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[5].role"
                  :text="avatarText(transactionBranchData[5].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(5)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[5].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[5].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[5].cash.toLocaleString() }}
            </div>
          </div>
          <!-- 일곱 번째 객체 -->
          <div
            v-if="transactionBranchData && transactionBranchData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('branch-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionBranchData[6].role"
                  :text="avatarText(transactionBranchData[6].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant1(6)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[6].nickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="transactionBranchData.length > 0">
                        {{ transactionBranchData[6].userid }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ transactionBranchData[6].cash.toLocaleString() }}
            </div>
          </div>
        </div>
        <div class="col-6">
          <!-- 첫 번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[0].role"
                  :text="avatarText(transactionUserData[0].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(0)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[0].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[0].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[0].contribution.toLocaleString() }}
            </div>
          </div>
          <!-- 두 번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[1].role"
                  :text="avatarText(transactionUserData[1].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(1)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[1].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[1].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[1].contribution.toLocaleString() }}
            </div>
          </div>
          <!-- 세 번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[2].role"
                  :text="avatarText(transactionUserData[2].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(2)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[2].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[2].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[2].contribution.toLocaleString() }}
            </div>
          </div>
          <!-- 네 번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[3].role"
                  :text="avatarText(transactionUserData[3].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(3)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[3].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[3].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[3].contribution.toLocaleString() }}
            </div>
          </div>
          <!-- 다섯 번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[4].role"
                  :text="avatarText(transactionUserData[4].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(4)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[4].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[4].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[4].contribution.toLocaleString() }}
            </div>
          </div>
          <!-- 다섯번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[5].role"
                  :text="avatarText(transactionUserData[5].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(5)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[5].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[5].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[5].contribution.toLocaleString() }}
            </div>
          </div>
          <!-- 여섯번째 객체 -->
          <div
            v-if="transactionUserData && transactionUserData.length >= 7"
            class="transaction-item"
            @mouseover="isHovered = true"
            @mouseout="isHovered = false"
            @click="goRoute('apps-users-list')"
          >
            <b-media no-body>
              <b-media-aside>
                <b-avatar
                  size="40"
                  rounded
                  :src="transactionUserData[6].role"
                  :text="avatarText(transactionUserData[6].no.toLocaleString())"
                  :style="{ backgroundColor: resolveUserRoleVariant2(6)}"
                />
              </b-media-aside>
              <b-media-body>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="transaction-title">
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[6].requestNickname }}
                      </div>
                    </h6>
                    <small>
                      <div v-if="contributionRankingData.length > 0">
                        {{ contributionRankingData[6].requestId }}
                      </div>
                    </small>
                  </div>
                </div>
              </b-media-body>
            </b-media>
            <div
              v-if="contributionRankingData.length > 0"
              class="font-weight-bolder"
              :style="{ color: '#33d918' }"
            >
              {{ contributionRankingData[6].contribution.toLocaleString() }}
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BAvatar, BMediaBody, BMedia, BMediaAside,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_REQUEST_ALARMS_DEPOSIT_FINISH, FETCH_REQUEST_ALARMS_EXCHANGE_FINISH } from '@/store/notice/action'
import { FETCH_USERS } from '@/store/users/action'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const noticeStore = createNamespacedHelpers('noticeStore')
const userStore = createNamespacedHelpers('userStore')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
    BAvatar,
    BMediaBody,
    BMedia,
    BMediaAside,
  },
  directives: {
    Ripple,
  },
  props: {
    // siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...noticeStore.mapGetters({
      fetchRequestAlarmsDepositFinish: 'fetchRequestAlarmsDepositFinish',
      fetchRequestAlarmsExchangeFinish: 'fetchRequestAlarmsExchangeFinish',
    }),
    ...userStore.mapGetters({
      fetchUsers: 'fetchUsers',
    }),
  },
  watch: {
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      this.fetchData()
    },
  },
  created() {
    // 랭킹 차트 충전 소켓 조회
    this.$socket.on('requestAlarmsDepositRankChartDashBoard', data => {
      // console.log('requestAlarmsDepositRankChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
    // 랭킹 차트 환전 소켓 조회
    this.$socket.on('requestAlarmsExchangeRankChartDashBoard', data => {
      // console.log('requestAlarmsExchangeRankChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
  },
  mounted() {
    this.fetchData()
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []

    // 'startOfMonth'부터 'endOfMonth'까지의 각 날짜를 'MM-DD' 형식으로 배열에 추가
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M-D'))
    }

    return {

      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      contributionRankingData: [],
      isHovered: false,
      transactionBranchData: [
        {
          no: 1,
          userid: 'paksd1',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 2,
          userid: 'paksd2',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 3,
          userid: 'paksd3',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 4,
          userid: 'paksd4',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 5,
          userid: 'paksd5',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 6,
          userid: 'paksd6',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 7,
          userid: 'paksd7',
          nickname: '개똥이',
          cash: '4,500,000',
        },
      ],
      transactionUserData: [
        {
          no: 1,
          userid: 'paksd11',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 2,
          userid: 'paksd22',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 3,
          userid: 'paksd33',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 4,
          userid: 'paksd44',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 5,
          userid: 'paksd55',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 6,
          userid: 'paksd66',
          nickname: '개똥이',
          cash: '4,500,000',
        },
        {
          no: 7,
          userid: 'paksd77',
          nickname: '개똥이',
          cash: '4,500,000',
        },
      ],

      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsDepositFinish: FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
      $fetchRequestAlarmsExchangeFinish: FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
    }),
    ...userStore.mapActions({
      $fetchUsers: FETCH_USERS,
    }),
    async fetchData() {
      await this.fetchUser()
      await this.fetchDepositFinish()
      await this.fetchExchangeFinish()
      await this.processNextChartData()
    },
    async fetchUser() {
      const mySite = this.siteSelected
      await this.$fetchUsers({
        site: mySite,
      })
      // console.log('RankChart fetchUsers:::', this.fetchUsers)
    },
    async fetchDepositFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsDepositFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
      })
      // console.log('RankChart fetchRequestAlarmsDepositFinish:::', this.fetchRequestAlarmsDepositFinish)
    },
    async fetchExchangeFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsExchangeFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
      })
      // console.log('RankChart fetchRequestAlarmsExchangeFinish:::', this.fetchRequestAlarmsExchangeFinish)
    },
    async sprepareChartData() {
      // 데이터 초기화
      this.contributionRankingData = []

      // Deposit 데이터와 Exchange 데이터 합산하여 기여 순위 데이터 생성
      const depositData = this.calculateTotalRequestCash(this.fetchRequestAlarmsDepositFinish)
      const exchangeData = this.calculateTotalRequestCash(this.fetchRequestAlarmsExchangeFinish)
      for (const requestId in depositData) {
        if (Object.prototype.hasOwnProperty.call(depositData, requestId)) {
          const contribution = depositData[requestId] - (exchangeData[requestId] || 0)
          if (!Number.isNaN(contribution)) {
            // 기여 순위 데이터에 요소 추가
            const depositEntry = this.fetchRequestAlarmsDepositFinish.find(item => item.requestId === requestId)
            const requestNickname = depositEntry ? depositEntry.requestNickname : ''
            this.contributionRankingData.push({ requestId, contribution, requestNickname })
          } else {
            console.error('Contribution is not a number for requestId:', requestId)
          }
        }
      }
      this.contributionRankingData.sort((a, b) => b.contribution - a.contribution)
      // console.log('contributionRankingData:::', this.contributionRankingData)
    },

    calculateTotalRequestCash(data) {
      // 유저별 requestCash 합 계산
      const totalRequestCashByUser = {}
      data.forEach(entry => {
        const { requestId, requestCash } = entry
        totalRequestCashByUser[requestId] = (totalRequestCashByUser[requestId] || 0) + requestCash
      })
      return totalRequestCashByUser
    },
    resolveUserRoleVariant1(no) {
      const gradientColor = '#ee7920' // 기본 색상

      // 그라데이션을 적용할 범위 정의
      const endNo = 1
      const startNo = 7

      // 그라데이션 계산
      const gradientFactor = (no - startNo) / (endNo - startNo)
      const r = parseInt(gradientColor.substring(1, 3), 16)
      const g = parseInt(gradientColor.substring(3, 5), 16)
      const b = parseInt(gradientColor.substring(5, 7), 16)
      const gradientR = Math.round(r * gradientFactor)
      const gradientG = Math.round(g * gradientFactor)
      const gradientB = Math.round(b * gradientFactor)

      return `rgb(${gradientR}, ${gradientG}, ${gradientB})`
    },
    resolveUserRoleVariant2(no) {
      const gradientColor = '#f139c7' // 기본 색상

      // 그라데이션을 적용할 범위 정의
      const endNo = 1
      const startNo = 7

      // 그라데이션 계산
      const gradientFactor = (no - startNo) / (endNo - startNo)
      const r = parseInt(gradientColor.substring(1, 3), 16)
      const g = parseInt(gradientColor.substring(3, 5), 16)
      const b = parseInt(gradientColor.substring(5, 7), 16)
      const gradientR = Math.round(r * gradientFactor)
      const gradientG = Math.round(g * gradientFactor)
      const gradientB = Math.round(b * gradientFactor)

      return `rgb(${gradientR}, ${gradientG}, ${gradientB})`
    },
    async processNextChartData() {
      await this.sprepareChartData()
    },
    goRoute(list) {
      this.$router.push({ name: list }).catch(() => {})
    },
  },
}
</script>

<style scoped>
.transaction-item {
  transition: background-color 0.3s;
}

.transaction-item:hover {
  background-color: #252B3D; /* 호버 효과에 적용할 배경색상 */
}
</style>
