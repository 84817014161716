import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_GAME_SET_BASIC,
  FETCH_GAME_SET_MENU,
  FETCH_GAME_SET_ITEM,
  FETCH_GAME_SET_LEAGUE,
  FETCH_GAME_SET_TEAM,
  FETCH_GAME_SET_ODDS_MARKET,
  FETCH_GAME_SET_ODDS_CUSTOM,
  FETCH_GAME_SET_COMBINATION,
  FETCH_CUSTOM_MATCH_BY_ADMIN,
  FETCH_GAME_SET_MATCH_BETTING_CUSTOM,
  FETCH_GAME_SET_VENDOR,
  FETCH_GAME_CASINO_TRANSACTION,
} from '@/store/game/action'
import {
  UPDATE_GAME_SET_BASIC,
  UPDATE_GAME_SET_MENU,
  UPDATE_GAME_SET_ITEM,
  UPDATE_GAME_SET_LEAGUE,
  UPDATE_GAME_SET_TEAM,
  CREATE_GAME_SET_TEAM,
  UPDATE_GAME_SET_TEAM_CUSTOM_NAME,
  UPDATE_GAME_SET_ODDS_MARKET,
  UPDATE_GAME_SET_ODDS_CUSTOM,
  UPDATE_GAME_SET_COMBINATION,
  DELETE_GAME_SET_COMBINATION,
  CREATE_MANY_CUSTOM_MATCH_BY_ADMIN,
  UPDATE_CUSTOM_MATCH_BY_ADMIN,
  UPDATE_CUSTOM_RESULT_CONFIRM,
  UPSERT_GAME_SET_MATCH_BETTING_CUSTOM,
  UPDATE_GAME_SET_VENDOR,
} from '@/store/game/mutation'

const gameStore = {
  namespaced: true,
  state: {
    fetchGameSetBasic: [],
    updateGameSetBasic: [],
    fetchGameSetMenu: [],
    updateGameSetMenu: [],
    fetchGameSetItem: [],
    updateGameSetItem: [],
    fetchGameSetLeague: [],
    updateGameSetLeague: [],
    fetchGameSetTeam: [],
    updateGameSetTeam: [],
    createGameSetTeam: [],
    fetchGameSetOddsMarket: [],
    updateGameSetOddsMarket: [],
    updateGameSetTeamCustomName: [],
    fetchGameSetOddsCustom: [],
    updateGameSetOddsCustom: [],
    fetchGameSetCombination: [],
    updateGameSettCombination: [],
    fetchCustomMatchByAdmin: [],
    createManyCustomMatchByAdmin: [],
    updateManyCustomMatchByAdmin: [],
    updateCustomResultConfirm: [],
    fetchGameSetMatchBettingCustom: [],
    upsertGameSetMatchBettingCustom: [],
    fetchGameSetVendor: [],
    updateGameSetVendor: [],
    fetchGameCasinoTransaction: [],
  },
  getters: {
    // getField,
    fetchGameSetBasic: state => state.fetchGameSetBasic[0],
    updateGameSetBasic: state => state.updateGameSetBasic[0],
    fetchGameSetMenu: state => state.fetchGameSetMenu,
    updateGameSetMenu: state => state.updateGameSetMenu,
    fetchGameSetItem: state => state.fetchGameSetItem,
    updateGameSetItem: state => state.updateGameSetItem,
    fetchGameSetLeague: state => state.fetchGameSetLeague,
    updateGameSetLeague: state => state.updateGameSetLeague,
    fetchGameSetTeam: state => state.fetchGameSetTeam,
    updateGameSetTeam: state => state.updateGameSetTeam,
    createGameSetTeam: state => state.createGameSetTeam,
    updateGameSetTeamCustomName: state => state.updateGameSetTeamCustomName,
    fetchGameSetOddsMarket: state => state.fetchGameSetOddsMarket,
    updateGameSetOddsMarket: state => state.updateGameSetOddsMarket,
    fetchGameSetOddsCustom: state => state.fetchGameSetOddsCustom,
    updateGameSetOddsCustom: state => state.updateGameSetOddsCustom,
    fetchGameSetCombination: state => state.fetchGameSetCombination,
    updateGameSettCombination: state => state.updateGameSettCombination,
    addCustomMatchAddon: state => state.addCustomMatchAddon,
    fetchCustomMatchByAdmin: state => state.fetchCustomMatchByAdmin,
    createManyCustomMatchByAdmin: state => state.createManyCustomMatchByAdmin,
    updateManyCustomMatchByAdmin: state => state.updateManyCustomMatchByAdmin,
    updateCustomResultConfirm: state => state.updateCustomResultConfirm,
    fetchGameSetMatchBettingCustom: state => state.fetchGameSetMatchBettingCustom,
    upsertGameSetMatchBettingCustom: state => state.upsertGameSetMatchBettingCustom,
    fetchGameSetVendor: state => state.fetchGameSetVendor,
    updateGameSetVendor: state => state.updateGameSetVendor,
    fetchGameCasinoTransaction: state => state.fetchGameCasinoTransaction,
  },
  mutations: {
    [FETCH_GAME_SET_BASIC](state, fetchGameSetBasic) {
      state.fetchGameSetBasic = fetchGameSetBasic
    },
    [UPDATE_GAME_SET_BASIC](state, updateGameSetBasic) {
      state.updateGameSetBasic = updateGameSetBasic
    },
    [FETCH_GAME_SET_MENU](state, fetchGameSetMenu) {
      state.fetchGameSetMenu = fetchGameSetMenu
    },
    [UPDATE_GAME_SET_MENU](state, updateGameSetMenu) {
      state.updateGameSetMenu = updateGameSetMenu
    },
    [FETCH_GAME_SET_ITEM](state, fetchGameSetItem) {
      state.fetchGameSetItem = fetchGameSetItem
    },
    [UPDATE_GAME_SET_ITEM](state, updateGameSetItem) {
      state.updateGameSetItem = updateGameSetItem
    },
    [FETCH_GAME_SET_LEAGUE](state, fetchGameSetLeague) {
      state.fetchGameSetLeague = fetchGameSetLeague
    },
    [UPDATE_GAME_SET_LEAGUE](state, updateGameSetLeague) {
      state.updateGameSetLeague = updateGameSetLeague
    },
    [FETCH_GAME_SET_TEAM](state, fetchGameSetTeam) {
      state.fetchGameSetTeam = fetchGameSetTeam
    },
    [UPDATE_GAME_SET_TEAM](state, updateGameSetTeam) {
      state.updateGameSetTeam = updateGameSetTeam
    },
    [CREATE_GAME_SET_TEAM](state, createGameSetTeam) {
      state.createGameSetTeam = createGameSetTeam
    },
    [UPDATE_GAME_SET_TEAM_CUSTOM_NAME](state, updateGameSetTeamCustomName) {
      state.updateGameSetTeamCustomName = updateGameSetTeamCustomName
    },
    [FETCH_GAME_SET_ODDS_MARKET](state, fetchGameSetOddsMarket) {
      state.fetchGameSetOddsMarket = fetchGameSetOddsMarket
    },
    [UPDATE_GAME_SET_ODDS_MARKET](state, updateGameSetOddsMarket) {
      state.updateGameSetOddsMarket = updateGameSetOddsMarket
    },
    [FETCH_GAME_SET_ODDS_CUSTOM](state, fetchGameSetOddsCustom) {
      state.fetchGameSetOddsCustom = fetchGameSetOddsCustom
    },
    [UPDATE_GAME_SET_ODDS_CUSTOM](state, updateGameSetOddsCustom) {
      state.updateGameSetOddsCustom = updateGameSetOddsCustom
    },
    [FETCH_GAME_SET_COMBINATION](state, fetchGameSetCombination) {
      state.fetchGameSetCombination = fetchGameSetCombination
    },
    [UPDATE_GAME_SET_COMBINATION](state, updateGameSettCombination) {
      state.updateGameSettCombination = updateGameSettCombination
    },
    [FETCH_CUSTOM_MATCH_BY_ADMIN](state, fetchCustomMatchByAdmin) {
      state.fetchCustomMatchByAdmin = fetchCustomMatchByAdmin
    },
    [CREATE_MANY_CUSTOM_MATCH_BY_ADMIN](state, createManyCustomMatchByAdmin) {
      state.createManyCustomMatchByAdmin = createManyCustomMatchByAdmin
    },
    [UPDATE_CUSTOM_MATCH_BY_ADMIN](state, updateCustomMatchByAdmin) {
      state.updateCustomMatchByAdmin = updateCustomMatchByAdmin
    },
    [UPDATE_CUSTOM_RESULT_CONFIRM](state, updateCustomResultConfirm) {
      state.updateCustomResultConfirm = updateCustomResultConfirm
    },
    [FETCH_GAME_SET_MATCH_BETTING_CUSTOM](state, fetchGameSetMatchBettingCustom) {
      state.fetchGameSetMatchBettingCustom = fetchGameSetMatchBettingCustom
    },
    [UPSERT_GAME_SET_MATCH_BETTING_CUSTOM](state, upsertGameSetMatchBettingCustom) {
      state.upsertGameSetMatchBettingCustom = upsertGameSetMatchBettingCustom
    },
    [FETCH_GAME_SET_VENDOR](state, fetchGameSetVendor) {
      state.fetchGameSetVendor = fetchGameSetVendor
    },
    [UPDATE_GAME_SET_VENDOR](state, updateGameSetVendor) {
      state.updateGameSetVendor = updateGameSetVendor
    },
    [FETCH_GAME_CASINO_TRANSACTION](state, fetchGameCasinoTransaction) {
      state.fetchGameCasinoTransaction = fetchGameCasinoTransaction
    },
    updateParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      state[events.t][0][events.e] = events.v
      // console.log('storeData', state.fetchSetBasic[0])
    },
    updateManyParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      console.log('i >> ', events.i) // idx
      state[events.t][events.i][events.e] = events.v
    },
    updateManyPushParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      console.log('i >> ', events.i) // index
      console.log('state[events.t] :: ', state[events.t])

      const targetIdx = state[events.t].findIndex(item => item.index === events.i)
      if (targetIdx !== -1) {
      } else {
        state[events.t].push(state[events.v])
      }
    },
    updateManyLeagueParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('f >> ', events.f) // from target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      console.log('i >> ', events.i) // idx
      if (!Array.isArray(state[events.t])) {
        state[events.t] = []
      }
      const targetId = events.i.leagueId
      const targetIdIdx = state[events.t].findIndex(item => item.leagueId === targetId)
      const fromIdIdx = state[events.f].findIndex(item => item.leagueId === targetId)
      state[events.f][fromIdIdx][events.e] = events.v
      if (targetIdIdx !== -1) {
        state[events.t][targetIdIdx][events.e] = events.v
      } else {
        state[events.t].push(state[events.f][fromIdIdx])
      }
      console.log('@@@ t :', state[events.t])
    },
    updateManyTeamParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('f >> ', events.f) // from target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      console.log('i >> ', events.i) // idx
      if (!Array.isArray(state[events.t])) {
        state[events.t] = []
      }
      const targetTeamId = events.i.teamId
      const targetLeagueId = events.i.leagueId
      const targetIdIdx = state[events.t].findIndex(item => item.teamId === targetTeamId && item.leagueId === targetLeagueId)
      const fromIdIdx = state[events.f].findIndex(item => item.teamId === targetTeamId && item.leagueId === targetLeagueId)
      state[events.f][fromIdIdx][events.e] = events.v
      if (targetIdIdx !== -1) {
        state[events.t][targetIdIdx][events.e] = events.v
      } else {
        state[events.t].push(state[events.f][fromIdIdx])
      }
      console.log('@@@ t :', state[events.t])
    },
    updateManyMarketParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('f >> ', events.f) // from target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      console.log('i >> ', events.i) // idx
      if (!Array.isArray(state[events.t])) {
        state[events.t] = []
      }
      const targetMenuId = events.i.menuId
      const targetItemId = events.i.itemId
      const targetMargetId = events.i.marketId
      const targetIdIdx = state[events.t].findIndex(item => item.menuId === targetMenuId && item.itemId === targetItemId && item.marketId === targetMargetId)
      const fromIdIdx = state[events.f].findIndex(item => item.menuId === targetMenuId && item.itemId === targetItemId && item.marketId === targetMargetId)
      state[events.f][fromIdIdx][events.e] = events.v
      if (targetIdIdx !== -1) {
        state[events.t][targetIdIdx][events.e] = events.v
      } else {
        state[events.t].push(state[events.f][fromIdIdx])
      }
      console.log('@@@ t :', state[events.t])
    },
    updateManyVendorParam(state, events) {
      console.log('t >> ', events.t) // target
      console.log('f >> ', events.f) // from target
      console.log('e >> ', events.e) // event
      console.log('v >> ', events.v) // value
      console.log('i >> ', events.i) // idx
      if (!Array.isArray(state[events.t])) {
        state[events.t] = []
      }
      const targetCategory = events.i.category
      const targetvendorName = events.i.vendorName
      const targetIdIdx = state[events.t].findIndex(item => item.category === targetCategory && item.vendorName === targetvendorName)
      const fromIdIdx = state[events.f].findIndex(item => item.category === targetCategory && item.vendorName === targetvendorName)
      state[events.f][fromIdIdx][events.e] = events.v
      if (targetIdIdx !== -1) {
        state[events.t][targetIdIdx][events.e] = events.v
      } else {
        state[events.t].push(state[events.f][fromIdIdx])
      }
      console.log('@@@ t :', state[events.t])
    },
  },
  actions: {
    async [FETCH_GAME_SET_BASIC]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetBasic($site: String) {
            fetchGameSetBasic(site: $site) {
              site
              userBetCancelTime
              gameBetCancelTime
              userBetHighAlarm
              betTimeCloseMini
              userBetHighAlarmMini
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_BASIC, data.fetchGameSetBasic)
      console.log('FETCH_GAME_SET_BASIC : ', data.fetchGameSetBasic)
    },
    async [UPDATE_GAME_SET_BASIC]({ commit, state }, payload) {
      // console.log('update state : ', state)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetBasic($site: String, $userBetCancelTime: Float, $gameBetCancelTime: Float, $userBetHighAlarm: Float, $betTimeCloseMini: Float, $userBetHighAlarmMini: Float, $userid: String, $userIp: String) {
            updateGameSetBasic(site: $site, userBetCancelTime: $userBetCancelTime, gameBetCancelTime: $gameBetCancelTime, userBetHighAlarm: $userBetHighAlarm, betTimeCloseMini: $betTimeCloseMini, userBetHighAlarmMini: $userBetHighAlarmMini, userid: $userid, userIp: $userIp) {
              site
              userBetCancelTime
              gameBetCancelTime
              userBetHighAlarm
              betTimeCloseMini
              userBetHighAlarmMini
            }
          }
        `,
        variables: {
          site: payload.site,
          userBetCancelTime: Number(state.fetchGameSetBasic[0].userBetCancelTime),
          gameBetCancelTime: Number(state.fetchGameSetBasic[0].gameBetCancelTime),
          userBetHighAlarm: Number(state.fetchGameSetBasic[0].userBetHighAlarm),
          betTimeCloseMini: Number(state.fetchGameSetBasic[0].betTimeCloseMini),
          userBetHighAlarmMini: Number(state.fetchGameSetBasic[0].userBetHighAlarmMini),
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_BASIC, data.updateGameSetBasic)
      console.log('UPDATE_GAME_SET_BASIC : ', data.updateGameSetBasic)
    },
    async [FETCH_GAME_SET_MENU]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetMenu($site: String) {
            fetchGameSetMenu(site: $site) {
              idx
              site
              type
              id
              name
              nameCustom
              minBet
              maxBet
              maxWin
              foldFrom
              foldTo
              useYn
              menuUseYn
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_MENU, data.fetchGameSetMenu)
      console.log('FETCH_GAME_SET_MENU : ', data.fetchGameSetMenu)
    },
    async [UPDATE_GAME_SET_MENU]({ commit, state }, payload) {
      // console.log('update state : ', state)
      const custom = state.fetchGameSetMenu.map(item => ({
        idx: Number(item.idx),
        id: item.id,
        site: payload.site,
        type: item.type,
        name: item.name,
        nameCustom: item.nameCustom,
        minBet: Number(item.minBet),
        maxBet: Number(item.maxBet),
        maxWin: Number(item.maxWin),
        foldFrom: Number(item.foldFrom),
        foldTo: Number(item.foldTo),
        useYn: item.useYn,
        menuUseYn: item.menuUseYn,
      }))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetMenu($gameSetMenuInputArgs: [GameSetMenuInputArgs!]!, $userid: String!, $userIp: String!) {
            updateGameSetMenu(gameSetMenuInputArgs: $gameSetMenuInputArgs, , userid: $userid, userIp: $userIp)
          }
        `,
        variables: {
          gameSetMenuInputArgs: custom,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_MENU, data.updateGameSetMenu)
      console.log('UPDATE_GAME_SET_MENU : ', data.updateGameSetMenu)
    },
    async [FETCH_GAME_SET_ITEM]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetItem($site: String, $type: String, $menuName: String) {
            fetchGameSetItem(site: $site, type: $type, menuName: $menuName) {
              idx
              site
              type
              menuId
              menuName
              id
              name
              nameCustom
              useYn
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          type: payload.type,
          menuName: payload.menuName,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_ITEM, data.fetchGameSetItem)
      console.log('FETCH_GAME_SET_ITEM : ', data.fetchGameSetItem)
    },
    async [UPDATE_GAME_SET_ITEM]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchGameSetItem)
      const custom = state.fetchGameSetItem.map(item => ({
        idx: Number(item.idx),
        type: item.type,
        menuId: item.menuId,
        menuName: item.menuName,
        id: item.id,
        site: payload.site,
        name: item.name,
        nameCustom: item.nameCustom,
        useYn: item.useYn,
      }))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetItem($gameSetItemInputArgs: [GameSetItemInputArgs!]!, $userid: String!, $userIp: String!) {
            updateGameSetItem(gameSetItemInputArgs: $gameSetItemInputArgs, userid: $userid, userIp: $userIp)
          }
        `,
        variables: {
          gameSetItemInputArgs: custom,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_ITEM, data.updateGameSetItem)
      console.log('UPDATE_GAME_SET_ITEM : ', data.updateGameSetItem)
    },
    async [FETCH_GAME_SET_LEAGUE]({ commit }, payload) {
      console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetLeague($site: String, $useYn: String, $date_end: String, $sports: String) {
            fetchGameSetLeague(site: $site, useYn: $useYn, date_end: $date_end, sports: $sports) {
              site
              sports
              leagueId
              country
              leagueName
              leagueNameCustom
              season
              date_start
              date_end
              iscup
              live_lineups
              live_stats
              path
              is_women
              useYn
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          useYn: payload.useYn === false ? '' : 'N',
          date_end: payload.date_end === false ? '' : 'N',
          sports: payload.sports,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_LEAGUE, data.fetchGameSetLeague)
      console.log('FETCH_GAME_SET_LEAGUE : ', data.fetchGameSetLeague)
    },
    async [UPDATE_GAME_SET_LEAGUE]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchGameSetLeague)
      // console.log('payload : ', payload)
      const custom = state.updateGameSetLeague.map(item => ({
        site: payload.site,
        sports: item.sports,
        leagueId: item.leagueId,
        leagueNameCustom: item.leagueNameCustom,
        useYn: item.useYn,
      }))
      console.log('custom state : ', custom)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetLeague($gameSetLeagueInputArgs: [GameSetLeagueInputArgs!]!) {
            updateGameSetLeague(gameSetLeagueInputArgs: $gameSetLeagueInputArgs)
          }
        `,
        variables: {
          gameSetLeagueInputArgs: custom, // 변수 이름을 올바르게 수정합니다.
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_LEAGUE, data.updateGameSetLeague)
      console.log('UPDATE_GAME_SET_LEAGUE : ', data.updateGameSetLeague)
    },

    async [FETCH_GAME_SET_TEAM]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetTeam($site: String, $teamNameCustom: String, $searchOption: String, $searchOptionValue: String, $leagueId: String, $sports: String) {
            fetchGameSetTeam(site: $site, teamNameCustom: $teamNameCustom, searchOption: $searchOption, searchOptionValue: $searchOptionValue, leagueId: $leagueId, sports: $sports) {
              site
              sports
              country
              leagueId
              leagueName
              leagueNameCustom
              teamId
              teamName
              teamNameCustom
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          teamNameCustom: payload.teamNameCustom === false ? '' : 'N',
          searchOption: payload.searchOption,
          searchOptionValue: payload.searchOptionValue,
          leagueId: payload.leagueId,
          sports: payload.sports,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_TEAM, data.fetchGameSetTeam)
      console.log('FETCH_GAME_SET_TEAM : ', data.fetchGameSetTeam)
    },

    async [UPDATE_GAME_SET_TEAM]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchGameSetLeague)
      // console.log('payload : ', payload)
      const custom = state.updateGameSetTeam.map(item => ({
        site: payload.site,
        sports: item.sports,
        leagueId: item.leagueId,
        teamId: item.teamId,
        teamNameCustom: item.teamNameCustom,
      }))
      console.log('custom state : ', custom)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetTeam($gameSetTeamInputArgs: [GameSetTeamInputArgs!]!) {
            updateGameSetTeam(gameSetTeamInputArgs: $gameSetTeamInputArgs)
          }
        `,
        variables: {
          gameSetTeamInputArgs: custom, // 변수 이름을 올바르게 수정합니다.
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_TEAM, data.updateGameSetTeam)
      console.log('UPDATE_GAME_SET_TEAM : ', data.updateGameSetTeam)
    },

    async [CREATE_GAME_SET_TEAM]({ commit, state }, payload) {
      // console.log('payload : ', payload)

      console.log('payload : ', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation createGameSetTeam($gameSetLeagueInputArgs: GameSetLeagueInputArgs!) {
            createGameSetTeam(gameSetLeagueInputArgs: $gameSetLeagueInputArgs)
          }
        `,
        variables: {
          gameSetLeagueInputArgs: payload, // 변수 이름을 올바르게 수정합니다.
        },
        fetchPolicy: 'no-cache',
      })
      commit(CREATE_GAME_SET_TEAM, data.createGameSetTeam)
      console.log('CREATE_GAME_SET_TEAM : ', data.createGameSetTeam)
    },

    async [UPDATE_GAME_SET_TEAM_CUSTOM_NAME]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchGameSetLeague)
      // console.log('payload : ', payload)
      console.log('payload : ', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetTeamCustomName($gameSetLeagueInputArgs: GameSetLeagueInputArgs!) {
            updateGameSetTeamCustomName(gameSetLeagueInputArgs: $gameSetLeagueInputArgs)
          }
        `,
        variables: {
          gameSetLeagueInputArgs: payload, // 변수 이름을 올바르게 수정합니다.
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_TEAM_CUSTOM_NAME, data.updateGameSetTeamCustomName)
      console.log('UPDATE_GAME_SET_TEAM_CUSTOM_NAME : ', data.updateGameSetTeamCustomName)
    },

    async [FETCH_GAME_SET_ODDS_MARKET]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetOddsMarket($site: String, $useYn: String, $itemName: String, $menuName: String) {
            fetchGameSetOddsMarket(site: $site, useYn: $useYn, itemName: $itemName, menuName: $menuName) {
                idx  
                site
                type
                menuId
                menuName
                itemId
                itemName
                marketId
                marketName
                marketNameCustom
                useYn
                createdAt
                updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          useYn: payload.useYn === false ? '' : 'Y',
          itemName: payload.sports,
          menuName: payload.menuName,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_ODDS_MARKET, data.fetchGameSetOddsMarket)
      console.log('FETCH_GAME_SET_ODDS_MARKET : ', data.fetchGameSetOddsMarket)
    },

    async [UPDATE_GAME_SET_ODDS_MARKET]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchGameSetLeague)
      // console.log('payload : ', payload)
      const custom = state.updateGameSetOddsMarket.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        type: item.type,
        menuId: item.menuId,
        menuName: item.menuName,
        itemId: item.itemId,
        itemName: item.itemName,
        marketId: item.marketId,
        marketName: item.marketName,
        marketNameCustom: item.marketNameCustom,
        useYn: item.useYn,
      }))

      console.log(custom)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetOddsMarket($gameSetOddsMarketInputArgs: [GameSetOddsMarketInputArgs!]!, $userid: String!, $userIp: String!) {
            updateGameSetOddsMarket(gameSetOddsMarketInputArgs: $gameSetOddsMarketInputArgs, userid: $userid, userIp: $userIp)
          }
        `,
        variables: {
          gameSetOddsMarketInputArgs: custom,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_ODDS_MARKET, data.updateGameSetOddsMarket)
      console.log('UPDATE_GAME_SET_ODDS_MARKET : ', data.updateGameSetOddsMarket)
    },

    async [FETCH_GAME_SET_ODDS_CUSTOM]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetOddsCustom($site: String, $type: String) {
            fetchGameSetOddsCustom(site: $site, type: $type) {
              idx
              site
              type
              menuId
              menuName
              itemId
              itemName
              oddsType
              oddsTypeName
              oddsAddHome
              oddsAddDraw
              oddsAddAway
              oddsAddStraight
              oddsAddUnderDog
              oddsMinInvisible
              oddsMaxInvisible
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          type: payload.type,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_ODDS_CUSTOM, data.fetchGameSetOddsCustom)
      console.log('FETCH_GAME_SET_ODDS_CUSTOM : ', data.fetchGameSetOddsCustom)
    },

    async [UPDATE_GAME_SET_ODDS_CUSTOM]({ commit, state }, payload) {
      // console.log('update state : ', state)
      const custom = state.fetchGameSetOddsCustom.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        type: item.type,
        menuId: item.menuId,
        menuName: item.menuName,
        itemId: item.itemId,
        itemName: item.itemName,
        oddsType: item.oddsType,
        oddsTypeName: item.oddsTypeName,
        oddsAddHome: Number(item.oddsAddHome),
        oddsAddDraw: Number(item.oddsAddDraw),
        oddsAddAway: Number(item.oddsAddAway),
        oddsAddStraight: Number(item.oddsAddStraight),
        oddsAddUnderDog: Number(item.oddsAddUnderDog),
        oddsMinInvisible: Number(item.oddsMinInvisible),
        oddsMaxInvisible: Number(item.oddsMaxInvisible),
      }))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetOddsCustom($gameSetOddsCustomInputArgs: [GameSetOddsCustomInputArgs!]!, $userid: String!, $userIp: String!) {
            updateGameSetOddsCustom(gameSetOddsCustomInputArgs: $gameSetOddsCustomInputArgs, userid: $userid, userIp: $userIp)
          }
        `,
        variables: {
          gameSetOddsCustomInputArgs: custom,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_ODDS_CUSTOM, data.updateGameSetOddsCustom)
      console.log('UPDATE_GAME_SET_ODDS_CUSTOM : ', data.updateGameSetOddsCustom)
    },

    // fetchGameCombination
    async [FETCH_GAME_SET_COMBINATION]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
        query fetchGameSetCombination($gameSetCombinationInputArgs: GameSetCombinationInputArgs!) {
          fetchGameSetCombination(gameSetCombinationInputArgs: $gameSetCombinationInputArgs) {
            idx
            site
            menuCode
            sportCode
            combination
            alertMessage
            allow
            useYn
            updatedAt
            createdAt
          }
        }

        `,
        variables: {
          gameSetCombinationInputArgs: {
            site: payload.site,
            menuCode: payload.menuName,
            sportCode: payload.sports,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_COMBINATION, data.fetchGameSetCombination)
      console.log('FETCH_GAME_COMBINATION : ', data.fetchGameSetCombination)
    },

    async [UPDATE_GAME_SET_COMBINATION]({ commit }, payload) {
      console.log('UPDATE_GAME_SET_COMBINATION', payload)
      await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetCombination($gameSetCombinationInputArgs: GameSetCombinationInputArgs!) {
            updateGameSetCombination(gameSetCombinationInputArgs: $gameSetCombinationInputArgs) {
              idx
              site
              menuCode
              sportCode
              combination
              alertMessage
              allow
              useYn
              updatedAt
              createdAt
            }
          }
        `,
        variables: {
          gameSetCombinationInputArgs: {
            site: payload.site,
            menuCode: payload.menu,
            sportCode: payload.sport,
            allow: payload.allow,
            combination: payload.marketId,
            alertMessage: payload.marketDesc,
            useYn: payload.marketUse,
          },
        },
      })
    },
    async [DELETE_GAME_SET_COMBINATION]({ commit }, payload) {
      // console.log('UPDATE_GAME_SET_COMBINATION', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation DeleteGameSetCombination($gameSetCombinationInputArgs: GameSetCombinationInputArgs!) {
            deleteGameSetCombination(gameSetCombinationInputArgs: $gameSetCombinationInputArgs)
          }
        `,
        variables: {
          gameSetCombinationInputArgs: {
            site: payload.site,
            menuCode: payload.menu,
            sportCode: payload.sport,
            combination: payload.marketId,
          },
        },
      })
      console.log('DELETE_GAME_SET_COMBINATION', data.deleteGameSetCombination)
    },

    async [FETCH_CUSTOM_MATCH_BY_ADMIN]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchCustomMatchByAdmin($site: String, $periodFrom: String, $periodTo: String, $useYn: String) {
            fetchCustomMatchByAdmin(site: $site, periodFrom: $periodFrom, periodTo: $periodTo, useYn: $useYn) {
              idx
              site
              sports
              category
              gameId
              gameDate
              country
              leagueId
              leagueName
              leagueNameCustom
              homeId
              homeName
              homeNameCustom
              awayId
              awayName
              awayNameCustom
              oddsType
              oddsId
              oddsHome
              oddsDraw
              oddsAway
              handicap
              homeTeamResult
              awayTeamResult
              status
              confirmed
              enrollId
              enrollIp
              enrollAt
              useYn
              uid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          useYn: payload.useYn,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_CUSTOM_MATCH_BY_ADMIN, data.fetchCustomMatchByAdmin)
      console.log('FETCH_CUSTOM_MATCH_BY_ADMIN : ', data.fetchCustomMatchByAdmin)
    },

    async [CREATE_MANY_CUSTOM_MATCH_BY_ADMIN]({ commit, state }, payload) {
      console.log('update state : ', payload)
      const custom = payload.map(item => ({
        site: item.site,
        sports: item.itemName,
        category: item.menuName,
        oddsType: item.oddsType,
        country: item.country,
        leagueId: item.leagueId,
        leagueName: item.leagueName,
        leagueNameCustom: item.leagueNameCustom,
        homeId: item.homeId,
        homeName: item.homeName,
        homeNameCustom: item.homeNameCustom,
        awayId: item.awayId,
        awayName: item.awayName,
        awayNameCustom: item.awayNameCustom,
        oddsHome: Number(item.oddsHome),
        oddsDraw: Number(item.oddsDraw),
        oddsAway: Number(item.oddsAway),
        handicap: item.handicap,
        gameDate: item.gameDate,
      }))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation createManyCustomMatchByAdmin($customMatchByAdminInputArgs: [CustomMatchByAdminInputArgs!]!) {
            createManyCustomMatchByAdmin(customMatchByAdminInputArgs: $customMatchByAdminInputArgs)
          }
        `,
        variables: {
          customMatchByAdminInputArgs: custom,
        },
        fetchPolicy: 'no-cache',
      })
      commit(CREATE_MANY_CUSTOM_MATCH_BY_ADMIN, data.createManyCustomMatchByAdmin)
      console.log('CREATE_MANY_CUSTOM_MATCH_BY_ADMIN : ', data.createManyCustomMatchByAdmin)
    },

    async [UPDATE_CUSTOM_MATCH_BY_ADMIN]({ commit, state }, payload) {
      console.log('update payload : ', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateCustomMatchByAdmin($customMatchByAdminInputArgs: CustomMatchByAdminInputArgs!) {
            updateCustomMatchByAdmin(customMatchByAdminInputArgs: $customMatchByAdminInputArgs) {
              site
              oddsId
              useYn
              homeTeamResult
              awayTeamResult
            }
          }
        `,
        variables: {
          customMatchByAdminInputArgs: {
            site: payload.site,
            oddsId: payload.oddsId,
            useYn: payload.useYn,
            homeTeamResult: payload.homeTeamResult,
            awayTeamResult: payload.awayTeamResult,
            status: payload.status,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_CUSTOM_MATCH_BY_ADMIN, data.updateCustomMatchByAdmin)
      console.log('UPDATE_CUSTOM_MATCH_BY_ADMIN : ', data.updateCustomMatchByAdmin)
    },

    async [UPDATE_CUSTOM_RESULT_CONFIRM]({ commit, state }, payload) {
      console.log('update payload : ', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateCustomResultConfirm($customMatchByAdminInputArgs: CustomMatchByAdminInputArgs!) {
            updateCustomResultConfirm(customMatchByAdminInputArgs: $customMatchByAdminInputArgs) {
              site
              oddsId
              useYn
              homeTeamResult
              awayTeamResult
              confirmed
            }
          }
        `,
        variables: {
          customMatchByAdminInputArgs: {
            site: payload.site,
            oddsId: payload.oddsId,
            useYn: payload.useYn,
            homeTeamResult: payload.homeTeamResult,
            awayTeamResult: payload.awayTeamResult,
            confirmed: payload.confirmed,
            status: payload.status,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_CUSTOM_RESULT_CONFIRM, data.updateCustomResultConfirm)
      console.log('UPDATE_CUSTOM_RESULT_CONFIRM : ', data.updateCustomResultConfirm)
    },

    async [UPSERT_GAME_SET_MATCH_BETTING_CUSTOM]({ commit, state }, payload) {
      console.log('update payload : ', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertGameSetMatchBettingCustom($gameSetMatchBettingCustomInputArgs: GameSetMatchBettingCustomInputArgs!) {
            upsertGameSetMatchBettingCustom(gameSetMatchBettingCustomInputArgs: $gameSetMatchBettingCustomInputArgs)
          }
        `,
        variables: {
          gameSetMatchBettingCustomInputArgs: {
            site: payload.site,
            menuName: payload.menuName,
            itemName: payload.itemName,
            gameId: payload.gameId,
            gameDate: payload.gameDate,
            oddsTypeId: payload.oddsTypeId,
            oddsTypeName: payload.oddsTypeName,
            leagueName: payload.leagueName,
            homeTeamName: payload.homeTeamName,
            awayTeamName: payload.awayTeamName,
            oddsAddHome: payload.oddsAddHome,
            oddsAddDraw: payload.oddsAddDraw,
            oddsAddAway: payload.oddsAddAway,
            handicap: payload.handicap,
            isStopAPI: payload.isStopAPI,
            isHideAPI: payload.isHideAPI,
            isClosedAPI: payload.isClosedAPI,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_GAME_SET_MATCH_BETTING_CUSTOM, data.upsertGameSetMatchBettingCustom)
      console.log('UPSERT_GAME_SET_MATCH_BETTING_CUSTOM : ', data.upsertGameSetMatchBettingCustom)
    },

    async [FETCH_GAME_SET_MATCH_BETTING_CUSTOM]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetMatchBettingCustom($site: String, $itemName: String) {
            fetchGameSetMatchBettingCustom(site: $site, itemName: $itemName) {
              idx
              site
              menuName
              itemName
              gameId
              gameDate
              oddsTypeId
              oddsTypeName
              leagueName
              homeTeamName
              awayTeamName
              oddsAddHome
              oddsAddDraw
              oddsAddAway
              handicap
              isStopAPI
              isHideAPI
              isClosedAPI
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          itemName: payload.sports,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_MATCH_BETTING_CUSTOM, data.fetchGameSetMatchBettingCustom)
      console.log('FETCH_GAME_SET_MATCH_BETTING_CUSTOM : ', data.fetchGameSetMatchBettingCustom)
    },

    // 벤더 설정 조회
    async [FETCH_GAME_SET_VENDOR]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameSetVendor($site: String, $category: String, $useYn: String) {
            fetchGameSetVendor(site: $site, category: $category, useYn: $useYn) {
              site
              category
              vendorName
              vendorNameCustom
              useYn
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          category: payload.category,
          useYn: payload.useYn === false ? '' : 'Y',
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_GAME_SET_VENDOR, data.fetchGameSetVendor)
      console.log('FETCH_GAME_SET_VENDOR : ', data.fetchGameSetVendor)
    },
    // 벤더 설정 업데이트
    async [UPDATE_GAME_SET_VENDOR]({ commit, state }, payload) {
      console.log('update state : ', state.fetchGameSetLeague)
      console.log('payload : ', payload)
      const custom = state.updateGameSetVendor.map(item => ({
        site: payload.site,
        category: item.category,
        vendorName: item.vendorName,
        vendorNameCustom: item.vendorNameCustom,
        useYn: item.useYn,
      }))

      console.log(custom)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateGameSetVendor($gameSetVendorInputArgs: [GameSetVendorInputArgs!]!) {
            updateGameSetVendor(gameSetVendorInputArgs: $gameSetVendorInputArgs)
          }
        `,
        variables: {
          gameSetVendorInputArgs: custom, // 변수 이름을 올바르게 수정합니다.
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_GAME_SET_VENDOR, data.updateGameSetVendor)
      console.log('UPDATE_GAME_SET_VENDOR : ', data.updateGameSetVendor)
    },
    async [FETCH_GAME_CASINO_TRANSACTION]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchGameCasinoTransaction($site: String, $username: String, $periodFrom: String, $periodTo: String, $type: String, $searchOption: String, $inputValue: String) {
            fetchGameCasinoTransaction(site: $site, username: $username, periodFrom: $periodFrom, periodTo: $periodTo, type: $type, searchOption: $searchOption, inputValue: $inputValue) {
              idx
              site
              id
              type
              username
              amount
              refererId
              processedAt
              targetId
              targetUsername
              targetBalance
              detailId
              round
              title
              detailType
              vendor
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          username: payload.username,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          type: payload.type,
          searchOption: payload.searchOption,
          inputValue: payload.inputValue,
        },
        fetchPolicy: 'no-cache',
      })
      const modifiedData = data.fetchGameCasinoTransaction.map(item => ({
        ...item,
        username: item.username ? item.username.slice(3) : '', // 앞 3자 제거
      }))

      commit(FETCH_GAME_CASINO_TRANSACTION, modifiedData)
      console.log('FETCH_GAME_CASINO_TRANSACTION : ', modifiedData)
    },
  },
}

export default gameStore
