import { createNamespacedHelpers } from 'vuex'
import axios from "axios";

const authStore = createNamespacedHelpers('authStore')
const settingsStore = createNamespacedHelpers('settingsStore')

const rootStore = {
  namespaced: true,
  state: {
    initialized: false,
    token: null, // 초기 토큰 저장
    mobile: null, // MFA 인증 번호
    userData: [], // 유저 데이터
    authSite: [], // 멀티 사이트 권한
    siteSelected: '', // 선택된 멀티 사이트
    setBasic: [],
    menuPermission: [], // 메뉴 권한
    userView: [],
    ip: '',
  },
  getters: {
    isInitialized: state => state.initialized,
    isAuthenticated: state => state.token,
    mobile: state => state.mobile,
    userData: state => state.userData,
    authSite: state => state.authSite,
    siteSelected: state => state.siteSelected,
    setBasic: state => state.setBasic,
    menuPermission: state => state.menuPermission,
    userView: state => state.userView,
    ip: state => state.ip,
  },
  mutations: {
    SET_INITIALIZED(state, status) {
      state.initialized = status
    },
    SET_TOKEN(state, token) {
      state.token = token
    },
    SET_MOBILE(state) {
      state.mobile = state.userData.mobile
    },
    SET_USER_DATA(state, userData) {
      state.userData = userData
    },
    SET_AUTH_SITE(state, authSite) {
      state.authSite = authSite
    },
    SET_SITE_SELECTED(state, siteSelected) {
      state.siteSelected = siteSelected
    },
    SET_BASIC(state, setBasic) {
      state.setBasic = setBasic
    },
    SET_MENU_PERMISSION(state, menuPermission) {
      state.menuPermission = menuPermission
    },
    SET_USER_VIEW(state, userView) {
      state.userView = userView
    },
    SET_IP(state, ip) {
      state.ip = ip
    },
  },
  actions: {
    async fetchInitialize({ commit, rootGetters, dispatch, state }) {
      try {
        const token = rootGetters['authStore/token']
        if (!token) {
          commit('SET_INITIALIZED', true) // 토큰이 없으면 초기화 완료로 간주
          return
        }

        // 토큰 설정 대기
        await new Promise(resolve => {
          const interval = setInterval(() => {
            const getToken = rootGetters['authStore/token']
            if (getToken) {
              clearInterval(interval)
              resolve(getToken)
            }
          }, 100)
        })

        // 2. Vuex에 token 저장
        commit('SET_TOKEN', token)

        // 3. ip 정보 저장
        const response = await axios.get('https://api.ipregistry.co?key=cwmdadv8au7nkq34')
        const { ip } = response.data
        await commit('SET_IP', ip)

        // 4. 토큰을 통해 사용자 데이터 요청
        await dispatch('authStore/VALIDATE_TOKEN', null, { root: true })
        // 5. AUTH SITE 데이터 요청
        await dispatch('authStore/VALIDATE_AUTH_SITE', state.userData.userid, { root: true })

        // 6. 기본 선택 사이트값 지정
        await commit('SET_SITE_SELECTED', state.authSite[0])

        // 7. BASIC 데이터 요청
        await dispatch('settingsStore/FETCH_SET_BASIC', state.authSite, { root: true })
        // 8. PERMISSION 데이터 요청
        await dispatch('settingsStore/MENU_SET_SITE_ADMIN_MENU_PERMISSION', state.userData, { root: true })

        // LAST. 초기화 완료 상태 설정
        commit('SET_INITIALIZED', true)
      } catch (error) {
        console.error('Failed to fetch global data:', error)
      }
    },
  },
}

export default rootStore
