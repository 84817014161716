<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.nickname }}
        </p>
        <span class="user-status">{{ userData.role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_rank/` + userData.rank + `.png`"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
<!--        <feather-icon-->
<!--          v-if="!userData.fullName"-->
<!--          icon="UserIcon"-->
<!--          size="22"-->
<!--        />-->
      </b-avatar>
    </template>

<!--    <b-dropdown-item-->
<!--      :to="{ name: 'notice-admin'}"-->
<!--      link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--        size="16"-->
<!--        icon="BookOpenIcon"-->
<!--        class="mr-50"-->
<!--      />-->
<!--      <span>관리자 공지사항</span>-->
<!--    </b-dropdown-item>-->
<!--    <b-dropdown-item-->
<!--      :to="{ name: 'apps-message' }"-->
<!--      link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--        size="16"-->
<!--        icon="MailIcon"-->
<!--        class="mr-50"-->
<!--      />-->
<!--      <span>쪽지함</span>-->
<!--    </b-dropdown-item>-->

<!--    <b-dropdown-divider />-->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>로그아웃</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { LOGOUT } from '@/store/auth/action'

import { createNamespacedHelpers } from 'vuex'
const authStore = createNamespacedHelpers('authStore')

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      // avatarText,
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
  },
  methods: {
    ...authStore.mapActions({
      logout: LOGOUT,
    }),
  },
  // methods: {
  //   logout() {
  //     // Remove userData from localStorage
  //     // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
  //     localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
  //     localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
  //
  //     // Remove userData from localStorage
  //     localStorage.removeItem('userData')
  //
  //     // Reset ability
  //     this.$ability.update(initialAbility)
  //
  //     // Redirect to login page
  //     this.$router.push({ name: 'auth-login' })
  //   },
  // },
}
</script>
