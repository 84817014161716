<template>

  <!-- 1.기본설정 -->
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          기본 설정
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            DB 연계
          </b-badge>
        </h4>
      </span>
      <hr class="pb-1">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="사이트 명"
              label-for="site-name"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetBasic"
                id="site-name"
                :value="fetchSetBasic.site"
                class="font-small-3"
                size="sm"
                placeholder="사이트 명"
                style="color:rgb(229,166,48)"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="사이트 상태"
              label-for="site-status"
              label-cols-md="4"
            >
              <b-form-select
                v-if="fetchSetBasic"
                id="site-status"
                :value="fetchSetBasic.siteStatus"
                class="font-small-3"
                size="sm"
                placeholder="사이트 상태"
                style="color:rgb(229,166,48)"
                disabled
                :options="options3"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3 d-flex align-items-center"
              label="게임 사용 상태"
              label-for="game-status"
              label-cols-md="2"
            >
              <div v-if="fetchSetBasic" class="d-flex flex-wrap gap-4">
                <b-badge
                  :variant="fetchSetBasic.sports === 'Y' ? 'primary' : 'dark'"
                  class="font-small-3"
                  style="margin-right: 0.5rem;"
                  size="sm"
                >
                  카지노
                </b-badge>
                <b-badge
                  :variant="fetchSetBasic.casino === 'Y' ? 'primary' : 'dark'"
                  class="font-small-3"
                  style="margin-right: 0.5rem;"
                  size="sm"
                >
                  스포츠
                </b-badge>
                <b-badge
                  :variant="fetchSetBasic.slot === 'Y' ? 'primary' : 'dark'"
                  class="font-small-3"
                  style="margin-right: 0.5rem;"
                  size="sm"
                >
                  슬롯
                </b-badge>
                <b-badge
                  :variant="fetchSetBasic.minigame === 'Y' ? 'primary' : 'dark'"
                  class="font-small-3"
                  style="margin-right: 0.5rem;"
                  size="sm"
                >
                  미니게임
                </b-badge>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3"
              label="메인 공지사항"
              label-for="main-notice"
              label-cols-md="2"
            >
              <b-form-textarea
                v-if="fetchSetBasic"
                id="main-notice"
                :value="fetchSetBasic.mainNotice"
                class="font-small-3"
                size="sm"
                placeholder="메인 공지사항"
                style="color:rgb(229,166,48)"
                rows="6"
                @input="updateParam('fetchSetBasic', 'mainNotice', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3"
              label="사이트 점검 메세지"
              label-for="notice-maintenanceNotice"
              label-cols-md="2"
            >
              <b-form-textarea
                v-if="fetchSetBasic"
                id="notice-maintenanceNotice"
                :value="fetchSetBasic.maintenanceNotice"
                class="font-small-3"
                size="sm"
                placeholder="사이트 점검 메세지"
                style="color:rgb(229,166,48)"
                rows="4"
                @input="updateParam('fetchSetBasic','maintenanceNotice', $event)"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col
            id="basic1-btn"
            class="d-flex justify-content-end"
            cols="12"
          >
            <b-button
              ref="submit"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submit('updateSetBasic')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!--2.회원가입 설정-->
    <b-card>
      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          회원가입 설정
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            DB 연계
          </b-badge>
        </h4>
      </span>
      <hr class="pb-1">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="회원가입 방식"
              label-for="signup-type"
              label-cols-md="4"
            >
              <b-form-select
                v-if="fetchSetSignup"
                id="signup-type"
                :value="fetchSetSignup.signupType"
                :options="options2"
                class="font-small-3"
                size="sm"
                placeholder="회원가입 방식"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignup','signupType', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="추천인 제도사용"
              label-for="signup-referral"
              label-cols-md="4"
            >
              <b-form-select
                v-if="fetchSetSignup"
                id="signup-referral"
                :value="fetchSetSignup.signupReferral"
                :options="options1"
                class="font-small-3"
                size="sm"
                placeholder="추천인 제도사용"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignup','signupReferral', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="가입시 포인트지급"
              label-for="signup-point"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetSignup"
                id="signup-point"
                :value="fetchSetSignup.signupPoint"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="5000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignup','signupPoint', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3"
              label="가입 환영쪽지"
              label-for="notice-signup"
              label-cols-md="2"
            >
              <b-form-textarea
                v-if="fetchSetSignup"
                id="notice-signup"
                :value="fetchSetSignup.signupNotice"
                class="font-small-3"
                size="sm"
                placeholder="가입 환영쪽지"
                style="color:rgb(229,166,48)"
                rows="5"
                @input="updateParam('fetchSetSignup','signupNotice', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3"
              label="가입 금지단어(아이디/별명)"
              label-for="signup-prohibit"
              label-cols-md="2"
            >
              <b-form-tags
                id="signup-prohibit"
                :value="signupProhibits"
                class="font-small-3"
                size="sm"
                placeholder="(입력후 Enter키 추가 / Delete키 삭제)"
                remove-on-delete
                :input-attrs="{ 'aria-describedby': 'tags-remove-on-delete-help' }"
                @input="signupProhibitsInput($event)"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col
            id="basic2-btn"
            class="d-flex justify-content-end"
            cols="12"
          >
            <b-button
              ref="submit"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submit('updateSetSignup')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!--3.로그인 설정-->
    <b-card>
      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          로그인 설정
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            DB 연계
          </b-badge>
        </h4>
      </span>
      <hr class="pb-1">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="중복로그인 허용"
              label-for="login-dup"
              label-cols-md="4"
            >
              <b-form-select
                v-if="fetchSetSignin"
                id="login-dup"
                :value="fetchSetSignin.signinDup"
                :options="options1"
                class="font-small-3"
                size="sm"
                placeholder="사용"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignin','signinDup', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
<!--            <b-badge variant="primary" style="font-size: xx-small">OK</b-badge>-->
            <b-form-group
              class="font-small-3"
              label="자동 로그아웃(분)"
              label-for="login-timeout"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetSignin"
                id="login-timeout"
                :value="fetchSetSignin.signinTimeout"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="120"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignin','signinTimeout', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="봇방지 캡차 사용(CAPTCHA)"
              label-for="login-captcha"
              label-cols-md="4"
            >
              <b-form-select
                v-if="fetchSetSignin"
                id="login-captcha"
                :value="fetchSetSignin.signinCaptcha"
                :options="options1"
                class="font-small-3"
                size="sm"
                placeholder="사용"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignin','signinCaptcha', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="휴먼계정 자동전환(일)"
              label-for="sleep-account"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetSignin"
                id="sleep-account"
                :value="fetchSetSignin.sleepAccount"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="90"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetSignin','sleepAccount', $event)"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col
            id="basic3-btn"
            class="d-flex justify-content-end"
            cols="12"
          >
            <b-button
              ref="submit"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submit('updateSetSignin')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!--4.충/환전설정-->
    <b-card>
      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          충/환전 설정
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            완료
          </b-badge>
        </h4>
      </span>
      <hr class="pb-1">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="충전 최소금액"
              label-for="deposit-min"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="deposit-min"
                :value="fetchSetDepositExchange.depositMin"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="10000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','depositMin', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="충전 금액단위"
              label-for="deposit-amout"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="deposit-amount"
                :value="fetchSetDepositExchange.depositAmount"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="1000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','depositAmount', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="충전 신청 제한주기(분)"
              label-for="deposit-interval"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="deposit-interval"
                :value="fetchSetDepositExchange.depositInterval"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="1"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','depositInterval', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3"
              label="충전 공지사항"
              label-for="deposit-notice"
              label-cols-md="2"
            >
              <b-form-textarea
                v-if="fetchSetDepositExchange"
                id="deposit-notice"
                :value="fetchSetDepositExchange.depositNotice"
                class="font-small-3"
                size="sm"
                placeholder="충전 공지사항"
                style="color:rgb(229,166,48)"
                rows="3"
                @input="updateParam('fetchSetDepositExchange','depositNotice', $event)"
              />
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="환전 최소금액"
              label-for="exchange-min"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="exchange-min"
                :value="fetchSetDepositExchange.exchangeMin"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="10000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','exchangeMin', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="환전 금액단위"
              label-for="exchange-amount"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="exchange-amount"
                :value="fetchSetDepositExchange.exchangeAmount"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="1000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','exchangeAmount', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="환전 신청 제한주기(분)"
              label-for="exchange-interval"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="exchange-interval"
                :value="fetchSetDepositExchange.exchangeInterval"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="1"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','exchangeInterval', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="환전 최대금액"
              label-for="exchange-max"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetDepositExchange"
                id="exchange-max"
                :value="fetchSetDepositExchange.exchangeMax"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="3000000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetDepositExchange','exchangeMax', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              class="font-small-3"
              label="환전 공지사항"
              label-for="exchange-notice"
              label-cols-md="2"
            >
              <b-form-textarea
                v-if="fetchSetDepositExchange"
                id="exchange-notice"
                :value="fetchSetDepositExchange.exchangeNotice"
                class="font-small-3"
                size="sm"
                placeholder="환전 공지사항"
                style="color:rgb(229,166,48)"
                rows="3"
                @input="updateParam('fetchSetDepositExchange','exchangeNotice', $event)"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col
            id="basic4-btn"
            class="d-flex justify-content-end"
            cols="12"
          >
            <b-button
              ref="submit"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submit('updateSetDepositExchange')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

    <!--5.기타설정-->
    <b-card>
      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          기타 설정
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            DB 연계
          </b-badge>
        </h4>
      </span>
      <hr class="pb-1">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="쪽지 자동삭제(일)"
              label-for="massage-delete"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetEtc"
                id="massage-delete"
                :value="fetchSetEtc.messageDelete"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="1"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetEtc','messageDelete', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="font-small-3"
              label="포인트전환 최소금액"
              label-for="point-min-exchange"
              label-cols-md="4"
            >
              <b-form-input
                v-if="fetchSetEtc"
                id="point-min-exchange"
                :value="fetchSetEtc.pointMinExchange"
                class="font-small-3"
                size="sm"
                type="number"
                placeholder="3000"
                style="color:rgb(229,166,48)"
                @input="updateParam('fetchSetEtc','pointMinExchange', $event)"
              />
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col
            id="basic4-btn"
            class="d-flex justify-content-end"
            cols="12"
          >
            <b-button
              ref="submit"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submit('updateSetEtc')"
            >
              저장
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>

  </div>

</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTags,
  BFormTextarea,
  BRow,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import {
  FETCH_SET_BASIC,
  FETCH_SET_DEPOSIT_EXCHANGE,
  FETCH_SET_ETC,
  FETCH_SET_SIGNIN,
  FETCH_SET_SIGNUP,
  FETCH_SET_SIGNUP_PROHIBITS,
} from '@/store/settings/action'
import {
  UPDATE_SET_SIGNUP,
  UPDATE_SET_BASIC,
  UPDATE_SET_DEPOSIT_EXCHANGE,
  UPDATE_SET_ETC,
  UPDATE_SET_SIGNIN,
  CREATE_MANY_SET_SIGNUP_PROHIBITS,
  DELETE_SET_SIGNUP_PROHIBITS,
} from '@/store/settings/mutation'
import { createNamespacedHelpers } from 'vuex'
import arrayToObject from 'vuex-map-fields/src/lib/array-to-object'

const settingsStore = createNamespacedHelpers('settingsStore')
import AppFooter from "@core/layouts/components/AppFooter";

export default {
  components: {
    BRow,
    BCard,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormTextarea,
    BFormSelect,
    BFormTags,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      options1: [
        { value: 'true', text: '사용' },
        { value: 'false', text: '사용안함' },
      ],
      options2: [
        { value: '1', text: '1차 승인' },
        { value: '2', text: '2차 승인' },
      ],
      options3: [
        { value: 'operation', text: '운영중' },
        { value: 'maintenance', text: '점검중' },
        { value: 'shutdown', text: '사이트 중단' },
      ],
      // signupProhibits: [
      //   { value: this.fetchSetSignupProhibits.signupProhibits },
      // ],
      info: {
        signupProhibitsArray: [],
        // 기본 설정
        // site: '1',
        // siteStatus: '1',
        // mainNotice: '1',
        // maintenanceNotice: '1',
        // 회원가입 설정
        // signupType: '관리자 승인후 가입',
        // signupPoint: 5000,
        // signupReferral: 'Y',
        // signupNotice: '안녕하세요 운영팀입니다.\n'
        //     + '가입을 환영합니다. 즐겁고 공정한 베팅을 하실수 있도록 최선을 다하겠습니다.\n',
        // signupProhibits: [],
        // 로그인 설정
        // signinDup: 'N',
        // signinTimeout: 120,
        // signinCaptcha: 'Y',
        // sleepAccount: 90,
        // 충/환전 설정
        // depositMin: '50000',
        // depositAmount: '10000',
        // depositInterval: '1',
        // depositNotice: '입금 신청후 운영자가 확인하면 입금계좌가 본 자리에 표시됩니다.',
        // exchangeMin: '50000',
        // exchangeAmount: '10000',
        // exchangeInterval: '1',
        // exchangeNotice: '환전 신청 후, 입금까지 최대 20분 소요될 수 있습니다.',
        // exchangeMax: '3000000',
        // 기타 설정
        massageDelete: 7,
        pointMinExchange: 5000,
      },
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...settingsStore.mapGetters({
      fetchSetBasic: 'fetchSetBasic',
      fetchSetSignin: 'fetchSetSignin',
      fetchSetDepositExchange: 'fetchSetDepositExchange',
      fetchSetEtc: 'fetchSetEtc',
      fetchSetSignup: 'fetchSetSignup',
      fetchSetSignupProhibits: 'fetchSetSignupProhibits',
    }),
    settingsStore() {
      // return this.$store.state.settingsStore.fetchSetSignupProhibits
      return this.$store.state.settingsStore.fetchSetSignupProhibits.map(row => row.signupProhibits)
    },
    signupProhibits() {
      const data = this.$store.state.settingsStore.fetchSetSignupProhibits.map(row => row.signupProhibits)
      this.signupProhibitsInput(data)
      return data
    },
  },
  watch: {

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...settingsStore.mapActions({
      $fetchSetBasic: FETCH_SET_BASIC,
      $updateSetBasic: UPDATE_SET_BASIC,
      $fetchSetSignin: FETCH_SET_SIGNIN,
      $updateSetSignin: UPDATE_SET_SIGNIN,
      $fetchSetDepositExchange: FETCH_SET_DEPOSIT_EXCHANGE,
      $updateSetDepositExchange: UPDATE_SET_DEPOSIT_EXCHANGE,
      $fetchSetEtc: FETCH_SET_ETC,
      $updateSetEtc: UPDATE_SET_ETC,
      $fetchSetSignup: FETCH_SET_SIGNUP,
      $fetchSetSignupProhibits: FETCH_SET_SIGNUP_PROHIBITS,
      $updateSetSignup: UPDATE_SET_SIGNUP,
      $createManySetSignupProhibits: CREATE_MANY_SET_SIGNUP_PROHIBITS,
      $deleteSetSignupProhibits: DELETE_SET_SIGNUP_PROHIBITS,
    }),
    updateParam(t, e, v) {
      // console.log(t)
      // console.log(e)
      // console.log(v)
      const events = { t, e, v }
      // this.info[e] = v
      // store에 param을 update 한다
      this.$store.commit('settingsStore/updateParam', events)
      // console.log(this.$store.mainNotice('settingsStore/mainNotice'))
    },
    signupProhibitsInput(v) {
      console.log(v)
      this.info.signupProhibitsArray = v
    },
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSetBasic({
        site: mySite,
      })
      await this.$fetchSetSignin({
        site: mySite,
      })
      await this.$fetchSetDepositExchange({
        site: mySite,
      })
      await this.$fetchSetEtc({
        site: mySite,
      })
      await this.$fetchSetSignup({
        site: mySite,
      })
      await this.$fetchSetSignupProhibits({
        site: mySite,
      })
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    confirmYes(target) {
      const mySite = this.siteSelected
      try {
        if (target === 'updateSetBasic') {
          this.$updateSetBasic({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
        if (target === 'updateSetSignup') {
          this.$updateSetSignup({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
          // this.$deleteSetSignupProhibits({
          //   site: mySite,
          //   userid: this.userData.userid,
          //   userIp: this.userIp,
          // })
          this.$createManySetSignupProhibits({
            site: mySite,
            signupProhibits: this.info.signupProhibitsArray, // 배열값을 param 전송.
            userid: this.userData.userid,
            userIp: this.userIp,
          })
        }
        if (target === 'updateSetSignin') {
          this.$updateSetSignin({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
        if (target === 'updateSetDepositExchange') {
          this.$updateSetDepositExchange({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
        if (target === 'updateSetEtc') {
          this.$updateSetEtc({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
          this.isHidden1 = true
          this.check1 = 'false'
          this.isHidden2 = true
          this.check2 = 'false'
          this.isHidden2 = true
          this.check2 = 'false'
          this.isHidden3 = true
          this.check3 = 'false'
          this.isHidden4 = true
          this.check4 = 'false'
          this.isHidden5 = true
          this.check5 = 'false'
        })
      }
    },
  },

}
</script>

<style scoped>
/*.width-size {*/
/*  width: 20rem;*/
/*}*/
</style>
