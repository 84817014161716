import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  INSERT_USER_SIGNIN_LOGS,
  TRIGGER_TASK,
  INSERT_LOGS_ADMIN,
  INSERT_LOGS_USERS,
  INSERT_LOGS_USERS_SIGNIN,
  TRIGGER_TASK_NEW_SITE,
} from '@/store/logs/mutation'
import {
  FETCH_SIGNIN_LOGS,
  SIGNIN_LOGS,
  FETCH_SET_BATCH_SCHEDULE_LOGS,
  FETCH_SET_BATCH_SCHEDULE_TASKS,
  FETCH_APPROVAL_LOGS,
  FETCH_LOGS_ADMIN,
  FETCH_LOGS_USERS,
  FETCH_LOGS_USERS_SIGNIN,
  FETCH_LOGS_BATCH,
  FETCH_LOGS_ADMIN_VUE,
  FETCH_LOGS_USERS_VUE,
  FETCH_LOGS_USERS_DETAIL_POINT,
  FETCH_LOGS_USERS_DETAIL_INFO,
} from '@/store/logs/action'

const logStore = {
  namespaced: true,
  state: () => ({
    userSigninLogs: [],
    fetchSigninLogs: [],
    fetchSetBatchScheduleLogs: [],
    fetchSetBatchScheduleTasks: [],
    triggerTask: [],
    triggerTaskNewSite: [],
    fetchApprovalLogs: [],
    fetchLogsAdmin: [],
    fetchLogsUsers: [],
    fetchLogsAdminVue: [],
    fetchLogsUsersVue: [],
    fetchLogsUsersDetailPoint: [],
    fetchLogsUsersDetailInfo: [],
    fetchLogsUsersSignin: [],
    fetchLogsBatch: [],
    insertLogsAdmin: [],
    insertLogsUsers: [],
    insertLogsUsersSignin: [],
  }),
  getters: {
    userSigninLogs: state => state.userSigninLogs,
    fetchSigninLogsItems: state => state.fetchSigninLogs,
    fetchSetBatchScheduleLogs: state => state.fetchSetBatchScheduleLogs,
    fetchSetBatchScheduleTasks: state => state.fetchSetBatchScheduleTasks,
    triggerTask: state => state.triggerTask,
    triggerTaskNewSite: state => state.triggerTaskNewSite,
    fetchApprovalLogs: state => state.fetchApprovalLogs,
    fetchLogsAdmin: state => state.fetchLogsAdmin,
    fetchLogsUsers: state => state.fetchLogsUsers,
    fetchLogsAdminVue: state => state.fetchLogsAdminVue,
    fetchLogsUsersVue: state => state.fetchLogsUsersVue,
    fetchLogsUsersDetailPoint: state => state.fetchLogsUsersDetailPoint,
    fetchLogsUsersDetailInfo: state => state.fetchLogsUsersDetailInfo,
    fetchLogsUsersSignin: state => state.fetchLogsUsersSignin,
    fetchLogsBatch: state => state.fetchLogsBatch,
    insertLogsAdmin: state => state.insertLogsAdmin,
    insertLogsUsers: state => state.insertLogsUsers,
    insertLogsUsersSignin: state => state.insertLogsUsersSignin,
  },
  mutations: {
    [INSERT_USER_SIGNIN_LOGS](state, userSigninLogs) {
      // state.userSigninLogs.push(userSigninLogs)
      state.userSigninLogs = userSigninLogs
    },
    [FETCH_SIGNIN_LOGS](state, fetchSigninLogs) {
      state.fetchSigninLogs = fetchSigninLogs
    },
    [FETCH_SET_BATCH_SCHEDULE_LOGS](state, fetchSetBatchScheduleLogs) {
      state.fetchSetBatchScheduleLogs = fetchSetBatchScheduleLogs
    },
    [FETCH_SET_BATCH_SCHEDULE_TASKS](state, fetchSetBatchScheduleTasks) {
      state.fetchSetBatchScheduleTasks = fetchSetBatchScheduleTasks
    },
    [TRIGGER_TASK](state, triggerTask) {
      state.triggerTask = triggerTask
    },
    [TRIGGER_TASK_NEW_SITE](state, triggerTaskNewSite) {
      state.triggerTaskNewSite = triggerTaskNewSite
    },
    [FETCH_APPROVAL_LOGS](state, fetchApprovalLogs) {
      state.fetchApprovalLogs = fetchApprovalLogs
    },
    [FETCH_LOGS_ADMIN](state, fetchLogsAdmin) {
      state.fetchLogsAdmin = fetchLogsAdmin
    },
    [FETCH_LOGS_USERS](state, fetchLogsUsers) {
      state.fetchLogsUsers = fetchLogsUsers
    },
    [FETCH_LOGS_ADMIN_VUE](state, fetchLogsAdminVue) {
      state.fetchLogsAdminVue = fetchLogsAdminVue
    },
    [FETCH_LOGS_USERS_VUE](state, fetchLogsUsersVue) {
      state.fetchLogsUsersVue = fetchLogsUsersVue
    },
    [FETCH_LOGS_USERS_DETAIL_POINT](state, fetchLogsUsersDetailPoint) {
      state.fetchLogsUsersDetailPoint = fetchLogsUsersDetailPoint
    },
    [FETCH_LOGS_USERS_DETAIL_INFO](state, fetchLogsUsersDetailInfo) {
      state.fetchLogsUsersDetailInfo = fetchLogsUsersDetailInfo
    },
    [FETCH_LOGS_USERS_SIGNIN](state, fetchLogsUsersSignin) {
      state.fetchLogsUsersSignin = fetchLogsUsersSignin
    },
    [FETCH_LOGS_BATCH](state, fetchLogsBatch) {
      state.fetchLogsBatch = fetchLogsBatch
    },
    [INSERT_LOGS_ADMIN](state, insertLogsAdmin) {
      state.insertLogsAdmin = insertLogsAdmin
    },
    [INSERT_LOGS_USERS](state, insertLogsUsers) {
      state.insertLogsUsers = insertLogsUsers
    },
    [INSERT_LOGS_USERS_SIGNIN](state, insertLogsUsersSignin) {
      state.insertLogsUsersSignin = insertLogsUsersSignin
    },
    updateManyFirstPush(state, events) {
      state[events.t].push(events.v)
      // console.log('state[events.t]:::', state[events.t])
    },
    updateManyLastPush(state, events) {
      state[events.t] = []
      // console.log('state[events.t]:::', state[events.t])
    },
    updateManyParam(state, events) {
      // console.log('t >> ', events.t) // target
      // console.log('f >> ', events.f) // from target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      // console.log('i >> ', events.i) // idx
      // state[events.t][events.i][events.e] = events.v

      const targetdIdx = state[events.t].findIndex(item => item.tableName === events.v.tableName)

      if (targetdIdx !== -1) {
        // 기존 항목이 있으면 업데이트
        state[events.t][targetdIdx][events.f] = events.e
      } else {
        // 중복 확인 후 추가
        state[events.t].push(events.v)
      }

      console.log('state[events.t] >> ', state[events.t])
    },
  },
  actions: {
    async [SIGNIN_LOGS]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertUserSigninLogs($site: String, $userid: String, $ip: String, $browser: String, $browserVer: String, $os: String, $osVer: String, $platform: String, $networkCo: String, $networkType: String, $asn: String, $route: String, $flag: String, $country: String, $city: String, $latitude: String, $longitude: String){
            insertUserSigninLogs(site: $site, userid: $userid, ip: $ip, browser: $browser, browserVer: $browserVer, os: $os, osVer: $osVer, platform: $platform, networkCo: $networkCo, networkType: $networkType, asn: $asn, route: $route, flag: $flag, country: $country, city: $city, latitude: $latitude, longitude: $longitude){
              site,
              userid,
              ip,
              browser,
              browserVer,
              os,
              osVer,
              platform,
              networkCo,
              networkType,
              asn,
              route,
              flag,
              country,
              city,
              latitude,
              longitude,
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          ip: payload.ip,
          browser: payload.browser,
          browserVer: payload.browserVer,
          os: payload.os,
          osVer: payload.osVer,
          platform: payload.platform,
          networkCo: payload.networkCo,
          networkType: payload.networkType,
          asn: payload.asn,
          route: payload.route,
          country: payload.country,
          flag: payload.flag,
          city: payload.city,
          latitude: payload.latitude,
          longitude: payload.longitude,
        },
      })
      commit(INSERT_USER_SIGNIN_LOGS, data)
    },
    async [FETCH_SIGNIN_LOGS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchUserSigninLogs($site: String, $periodFrom: String, $periodTo: String) {
            fetchUserSigninLogs(site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              id
              site
              userid
              ip
              browser
              browserVer
              os
              osVer
              platform
              networkCo
              networkType
              asn
              route
              flag
              country
              city
              latitude
              longitude
              createdAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SIGNIN_LOGS, data.fetchUserSigninLogs)
      console.log('FETCH_SIGNIN_LOGS : ', data.fetchUserSigninLogs)
    },
    async [FETCH_SET_BATCH_SCHEDULE_LOGS]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetBatchScheduleLogs($result: String, $periodFrom: String, $periodTo: String) {
            fetchSetBatchScheduleLogs(result: $result, periodFrom: $periodFrom, periodTo: $periodTo) {
              idx
              taskId
              taskName
              startAt
              endAt
              runTime
              result
              response
              error
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          result: payload.result,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_BATCH_SCHEDULE_LOGS, data.fetchSetBatchScheduleLogs)
      console.log('FETCH_SET_BATCH_SCHEDULE_LOGS : ', data.fetchSetBatchScheduleLogs)
    },
    async [FETCH_SET_BATCH_SCHEDULE_TASKS]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetBatchScheduleTasks {
            fetchSetBatchScheduleTasks {
              idx
              taskId
              taskName
              taskDesc
              schedule
              active
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_BATCH_SCHEDULE_TASKS, data.fetchSetBatchScheduleTasks)
      console.log('FETCH_SET_BATCH_SCHEDULE_TASKS : ', data.fetchSetBatchScheduleTasks)
    },
    async [TRIGGER_TASK]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation triggerTask($taskId: String){
            triggerTask(taskId: $taskId){
              result
            }
          }
        `,
        variables: {
          taskId: payload.taskId,
        },
      })
      console.log('TRIGGER_TASK Result :::', data.triggerTask.result)
      commit(TRIGGER_TASK, data.triggerTask)
    },
    async [TRIGGER_TASK_NEW_SITE]({ commit, state }, payload) {

      const formattedPayload = state.triggerTaskNewSite
        .filter(item => item.useYn === 'Y')
        .map(item => item.tableName)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation triggerTaskNewSite($taskId: String, $siteName: String, $selectedTables: [String!]!) {
            triggerTaskNewSite(taskId: $taskId, siteName: $siteName, selectedTables: $selectedTables) {
              result
            }
          }
        `,
        variables: {
          taskId: payload.taskId,
          siteName: payload.siteName,
          selectedTables: formattedPayload,
        },
      })
      console.log('TRIGGER_TASK_NEW_SITE Result :::', data.triggerTaskNewSite.result)
      commit(TRIGGER_TASK_NEW_SITE, data.triggerTaskNewSite)
    },
    async [FETCH_APPROVAL_LOGS]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchApprovalLogs($site: String, $periodFrom: String, $periodTo: String) {
            fetchApprovalLogs(site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              idx
              site
              category
              categoryDetail
              userid
              targetUserid
              tran
              asisContent
              tobeContent
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_APPROVAL_LOGS, data.fetchApprovalLogs)
      console.log('FETCH_APPROVAL_LOGS : ', data.fetchApprovalLogs)
    },
    async [FETCH_LOGS_ADMIN]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsAdmin($baseDate: String, $site: String, $periodFrom: String, $periodTo: String) {
            fetchLogsAdmin(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_ADMIN, data.fetchLogsAdmin)
      console.log('FETCH_LOGS_ADMIN : ', data.fetchLogsAdmin)
    },
    async [INSERT_LOGS_ADMIN]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertLogsAdmin($baseDate: String, $site: String, $title: String, $titleSub: String, $category: String, $categorySub: String, $userid: String, $asisContent: String, $tobeContent: String, $changeContent: String, $memo: String, $systemType: String, $systemTypeDetail: String, $performer: String, $adminId: String, $adminIp: String){
            insertLogsAdmin(baseDate: $baseDate, site: $site, title: $title, titleSub: $titleSub, category: $category, categorySub: $categorySub, userid: $userid, asisContent: $asisContent, tobeContent: $tobeContent, changeContent: $changeContent, memo: $memo, systemType: $systemType, systemTypeDetail: $systemTypeDetail, performer: $performer, adminId: $adminId, adminIp: $adminIp){
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
            }
          }
        `,
        variables: {
          baseDate: payload.baseDate,
          site: payload.site,
          title: payload.title,
          titleSub: payload.titleSub,
          category: payload.category,
          categorySub: payload.categorySub,
          userid: payload.userid,
          asisContent: payload.asisContent,
          tobeContent: payload.tobeContent,
          changeContent: payload.changeContent,
          memo: payload.memo,
          systemType: payload.systemType,
          systemTypeDetail: payload.systemTypeDetail,
          performer: payload.performer,
          adminId: payload.adminId,
          adminIp: payload.adminIp,
        },
      })
      commit(INSERT_LOGS_ADMIN, data)
    },
    async [FETCH_LOGS_ADMIN_VUE]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsAdminVue($baseDate: String, $site: String, $periodFrom: String, $periodTo: String) {
            fetchLogsAdminVue(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              changeTable
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_ADMIN_VUE, data.fetchLogsAdminVue)
      console.log('FETCH_LOGS_ADMIN_VUE : ', data.fetchLogsAdminVue)
    },
    async [FETCH_LOGS_USERS]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsUsers($baseDate: String, $site: String, $periodFrom: String, $periodTo: String) {
            fetchLogsUsers(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_USERS, data.fetchLogsUsers)
      console.log('FETCH_LOGS_USERS : ', data.fetchLogsUsers)
    },
    async [INSERT_LOGS_USERS]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertLogsUsers($baseDate: String, $site: String, $title: String, $titleSub: String, $category: String, $categorySub: String, $userid: String, $asisContent: String, $tobeContent: String, $changeContent: String, $memo: String, $systemType: String, $systemTypeDetail: String, $performer: String, $adminId: String, $adminIp: String){
            insertLogsUsers(baseDate: $baseDate, site: $site, title: $title, titleSub: $titleSub, category: $category, categorySub: $categorySub, userid: $userid, asisContent: $asisContent, tobeContent: $tobeContent, changeContent: $changeContent, memo: $memo, systemType: $systemType, systemTypeDetail: $systemTypeDetail, performer: $performer, adminId: $adminId, adminIp: $adminIp){
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
            }
          }
        `,
        variables: {
          baseDate: payload.baseDate,
          site: payload.site,
          title: payload.title,
          titleSub: payload.titleSub,
          category: payload.category,
          categorySub: payload.categorySub,
          userid: payload.userid,
          asisContent: payload.asisContent,
          tobeContent: payload.tobeContent,
          changeContent: payload.changeContent,
          memo: payload.memo,
          systemType: payload.systemType,
          systemTypeDetail: payload.systemTypeDetail,
          performer: payload.performer,
          adminId: payload.adminId,
          adminIp: payload.adminIp,
        },
      })
      commit(INSERT_LOGS_USERS, data)
    },
    // 유저 변경 로그 (관리자)
    async [FETCH_LOGS_USERS_VUE]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsUsersVue($baseDate: String, $site: String, $periodFrom: String, $periodTo: String) {
            fetchLogsUsersVue(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_USERS_VUE, data.fetchLogsUsersVue)
      console.log('FETCH_LOGS_USERS_VUE : ', data.fetchLogsUsersVue)
    },
    // 유저 point 변경 로그(유저 상세)
    async [FETCH_LOGS_USERS_DETAIL_POINT]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsUsersDetailPoint($baseDate: String, $site: String, $periodFrom: String, $periodTo: String, $userid: String, $systemTypeDetail: String) {
            fetchLogsUsersDetailPoint(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo, userid: $userid, systemTypeDetail: $systemTypeDetail) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          userid: payload.userid,
          systemTypeDetail: payload.systemTypeDetail,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_USERS_DETAIL_POINT, data.fetchLogsUsersDetailPoint)
      console.log('FETCH_LOGS_USERS_DETAIL_POINT : ', data.fetchLogsUsersDetailPoint)
    },
    // 유저 info 변경 로그(유저 상세)
    async [FETCH_LOGS_USERS_DETAIL_INFO]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsUsersDetailInfo($baseDate: String, $site: String, $periodFrom: String, $periodTo: String, $userid: String, $systemTypeDetail: String) {
            fetchLogsUsersDetailInfo(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo, userid: $userid, systemTypeDetail: $systemTypeDetail) {
              baseDate
              site
              title
              titleSub
              category
              categorySub
              userid
              asisContent
              tobeContent
              changeContent
              memo
              systemType
              systemTypeDetail
              performer
              adminId
              adminIp
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
          userid: payload.userid,
          systemTypeDetail: payload.systemTypeDetail,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_USERS_DETAIL_INFO, data.fetchLogsUsersDetailInfo)
      console.log('FETCH_LOGS_USERS_DETAIL_INFO : ', data.fetchLogsUsersDetailInfo)
    },
    async [FETCH_LOGS_USERS_SIGNIN]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsUsersSignin($baseDate: String, $site: String, $periodFrom: String, $periodTo: String) {
            fetchLogsUsersSignin(baseDate: $baseDate, site: $site, periodFrom: $periodFrom, periodTo: $periodTo) {
              baseDate
              site
              userid
              ip
              browser
              browserVer
              os
              osVer
              platform
              networkCo
              networkType
              asn
              route
              flag
              country
              city
              latitude
              longitude
              createdAt
            }
          }
        `,
        variables: {
          site: payload.site,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_USERS_SIGNIN, data.fetchLogsUsersSignin)
      console.log('FETCH_LOGS_USERS_SIGNIN : ', data.fetchLogsUsersSignin)
    },
    async [INSERT_LOGS_USERS_SIGNIN]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertLogsUsersSignin($baseDate: String, $site: String, $userid: String, $ip: String, $browser: String, $browserVer: String, $os: String, $osVer: String, $platform: String, $networkCo: String, $networkType: String, $asn: String, $route: String, $flag: String, $country: String, $city: String, $latitude: String, $longitude: String) {
            insertLogsUsersSignin(baseDate: $baseDate, site: $site, userid: $userid, ip: $ip, browser: $browser, browserVer: $browserVer, os: $os, osVer: $osVer, platform: $platform, networkCo: $networkCo, networkType: $networkType, asn: $asn, route: $route, flag: $flag, country: $country, city: $city, latitude: $latitude, longitude: $longitude) {
              baseDate
              site
              userid
              ip
              browser
              browserVer
              os
              osVer
              platform
              networkCo
              networkType
              asn
              route
              flag
              country
              city
              latitude
              longitude
            }
          }
        `,
        variables: {
          baseDate: payload.baseDate,
          site: payload.site,
          userid: payload.userid,
          ip: payload.ip,
          browser: payload.browser,
          browserVer: payload.browserVer,
          os: payload.os,
          osVer: payload.osVer,
          platform: payload.platform,
          networkCo: payload.networkCo,
          networkType: payload.networkType,
          asn: payload.asn,
          route: payload.route,
          country: payload.country,
          flag: payload.flag,
          city: payload.city,
          latitude: payload.latitude,
          longitude: payload.longitude,
        },
      })
      commit(INSERT_LOGS_USERS_SIGNIN, data)
    },
    async [FETCH_LOGS_BATCH]({ commit }, payload) {
      console.log(payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchLogsBatch($baseDate: String, $result: String, $periodFrom: String, $periodTo: String) {
            fetchLogsBatch(baseDate: $baseDate, result: $result, periodFrom: $periodFrom, periodTo: $periodTo) {
              baseDate
              taskId
              taskName
              startAt
              endAt
              runTime
              result
              response
              error
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          result: payload.result,
          periodFrom: payload.periodFrom,
          periodTo: payload.periodTo,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_LOGS_BATCH, data.fetchLogsBatch)
      console.log('FETCH_LOGS_BATCH : ', data.fetchLogsBatch)
    },
  },
}

export default logStore
