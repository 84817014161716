<!-- src/views/UserDetailView.vue -->
<template>
  <div class="m-2">
    <b-card>
      <b-row>
        <!--        <b-row class="justify-content-between">-->
        <b-col cols="3">
          <b-form-group
            label="사이트"
            label-for="branch-site"
          >
            <b-form-select
              v-if="authSite"
              id="branch-site"
              ref="branch-site"
              class="font-small-3"
              style="color:rgb(229,166,48);"
              size="sm"
              disabled
            >
              <b-form-select-option
                selected
              >
                {{ siteSelected }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="총판"
            label-for="branch"
          >
            <b-form-select
              v-if="false"
              id="branch"
              ref="branch"
              class="font-small-3"
              style="color:rgb(229,166,48)"
              size="sm"
              disabled
            >
              <b-form-select-option
                selected
              >
                [{{ userData.role }}] {{ userData.nickname }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group
            label="총판"
            label-for="branch"
          >
            <b-input-group
              class="calendar-size font-small-3"
            >
              <b-form-datepicker
                v-model="periodFrom"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="시작일"
                aria-controls="example-input"
                size="sm"
                :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                v-model="periodTo"
                class="align-self-center"
                boundary="window"
                locale="ko"
                placeholder="종료일"
                aria-controls="example-input"
                size="sm"
                :min="periodFrom"
              />
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                size="sm"
                class="ml-1"
                @click="searchData"
              >
                검색
              </b-button>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--        </b-row>-->
        <!--        <b-row class="justify-content-between">-->
        <b-col
          cols="12"
          md="12"
        >
          <b-form-group
            label="검색"
            label-for="branch-input"
            class="font-small-3"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                style="color: #99dff1"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <!-- Table Fitler -->
        <div>
          <b-row class="my-1">
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <b-form-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                size="sm"
                class="per-page-selector d-inline-block mx-50"
                style="width: 100px;"
              />
            </b-col>
            <!-- Search -->
            <b-col v-if="0">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="searchFilter"
                  type="search"
                  placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                  style="color: #99dff1"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!searchFilter"
                    @click="searchFilter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>
        </div>
        <!-- table content -->
        <div>
          <b-table
            responsive
            show-empty
            small
            empty-text="No matching records found"
            style="text-align: center; font-size: small"
            head-variant="light"
            :items="paginatedItems"
            :fields="branchFields"
          >
            <template #thead-top>
              <!-- th-row: 1 -->
              <b-tr>
                <!-- 일반적인 헤더 컬럼 -->
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  No.
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  상위
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  권한
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  아이디
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  닉네임
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  이름
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  등급
                </b-th>
                <b-th
                  rowspan="3"
                  class="align-middle"
                >
                  상태
                </b-th>
                <b-th
                  colspan="2"
                  class="align-middle"
                  style="font-size: small; "
                >
                  현재머니
                </b-th>
                <b-th
                  colspan="2"
                  class="text-center align-middle"
                >
                  입출금
                </b-th>
                <b-th
                  colspan="9"
                  class="text-center align-middle"
                >
                  롤링
                </b-th>
              </b-tr>
              <!-- th-row: 2 -->
              <b-tr>
                <!-- 현재머니 -->
                <b-th
                  rowspan="2"
                  class="text-center align-middle"
                  style="font-size: small; "
                >
                  캐쉬
                </b-th>
                <b-th
                  rowspan="2"
                  class="text-center align-middle"
                  style="font-size: small; "
                >
                  포인트
                </b-th>

                <!-- 입출금 -->
                <b-th
                  rowspan="2"
                  class="text-center align-middle"
                  style="font-size: small; "
                >
                  충전<br>
                  <span style="font-size: xx-small;">(M)</span>
                </b-th>
                <b-th
                  rowspan="2"
                  class="text-center align-middle"
                  style="font-size: small; "
                >
                  환전<br>
                  <span style="font-size: xx-small;">(N)</span>
                </b-th>
                <b-th
                  colspan="3"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  스포츠
                </b-th>
                <b-th
                  colspan="3"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  카지노
                </b-th>
                <b-th
                  colspan="3"
                  style="border-left: solid 2px #3b4253 !important;"
                >
                  슬롯
                </b-th>
              </b-tr>
              <!-- th-row: 3 -->
              <b-tr>
                <!-- 스포츠 -->
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  배팅금
                </b-th>
                <b-th>요율(%)</b-th>
                <b-th>롤링금</b-th>

                <!-- 카지노 -->
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  배팅금
                </b-th>
                <b-th>요율(%)</b-th>
                <b-th>롤링금</b-th>

                <!-- 슬롯 -->
                <b-th style="border-left: solid 2px #3b4253 !important;">
                  배팅금
                </b-th>
                <b-th>요율(%)</b-th>
                <b-th>롤링금</b-th>
              </b-tr>
            </template>
            <!-- Column: no -->
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>
            <!-- Column: recommendNickname -->
            <template #cell(recommendNickname)="data">
              <div style="text-align: left; min-width: 7rem">
                <b-badge
                  :variant="`light-primary`"
                  style=""
                  class=""
                >
                  상위
                </b-badge>
                {{ data.item.recommendNickname === siteSelected ? '본사' : data.item.recommendNickname }}
                <div
                  v-if="data.item.recommendUserNickname"
                  style="text-align: left;"
                >
                  <b-badge
                    :variant="`light-warning`"
                    style=""
                    class=""
                  >
                    추천
                  </b-badge>
                  {{ data.item.recommendUserNickname }}
                </div>
              </div>
            </template>
            <!-- Column: role -->
            <template #cell(role)="data">
              <div style="min-width: 3rem">
                <b-media class="align-content-center">
                  <template>
                    <b-avatar
                      size="24"
                      :src="data.item.role"
                      :text="avatarText(data.item.role)"
                      :variant="`${resolveUserRoleVariant(data.item.role)}`"
                    />
                  </template>
                </b-media>
              </div>
            </template>
            <!-- Column: userid -->
            <template
              #cell(userid)="data"
            >
              <div
                class="d-flex align-items-center"
              >
                <span
                  :style="{ paddingLeft: `${(data.item.level || 0) * 5}px` }"
                >
                  {{ data.item.level > 0 ? '▸' : '' }}
                </span>
                <span>
                  {{ data.item.userid }}
                </span>
              </div>
            </template>
            <!-- Column: nickname -->
            <template #cell(nickname)="data">
              <div
                class="font-weight-bold d-block text-nowrap"
                style="text-align: left !important; min-width: 7rem;"
              >
                {{ data.item.nickname }}
              </div>
            </template>
            <!-- Column: username -->
            <template #cell(username)="data">
              <div
                class="font-weight-bold d-block text-nowrap"
                style="text-align: left !important; min-width: 5rem;"
              >
                {{ data.item.username }}
              </div>
            </template>
            <!-- Column: rank -->
            <template #cell(rank)="data">
              <div style="min-width: 5rem">
                <b-avatar
                  size="28"
                  style="text-align: center; min-width: 2.1rem"
                  :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_rank/` + siteSelected + `_` + data.item.rank + `.png`"
                />
                <span class="pl-1">Lv.{{ data.item.rank }}</span>
              </div>
            </template>
            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                pill
                :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                class="text-capitalize"
              >
                {{ convertUserStatus(data.item.status) }}
              </b-badge>
            </template>
            <!-- Column: sportBettingAmount -->
            <template #cell(sportBettingAmount)="data">
              <div v-if="data.item.sportBettingAmount > 0">
                <span
                  class="font-weight-bold text-nowrap cursor-pointer"
                  style="text-align: left !important; text-decoration: underline; text-underline-offset: 5px;"
                  @click.stop="handleFetchBalanceRolling('sport', data)"
                >
                  {{ Number(data.item.sportBettingAmount).toLocaleString() }}
                </span>
              </div>
              <div v-else>
                <span>
                  {{ Number(data.item.sportBettingAmount).toLocaleString() }}
                </span>
              </div>
            </template>
            <!-- Column: casinoBettingAmount -->
            <template #cell(casinoBettingAmount)="data">
              <div v-if="data.item.casinoBettingAmount > 0">
                <span
                  class="font-weight-bold text-nowrap cursor-pointer"
                  style="text-align: left !important; text-decoration: underline; text-underline-offset: 5px;"
                  @click.stop="handleFetchBalanceRolling('casino', data)"
                >
                  {{ Number(data.item.casinoBettingAmount).toLocaleString() }}
                </span>
              </div>
              <div v-else>
                <span>
                  {{ Number(data.item.casinoBettingAmount).toLocaleString() }}
                </span>
              </div>
            </template>
            <!-- Column: slotBettingAmount -->
            <template #cell(slotBettingAmount)="data">
              <div v-if="data.item.slotBettingAmount > 0">
                <span
                  class="font-weight-bold text-nowrap cursor-pointer"
                  style="text-align: left !important; text-decoration: underline; text-underline-offset: 5px;"
                  @click.stop="handleFetchBalanceRolling('slot', data)"
                >
                  {{ Number(data.item.slotBettingAmount).toLocaleString() }}
                </span>
              </div>
              <div v-else>
                <span>
                  {{ Number(data.item.slotBettingAmount).toLocaleString() }}
                </span>
              </div>
            </template>

          </b-table>
        </div>
        <!-- table footer -->
        <div>
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
              style="margin-bottom: 0.5rem;"
            >
              <span
                class="font-small-2"
              >{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }}
              </span>
            </b-col>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
      <branch-Users-rolling
        ref="BranchUsersRolling"
        :fetch-balance-user-rolling="fetchBalanceBranchDailyProfitUserRolling"
      />
    </b-card>
    <!-- {{ fetchBalanceBranchDailyProfitUser }} -->
    <!-- {{ fetchBalanceBranchDailyProfitUserRolling }} -->
  </div>
</template>

<script>

import {
  BCard, BRow, BCol,
  BForm, BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect, BFormSelectOption, BButton,
  BTable, BTr, BTh, BPagination,

  BFormDatepicker,
  BBadge, BMedia, BAvatar,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER, FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER_ROLLING } from '@/store/branch/action'

import { createNamespacedHelpers } from 'vuex'

import BranchUsersRolling from '@/views/branch/BranchCalculateDailyUsersRolling.vue'

import { avatarText } from '@core/utils/filter'

const branchStore = createNamespacedHelpers('branchStore')

export default {
  name: 'BranchCalculateDailyUser',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BFormSelectOption,
    BButton,
    BTable,
    BTr,
    BTh,
    BPagination,

    BFormDatepicker,
    BBadge,
    BMedia,
    BAvatar,

    BranchUsersRolling,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      avatarText,

      userData: {},

      // TableFilter
      searchFilter: '',

      periodFrom: '',
      periodTo: '',

      // TableOption
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      // Tablefields
      branchFields: [
        { key: 'no', label: 'No.', thClass: 'd-none' },
        { key: 'recommendNickname', label: '상위', thClass: 'd-none' },
        { key: 'role', label: '권한', thClass: 'd-none' },
        { key: 'userid', label: '아이디', thClass: 'd-none' },
        { key: 'nickname', label: '닉네임', thClass: 'd-none' },
        { key: 'username', label: '이름', thClass: 'd-none' },
        { key: 'rank', label: '등급', thClass: 'd-none' },
        { key: 'status', label: '상태', thClass: 'd-none' },
        { key: 'cash', label: '캐쉬', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'point', label: '포인트', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'deposit', label: '충전', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'exchange', label: '환전', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        // { key: 'balance', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },

        { key: 'sportBettingAmount', label: '스포츠/배팅금', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },
        { key: 'rateSportRolling', label: '스포츠/요율', thClass: 'd-none' },
        { key: 'sportRollingAmount', label: '스포츠/롤링금', thClass: 'd-none', tdClass: 'text-right', formatter: 'formatNumberWithCommas' },

        { key: 'casinoBettingAmount', label: '카지노/배팅금', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
        { key: 'rateCasinoRolling', label: '카지노/요율', thClass: 'd-none' },
        { key: 'casinoRollingAmount', label: '카지노/롤링금', thClass: 'd-none', formatter: 'formatNumberWithCommas' },

        { key: 'slotBettingAmount', label: '슬롯/배팅금', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
        { key: 'rateSlotRolling', label: '슬롯/요율', thClass: 'd-none' },
        { key: 'slotRollingAmount', label: '슬롯/롤링금', thClass: 'd-none', formatter: 'formatNumberWithCommas' },
      ],
    }
  },
  computed: {
    ...branchStore.mapGetters({
      fetchBalanceBranchDailyProfitUser: 'fetchBalanceBranchDailyProfitUser',
      fetchBalanceBranchDailyProfitUserRolling: 'fetchBalanceBranchDailyProfitUserRolling',
    }),
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    filteredItems() {
      let filteredItems = []
      filteredItems = this.fetchBalanceBranchDailyProfitUser
      // if (this.fetchBalanceBranchDailyProfitUser.length) {
      //   const { children, ...rest } = this.fetchBalanceBranchDailyProfitUser[0]
      //   this.userData = rest

      //   console.log('rest', rest)
      //   filteredItems = children.filter(item => item.role === 'user')
      // }
      // if (this.searchFilter) {
      //   filteredItems = filteredItems.filter(item => Object.values(item).some(val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase())))
      // }
      return filteredItems
    },
    // Table filtered Items => Table Pagination Items
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },
  },
  created() {
    console.log(this.componentName, 'created()')
    console.log('userid:', this.$route.params.userid)
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBalanceBranchDailyProfitUser: FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER,
      $fetchBalanceBranchDailyProfitUserRolling: FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER_ROLLING,
    }),
    async init() {
      // this.periodFrom = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7).toISOString().slice(0, 10)
      // this.periodTo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1).toISOString().slice(0, 10)
      this.periodFrom = this.$route.query.fromAt
      this.periodTo = this.$route.query.toAt

      await this.fetchData()
    },
    async fetchData() {
      await this.$fetchBalanceBranchDailyProfitUser({
        site: this.siteSelected,
        userid: this.$route.query.userid,
        fromAt: this.$route.query.fromAt,
        toAt: this.$route.query.toAt,
      })
    },
    searchData() {
      this.fetchData()
    },
    async handleFetchBalanceRolling(type, data) {
      console.log('handleFetchBalanceRolling', data.item)
      await this.$fetchBalanceBranchDailyProfitUserRolling({
        site: this.siteSelected,
        userid: data.item.userid,
        type,
        fromAt: this.periodFrom,
        toAt: this.periodTo,
      })
      this.$refs.BranchUsersRolling.$bvModal.show('BranchCalculateDailyUsersRolling')
    },

    /* [formatter] */
    formatRate(value) {
      return value !== null ? value : '-'
    },
    formatNumberWithCommas(value) {
      if (value == null) {
        return ''
      }
      return parseInt(value, 10).toLocaleString()
    },

    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
    resolveUserStatusVariant(status) {
      if (status === 'additional') return 'info'
      if (status === 'await') return 'info'
      if (status === 'active') return 'success'
      if (status === 'sleep') return 'primary'
      if (status === 'block') return 'primary'
    },
    convertUserStatus(status) {
      if (status === 'apply') return '1차가입'
      if (status === 'additional') return '2차가입'
      if (status === 'await') return '최종대기'
      if (status === 'active') return '정상'
      if (status === 'sleep') return '휴먼'
      if (status === 'block') return '차단'
    },

    closeWindow() {
      window.close() // 새 창 닫기
    },
  },
}
</script>

<style scoped>
h2 {
  margin-bottom: 20px;
}
</style>
