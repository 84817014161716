<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        경기 관리 (결과)
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="m-1">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- 날짜 -->
          <b-col
            cols="6"
            lg="6"
            md="6"
            sm="12"
            class=""
          >
            <b-form-group
              class="font-small-3"
              label="경기일시"
              label-for="period"
              label-cols-md="2"
            >
              <b-input-group
                name="period"
                class="calendar-size font-small-3"
              >
                <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
                />
                <div class="pl-1 pr-1">
                  ~
                </div>
                <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '1' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('soccer', '1')"
            >
              축구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '2' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('basketball', '2')"
            >
              농구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '3' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('baseball', '3')"
            >
              야구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '4' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('tennis', '4')"
            >
              테니스
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '5' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('volleyball', '5')"
            >
              배구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '6' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('football', '6')"
            >
              풋볼
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '7' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('hockey', '7')"
            >
              하키
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '8' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('esports', '8')"
            >
              E스포츠
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="font-small-3"
              label="검색옵션"
              label-for="search"
              label-cols-md="2"
            >
              <b-input-group
                class="align-self-center"
                size="sm"
                name="search"
              >
                <b-col
                  cols=""
                  md=""
                  class=""
                >
                  <b-form-select
                    v-model="searchOption"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="searchOptionTitle"
                    size="sm"
                    :clearable="false"
                    class="per-page-selector d-inline-block"
                    style="margin-left: -1rem"
                  />
                </b-col>
                <b-form-input
                  v-model="inputValue"
                  type="search"
                  placeholder="Type to Search"
                  style="width: 200px"
                  @keyup.enter="fetchData(sports)"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="ml-1"
                    @click="fetchData(sports)"
                  >
                    검색
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="isLoading" class="text-center my-3 pt-3 text-primary">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <div v-else>
        <b-table
          responsive
          show-empty
          hover
          small
          striped
          empty-text="No matching records found"
          :per-page="perPage"
          :items="items"
          :fields="currentFields"
          :filter="filter"
          :filter-included-fields="filterOn"
          style="text-align: center !important; font-size: small"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :current-page="currentPage"
          @filtered="onFiltered"
        >
          <template #cell(index)="data">
            {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
          </template>
          <template #cell(gameDate)="data">
            <div
              style="min-width: 3rem"
            >
              {{ data.item.gameId }}
            </div>
          </template>
          <template #cell(status)="data">
            <div
              v-if="data.item.status !== 'FT' && data.item.status !== 'Finished' "
              size="sm"
              style="text-align: center; min-width: 4rem; color: yellow"
            >
              {{ data.item.status }}
            </div>
            <div
              v-else
              size="sm"
              style="text-align: center; min-width: 4rem;"
            >
              {{ data.item.status }}
            </div>
          </template>
          <template #cell(gameDate)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 9rem;"
            >
              {{ data.item.gameDate }}
            </div>
          </template>
          <template #cell(leagueName)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.leagueName"
              class="text-truncate"
              style="text-align: left; max-width: 10em;"
            >
              {{ data.item.leagueName }}
            </div>
          </template>
          <template #cell(homeTeamName)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.homeTeamName"
              size="sm"
              class="text-truncate"
              style="text-align: left; float: left; max-width: 7rem;"
            >
              {{ data.item.homeTeamName }}
            </div>
          </template>
          <template #cell(awayTeamName)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.awayTeamName"
              size="sm"
              class="text-truncate"
              style="text-align: left; float: left; max-width: 7rem;"
            >
              {{ data.item.awayTeamName }}
            </div>
          </template>
          <template #cell(homeTeamResult)="data">
            <div
              v-if="false"
              v-b-tooltip.hover.bottom.v-danger="data.item.homeTeamResult"
              size="sm"
              class="text-truncate"
              style="text-align: center; min-width: 4rem;"
            >
              {{ data.item.homeTeamResult ? data.item.homeTeamResult : data.item.homeTeamCustomResult }}
            </div>
            <div class="d-flex justify-content-center">
              <b-form-input
                v-b-tooltip.hover.bottom.v-danger="data.item.homeTeamResult"
                :value="data.item.homeTeamCustomResult ? data.item.homeTeamCustomResult : data.item.homeTeamResult"
                style="color:rgb(229,166,48); text-align: center; width: 6rem;"
                size="sm"
                type="number"
                class="center"
                @input="updateParam('updateGameResultsCustom',`${getTargetGetter}`,'homeTeamCustomResult', $event, data.item.idx)"
              />
            </div>
          </template>
          <template #cell(awayTeamResult)="data">
            <div class="d-flex justify-content-center">
              <b-form-input
                v-b-tooltip.hover.bottom.v-danger="data.item.awayTeamResult"
                :value="data.item.awayTeamCustomResult ? data.item.awayTeamCustomResult : data.item.awayTeamResult"
                style="color:rgb(229,166,48); text-align: center; width: 6rem;"
                size="sm"
                type="number"
                class="center"
                @input="updateParam('updateGameResultsCustom',`${getTargetGetter}`,'awayTeamCustomResult', $event, data.item.idx)"
              />
            </div>
          </template>
          <template #cell(halfTimeResult)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.halfTimeResult"
              size="sm"
              class="text-truncate"
              style="text-align: center; min-width: 4rem;"
            >
              {{ data.item.halfTimeResult }}
            </div>
          </template>
          <template #cell(fullTimeResult)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.fullTimeResult"
              size="sm"
              class="text-truncate"
              style="text-align: center; min-width: 4rem;"
            >
              {{ data.item.fullTimeResult }}
            </div>
          </template>
          <template #cell(homeGameScore)="data">
            <b-form-input
              v-b-tooltip.hover.bottom.v-danger="data.item.homeGameScore"
              :value="data.item.homeGameCustomScore ? data.item.homeGameCustomScore : data.item.homeGameScore"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('updateGameResultsCustom',`${getTargetGetter}`,'homeGameCustomScore', $event, data.item.idx)"
            />
          </template>
          <template #cell(awayGameScore)="data">
            <b-form-input
              v-b-tooltip.hover.bottom.v-danger="data.item.awayGameScore"
              :value="data.item.awayGameCustomScore ? data.item.awayGameCustomScore : data.item.awayGameScore"
              style="color:rgb(229,166,48); text-align: center; width: 6rem;"
              size="sm"
              type="number"
              class="center"
              @input="updateParam('updateGameResultsCustom',`${getTargetGetter}`,'awayGameCustomScore', $event, data.item.idx)"
            />
          </template>

          <template #cell(edit)="data">
            <div
              class="d-flex justify-content-center align-items-center space"
              style="min-width: 145px"
            >
              <b-form-select
                v-if="false"
                :value="data.item.customResultYn === 'Y' ? 'Y' : 'N'"
                size="sm"
                class="center"
                :options="options1"
                style="margin-right: 1rem; width: 55px;"
                @change="updateParam('updateGameResultsCustom','menuUseYn', $event, data.item.idx)"
              />
              <b-button
                class=""
                size="sm"
                variant="outline-success"
                type="submit"
                style="margin-right: 1rem; width: 55px;"
                @click="submitUpdate('updateGameResultsCustom', data.item)"
              >
                저장
              </b-button>
              <b-button
                class=""
                size="sm"
                variant="outline-primary"
                type="submit"
                style="width: 90px;"
                @click="submitUpdate('updateGameSetTeamCustomName')"
              >
                결과재적용
              </b-button>
            </div>
          </template>
        </b-table>
      </div>
      <template #header>
        <!-- 빈 템플릿으로 헤더 제거 -->
      </template>
      <b-modal
        id="modal-lg"
        ref="my-modal"
        small
        title="상세"
        centered
        button-size="sm"
        size="lg"
        style="max-width: 400px !important;"
      >
        <div class="mt-2">
          <quill-editor
            v-model="detailContent"
            :options="editorOption2"
          />
        </div>
      </b-modal>
      <template #footer>
        <!-- 빈 템플릿으로 풋터 제거 -->
      </template>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BPagination,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BBadge,
  BFormDatepicker,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import {
  FETCH_GAME_RESULTS_SOCCER, FETCH_GAME_RESULTS_BASKETBALL, FETCH_GAME_RESULTS_BASEBALL, FETCH_GAME_RESULTS_TENNIS, FETCH_GAME_RESULTS_VOLLEYBALL, FETCH_GAME_RESULTS_FOOTBALL, FETCH_GAME_RESULTS_HOCKEY, FETCH_GAME_RESULTS_ESPORTS,
} from '@/store/result/action'
import {
  UPDATE_GAME_RESULTS_CUSTOM,
  CLEAR_GAME_RESULT_CUSTOM,
} from '@/store/result/mutation'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

const resultStore = createNamespacedHelpers('resultStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BFormDatepicker,
    quillEditor,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    const today = moment().format('YYYY-MM-DD')
    const oneWeekAgo = moment().subtract(2, 'days').format('YYYY-MM-DD')
    return {
      sports: 'soccer',
      items: [],
      selectedButton: '1',
      currentFields: [],
      complete: true,
      perPage: 50,
      pageOptions: [50, 100, 500],
      sortBy: 'gameDate',
      sortDesc: true,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      periodFrom: oneWeekAgo,
      periodTo: today,
      inputValue: '',
      searchOption: 'gameId',
      detailContent: '',
      isLoading: false, // 로딩 상태를 위한 변수 추가
      options1: [
        { value: 'N', text: 'N' },
        { value: 'Y', text: 'Y' },
      ],
      searchOptionTitle: [
        // { value: null, text: '선택' },
        { value: 'gameId', text: 'GAME ID' },
        { value: 'gameId2', text: 'GAME ID2' },
        { value: 'leagueId', text: '리그 ID' },
        { value: 'leagueName', text: '리그명' },
        { value: 'status', text: '상태' },
      ],
      fieldsSoccer: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        { key: 'halfTimeResult', sortable: false, label: '전반 결과' },
        { key: 'fullTimeResult', sortable: false, label: '최종 결과' },
        // { key: 'timer', sortable: false, label: '타이머' },
        // { key: 'injTime', sortable: false, label: '추가 시간' },
        // { key: 'commentary', sortable: false, label: '해설' },
        // { key: 'heatmap', sortable: false, label: '히트 맵' },
        // { key: 'groupId', sortable: false, label: '그룹 ID' },
        // { key: 'staticId', sortable: false, label: '정적 ID' },
        // { key: 'fixedId', sortable: false, label: '고정 ID' },
        // { key: 'venue', sortable: false, label: '경기장' },
        // { key: 'v', sortable: false, label: '버전' },
        // { key: 'events', sortable: false, label: '이벤트' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsBasketball: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        { key: 'homeOt', sortable: false, label: '홈 연장' },
        // { key: 'homeQ1', sortable: false, label: '홈 1쿼터' },
        // { key: 'homeQ2', sortable: false, label: '홈 2쿼터' },
        // { key: 'homeQ3', sortable: false, label: '홈 3쿼터' },
        // { key: 'homeQ4', sortable: false, label: '홈 4쿼터' },
        { key: 'awayOt', sortable: false, label: '원정 연장' },
        // { key: 'awayQ1', sortable: false, label: '원정 1쿼터' },
        // { key: 'awayQ2', sortable: false, label: '원정 2쿼터' },
        // { key: 'awayQ3', sortable: false, label: '원정 3쿼터' },
        // { key: 'awayQ4', sortable: false, label: '원정 4쿼터' },
        // { key: 'homeAssists', sortable: false, label: '홈 어시스트' },
        // { key: 'homeBlocks', sortable: false, label: '홈 블록' },
        // { key: 'homeFieldGoalsAttempts', sortable: false, label: '홈 필드골 시도' },
        // { key: 'homeFieldGoalsPct', sortable: false, label: '홈 필드골 성공률' },
        // { key: 'homeFieldGoalsTotal', sortable: false, label: '홈 필드골 총합' },
        // { key: 'homeFreeThrowsAttempts', sortable: false, label: '홈 자유투 시도' },
        // { key: 'homeFreeThrowsPct', sortable: false, label: '홈 자유투 성공률' },
        // { key: 'homeFreeThrowsTotal', sortable: false, label: '홈 자유투 총합' },
        // { key: 'homePersonalFouls', sortable: false, label: '홈 개인 파울' },
        // { key: 'homeReboundsDefense', sortable: false, label: '홈 수비 리바운드' },
        // { key: 'homeReboundsOffense', sortable: false, label: '홈 공격 리바운드' },
        // { key: 'homeReboundsTotal', sortable: false, label: '홈 리바운드 총합' },
        // { key: 'homeSteals', sortable: false, label: '홈 스틸' },
        // { key: 'homeThreePointAttempts', sortable: false, label: '홈 3점슛 시도' },
        // { key: 'homeThreePointPct', sortable: false, label: '홈 3점슛 성공률' },
        // { key: 'homeThreePointTotal', sortable: false, label: '홈 3점슛 총합' },
        // { key: 'homeTurnovers', sortable: false, label: '홈 턴오버' },
        // { key: 'awayAssists', sortable: false, label: '원정 어시스트' },
        // { key: 'awayBlocks', sortable: false, label: '원정 블록' },
        // { key: 'awayFieldGoalsAttempts', sortable: false, label: '원정 필드골 시도' },
        // { key: 'awayFieldGoalsPct', sortable: false, label: '원정 필드골 성공률' },
        // { key: 'awayFieldGoalsTotal', sortable: false, label: '원정 필드골 총합' },
        // { key: 'awayFreeThrowsAttempts', sortable: false, label: '원정 자유투 시도' },
        // { key: 'awayFreeThrowsPct', sortable: false, label: '원정 자유투 성공률' },
        // { key: 'awayFreeThrowsTotal', sortable: false, label: '원정 자유투 총합' },
        // { key: 'awayPersonalFouls', sortable: false, label: '원정 개인 파울' },
        // { key: 'awayReboundsDefense', sortable: false, label: '원정 수비 리바운드' },
        // { key: 'awayReboundsOffense', sortable: false, label: '원정 공격 리바운드' },
        // { key: 'awayReboundsTotal', sortable: false, label: '원정 리바운드 총합' },
        // { key: 'awaySteals', sortable: false, label: '원정 스틸' },
        // { key: 'awayThreePointAttempts', sortable: false, label: '원정 3점슛 시도' },
        // { key: 'awayThreePointPct', sortable: false, label: '원정 3점슛 성공률' },
        // { key: 'awayThreePointTotal', sortable: false, label: '원정 3점슛 총합' },
        // { key: 'awayTurnovers', sortable: false, label: '원정 턴오버' },
        // { key: 'homeStarters', sortable: false, label: '홈 선발' },
        // { key: 'homeBench', sortable: false, label: '홈 벤치' },
        // { key: 'awayStarters', sortable: false, label: '원정 선발' },
        // { key: 'awayBench', sortable: false, label: '원정 벤치' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsBaseball: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        // { key: 'extraInn', sortable: false, label: '연장전' },
        // { key: 'homeIn1', sortable: false, label: '홈 1회' },
        // { key: 'homeIn2', sortable: false, label: '홈 2회' },
        // { key: 'homeIn3', sortable: false, label: '홈 3회' },
        // { key: 'homeIn4', sortable: false, label: '홈 4회' },
        // { key: 'homeIn5', sortable: false, label: '홈 5회' },
        // { key: 'homeIn6', sortable: false, label: '홈 6회' },
        // { key: 'homeIn7', sortable: false, label: '홈 7회' },
        // { key: 'homeIn8', sortable: false, label: '홈 8회' },
        // { key: 'homeIn9', sortable: false, label: '홈 9회' },
        { key: 'homeEx', sortable: false, label: '홈 연장' },
        // { key: 'homeHits', sortable: false, label: '홈 안타' },
        // { key: 'homeErrors', sortable: false, label: '홈 실책' },
        // { key: 'awayIn1', sortable: false, label: '원정 1회' },
        // { key: 'awayIn2', sortable: false, label: '원정 2회' },
        // { key: 'awayIn3', sortable: false, label: '원정 3회' },
        // { key: 'awayIn4', sortable: false, label: '원정 4회' },
        // { key: 'awayIn5', sortable: false, label: '원정 5회' },
        // { key: 'awayIn6', sortable: false, label: '원정 6회' },
        // { key: 'awayIn7', sortable: false, label: '원정 7회' },
        // { key: 'awayIn8', sortable: false, label: '원정 8회' },
        // { key: 'awayIn9', sortable: false, label: '원정 9회' },
        { key: 'awayEx', sortable: false, label: '원정 연장' },
        // { key: 'awayHits', sortable: false, label: '원정 안타' },
        // { key: 'awayErrors', sortable: false, label: '원정 실책' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsTennis: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        // { key: 'tiebreak', sortable: false, label: '타이브레이크' },
        { key: 'homeTeamName', sortable: false, label: '홈탐' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        // { key: 'homeServe', sortable: false, label: '홈 서브' },
        { key: 'homeGameScore', sortable: false, label: '홈 점수' },
        // { key: 'homeSet1', sortable: false, label: '홈 1세트' },
        // { key: 'homeSet2', sortable: false, label: '홈 2세트' },
        // { key: 'homeSet3', sortable: false, label: '홈 3세트' },
        // { key: 'homeSet4', sortable: false, label: '홈 4세트' },
        // { key: 'homeSet5', sortable: false, label: '홈 5세트' },
        // { key: 'homeWinner', sortable: false, label: '홈 승자' },
        // { key: 'awayServe', sortable: false, label: '원정 서브' },
        { key: 'awayGameScore', sortable: false, label: '원정 점수' },
        // { key: 'awaySet1', sortable: false, label: '원정 1세트' },
        // { key: 'awaySet2', sortable: false, label: '원정 2세트' },
        // { key: 'awaySet3', sortable: false, label: '원정 3세트' },
        // { key: 'awaySet4', sortable: false, label: '원정 4세트' },
        // { key: 'awaySet5', sortable: false, label: '원정 5세트' },
        // { key: 'awayWinner', sortable: false, label: '원정 승자' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsVolleyball: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        // { key: 'homeS1', sortable: false, label: '홈 세트 1' },
        // { key: 'homeS2', sortable: false, label: '홈 세트 2' },
        // { key: 'homeS3', sortable: false, label: '홈 세트 3' },
        // { key: 'homeS4', sortable: false, label: '홈 세트 4' },
        // { key: 'homeS5', sortable: false, label: '홈 세트 5' },
        // { key: 'awayS1', sortable: false, label: '원정 세트 1' },
        // { key: 'awayS2', sortable: false, label: '원정 세트 2' },
        // { key: 'awayS3', sortable: false, label: '원정 세트 3' },
        // { key: 'awayS4', sortable: false, label: '원정 세트 4' },
        // { key: 'awayS5', sortable: false, label: '원정 세트 5' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsFootball: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        // { key: 'events', sortable: false, label: '이벤트' },
        // { key: 'createdAt', sortable: false, label: '생성일' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsHockey: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        // { key: 'scoreP1', sortable: false, label: '1피리어드 점수' },
        // { key: 'scoreP2', sortable: false, label: '2피리어드 점수' },
        // { key: 'scoreP3', sortable: false, label: '3피리어드 점수' },
        { key: 'scoreOver', sortable: false, label: '연장전 점수' },
        { key: 'scorePen', sortable: false, label: '승부차기 점수' },
        // { key: 'eventP1', sortable: false, label: '1피리어드 이벤트' },
        // { key: 'eventP2', sortable: false, label: '2피리어드 이벤트' },
        // { key: 'eventP3', sortable: false, label: '3피리어드 이벤트' },
        // { key: 'eventOver', sortable: false, label: '연장전 이벤트' },
        // { key: 'eventPen', sortable: false, label: '승부차기 이벤트' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      fieldsEsports: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'gameId2', sortable: false, label: 'GAME ID2' },
        { key: 'status', sortable: false, label: '상태' },
        { key: 'gameDate', sortable: false, label: '경기 일시' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그 명' },
        { key: 'round', sortable: false, label: '라운드' },
        { key: 'type', sortable: false, label: '유형' },
        { key: 'homeTeamName', sortable: false, label: '홈팀' },
        { key: 'awayTeamName', sortable: false, label: '원정팀' },
        { key: 'homeTeamResult', sortable: false, label: '홈 결과' },
        { key: 'awayTeamResult', sortable: false, label: '원정 결과' },
        // { key: 'scoreBoard', sortable: false, label: '점수판' },
        // { key: 'games', sortable: false, label: '게임' },
        // { key: 'lineups', sortable: false, label: '라인업' },
        // { key: 'maps', sortable: false, label: '맵' },
        // { key: 'streams', sortable: false, label: '스트림' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      editorOption: {
        // debug: 'info',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // <strong>, <em>, <u>, <s>
            ['blockquote', 'code-block'], // <blockquote>, <pre class="ql-syntax" spellcheck="false">
            [{ header: 1 }, { header: 2 }], // <h1>, <h2>
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // <sub>, <sup>
            [{ indent: '-1' }, { indent: '+1' }], // class제어
            [{ direction: 'rtl' }], // class 제어
            [{ size: ['small', false, 'large', 'huge'] }], // class 제어 - html로 되도록 확인
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
            [{ font: [] }], // 글꼴 class로 제어
            [{ color: [] }, { background: [] }], // style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"
            [{ align: [] }], // class
            // ["clean"],
            ['link', 'image', 'video'],
          ],
        },
        readOnly: true,
        theme: 'snow',
      },
      editorOption2: {
        modules: {
          toolbar: [
            [''],
          ],
        },
      },
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...resultStore.mapGetters({
      fetchGameResultsSoccer: 'fetchGameResultsSoccer',
      fetchGameResultsBasketball: 'fetchGameResultsBasketball',
      fetchGameResultsBaseball: 'fetchGameResultsBaseball',
      fetchGameResultsTennis: 'fetchGameResultsTennis',
      fetchGameResultsVolleyball: 'fetchGameResultsVolleyball',
      fetchGameResultsFootball: 'fetchGameResultsFootball',
      fetchGameResultsHockey: 'fetchGameResultsHockey',
      fetchGameResultsEsports: 'fetchGameResultsEsports',
      updateGameResultsCustom: 'updateGameResultsCustom',
    }),
    getTargetGetter() {
      const targetGetter = {
        soccer: 'fetchGameResultsSoccer',
        basketball: 'fetchGameResultsBasketball',
        baseball: 'fetchGameResultsBaseball',
        tennis: 'fetchGameResultsTennis',
        volleyball: 'fetchGameResultsVolleyball',
        football: 'fetchGameResultsFootball',
        hockey: 'fetchGameResultsHockey',
        esports: 'fetchGameResultsEsports',
      }[this.sports]

      return targetGetter // 이 computed 속성에서 계산된 결과를 반환
    },

  },
  watch: {
    sports(newSport) { // 스포츠가 변경될 때 fetchData 호출
      this.fetchData(newSport)
    },
    periodFrom() {
      this.fetchData(this.sports)
    },
    periodTo() {
      this.fetchData(this.sports)
    },
  },
  created() {
    this.fetchData(this.sports)
  },
  mounted() {
  },
  setup() {
  },
  methods: {
    ...resultStore.mapActions({
      $fetchGameResultsSoccer: FETCH_GAME_RESULTS_SOCCER,
      $fetchGameResultsBasketball: FETCH_GAME_RESULTS_BASKETBALL,
      $fetchGameResultsBaseball: FETCH_GAME_RESULTS_BASEBALL,
      $fetchGameResultsTennis: FETCH_GAME_RESULTS_TENNIS,
      $fetchGameResultsVolleyball: FETCH_GAME_RESULTS_VOLLEYBALL,
      $fetchGameResultsFootball: FETCH_GAME_RESULTS_FOOTBALL,
      $fetchGameResultsHockey: FETCH_GAME_RESULTS_HOCKEY,
      $fetchGameResultsEsports: FETCH_GAME_RESULTS_ESPORTS,
      $updateGameResultsCustom: UPDATE_GAME_RESULTS_CUSTOM,
    }),
    ...resultStore.mapMutations({
      $claerGameResultsCustom: CLEAR_GAME_RESULT_CUSTOM,
    }),

    init() {
    },
    async menuSelect(sports, index) {
      this.sports = sports
      this.selectedButton = index
      this.currentFields = this[`fields${sports.charAt(0).toUpperCase() + sports.slice(1)}`] // 필드 설정 추가
      // this.searchOption = null
      this.inputValue = ''
      await this.fetchData()
      this.$claerGameResultsCustom()
    },
    updateParam(t, f, e, v, i) {
      // console.log(t)
      // console.log(f)
      // console.log(e)
      // console.log(v)
      // console.log(i)
      const events = {
        t, f, e, v, i,
      }
      // console.log(events)
      // store에 param을 update 한다
      this.$store.commit('resultStore/updateManyParam', events)
    },
    async fetchData() {
      const site = this.siteSelected
      console.log('fetchData', this.sports)
      const periodFrom = this.periodFrom ? `${moment(this.periodFrom).format('YYYY.MM.DD')} 00:00` : ''
      const periodTo = this.periodTo ? `${moment(this.periodTo).format('YYYY.MM.DD')} 23:59` : ''
      const { searchOption } = this
      const { inputValue } = this
      this.isLoading = true // 데이터 가져오기 시작 전에 로딩 상태 활성화

      try {
        switch (this.sports) {
          case 'soccer':
            await this.$fetchGameResultsSoccer({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsSoccer
            this.currentFields = this.fieldsSoccer
            break
          case 'basketball':
            await this.$fetchGameResultsBasketball({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsBasketball
            this.currentFields = this.fieldsBasketball
            break
          case 'baseball':
            await this.$fetchGameResultsBaseball({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsBaseball
            this.currentFields = this.fieldsBaseball
            break
          case 'tennis':
            await this.$fetchGameResultsTennis({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsTennis
            this.currentFields = this.fieldsTennis
            break
          case 'volleyball':
            await this.$fetchGameResultsVolleyball({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsVolleyball
            this.currentFields = this.fieldsVolleyball
            break
          case 'football':
            await this.$fetchGameResultsFootball({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsFootball
            this.currentFields = this.fieldsFootball
            break
          case 'hockey':
            await this.$fetchGameResultsHockey({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsHockey
            this.currentFields = this.fieldsHockey
            break
          case 'esports':
            await this.$fetchGameResultsEsports({
              site, periodFrom, periodTo, searchOption, inputValue,
            })
            this.items = this.fetchGameResultsEsports
            this.currentFields = this.fieldsEsports
            break
          default:
            this.items = []
            this.currentFields = []
        }
      } catch (error) {
        this.items = []
      } finally {
        this.isLoading = false // 데이터 로드 완료 후 로딩 상태 비활성화
        this.totalRows = this.items.length
      }

      // this.totalRows = this.items.length
    },
    async submitUpdate(target, data) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      const payload = {
        site: this.siteSelected,
        sports: this.sports,
        idx: data.idx,
      }
      try {
        console.log(target)
        if (target === 'updateGameResultsCustom') {
          await this.$updateGameResultsCustom(payload)
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
          this.$claerGameResultsCustom()
        })
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    handleImageError(e) {
      e.target.src = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_league_full_name_svg/default.svg'
    },
    rowClickHandler(record) {
      let content = ''
      if (typeof record === 'object' && record !== null) {
        content = JSON.stringify(record, null, 2) // 객체를 JSON 문자열로 변환
      } else {
        content = String(record) // 다른 타입의 데이터는 문자열로 변환
      }
      if (new Blob([content]).size > 10) {
        this.detailContent = content // 문자열 할당
        this.$refs['my-modal'].show()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 110px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
