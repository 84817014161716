<template>

  <b-card>
    <span class="pl-1 row justify-content-between">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        공지사항 생성</h4>
    </span>
    <hr class="pb-1">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="제목"
            label-for="notice-title"
            label-cols-sm="2"
          >
            <b-form-input
              id="popup-title"
              v-model="detailTitle"
              class="font-small-3 text-info"
              size="sm"
              placeholder="공지사항 제목을 입력하세요"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div class="mt-2">
      <quill-editor
        v-model="detailContent"
        :options="editorOption"
      />
    </div>
    <hr>
    <div class="row">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="float-right"
          @click="validationForm('upsertBoard')"
        >
          저장
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          class="float-right mr-1"
          :to="{ name: 'notice-settings' }"
        >
          취소
        </b-button>
      </b-col>
    </div>
  </b-card>

</template>

<script>
import {
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor'

import { UPSERT_BOARD } from '@/store/notice/mutation'
import { createNamespacedHelpers } from 'vuex'
import router from "@/router";

const noticeStore = createNamespacedHelpers('noticeStore')

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BForm,
    BRow,
    BCol,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
  },
  data() {
    return {
      detailContent: `<h1>공지사항 예시</h1>
                      <p><br></p>
                      <p>[드라이브]<p><br>
                      <p>– Drive탐색기 Tray 메뉴에서 노출되던 ‘정보’가 환경설정 하위로 이동합니다.<p><br>
                      <p>  [캘린더]<p><br>
                      <p>– 모바일앱 캘린더에서 일정을 작성하거나 조회 시 ‘장소’ 필드 하단에 제공했던 주소 정보 표기 영역이 제거됩니다.<p><br>
                      <p>  [주소록]<p><br>
                      <p>– PC웹에서 좌측 상단 ‘그룹’ 아이콘 선택 후 보이는 페이지에서 ‘그룹 마스터’ 필드가 제거됩니다.<p><br>
                      <p>– PC웹에서 구성원 검색 시 보이는 페이지에서 ‘그룹’ 필드가 제거됩니다.<p><br>
                      <p><br></p>
                     `,
      editorOption: {
        // debug: 'info',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // <strong>, <em>, <u>, <s>
            ['blockquote', 'code-block'], // <blockquote>, <pre class="ql-syntax" spellcheck="false">
            [{ header: 1 }, { header: 2 }], // <h1>, <h2>
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // <sub>, <sup>
            [{ indent: '-1' }, { indent: '+1' }], // class제어
            [{ direction: 'rtl' }], // class 제어
            [{ size: ['small', false, 'large', 'huge'] }], // class 제어 - html로 되도록 확인
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
            [{ font: [] }], // 글꼴 class로 제어
            [{ color: [] }, { background: [] }], // style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"
            [{ align: [] }], // class
            // ["clean"],
            ['link', 'image', 'video'],
          ],
        },
        placeholder: '글을 입력해 주세요...',
        readOnly: true,
        theme: 'snow',
      },
    }
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...noticeStore.mapActions({
      $upsertBoard: UPSERT_BOARD,
    }),
    validationForm(target) {
      if (this.detailTitle === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '제목을 입력해주세요',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        this.submit(target)
      }
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    confirmYes(target) {
      const mySite = this.siteSelected
      const adminId = this.userData.userid
      try {
        if (target === 'upsertBoard') {
          this.$upsertBoard({
            site: mySite,
            title: this.detailTitle,
            content: this.detailContent,
            enrollId: adminId,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          router.replace('/boards/notice-settings')
        })
      }
    },
    // onContext(ctx) {
    //   // The date formatted in the locale, or the `label-no - date - selected` string
    //   this.formattedFrom = ctx.selectedFormatted
    //   // The following will be an empty string until a valid date is entered
    //   this.selectedFrom = ctx.selectedYMD
    //   // The date formatted in the locale, or the `label-no - date - selected` string
    //   this.formattedTo = ctx.selectedFormatted
    //   // The following will be an empty string until a valid date is entered
    //   this.selectedTo = ctx.selectedYMD
    // },
  },
}
</script>

<style scoped>
/*.ql-toolbar {*/
/*  background-color: #e5e2e2 !important;*/
/*}*/
</style>
