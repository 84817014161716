<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in filteredItems"
      :key="item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import {createNamespacedHelpers} from 'vuex'
import { MENU_SET_SITE_ADMIN_MENU_PERMISSION } from '@/store/settings/action'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    menuPermission() {
      return this.$store.getters['rootStore/menuPermission']
    },
    // ...settingsStore.mapGetters({
    //   menuSetSiteAdminMenuPermission: 'menuSetSiteAdminMenuPermission',
    // }),
    // Filter the items based on permissions
    filteredItems() {
      if (this.isLoading || this.menuPermission.length === 0) return []

      return this.items.filter(item => {
        // '총판 관리'는 항상 표시
        if (item.title === '총판 관리') return true

        // '마스터 관리자'는 authMasterAdmin이 'Y'일 때만 표시
        if (item.title === '마스터 관리자') {
          return this.menuPermission[0].authMasterAdmin === 'Y'
        }

        // '슈퍼 관리자'는 authSuperAdmin이 'Y'일 때만 표시
        if (item.title === '슈퍼 관리자') {
          return this.menuPermission[0].authSuperAdmin === 'Y'
        }

        // 그 외 나머지 항목들은 authBasicAdmin이 'Y'일 때만 표시
        return this.menuPermission[0].authBasicAdmin === 'Y'
      })
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $menuSetSiteAdminMenuPermission: MENU_SET_SITE_ADMIN_MENU_PERMISSION,
    }),
    async fetchData() {
      try {
        // await this.$menuSetSiteAdminMenuPermission({
        //   userid: this.userData.userid,
        // })
      } catch (error) {
        console.error('Failed to fetch admin settings:', error)
      } finally {
        // Set loading to false after fetching data
        this.isLoading = false
      }
    },
  },
}
</script>
