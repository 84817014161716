<template>
  <div>
    <b-modal
      id="messagesHistoySendReceiversInfoModal"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      body-class=""
      @hidden="closeModal"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          수신 확인
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="closeModal"
          />
        </div>
      </template>

      <b-card no-body>
        <div class="table-container">
          <b-table
            responsive
            striped
            hover
            small
            :items="receiverItems"
            :fields="receiverFields"
            class="text-center table-scroll mb-2"
          >
            <template #cell(index)="data">
              <div style="text-align: center; min-width: 2rem">
                {{ data.index + 1 }}
              </div>
            </template>
            <template #cell(isRead)="data">
              <b-badge :variant="data.item.isRead ? 'success': 'dark'">
                {{ data.item.isRead ? '열람' : '미열람' }}
              </b-badge>
            </template>
            <template #cell(readAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 11rem"
              >
                {{ data.item.readAt }}
              </div>
            </template>
          </b-table>
        </div>
        <div>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mx-auto"
            >
              <div class="text-center">
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  size="sm"
                  @click="closeModal"
                >
                  닫기
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, BCard, BRow, BCol, BButton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable, BCard, BRow, BCol, BButton, BBadge,
  },
  directives: {
    Ripple,
  },
  props: ['messagesReceivers'],
  data() {
    return {
      receiverFields: [
        { key: 'index', label: 'No.'},
        { key: 'receiverId', label: '수신 회원' },
        { key: 'isRead', label: '열람 여부' },
        { key: 'receiverIp', label: '열람 IP' },
        { key: 'readAt', label: '열람 시간' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    receiverItems() {
      return this.messagesReceivers
    },
  },
  methods: {
    showModal() {
      this.$bvModal.show('messagesHistoySendReceiversInfoModal')
    },
    closeModal() {
      this.$bvModal.hide('messagesHistoySendReceiversInfoModal')
    },
  },
}
</script>
<style>
</style>
