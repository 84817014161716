<template>
  <b-card no-body>
    <b-card-header style="margin-bottom: -20px">
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Cumulative Profit Chart
        </b-card-title>
        <b-card-sub-title>누적 수익 차트</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', onChange: handleDateChange }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          style="width: 210px;"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>
    <!-- badge -->
    <div class="d-flex justify-content-end flex-wrap pr-3">
      <h5 class="font-weight-bolder mb-0 mr-1 text-success">
        ₩ {{ totalData.toLocaleString() }}
      </h5>
      <!--      <b-badge variant="light-secondary">-->
      <!--        <feather-icon-->
      <!--          icon="ArrowUpIcon"-->
      <!--          size="16"-->
      <!--          class="text-primary mr-25"-->
      <!--        />-->
      <!--        <span class="align-middle">20%</span>-->
      <!--      </b-badge>-->
    </div>
    <!--/ badge -->
    <b-card-body>
      <vue-apex-charts
        type="line"
        height="400"
        :options="lineChartSimple.chartOptions"
        :series="lineChartSimple.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'

import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
  FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
} from '@/store/notice/action'
import { avatarText } from '@core/utils/filter'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    // siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    ...noticeStore.mapGetters({
      fetchRequestAlarmsDepositFinish: 'fetchRequestAlarmsDepositFinish',
      fetchRequestAlarmsExchangeFinish: 'fetchRequestAlarmsExchangeFinish',
    }),
  },
  watch: {
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      // this.fetchData()
    },
  },
  created() {
    // 누적 수익 차트 소켓 조회
    this.$socket.on('requestAlarmsDepositLineChartDashBoard', data => {
      // console.log('requestAlarmsDepositLineChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
    this.$socket.on('requestAlarmsExchangeLineChartDashBoard', data => {
      // console.log('requestAlarmsExchangeLineChartDashBoard: ', data)
      this.processNextChartData(data) // 다음 작업을 호출
    })
  },
  mounted() {
    this.fetchData()
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []

    // 'startOfMonth'부터 'endOfMonth'까지의 각 날짜를 'MM-DD' 형식으로 배열에 추가
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M/D'))
    }
    return {
      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      totalData: 0,
      lineChartSimple: {
        series: [
          {
            data: [-100, 200, -5700, -800, 300, 870, 1650, 2260, 1240, 1200, 660, 300, 695,
              210, 160, 80, 220, 460, 540, 790, 910, 1510, 1800, 1740, 1620, 2200,
              3300, 4410, 5723],
          },
        ],
        chartOptions: {
          chart: {
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          markers: {
            strokeWidth: 7,
            strokeOpacity: 1,
            strokeColors: [$themeColors.light],
            colors: [$themeColors.warning],
          },
          colors: ['#71e9fa'],
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          xaxis: {
            categories:
            monthDays,
            labels: {
              rotate: -45, // 레이블을 -45도 회전
            },
          },
          yaxis: {
            labels: {
              formatter(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              },
            },
          },
          tooltip: {
            custom(data) {
              return `${'<div class="px-1 py-50"><span>'}${
                data.series[data.seriesIndex][data.dataPointIndex]
              }</span></div>`
            },
          },
        },
      },
      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsDepositFinish: FETCH_REQUEST_ALARMS_DEPOSIT_FINISH,
      $fetchRequestAlarmsExchangeFinish: FETCH_REQUEST_ALARMS_EXCHANGE_FINISH,
    }),
    async fetchData() {
      if (this.periodTo === undefined) {
        this.periodTo = this.periodFrom
      }
      const periodFromWithTime = moment(this.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const periodToWithTime = moment(this.periodTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      // 시, 분, 초가 추가된 날짜를 다시 변수에 할당
      this.periodFrom = periodFromWithTime
      this.periodTo = periodToWithTime

      await this.fetchDepositFinish()
      await this.fetchExchangeFinish()
      await this.sprepareChartData()
    },
    async fetchDepositFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsDepositFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      // console.log('Cumulative fetchRequestAlarmsDepositFinish::', this.fetchRequestAlarmsDepositFinish)
    },
    async fetchExchangeFinish() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsExchangeFinish({
        site: mySite,
        confirmed: 'CONFIRMED',
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
      // console.log('Cumulative fetchRequestAlarmsExchangeFinish::', this.fetchRequestAlarmsExchangeFinish)
    },
    async sprepareChartData() {
      this.totalData = 0
      // startOfMonth부터 endOfMonth까지의 depositFinish 데이터 필터링
      const filteredDepositFinish = this.fetchRequestAlarmsDepositFinish.filter(entry => moment(entry.confirmAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))

      // startOfMonth부터 endOfMonth까지의 exchangeFinish 데이터 필터링
      const filteredExchangeFinish = this.fetchRequestAlarmsExchangeFinish.filter(entry => moment(entry.confirmAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))

      // startOfMonth부터 endOfMonth까지의 날짜 목록 생성
      const dateRange = []
      const currentDate = moment(this.periodFrom)
      while (currentDate.isSameOrBefore(this.periodTo)) {
        dateRange.push(currentDate.format('M/D'))
        currentDate.add(1, 'day')
      }

      // 충전(deposit) 데이터 가공
      const depositData = dateRange.map(date => {
        const matchingEntries = filteredDepositFinish.filter(entry => moment(entry.confirmAt).format('M/D') === date)
        const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        return {
          x: date,
          y: totalRequestCash,
        }
      })

      // 환전(exchange) 데이터 가공
      const exchangeData = dateRange.map(date => {
        const matchingEntries = filteredExchangeFinish.filter(entry => moment(entry.confirmAt).format('M/D') === date)
        const totalRequestCash = matchingEntries.reduce((sum, entry) => sum + entry.requestCash, 0)
        return {
          x: date,
          y: totalRequestCash,
        }
      })

      // 충전(deposit) - 환전(exchange)을 계산하여 하나의 데이터로 합치기
      const combinedData = dateRange.map((date, index) => ({
        x: date,
        y: depositData[index].y - exchangeData[index].y,
      }))

      // lineAreaChartSpline의 series에 데이터 적용
      this.lineChartSimple.series = [
        {
          name: '충전-환전',
          data: combinedData,
        },
      ]
      // 모든 날짜의 총 합 계산
      const totalSum = combinedData.reduce((sum, data) => sum + data.y, 0)
      this.totalData = totalSum
    },
    async processNextChartData(data) {
      if (data.category === 'DEPOSIT') {
        if (data.confirmed === 'CONFIRMED') {
          this.fetchRequestAlarmsDepositFinish.push(data)
        } else if (data.confirmed === 'CANCELED') {
          for (let i = this.fetchRequestAlarmsDepositFinish.length - 1; i >= 0; i -= 1) {
            if (this.fetchRequestAlarmsDepositFinish[i].paymentId === data.paymentId) {
              this.fetchRequestAlarmsDepositFinish.splice(i, 1)
            }
          }
        }
      } else if (data.category === 'EXCHANGE') {
        if (data.confirmed === 'CONFIRMED') {
          this.fetchRequestAlarmsExchangeFinish.push(data)
        } else if (data.confirmed === 'CANCELED') {
          for (let i = this.fetchRequestAlarmsExchangeFinish.length - 1; i >= 0; i -= 1) {
            if (this.fetchRequestAlarmsExchangeFinish[i].paymentId === data.paymentId) {
              this.fetchRequestAlarmsExchangeFinish.splice(i, 1)
            }
          }
        }
      }
      await this.sprepareChartData()
    },
    handleDateChange(selectedDates, dateStr, instance) {
      this.$nextTick(() => {
        // selectedDates 배열의 길이가 2인 경우에만 fetchData 함수 호출
        if (selectedDates.length === 2) {
          this.fetchData()
        }
      })
    },
  },
}
</script>
