import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'regenerator-runtime/runtime'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/portal-vue'
import '@/libs/toastification'
import {createProvider} from "@/vue-apollo";

import io from 'socket.io-client'

// Axios Mock Adapter
import '@/@fake-db/db'

import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs().tz('Asia/Seoul')

Vue.filter('formatDateMinute', (value, format = 'YYYY-MM-DD HH:mm') => {
  if (!value) {
    return ''
  }
  return dayjs(value).tz().format(format)
})
Vue.filter('formatDate', (value, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) {
    return ''
  }
  return dayjs(value).tz().format(format)
})
Vue.filter('formatDateSmpl', (value, format = 'YYYY-MM-DD') => {
  if (!value) {
    return ''
  }
  return dayjs(value).tz().format(format)
})
Vue.filter('formatNumber', value => {
  if (!value) return 0
  return parseFloat(value).toLocaleString()
})
// const socket = io(process.env.VUE_APP_WS_ENDPOINT)
// Vue.prototype.$socket = socket

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue()
console.log('EventBus setup complete')

Vue.prototype.$site = process.env.VUE_APP_SITE
console.log('Admin Site setup complete')

new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: h => h(App),
  // sockets: {
  //   connect() {
  //     console.log('socket connected')
  //   },
  //   customEmit(val) {
  //     console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
  //   },
  // },
}).$mount('#app')
