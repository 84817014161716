<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      @shown="btnModal('show')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          롤링금 산정 내역
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.prevent="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>

        <div>
          <!-- Table Fitler -->
          <div class="mb-2">
            <!-- Search Condition -->
            <b-row>
              <b-col sm="3">
                <b-form-group
                  v-if="false"
                  class="font-small-3 mt-lg-1"
                  label="적용일시"
                  label-for="issueDate"
                  label-align-sm="2"
                  content-cols-sm="6"
                >
                  <b-form-select
                    id="issueDate"
                    ref="issueDate"
                    v-model="selectedIssueDate"
                    :options="issueDateOptions"
                    class="font-small-3"
                    style="color:rgb(229,166,48)"
                  >
                    <template #first>
                      <option
                        value=""
                        style="color: white;"
                      >
                        == 전체 ==
                      </option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                offset-sm="3"
                sm="6"
              >
                <b-form-group
                  class="font-small-3 mt-lg-1"
                  label="검색"
                  label-for="filterInput"
                  label-align-sm="2"
                  content-cols-sm="10"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="searchFilter"
                      type="search"
                      placeholder=""
                      style="color: #99dff1"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!searchFilter"
                        @click="searchFilter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!-- table content -->
          <div>
            <b-table
              ref="messagesSendHistoyTable"
              responsive
              show-empty
              small
              empty-text="No matching records found"
              style="text-align: center; font-size: small"
              :items="filteredItems"
              :fields="fields"
            >
              <!-- Table Header -->
              <template v-slot:head(sportBettingAmount)>
                스포츠<br>배팅금
              </template>
              <template v-slot:head(sportRollingAmount)>
                스포츠<br>롤링금
              </template>
              <template v-slot:head(casinoBettingAmount)>
                카지노<br>배팅금
              </template>
              <template v-slot:head(casinoRollingAmount)>
                카지노<br>롤링금
              </template>
              <template v-slot:head(slotBettingAmount)>
                슬롯<br>배팅금
              </template>
              <template v-slot:head(slotRollingAmount)>
                슬롯<br>롤링금
              </template>

              <!-- Table Body -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(user)="data">
                <div
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: `${(data.item.level || 0) * 20}px`,
                  }"
                >
                  <feather-icon
                    v-if="data.item.role === 'branch'"
                    style="width: 20px; text-align: center;"
                    icon="ChevronDownIcon"
                    size="14"
                  />

                  <!-- 사용자 정보 -->
                  <b-avatar
                    size="24"
                    :text="avatarText(data.item.role)"
                    :variant="resolveUserRoleVariant(data.item.role)"
                    :style="{ marginRight: '10px' }"
                  />
                  <div
                    :style="{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }"
                  >
                    <div :style="{ marginBottom: '0', color: 'green' }">
                      {{ data.item.userid }}
                    </div>
                    <div
                      v-if="false"
                      :style="{ marginBottom: '0' }"
                    >
                      {{ data.item.nickname }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(sportBettingAmount)="data">
                {{ (data.item.sport.bettingAmount).toLocaleString() }}
              </template>
              <template #cell(sportRollingAmount)="data">
                {{ (data.item.sport.rollingAmount).toLocaleString() }}
              </template>
              <template #cell(sportRollingFormula)="data">
                <div
                  v-if="data.item.sportBettingAmount > 0"
                  style="text-align: center; minWidth: 21rem; padding: 0.5rem"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td style="border-top: 0px; padding: 10px 10px 5px 10px">
                        <strong>상위</strong>
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.parentFormula }}
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.parentRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: 0px; padding: 10px 10px 5px 10px">
                        <strong>본인</strong>
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.selfFormula }}
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.selfRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <template #cell(casinoBettingAmount)="data">
                {{ (data.item.casino.bettingAmount).toLocaleString() }}
              </template>
              <template #cell(casinoRollingAmount)="data">
                {{ (data.item.casino.rollingAmount).toLocaleString() }}
              </template>
              <template #cell(casinoRollingFormula)="data">
                <div
                  v-if="data.item.casinoBettingAmount > 0"
                  style="text-align: center; minWidth: 21rem; padding: 0.5rem"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td style="border-top: 0px; padding: 10px 10px 5px 10px">
                        <strong>상위</strong>
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.parentFormula }}
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.parentRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: 0px; padding: 10px 10px 5px 10px">
                        <strong>본인</strong>
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.selfFormula }}
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.selfRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <template #cell(slotBettingAmount)="data">
                {{ (data.item.slot.bettingAmount).toLocaleString() }}
              </template>
              <template #cell(slotRollingAmount)="data">
                {{ (data.item.slot.rollingAmount).toLocaleString() }}
              </template>
              <template #cell(slotRollingFormula)="data">
                <div
                  v-if="data.item.slotBettingAmount > 0"
                  style="text-align: center; minWidth: 21rem; padding: 0.5rem"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td style="border-top: 0px; padding: 10px 10px 5px 10px">
                        <strong>상위</strong>
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.parentFormula }}
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.parentRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: 0px; padding: 10px 10px 5px 10px">
                        <strong>본인</strong>
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.selfFormula }}
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.selfRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <!-- Table Footer -->
              <template
                slot="bottom-row"
              >
                <td
                  colspan="3"
                  class="text-center"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('sport', 'bettingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('sport', 'rollingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('casino', 'bettingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('casino', 'rollingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('slot', 'bettingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('slot', 'rollingAmount').toLocaleString() }}
                </td>
              </template>
            </b-table>
          </div>
        </div>

        <b-row
          v-if="false"
        >
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              size="sm"
              class="float-right"
              @click.prevent="btnSave"
            >
              저장
            </b-button>
            <b-button
              ref="reset"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="reset"
              variant="primary"
              size="sm"
              class="float-right mr-2"
              @click.prevent="btnModal('close')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard, BRow, BCol, BButton,
  BFormGroup,
  BFormInput, BInputGroupAppend, BInputGroup,
  BFormSelect,
  BTable,
  VBTooltip,
  BMedia,
  BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')

export default {
  name: 'BranchCalculateDailyBalanceRolling',
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BTable,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    fetchBalanceRolling: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,
      // TableFilter
      selectedIssueDate: '',
      searchFilter: '',

      // Tablefields
      fields_: [
        { key: 'index', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'issueDate', sortable: false, label: '적용일시', thClass: 'col3', thStyle: { minWidth: '9rem' }, formatter: this.formatTime },
        { key: 'role', sortable: false, label: 'role', thClass: 'col3' },
        { key: 'userid', sortable: false, label: '유저', thClass: 'col3' },

        // 스포츠
        { key: 'sportBettingAmount', sortable: false, label: '스포츠 배팅금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'sportRollingAmount', sortable: false, label: '스포츠 롤링금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'sportRollingFormula', sortable: false, label: '스포츠 산정식', thClass: 'col3', tdClass: 'p-0', thStyle: { minWidth: '10rem' } },

        // 카지노
        { key: 'casinoBettingAmount', sortable: false, label: '카지노 배팅금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'casinoRollingAmount', sortable: false, label: '카지노 롤링금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'casinoRollingFormula', sortable: false, label: '카지노 산정식', thClass: 'col3', tdClass: 'p-0', thStyle: { minWidth: '10rem' } },

        // 슬롯
        { key: 'slotBettingAmount', sortable: false, label: '슬롯 배팅금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'slotRollingAmount', sortable: false, label: '슬롯 롤링금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'slotRollingFormula', sortable: false, label: '슬롯 산정식', thClass: 'col3', tdClass: 'p-0', thStyle: { minWidth: '10rem' } },
      ],
      fields: [
        { key: 'index', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'user', sortable: false, label: '유저', thClass: 'col3' },
        { key: 'recommendNickname', sortable: false, label: '상위', thClass: 'col3' },

        // 스포츠
        { key: 'sportBettingAmount', sortable: false, label: '스포츠 배팅금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'sportRollingAmount', sortable: false, label: '스포츠 롤링금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        // { key: 'sportRollingFormula', sortable: false, label: '스포츠 산정식', thClass: 'col3', tdClass: 'p-0', thStyle: { minWidth: '10rem' } },

        // 카지노
        { key: 'casinoBettingAmount', sortable: false, label: '카지노 배팅금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'casinoRollingAmount', sortable: false, label: '카지노 롤링금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        // { key: 'casinoRollingFormula', sortable: false, label: '카지노 산정식', thClass: 'col3', tdClass: 'p-0', thStyle: { minWidth: '10rem' } },

        // 슬롯
        { key: 'slotBettingAmount', sortable: false, label: '슬롯 배팅금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        { key: 'slotRollingAmount', sortable: false, label: '슬롯 롤링금', thClass: 'col3', tdClass: 'text-right', thStyle: { minWidth: '8rem' }, formatter: this.formatNumberWithCommas },
        // { key: 'slotRollingFormula', sortable: false, label: '슬롯 산정식', thClass: 'col3', tdClass: 'p-0', thStyle: { minWidth: '10rem' } },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    issueDateOptions() {
      return this.fetchBalanceRolling.reduce((acc, item) => {
        const time = item.issueDate.split(' ')[1]
        const hoursMinutes = `${time.split(':')[0]}시 ${time.split(':')[1]}분`

        if (!acc.some(option => option.value === item.issueDate)) {
          acc.push({
            text: hoursMinutes,
            value: item.issueDate,
          })
        }

        return acc
      }, [])
    },
    _calculatedItems() {
      const data = this.fetchBalanceRolling

      const groupedData = data.reduce((acc, entry) => {
      // issueDate를 키로 그룹화
        if (!acc[entry.issueDate]) {
          acc[entry.issueDate] = []
        }
        acc[entry.issueDate].push(entry)
        return acc
      }, {})

      Object.keys(groupedData).forEach(issueDate => {
        const group = groupedData[issueDate]

        // 그룹에서 seq가 0인 부모 객체 찾기
        const parent = group.find(user => user.seq === 0)

        if (parent) {
          groupedData[issueDate] = group.map(child => {
            if (child.seq > 0) {
              // 스포츠 롤링 계산
              const sportRollingAmountTP = child.sportBettingAmount > 0 ? (child.sportBettingAmount * parent.rateSportRolling) / 100 : 0
              const sportRollingFormula = child.sportBettingAmount > 0 ? {
                parentRollingAmount: (child.sportBettingAmount * parent.rateSportRolling) / 100,
                parentFormula: `(${child.sportBettingAmount.toLocaleString()} * ${parent.rateSportRolling}) / 100`,
                selfRollingAmount: (child.sportBettingAmount * child.rateSportRolling) / 100,
                selfFormula: `(${child.sportBettingAmount.toLocaleString()} * ${child.rateSportRolling}) / 100`,
              } : ''

              // 카지노 롤링 계산
              const casinoRollingAmountTP = child.casinoBettingAmount > 0 ? (child.casinoBettingAmount * parent.rateCasinoRolling) / 100 : 0
              const casinoRollingFormula = child.casinoBettingAmount > 0 ? {
                parentRollingAmount: (child.casinoBettingAmount * parent.rateCasinoRolling) / 100,
                parentFormula: `(${child.casinoBettingAmount.toLocaleString()} * ${parent.rateCasinoRolling}) / 100`,
                selfRollingAmount: (child.casinoBettingAmount * child.rateCasinoRolling) / 100,
                selfFormula: `(${child.casinoBettingAmount.toLocaleString()} * ${child.rateCasinoRolling}) / 100`,
              } : ''

              // 슬롯 롤링 계산
              const slotRollingAmountTP = child.slotBettingAmount > 0 ? (child.slotBettingAmount * parent.rateSlotRolling) / 100 : 0
              const slotRollingFormula = child.slotBettingAmount > 0 ? {
                parentRollingAmount: (child.slotBettingAmount * parent.rateSlotRolling) / 100,
                parentFormula: `(${child.slotBettingAmount.toLocaleString()} * ${parent.rateSlotRolling}) / 100`,
                selfRollingAmount: (child.slotBettingAmount * child.rateSlotRolling) / 100,
                selfFormula: `(${child.slotBettingAmount.toLocaleString()} * ${child.rateSlotRolling}) / 100`,
              } : ''

              return {
                ...child,
                sportRollingAmountTP,
                sportRollingFormula,
                casinoRollingAmountTP,
                casinoRollingFormula,
                slotRollingAmountTP,
                slotRollingFormula,
              }
            }
            return child // seq가 0인 경우 원래 객체를 반환
          })
        }
      })

      const flatData = Object.values(groupedData).flat()
      // console.log(JSON.stringify(flatData))
      return flatData
    },
    calculatedItems() {
      const data = this.fetchBalanceRolling

      const groupedUser = data.reduce((acc, item) => {
        if (!acc[item.userid]) {
          acc[item.userid] = {
            userid: item.userid,
            sportBettingAmount: 0,
            selfSportRollingAmount: 0,
            lowerSportRollingAmount: 0,
            upperSportRollingAmount: 0,
            casinoBettingAmount: 0,
            selfCasinoRollingAmount: 0,
            lowerCasinoRollingAmount: 0,
            upperCasinoRollingAmount: 0,
            slotBettingAmount: 0,
            selfSlotRollingAmount: 0,
            lowerSlotRollingAmount: 0,
            upperSlotRollingAmount: 0,
          }
        }
        return acc
      }, {})
      // console.log(JSON.stringify(groupedUser))

      const groupedData = data.reduce((acc, item) => {
        if (!acc[item.issueDate]) {
          acc[item.issueDate] = []
        }
        acc[item.issueDate].push(item)
        return acc
      }, {})

      Object.keys(groupedData).forEach(issueDate => {
        const group = groupedData[issueDate]

        // 그룹에서 seq가 0인 부모 객체 찾기
        const parent = group.find(user => user.seq === 0)
        // console.log(issueDate, parent, group)
        if (parent) {
          groupedData[issueDate] = group.forEach(child => {
            if (child.seq > 0) {
              // 스포츠 롤링 계산
              const selfSportRollingAmount = child.sportBettingAmount > 0 ? (child.sportBettingAmount * child.rateSportRolling) / 100 : 0
              const upperSportRollingAmount = child.sportBettingAmount > 0 ? (child.sportBettingAmount * parent.rateSportRolling) / 100 : 0

              // 카지노 롤링 계산
              const selfCasinoRollingAmount = child.casinoBettingAmount > 0 ? (child.casinoBettingAmount * child.rateCasinoRolling) / 100 : 0
              const upperCasinoRollingAmount = child.casinoBettingAmount > 0 ? (child.casinoBettingAmount * parent.rateCasinoRolling) / 100 : 0

              // 슬롯 롤링 계산
              const selfSlotRollingAmount = child.slotBettingAmount > 0 ? (child.slotBettingAmount * child.rateSlotRolling) / 100 : 0
              const upperSlotRollingAmount = child.slotBettingAmount > 0 ? (child.slotBettingAmount * parent.rateSlotRolling) / 100 : 0

              groupedUser[child.userid].sportBettingAmount += child.sportBettingAmount
              groupedUser[child.userid].selfSportRollingAmount += selfSportRollingAmount
              groupedUser[child.userid].upperSportRollingAmount += upperSportRollingAmount
              groupedUser[parent.userid].lowerSportRollingAmount += upperSportRollingAmount

              groupedUser[child.userid].casinoBettingAmount += child.casinoBettingAmount
              groupedUser[child.userid].selfCasinoRollingAmount += selfCasinoRollingAmount
              groupedUser[child.userid].upperCasinoRollingAmount += upperCasinoRollingAmount
              groupedUser[parent.userid].lowerCasinoRollingAmount += upperCasinoRollingAmount

              groupedUser[child.userid].slotBettingAmount += child.slotBettingAmount
              groupedUser[child.userid].selfSlotRollingAmount += selfSlotRollingAmount
              groupedUser[child.userid].upperSlotRollingAmount += upperSlotRollingAmount
              groupedUser[parent.userid].lowerSlotRollingAmount += upperSlotRollingAmount
            }
          })
        }
      })

      const flatData = Object.values(groupedUser).flat()
      // console.log(JSON.stringify(flatData))
      // console.log('Totals by User:', JSON.stringify(flatData))

      return flatData
    },

    transformData() {
      return this.fetchBalanceRolling.map(entry => {
        const { site, userid, username, nickname, recommendNickname, role, rolling } = entry

        // 기본 구조 생성
        const result = {
          site,
          userid,
          username,
          nickname,
          recommendNickname,
          role,
          sport: {
            bettingAmount: 0,
            rollingAmount: 0,
            min_betDate: null,
            max_betDate: null,
          },
          casino: {
            bettingAmount: 0,
            rollingAmount: 0,
            min_betDate: null,
            max_betDate: null,
          },
          slot: {
            bettingAmount: 0,
            rollingAmount: 0,
            min_betDate: null,
            max_betDate: null,
          },
        }

        // `rolling` 배열이 존재하는 경우 처리
        if (Array.isArray(rolling)) {
          ['sport', 'casino', 'slot'].forEach(gameType => {
            const filteredData = rolling.filter(roll => roll.gameType === gameType)

            if (filteredData.length > 0) {
              result[gameType] = {
                bettingAmount: filteredData.reduce((sum, roll) => sum + (roll.bettingAmount || 0), 0), // 배팅금 합계
                rollingAmount: filteredData.reduce((sum, roll) => sum + (roll.rollingAmount || 0), 0),
                min_betDate: filteredData.reduce((min, roll) => (roll.betDate < min ? roll.betDate : min), filteredData[0].betDate),
                max_betDate: filteredData.reduce((max, roll) => (roll.betDate > max ? roll.betDate : max), filteredData[0].betDate),
              }
            }
          })
        }

        return result
      })
    },

    // 검색필터된 Items
    filteredItems() {
      let filteredData = this.transformData

      const buildHierarchy = data => {
        // 1. nickname을 기준으로 데이터 매핑
        const nicknameMap = data.reduce((acc, item) => ({ ...acc, [item.nickname]: item }), {})

        const visited = new Set()
        const hierarchy = []

        // 2. 계층 구조 생성 함수 (재귀)
        function buildTree(nickname, level) {
          if (!nicknameMap[nickname] || visited.has(nickname)) {
            return null
          }
          visited.add(nickname)

          const node = nicknameMap[nickname]
          const children = data
            .filter(
              child => child.recommendNickname === nickname && !visited.has(child.nickname),
            )
            .map(child => buildTree(child.nickname, level + 1))
            .filter(Boolean) // null 제거

          return {
            ...node, // 기존의 노드 속성을 모두 포함
            level,
            children,
          }
        }

        // 3. 최상위 노드 탐색 및 트리 생성
        data.forEach(item => {
          if (!nicknameMap[item.recommendNickname]) {
            const root = buildTree(item.nickname, 0)
            if (root) {
              hierarchy.push(root)
            }
          }
        })

        return hierarchy
      }

      const flattenHierarchy = hierarchy => {
        const flatArray = []

        // 재귀적으로 평탄화
        function traverse(node) {
          flatArray.push({
            ...node,
          })

          // 자식 노드 탐색
          if (node.children && node.children.length > 0) {
            node.children.forEach(child => traverse(child))
          }
        }

        // 최상위 노드부터 순회
        hierarchy.forEach(rootNode => traverse(rootNode))

        return flatArray
      }

      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }
      if (this.selectedIssueDate) {
        filteredData = filteredData.filter(item => item.issueDate && item.issueDate.includes(this.selectedIssueDate))
      }

      filteredData = buildHierarchy(filteredData)
      filteredData = flattenHierarchy(filteredData)

      return filteredData
    },

  },
  methods: {
    // Modal
    btnModal(target) {
      if (target === 'close') {
        // console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
      }
    },

    // fnc
    /* Table Variant */
    cashOutConfirmedVariant(data) {
      return {
        CONFIRMED: { value: 'CONFIRMED', label: '출금승인', style: { color: '#28c76f' } },
        REJECT: { value: 'REJECT', label: '출금부결', style: { color: 'red' } },
        CANCELED: { value: 'CANCELED', label: '출금취소', style: { color: 'yellow' } },
      }[data]
    },
    rowClass(item, type) {
      // baseDate 값에 따라 다른 클래스를 반환
      if (type === 'row') {
        // issueDate에서 시간 부분만 추출 (HH:mm:ss)
        const time = item.issueDate.split(' ')[1]

        // 분(minute) 부분을 추출
        const minutes = parseInt(time.split(':')[1], 10)

        // 분을 5로 나누었을 때 나머지가 짝수면 table-bright', 홀수면 'table-dark'' 클래스를 적용
        return (minutes / 5) % 2 === 0 ? 'table-bright' : 'table-dark'
      }
    },

    /* [formatter] */
    formatTime(value) {
      return moment(value, moment.ISO_8601, true).isValid() ? moment(value).format('HH시 mm분') : value
    },
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString()
    },
    resolveUserRoleVariant(role) {
      if (role === 'user') return 'secondary'
      if (role === 'branch') return 'warning'
      if (role === 'admin') return 'primary'
      if (role === 'godfather') return 'danger'
      return 'secondary'
    },
    getTreeSymbol(item) {
      const level = item.level || 0
      const hasChildren = item.children && item.children.length > 0

      // 트리 심볼 정의
      const symbols = {
        branch: hasChildren ? '┣━' : '┗━', // 현재 노드의 브랜치
        vertical: '┃', // 부모 노드의 연결선
        empty: '│', // 빈 공간 (고정된 너비 사용)
      }

      if (level === 0) return '' // 최상위 노드에는 심볼 없음

      // 상위 레벨의 연결선 생성
      let padding = ''
      for (let i = 1; i < level; i += 1) {
        padding += `${symbols.vertical}   ` // 부모 레벨에서 연결선 추가
      }

      // 현재 노드의 브랜치 추가
      return padding + symbols.branch
    },
    _getTotal(field) {
      return this.filteredItems.reduce((acc, item) => acc + item[field], 0)
    },
    getTotal(category, field) {
    // category: sport, casino, slot
    // field: bettingAmount or rollingAmount
      return this.filteredItems.reduce((acc, item) => {
        if (item[category] && item[category][field] !== undefined) {
          return acc + item[category][field]
        }
        return acc
      }, 0)
    },
  },

}
</script>

<style>
  .modal-dialog {
    max-width: 1000px;
  }
</style>
