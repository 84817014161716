<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        사이트 정산관리
      </h4>
      <hr>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'


export default {
  components: {
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRowsAdmin: 1,
      totalRowsBlock: 1,
      currentPageAdmin: 1,
      currentPageBlock: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields1: [
      ],
      tableItems: [
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
  },
  watch: {

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const mySite = this.siteSelected

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAdmin = filteredItems.length
      this.currentPageAdmin = 1
      this.totalRowsBlock = filteredItems.length
      this.currentPageBlock = 1
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
</style>
