<template>
  <div>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        어드민 IP등록
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          완료
        </b-badge>
      </h4>
      <hr>
      <b-col cols="6">
        <b-form-group
          class="font-small-3"
          label="등록IP"
          label-for="add-admin-IP"
          label-cols-md="4"
        >
          <b-form-input
            id="add-admin-IP"
            v-model="info.addAdminIP"
            class="font-small-3"
            size="sm"
            type="text"
            placeholder="등록한 관리자 IP정보(xxx.xxx.xxx.xxx)"
            style="color:rgb(229,166,48)"
          />
        </b-form-group>
        <b-form-group
          class="font-small-3"
          label="메모"
          label-for="add-admin-desc"
          label-cols-md="4"
        >
          <b-form-input
            id="add-admin-desc"
            v-model="info.addAdminMemo"
            class="font-small-3"
            size="sm"
            type="text"
            placeholder="관리자 메모"
            style="color:rgb(229,166,48)"
          />
        </b-form-group>
        <b-form-group
          class="font-small-3"
          label="관리자역할"
          label-for="add-admin-role"
          label-cols-md="4"
        >
          <b-form-select
            id="add-admin-role"
            v-model="info.addAdminRole"
            :options="options"
            class="font-small-3"
            size="sm"
            type="text"
            style="color:rgb(229,166,48)"
          />
        </b-form-group>
      </b-col>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        class="mt-1"
        variant="primary"
        size="sm"
        @click="submit('insertSetIpAdmin')"
      >
        관리자 등록
      </b-button>
    </b-card>
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        어드민 IP 리스트(접속허용)
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          완료
        </b-badge>
      </h4>
      <hr>
      <div class="m-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <!--                <div class="d-flex justify-content-end">-->
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput2"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!--                </div>-->
          </b-col>
        </b-row>
      </div>

      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchSetIpAdmin"
        :fields="fields2"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPageAdmin"
        @filtered="onFiltered"
      >
        <template
          #cell(edit)="data"
        >
          <div
            class="editClass"
            @click.stop
          >
            <span class="d-inline-flex">
              <div style="min-width: 8rem;">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="outline-primary"
                  style="font-size: smaller;"
                  type="submit"
                  @click="submit('deleteSetIpAdmin', data)"
                >
                  삭제
                </b-button>
              </div>
            </span>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index+1 }}
        </template>
        <template #cell(ip)="data">
          <div
            v-b-tooltip.hover.bottom.v-danger="data.item.ip"
            class="text-info"
          >
            {{ truncateText(data.item.ip, 15) }}
          </div>
        </template>
        <template #cell(adminRole)="data">
          {{ data.item.adminRole }}
        </template>
        <template #cell(memo)="data">
          <div style="text-align: center; min-width: 5rem">
            {{ data.item.memo }}
          </div>
        </template>
        <template #cell(enrollId)="data">
          {{ data.item.enrollId }}
        </template>
        <template #cell(createdAt)="data">
          <div style="text-align: center; min-width: 7rem">
            {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPageAdmin"
              :total-rows="totalRowsAdmin"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import { FETCH_SET_IP_BLOCK, FETCH_SET_IP_ADMIN } from '@/store/settings/action'
import {
  INSERT_SET_IP_BLOCK, DELETE_SET_IP_BLOCK, INSERT_SET_IP_ADMIN, DELETE_SET_IP_ADMIN,
} from '@/store/settings/mutation'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      options: [
        { value: '', text: '선택' },
        { value: 'admin', text: '어드민' },
        { value: 'branch', text: '총판' },
      ],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRowsAdmin: 1,
      totalRowsBlock: 1,
      currentPageAdmin: 1,
      currentPageBlock: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      info: {
        // 차단 IP 수동 등록
        addBlockIP: '',
        addBlockMemo: '',
        addAdminIP: '',
        addAdminMemo: '',
        addAdminRole: '',
      },
      disabled: false,
      isHidden1: true, // 계좌 설정
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'ip', sortable: false, label: 'IP', thClass: 'col2',
        },
        {
          key: 'type', sortable: false, label: '타입', thClass: 'col3',
        },
        {
          key: 'memo', sortable: false, label: '상세정보', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'edit', sortable: false, label: '삭제', thClass: 'col6',
        },
      ],
      tableItems1: [
        {
          no: 1,
          ip: '100.102.122.39',
          type: '수동차단',
          memo: '이상행동 IP로 차단함',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 2,
          ip: '190.102.11.200',
          type: '자동차단(해킹시도)',
          memo: '새로고침 반복(1초당 10회이상)',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 3,
          ip: '111.221.202.19',
          type: '자동차단(해킹시도)',
          memo: '미가입 ID 로그인 반복 시도(5회이상)',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 4,
          ip: '190.102.11.202',
          type: '자동차단(해킹시도)',
          memo: 'PW 반복 실패(5회이상)',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 5,
          ip: '190.102.110.922',
          type: '자동차단(해킹시도)',
          memo: '해외IP 차단(해외 및 VPN)',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'ip', sortable: false, label: 'IP', thClass: 'col2',
        },
        {
          key: 'adminRole', sortable: false, label: '권한', thClass: 'col3',
        },
        {
          key: 'memo', sortable: false, label: '상세정보', thClass: 'col4',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
        {
          key: 'edit', sortable: false, label: '삭제', thClass: 'col6',
        },
      ],
      tableItems2: [
        {
          no: 1,
          ip: '100.102.122.39',
          adminRole: '총판',
          memo: 'MP총판',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 2,
          ip: '100.102.122.39',
          adminRole: '총판',
          memo: 'IC총판',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 3,
          ip: '100.102.122.39',
          adminRole: '총판',
          memo: 'DG총판',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 4,
          ip: '190.102.11.202',
          adminRole: '어드민',
          memo: '철구(사무실2)',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
        {
          no: 5,
          ip: '190.102.110.922',
          adminRole: '어드민',
          memo: '철구(사무실1)',
          enrollId: 'administrator',
          createdAt: '2022-10-04 13:10:22',
        },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...settingsStore.mapGetters({
      fetchSetIpBlock: 'fetchSetIpBlock',
      fetchSetIpAdmin: 'fetchSetIpAdmin',
    }),
    settingsStore() {
      return this.$store.state.settingsStore.fetchSetIpBlock[0]
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetIpBlock: FETCH_SET_IP_BLOCK,
      $insertSetIpBlock: INSERT_SET_IP_BLOCK,
      $deleteSetIpBlock: DELETE_SET_IP_BLOCK,
      $fetchSetIpAdmin: FETCH_SET_IP_ADMIN,
      $insertSetIpAdmin: INSERT_SET_IP_ADMIN,
      $deleteSetIpAdmin: DELETE_SET_IP_ADMIN,
    }),
    updateParam(t, e, v) {
      // console.log(t)
      // console.log(e)
      // console.log(v)
      const events = { t, e, v }
      // this.info[e] = v
      // store에 param을 update 한다
      this.$store.commit('settingsStore/updateParam', events)
      // console.log(this.$store.mainNotice('settingsStore/mainNotice'))
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    async fetchData() {
      const mySite = this.userData.site
      await this.$fetchSetIpBlock({
        site: mySite,
      })
      await this.$fetchSetIpAdmin({
        site: mySite,
      })
      this.totalRowsAdmin = this.fetchSetIpAdmin.length
      this.totalRowsBlock = this.fetchSetIpBlock.length
    },
    // IP 차단
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    confirmYes(target, data) {
      // localStorage.setItem(this.fetchSetRank.length + 1, JSON.stringify(addItems))
      // this.fetchSetRank.push(addItems)
      // console.log('addItems :', addItems)
      const mySite = this.userData.site
      try {
        if (target === 'insertSetIpBlock') {
          this.$insertSetIpBlock({
            site: mySite,
            ip: this.info.addBlockIP,
            type: 'manual',
            memo: this.info.addBlockMemo,
            enrollId: 'admin',
          })
        }
        if (target === 'deleteSetIpBlock') {
          const blockCancelIP = data.item.ip
          this.$deleteSetIpBlock({
            site: mySite,
            ip: blockCancelIP,
          })
        }
        if (target === 'insertSetIpAdmin') {
          this.$insertSetIpAdmin({
            site: mySite,
            ip: this.info.addAdminIP,
            adminRole: this.info.addAdminRole,
            memo: this.info.addAdminMemo,
            enrollId: 'admin',
          })
        }
        if (target === 'deleteSetIpAdmin') {
          const adminCancelIP = data.item.ip
          this.$deleteSetIpAdmin({
            site: mySite,
            ip: adminCancelIP,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
          this.info.addBlockIP = ''
          this.info.addBlockMemo = ''
          this.info.addAdminIP = ''
          this.info.addAdminMemo = ''
          this.info.addAdminRole = ''
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsAdmin = filteredItems.length
      this.currentPageAdmin = 1
      this.totalRowsBlock = filteredItems.length
      this.currentPageBlock = 1
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}

/*.col1 {*/
/*  width: 10%;*/
/*}*/
/*.col2 {*/
/*  width: 20%;*/
/*}*/
/*.col3 {*/
/*  width: 20%;*/
/*}*/
/*.col4 {*/
/*min-width: 200px;*/
/*}*/
/*.col5 {*/
/*  min-width: 140px;*/
/*}*/
/*!* Responsive: Use a vertical column if under 450px wide *!*/
/*@media screen and (max-width: 805px) {*/
/*  .col1 {*/
/*    min-width: 10px;*/
/*  }*/
/*  .col2 {*/
/*    min-width: 200px;*/
/*  }*/
/*  .col3 {*/
/*    min-width: 150px;*/
/*  }*/
/*  .col4 {*/
/*    min-width: 80px;*/
/*  }*/
/*  .col5 {*/
/*    min-width: 140px;*/
/*  }*/
/*}*/
</style>
