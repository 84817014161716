<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          팝업 관리(유저)
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            DB 연계
          </b-badge>
        </h4>
        <hr>
        <b-table
          responsive
          show-empty
          hover
          empty-text="No matching records found"
          :items="fetchSetNoticePopup"
          :fields="fields1"
          style="text-align: center; font-size: small;"
          @row-clicked="rowClickHandler"
        >
          <template
            #cell(index)="data"
            size="sm"
            style="text-align: center; min-width: 3rem"
          >
            {{ data.index + 1 }}
          </template>
          <template #cell(title)="data">
            <div
              size="sm"
              style="text-align: left; min-width: 10rem"
            >
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(term)="data">
            <div
              size="sm"
              style="text-align: left; min-width: 8rem"
            >
              {{ data.item.activeFrom | formatDate('YYYY-MM-DD') }}
              ~{{ data.item.activeTo | formatDate('YYYY-MM-DD') }}
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 7rem"
            >
              {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
          <template #cell(updatedAt)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 7rem"
            >
              {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
          <template #cell(active)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              <div v-if="data.item.active === 'true'">
                Y
              </div>
              <div v-if="data.item.active === 'false'">
                N
              </div>
            </div>
          </template>
          <template
            #cell(edit)="data"
            v-slot:button-content
          >
            <div
              class="editClass"
              style="min-width: 3rem"
              @click.stop
            >
              <feather-icon
                v-b-modal.modal-lg
                icon="Edit2Icon"
                class="mr-1"
                style="color:rgb(229,166,48);"
                type="button"
                @click="rowClick(data)"
              />
              <feather-icon
                icon="TrashIcon"
                class=""
                style="color:rgb(229,166,48);"
                @click="submit('deleteSetNoticePopup', data.item.title)"
              />
            </div>
          </template>
        </b-table>
        <b-modal
          id="modal-lg"
          ref="my-modal"
          small
          title="상세설정"
          centered
          button-size="sm"
          size="lg"
          style="max-width: 400px !important;"
          ok-title="저장"
          @ok="submit('upsertSetNoticePopup')"
        >
          <b-col cols="12">
            <b-form-group
              class="font-small-4 text-info"
              label="제목"
              label-for="event-period"
              label-cols-md="1"
            >
              {{ detailTitle }}
            </b-form-group>
            <b-form-group
              class="font-small-3 text-info"
              label="사용여부"
              label-for="event-period"
              label-cols-md="1"
            >
              <b-form-select
                id="signup-referral"
                v-model="detailActive"
                :options="options1"
                class="select-size font-small-3"
                size="sm"
                style="color:rgb(229,166,48)"
              />
              <!--            </b-form-select>-->
            </b-form-group>
            <b-form-group
              class="font-small-3"
              label="게시기간"
              label-for="event-period"
              label-cols-md="1"
            >
              <b-input-group
                class="calendar-size font-small-3"
                style="padding-top: 0.5rem"
              >
                <b-form-input
                  id="event-fisrst-period-from"
                  v-model="eventFirstPeriodFrom"
                  type="text"
                  placeholder="시작일"
                  autocomplete="off"
                  show-decade-nav
                  size="sm"
                  style="color:rgb(229,166,48)"
                />
                <b-form-datepicker
                  v-model="eventFirstPeriodFrom"
                  show-decade-nav
                  button-only
                  right
                  locale="ko"
                  aria-controls="example-input"
                  size="sm"
                  button-variant="outline-primary"
                  style="height: 30px; padding-right: 10px"
                  @context="onContext"
                />
                <b-form-input
                  id="event-first-period-to"
                  v-model="eventFirstPeriodTo"
                  type="text"
                  placeholder="종료일"
                  autocomplete="off"
                  show-decade-nav
                  size="sm"
                  style="color:rgb(229,166,48)"
                />
                <b-form-datepicker
                  v-model="eventFirstPeriodTo"
                  show-decade-nav
                  button-only
                  right
                  locale="ko"
                  aria-controls="example-input"
                  size="sm"
                  button-variant="outline-primary"
                  style="height: 30px;"
                  @context="onContext"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <!--        <b-col cols="5">-->
          <!--          <b-form-group-->
          <!--            class="font-small-3"-->
          <!--            label="팝업크기(px)"-->
          <!--            label-for="popup-size"-->
          <!--            label-cols-md="4"-->
          <!--          >-->
          <!--            <b-form-input-->
          <!--              id="popup-size-width"-->
          <!--              v-model="info.popupSizeWidth"-->
          <!--              class="font-small-3"-->
          <!--              size="sm"-->
          <!--              placeholder="팝업크기"-->
          <!--                style="color:rgb(229,166,48);"-->
          <!--            />-->
          <!--            <b-form-input-->
          <!--              id="popup-size-height"-->
          <!--              v-model="info.popupSizeHeight"-->
          <!--              class="font-small-3"-->
          <!--              size="sm"-->
          <!--              placeholder="팝업크기"-->
          <!--              style="color:rgb(229,166,48); margin-top: 0.5rem"-->
          <!--            />-->
          <!--          </b-form-group>-->
          <!--        </b-col>-->
          <div class="mt-2">
            <quill-editor
              ref="myTextEditor"
              v-model="detailContent"
              class="editor"
              :disabled="false"
              :options="editorOption"
            />
          </div>
        </b-modal>
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="content-right w-auto mr-1 mt-1"
          size="sm"
          :to="{ name: 'popup-settings-detail', params: { type: 'homePage', id: fetchSetNoticePopup.length + 1, siteSelected: siteSelected } }"
        >
          팝업 생성
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BButton,
  BLink,
  BBadge,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'

import { quillEditor } from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

import { FETCH_SET_NOTICE_POPUP } from '@/store/notice/action'
import { UPSERT_SET_NOTICE_POPUP, DELETE_SET_NOTICE_POPUP } from '@/store/notice/mutation'
import * as dayjs from 'dayjs'
import PopupSettingsDetail from './PopupSettingsDetail'

const noticeStore = createNamespacedHelpers('noticeStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BButton,
    BLink,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    quillEditor,
    PopupSettingsDetail,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      detailTitle: '',
      detailDataRow: 0,
      detailContent: '',
      eventFirstPeriodFrom: '',
      eventFirstPeriodTo: '',
      detailActive: '',
      content: `<h1>Quill Rich Text Editor</h1>
                <p>Quill is a free, <a href="https://github.com/quilljs/quill/">open source</a> WYSIWYG editor built for the modern web. With its <a href="http://quilljs.com/docs/modules/">modular architecture</a> and expressive <a href="http://quilljs.com/docs/api/">API</a>, it is completely customizable to fit any need.</p>
                <iframe class="ql-video" src="https://www.youtube.com/embed/QHH3iSeDBLo?showinfo=0" width="560" height="238"></iframe>
                <p><br></p>
                <h2>Getting Started is Easy</h2>
                <p><br></p>
                `,
      editorOption: {
        // debug: 'info',
        // modules: {
        //   toolbar: [
        //     {
        //       color: [],
        //     },
        //   ],
        // },
        placeholder: '글을 입력해 주세요...',
        readOnly: true,
        theme: 'snow',
      },
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col2',
        },
        {
          key: 'term', sortable: false, label: '기간', thClass: 'col3',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
        {
          key: 'updatedAt', sortable: false, label: '수정일', thClass: 'col6',
        },
        {
          key: 'active', sortable: false, label: '상태', thClass: 'col7',
        },
        {
          key: 'edit', sortable: false, label: '관리', thClass: 'col8',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col2',
        },
        {
          key: 'term', sortable: false, label: '기간', thClass: 'col3',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
        {
          key: 'updatedAt', sortable: false, label: '수정일', thClass: 'col6',
        },
        {
          key: 'active', sortable: false, label: '상태', thClass: 'col4',
        },
        {
          key: 'edit', sortable: false, label: '관리', thClass: 'col5',
        },
      ],
      fields3: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col2',
        },
        {
          key: 'term', sortable: false, label: '기간', thClass: 'col3',
        },
        {
          key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5',
        },
        {
          key: 'updatedAt', sortable: false, label: '수정일', thClass: 'col6',
        },
        {
          key: 'active', sortable: false, label: '상태', thClass: 'col4',
        },
        {
          key: 'edit', sortable: false, label: '관리', thClass: 'col5',
        },
      ],
      tableItems1: [
        {
          no: 1,
          title: '첫번째 팝업입니다',
          term: '2022-01-01 ~ 2022-12-31',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
          active: 'Y',
        },
        {
          no: 2,
          title: '두번째 팝업입니다',
          term: '2022-06-01 ~ 2022-12-31',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
          active: 'N',
        },
      ],
      tableItems2: [
        {
          no: 1,
          title: '첫번째 모바일 팝업입니다',
          term: '2022-01-01 ~ 2022-12-31',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
          active: 'Y',
        },
        {
          no: 2,
          title: '두번째 모바일 팝업입니다',
          term: '2022-06-01 ~ 2022-12-31',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
          active: 'N',
        },
      ],
      tableItems3: [
        {
          no: 1,
          title: '첫번째 파트너 팝업입니다',
          term: '2022-01-01 ~ 2022-12-31',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
          active: 'Y',
        },
        {
          no: 2,
          title: '두번째 파트너 팝업입니다',
          term: '2022-06-01 ~ 2022-12-31',
          enrollId: 'administrator',
          createdAt: '2022-01-01 23:10:23',
          updatedAt: '2022-01-01 23:10:23',
          active: 'N',
        },
      ],
      options1: [
        { value: 'true', text: '사용' },
        { value: 'false', text: '사용안함' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...noticeStore.mapGetters({
      fetchSetNoticePopup: 'fetchSetNoticePopup',
    }),
    ...settingsStore.mapGetters({
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchSetNoticePopup
    },
  },
  watch: {

  },
  mounted() {
    // this.siteAdminData = this.fetchSetSiteAdmin
    // this.authSite = this.fetchSetSiteAdmin.map(item => item.authSite)
    // this.siteSelected = this.authSite[0]
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...noticeStore.mapActions({
      $fetchSetNoticePopup: FETCH_SET_NOTICE_POPUP,
      $upsertSetNoticePopup: UPSERT_SET_NOTICE_POPUP,
      $deleteSetNoticePopup: DELETE_SET_NOTICE_POPUP,
    }),
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSetNoticePopup({
        site: mySite,
      })
    },
    rowClick(data) {
      console.log('editdata: ', data)
      this.detailDataRow = data.item.no
      this.detailTitle = data.item.title
      this.detailContent = data.item.content
      this.eventFirstPeriodFrom = dayjs(data.item.activeFrom).format('YYYY-MM-DD')
      this.eventFirstPeriodTo = dayjs(data.item.activeTo).format('YYYY-MM-DD')
      this.detailActive = data.item.active
    },
    async submit(target, row) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, row)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, row) {
      const mySite = this.siteSelected
      const adminId = this.userData.userid
      try {
        if (target === 'upsertSetNoticePopup') {
          await this.$upsertSetNoticePopup({
            site: mySite,
            title: this.detailTitle,
            content: this.detailContent,
            enrollId: adminId,
            activeFrom: this.eventFirstPeriodFrom,
            activeTo: this.eventFirstPeriodTo,
            active: this.detailActive,
            userIp: this.userIp,
          })
        }
        if (target === 'deleteSetNoticePopup') {
          await this.$deleteSetNoticePopup({
            site: mySite,
            title: row,
            enrollId: adminId,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    // delete row
    deleteRow(type, row) {
      Swal.fire({
        title: '선택한 항목을 삭제하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes()
        }
      })
    },

    rowClickHandler(record, index, type) {
      if (type.target.ariaColIndex !== '6') {
        this.detailDataRow = record.no
        this.detailTitle = record.title
        this.detailActive = record.active
        this.eventFirstPeriodFrom = record.activeFrom
        this.eventFirstPeriodTo = record.activeTo
        this.detailContent = record.content
        this.$refs['my-modal'].show()
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formattedFrom = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selectedFrom = ctx.selectedYMD
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formattedTo = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selectedTo = ctx.selectedYMD
    },
  },
}

</script>

<style scoped>
.select-size {
  width: 7rem;
}
.calendar-size {
  width: 21rem;
}
/*.col1 {*/
/*  width: 10%;*/
/*}*/
/*.col2 {*/
/*  width: 35%;*/
/*}*/
/*.col3 {*/
/*  width: 30%;*/
/*}*/
/*.col4 {*/
/*  width: 10%;*/
/*}*/
/*.col5 {*/
/*  width: 15%;*/
/*}*/
/*!* Responsive: Use a vertical column if under 450px wide *!*/
/*@media screen and (max-width: 805px) {*/
/*  .col1 {*/
/*    min-width: 10px;*/
/*  }*/
/*  .col2 {*/
/*    min-width: 200px;*/
/*  }*/
/*  .col3 {*/
/*    min-width: 150px;*/
/*  }*/
/*  .col4 {*/
/*    min-width: 80px;*/
/*  }*/
/*  .col5 {*/
/*    min-width: 140px;*/
/*  }*/
/*}*/
</style>
