import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'
import {
  FETCH_SET_SITE_ADMIN,
  FETCH_SET_EVENT_DEPOSIT,
  FETCH_SET_SIGNUP_PROHIBITS,
  FETCH_SET_SIGNUP,
  FETCH_SET_EVENT,
  FETCH_SET_IP_ADMIN,
  FETCH_SET_IP_BLOCK,
  FETCH_SET_RANK_DETAIL,
  FETCH_SET_RANK,
  FETCH_SET_ACCOUNT,
  FETCH_SET_BASIC,
  FETCH_SET_BASIC_GAME_SETTINGS,
  FETCH_SET_DEPOSIT_EXCHANGE,
  FETCH_SET_ETC,
  FETCH_SET_SIGNIN,
  FETCH_SET_ALARMS,
  FETCH_SET_ALARMS_ARRAY,
  MENU_SET_SITE_ADMIN_MENU_PERMISSION,
  FETCH_SET_SITE_ADMIN_MENU_PERMISSION,
} from '@/store/settings/action'
import {
  UPDATEMANY_SET_EVENT_DEPOSIT,
  DELETE_SET_SIGNUP_PROHIBITS,
  CREATE_MANY_SET_SIGNUP_PROHIBITS,
  UPDATE_SET_SIGNUP,
  INSERT_SET_EVENT,
  UPDATEMANY_SET_EVENT,
  INSERT_SET_IP_ADMIN,
  DELETE_SET_IP_ADMIN,
  DELETE_SET_IP_BLOCK,
  INSERT_SET_IP_BLOCK,
  CREATEMANY_SET_RANK_DETAIL,
  UPDATEMANY_SET_RANK_DETAIL,
  UPSERT_SET_RANK_DETAIL,
  DELETE_SET_RANK,
  UPSERT_SET_RANK,
  UPDATE_SET_RANK_ACCOUNT,
  UPDATEMANY_SET_RANK,
  UPDATE_SET_ACCOUNT,
  UPDATE_SET_BASIC,
  UPDATE_SET_BASIC_GAME_SETTINGS,
  UPDATE_SET_DEPOSIT_EXCHANGE,
  UPDATE_SET_ETC,
  UPDATE_SET_SIGNIN,
  UPSERT_REQUEST_ALARMS,
  UPDATE_SET_ALARMS,
  CREATEMANY_SET_SITE_ADMIN,
  UPDATEMANY_SET_SITE_ADMIN,
  DELETE_SET_SITE_ADMIN,
  UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION,
} from '@/store/settings/mutation'

const settingsStore = {
  namespaced: true,
  state: {
    fetchSetBasic: [],
    fetchSetBasicGameSettings: [],
    updateSetBasic: [],
    updateSetBasicGameSettings: [],
    fetchSetSignin: [],
    updateSetSignin: [],
    fetchSetDepositExchange: [],
    updateSetDepositExchange: [],
    fetchSetEtc: [],
    updateSetEtc: [],
    fetchSetAccount: [],
    updateSetAccount: [],
    fetchSetRank: [],
    upsertSetRank: [],
    updateManySetRank: [],
    deleteSetRank: [],
    fetchSetRankDetail: [],
    upsertSetRankDetail: [],
    updateManySetRankDetail: [],
    createManySetRankDetail: [],
    fetchSetIpBlock: [],
    insertSetIpBlock: [],
    deleteSetIpBlock: [],
    fetchSetIpAdmin: [],
    insertSetIpAdmin: [],
    deleteSetIpAdmin: [],
    fetchSetEvent: [],
    updateManySetEvent: [],
    insertSetEvent: [],
    fetchSetEventDeposit: [],
    updateManySetEventDeposit: [],
    fetchSetSignup: [],
    fetchSetSignupProhibits: [],
    updateSetSignup: [],
    createSetSignupProhibits: [],
    deleteSetSignupProhibits: [],
    upsertRequestAlarms: [],
    fetchSetAlarms: [],
    fetchSetAlarmsArray: [],
    updateSetAlarms: [],
    fetchSetSiteAdmin: [],
    createManySetSiteAdmin: [],
    updateManySetSiteAdmin: [],
    deleteSetSiteAdmin: [],
    menuSetSiteAdminMenuPermission: [], // 권한 menu Setting 용도, 다른곳에서 사용하지 말것 (store 공유로 메뉴 사용불가함)
    fetchSetSiteAdminMenuPermission: [], // 권한 menu (다른곳에서 사용가능)
    updateManySetSiteAdminMenuPermission: [],
  },
  getters: {
    // getField,
    fetchSetSiteAdmin: state => state.fetchSetSiteAdmin,
    fetchSetBasic: state => state.fetchSetBasic[0],
    fetchSetBasicGameSettings: state => state.fetchSetBasicGameSettings,
    updateSetBasic: state => state.updateSetBasic[0],
    updateSetBasicGameSettings: state => state.updateSetBasicGameSettings,
    fetchSetSignin: state => state.fetchSetSignin[0],
    updateSetSignin: state => state.updateSetSignin[0],
    fetchSetDepositExchange: state => state.fetchSetDepositExchange[0],
    updateSetDepositExchange: state => state.updateSetDepositExchange[0],
    fetchSetEtc: state => state.fetchSetEtc[0],
    updateSetEtc: state => state.updateSetEtc[0],
    fetchSetAccount: state => state.fetchSetAccount[0],
    updateSetAccount: state => state.updateSetAccount[0],
    fetchSetRank: state => state.fetchSetRank,
    upsertSetRank: state => state.upsertSetRank,
    updateManySetRank: state => state.updateManySetRank,
    deleteSetRank: state => state.deleteSetRank[0],
    fetchSetRankDetail: state => state.fetchSetRankDetail,
    upsertSetRankDetail: state => state.upsertSetRankDetail,
    updateManySetRankDetail: state => state.updateManySetRankDetail,
    createManySetRankDetail: state => state.createManySetRankDetail,
    fetchSetIpBlock: state => state.fetchSetIpBlock,
    insertSetIpBlock: state => state.insertSetIpBlock[0],
    deleteSetIpBlock: state => state.deleteSetIpBlock[0],
    fetchSetIpAdmin: state => state.fetchSetIpAdmin,
    insertSetIpAdmin: state => state.insertSetIpAdmin[0],
    deleteSetIpAdmin: state => state.deleteSetIpAdmin[0],
    fetchSetEvent: state => state.fetchSetEvent,
    updateManySetEvent: state => state.updateManySetEvent,
    insertSetEvent: state => state.insertSetEvent,
    fetchSetEventDeposit: state => state.fetchSetEventDeposit,
    updateManySetEventDeposit: state => state.updateManySetEventDeposit,
    fetchSetSignup: state => state.fetchSetSignup[0],
    fetchSetSignupProhibits: state => state.fetchSetSignupProhibits[0],
    updateSetSignup: state => state.updateSetSignup[0],
    createSetSignupProhibits: state => state.createSetSignupProhibits,
    deleteSetSignupProhibits: state => state.deleteSetSignupProhibits[0],
    upsertRequestAlarms: state => state.upsertRequestAlarms,
    fetchSetAlarms: state => state.fetchSetAlarms,
    fetchSetAlarmsArray: state => state.fetchSetAlarmsArray,
    updateSetAlarms: state => state.updateSetAlarms,
    createManySetSiteAdmin: state => state.createManySetSiteAdmin,
    updateManySetSiteAdmin: state => state.updateManySetSiteAdmin,
    deleteSetSiteAdmin: state => state.deleteSetSiteAdmin[0],
    menuSetSiteAdminMenuPermission: state => state.menuSetSiteAdminMenuPermission,
    fetchSetSiteAdminMenuPermission: state => state.fetchSetSiteAdminMenuPermission,
    updateManySetSiteAdminMenuPermission: state => state.updateManySetSiteAdminMenuPermission,
  },
  mutations: {
    // updateField,
    [FETCH_SET_SITE_ADMIN](state, fetchSetSiteAdmin) {
      state.fetchSetSiteAdmin = fetchSetSiteAdmin
    },
    [FETCH_SET_BASIC](state, fetchSetBasic) {
      state.fetchSetBasic = fetchSetBasic
      // localStorage.setItem('fetchSetBasic', JSON.stringify(fetchSetBasic[0]))
    },
    [FETCH_SET_BASIC_GAME_SETTINGS](state, fetchSetBasicGameSettings) {
      state.fetchSetBasicGameSettings = fetchSetBasicGameSettings
    },
    [UPDATE_SET_BASIC](state, updateSetBasic) {
      state.updateSetBasic = updateSetBasic
    },
    [UPDATE_SET_BASIC_GAME_SETTINGS](state, updateSetBasicGameSettings) {
      state.updateSetBasicGameSettings = updateSetBasicGameSettings
    },
    [FETCH_SET_SIGNIN](state, fetchSetSignin) {
      state.fetchSetSignin = fetchSetSignin
    },
    [UPDATE_SET_SIGNIN](state, updateSetSignin) {
      state.updateSetSignin = updateSetSignin
    },
    [FETCH_SET_DEPOSIT_EXCHANGE](state, fetchSetDepositExchange) {
      state.fetchSetDepositExchange = fetchSetDepositExchange
    },
    [UPDATE_SET_DEPOSIT_EXCHANGE](state, updateSetDepositExchange) {
      state.updateSetDepositExchange = updateSetDepositExchange
    },
    [FETCH_SET_ETC](state, fetchSetEtc) {
      state.fetchSetEtc = fetchSetEtc
    },
    [UPDATE_SET_ETC](state, updateSetEtc) {
      state.updateSetEtc = updateSetEtc
    },
    [FETCH_SET_ACCOUNT](state, fetchSetAccount) {
      state.fetchSetAccount = fetchSetAccount
    },
    [UPDATE_SET_ACCOUNT](state, updateSetAccount) {
      state.updateSetAccount = updateSetAccount
    },
    [FETCH_SET_RANK](state, fetchSetRank) {
      state.fetchSetRank = fetchSetRank
    },
    [UPSERT_SET_RANK](state, upsertSetRank) {
      state.upsertSetRank = upsertSetRank
    },
    [UPDATE_SET_RANK_ACCOUNT](state, updateSetRankAccount) {
      const index = state.fetchSetRank.findIndex(item => item.site === updateSetRankAccount.site && item.rankname === updateSetRankAccount.rankname)

      if (index !== -1) {
        // 객체를 찾았을 때 해당 객체의 정보를 업데이트합니다.
        state.fetchSetRank[index].adminAccountBank = updateSetRankAccount.adminAccountBank
        state.fetchSetRank[index].adminAccountNumber = updateSetRankAccount.adminAccountNumber
        state.fetchSetRank[index].adminAccountName = updateSetRankAccount.adminAccountName
      }
    },
    [UPDATEMANY_SET_RANK](state, updateManySetRank) {
      state.updateManySetRank = updateManySetRank
    },
    [DELETE_SET_RANK](state, deleteSetRank) {
      state.deleteSetRank = deleteSetRank
    },
    [FETCH_SET_RANK_DETAIL](state, fetchSetRankDetail) {
      state.fetchSetRankDetail = fetchSetRankDetail
    },
    [UPSERT_SET_RANK_DETAIL](state, upsertSetRankDeteil) {
      state.upsertSetRankDetail = upsertSetRankDeteil
    },
    [UPDATEMANY_SET_RANK_DETAIL](state, updateManySetRankDetail) {
      state.updateManySetRankDetail = updateManySetRankDetail
    },
    [CREATEMANY_SET_RANK_DETAIL](state, createManySetRankDetail) {
      state.createManySetRankDetail = createManySetRankDetail
    },
    [FETCH_SET_IP_BLOCK](state, fetchSetIpBlock) {
      state.fetchSetIpBlock = fetchSetIpBlock
    },
    [INSERT_SET_IP_BLOCK](state, insertSetIpBlock) {
      state.insertSetIpBlock = insertSetIpBlock
    },
    [DELETE_SET_IP_BLOCK](state, deleteSetIpBlock) {
      state.deleteSetIpBlock = deleteSetIpBlock
    },
    [FETCH_SET_IP_ADMIN](state, fetchSetIpAdmin) {
      state.fetchSetIpAdmin = fetchSetIpAdmin
    },
    [INSERT_SET_IP_ADMIN](state, insertSetIpAdmin) {
      state.insertSetIpAdmin = insertSetIpAdmin
    },
    [DELETE_SET_IP_ADMIN](state, deleteSetIpAdmin) {
      state.deleteSetIpAdmin = deleteSetIpAdmin
    },
    [FETCH_SET_EVENT](state, fetchSetEvent) {
      state.fetchSetEvent = fetchSetEvent
    },
    [UPDATEMANY_SET_EVENT](state, updateManySetEvent) {
      state.updateManySetEvent = updateManySetEvent
    },
    [INSERT_SET_EVENT](state, insertSetEvent) {
      state.insertSetEvent = insertSetEvent
    },
    [FETCH_SET_EVENT_DEPOSIT](state, fetchSetEventDeposit) {
      state.fetchSetEventDeposit = fetchSetEventDeposit
    },
    [UPDATEMANY_SET_EVENT_DEPOSIT](state, updateManySetEventDeposit) {
      state.updateManySetEventDeposit = updateManySetEventDeposit
    },
    [FETCH_SET_SIGNUP](state, fetchSetSignUp) {
      state.fetchSetSignup = fetchSetSignUp
    },
    [FETCH_SET_SIGNUP_PROHIBITS](state, fetchSetSignupProhibits) {
      state.fetchSetSignupProhibits = fetchSetSignupProhibits
    },
    [UPDATE_SET_SIGNUP](state, updateSetSignup) {
      state.updateSetSignup = updateSetSignup
    },
    [CREATE_MANY_SET_SIGNUP_PROHIBITS](state, createManySetSignupProhibits) {
      state.createManySetSignupProhibits = createManySetSignupProhibits
    },
    [DELETE_SET_SIGNUP_PROHIBITS](state, deleteSetSignupProhibits) {
      state.deleteSetSignupProhibits = deleteSetSignupProhibits
    },
    [UPSERT_REQUEST_ALARMS](state, upsertRequestAlarms) {
      state.upsertRequestAlarms = upsertRequestAlarms
    },
    [FETCH_SET_ALARMS](state, fetchSetAlarms) {
      state.fetchSetAlarms = fetchSetAlarms
    },
    [FETCH_SET_ALARMS_ARRAY](state, fetchSetAlarmsArray) {
      state.fetchSetAlarmsArray = fetchSetAlarmsArray
    },
    [UPDATE_SET_ALARMS](state, updateSetAlarms) {
      state.updateSetAlarms = updateSetAlarms
    },
    [CREATEMANY_SET_SITE_ADMIN](state, createManySetSiteAdmin) {
      state.createManySetSiteAdmin = createManySetSiteAdmin
    },
    [UPDATEMANY_SET_SITE_ADMIN](state, updateManySetSiteAdmin) {
      state.updateManySetSiteAdmin = updateManySetSiteAdmin
    },
    [DELETE_SET_SITE_ADMIN](state, deleteSetSiteAdmin) {
      state.deleteSetSiteAdmin = deleteSetSiteAdmin
    },
    [MENU_SET_SITE_ADMIN_MENU_PERMISSION](state, menuSetSiteAdminMenuPermission) {
      state.menuSetSiteAdminMenuPermission = menuSetSiteAdminMenuPermission
      // localStorage.setItem('menuPermission', JSON.stringify(menuSetSiteAdminMenuPermission[0]))
    },
    [FETCH_SET_SITE_ADMIN_MENU_PERMISSION](state, fetchSetSiteAdminMenuPermission) {
      state.fetchSetSiteAdminMenuPermission = fetchSetSiteAdminMenuPermission
    },
    [UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION](state, updateManySetSiteAdminMenuPermission) {
      state.updateManySetSiteAdminMenuPermission = updateManySetSiteAdminMenuPermission
    },
    updateParam(state, events) {
      // console.log('t >> ', events.t) // target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      state[events.t][0][events.e] = events.v
      // console.log('storeData', state.fetchSetBasic[0])
    },
    updateManyParam(state, events) {
      // console.log('t >> ', events.t) // target
      // console.log('e >> ', events.e) // event
      // console.log('v >> ', events.v) // value
      // console.log('i >> ', events.i) // idx
      state[events.t][events.i][events.e] = events.v
      // console.log('state :', state[events.t])
    },
  },
  actions: {
    async [FETCH_SET_BASIC]({ commit }, payload) {
      console.log('payload ::', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetBasic($site: String) {
            fetchSetBasic(site: $site) {
              idx
              site
              siteStatus
              mainNotice
              maintenanceNotice
              sports
              casino
              slot
              minigame
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_BASIC, data.fetchSetBasic)
      await commit('rootStore/SET_BASIC', data.fetchSetBasic, { root: true })
      console.log('FETCH_SET_BASIC : ', data.fetchSetBasic)
    },
    async [UPDATE_SET_BASIC]({ commit, state }, payload) {
      // console.log('update state : ', state)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetBasic($site: String, $siteStatus: String, $mainNotice: String, $maintenanceNotice: String, $userid: String, $userIp: String) {
            updateSetBasic(site: $site, siteStatus: $siteStatus, mainNotice: $mainNotice, maintenanceNotice: $maintenanceNotice, userid: $userid, userIp: $userIp) {
              site
              siteStatus
              mainNotice
              maintenanceNotice
            }
          }
        `,
        variables: {
          site: payload.site,
          siteStatus: state.fetchSetBasic[0].siteStatus,
          mainNotice: state.fetchSetBasic[0].mainNotice,
          maintenanceNotice: state.fetchSetBasic[0].maintenanceNotice,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_BASIC, data.updateSetBasic)
      console.log('UPDATE_SET_BASIC : ', data.updateSetBasic)
    },
    async [FETCH_SET_BASIC_GAME_SETTINGS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetBasic($site: String) {
            fetchSetBasic(site: $site) {
              idx
              site
              siteStatus
              mainNotice
              maintenanceNotice
              sports
              casino
              slot
              minigame
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_BASIC_GAME_SETTINGS, data.fetchSetBasic)
      console.log('FETCH_SET_BASIC_GAME_SETTINGS : ', data.fetchSetBasic)
    },

    async [UPDATE_SET_BASIC_GAME_SETTINGS]({ commit, state }, payload) {
      // Preprocess the state data to ensure it matches the GraphQL input type
      const processedData = state.fetchSetBasicGameSettings.map(item => ({
        idx: parseFloat(item.idx), // Convert idx to Float
        site: item.site,
        siteStatus: item.siteStatus,
        mainNotice: item.mainNotice,
        maintenanceNotice: item.maintenanceNotice,
        userid: item.userid,
        userIp: item.userIp,
        sports: item.sports,
        casino: item.casino,
        slot: item.slot,
        minigame: item.minigame,
      }))

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetBasicGameSettings($setBasicInputArgs: [SetBasicInputArgs!]!) {
            updateSetBasicGameSettings(setBasicInputArgs: $setBasicInputArgs) {
              idx
              site
              sports
              casino
              slot
              minigame
            }
          }
        `,
        variables: {
          setBasicInputArgs: processedData,
        },
        fetchPolicy: 'no-cache',
      })

      commit(UPDATE_SET_BASIC_GAME_SETTINGS, data.updateSetBasicGameSettings)
      console.log('UPDATE_SET_BASIC_GAME_SETTINGS : ', data.updateSetBasicGameSettings)
    },

    async [FETCH_SET_SIGNIN]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSignin($site: String) {
            fetchSetSignin(site: $site) {
              idx
              site
              signinDup
              signinTimeout
              signinCaptcha
              sleepAccount
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_SIGNIN, data.fetchSetSignin)
      console.log('FETCH_SET_SIGNIN : ', data.fetchSetSignin)
    },
    async [UPDATE_SET_SIGNIN]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetSignin[0])
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetSignin($site: String, $signinDup: String, $signinTimeout: Float, $signinCaptcha: String, $sleepAccount: Float, $userid: String, $userIp: String) {
            updateSetSignin(site: $site, signinDup: $signinDup, signinTimeout: $signinTimeout, signinCaptcha: $signinCaptcha, sleepAccount: $sleepAccount, userid: $userid, userIp: $userIp) {
              site
              signinDup
              signinTimeout
              signinCaptcha
              sleepAccount
            }
          }
        `,
        variables: {
          site: payload.site,
          signinDup: state.fetchSetSignin[0].signinDup,
          signinTimeout: Number(state.fetchSetSignin[0].signinTimeout),
          signinCaptcha: state.fetchSetSignin[0].signinCaptcha,
          sleepAccount: Number(state.fetchSetSignin[0].sleepAccount),
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_SIGNIN, data.updateSetSignin)
      console.log('UPDATE_SET_SIGNIN : ', data.updateSetSignin)
    },
    async [FETCH_SET_DEPOSIT_EXCHANGE]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetDepositExchange($site: String) {
            fetchSetDepositExchange(site: $site) {
              idx
              site
              depositMin
              depositAmount
              depositInterval
              depositNotice
              exchangeMin
              exchangeMax
              exchangeAmount
              exchangeInterval
              exchangeNotice
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_DEPOSIT_EXCHANGE, data.fetchSetDepositExchange)
      console.log('FETCH_SET_DEPOSIT_EXCHANGE : ', data.fetchSetDepositExchange)
    },
    async [UPDATE_SET_DEPOSIT_EXCHANGE]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetDepositExchange[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetDepositExchange($site: String, $depositMin: Float, $depositAmount: Float, $depositInterval: Float, $depositNotice: String, $exchangeMin: Float, $exchangeMax: Float, $exchangeAmount: Float, $exchangeInterval: Float, $exchangeNotice: String, $userid: String, $userIp: String){
            updateSetDepositExchange(site: $site, depositMin: $depositMin, depositAmount: $depositAmount, depositInterval: $depositInterval, depositNotice: $depositNotice, exchangeMin: $exchangeMin, exchangeMax: $exchangeMax, exchangeAmount: $exchangeAmount, exchangeInterval: $exchangeInterval, exchangeNotice: $exchangeNotice, userid: $userid, userIp: $userIp) {
              site
              depositMin
              depositAmount
              depositInterval
              depositNotice
              exchangeMin
              exchangeMax
              exchangeAmount
              exchangeInterval
              exchangeNotice
            }
          }
        `,
        variables: {
          site: payload.site,
          depositMin: Number(state.fetchSetDepositExchange[0].depositMin),
          depositAmount: Number(state.fetchSetDepositExchange[0].depositAmount),
          depositInterval: Number(state.fetchSetDepositExchange[0].depositInterval),
          depositNotice: state.fetchSetDepositExchange[0].depositNotice,
          exchangeMin: Number(state.fetchSetDepositExchange[0].exchangeMin),
          exchangeMax: Number(state.fetchSetDepositExchange[0].exchangeMax),
          exchangeAmount: Number(state.fetchSetDepositExchange[0].exchangeAmount),
          exchangeInterval: Number(state.fetchSetDepositExchange[0].exchangeInterval),
          exchangeNotice: state.fetchSetDepositExchange[0].exchangeNotice,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_DEPOSIT_EXCHANGE, data.updateSetDepositExchange)
      console.log('UPDATE_SET_DEPOSIT_EXCHANGE : ', data.updateSetDepositExchange)
    },
    async [FETCH_SET_ETC]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetEtc($site: String) {
            fetchSetEtc(site: $site) {
              idx
              site
              messageDelete
              pointMinExchange
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_ETC, data.fetchSetEtc)
      console.log('FETCH_SET_ETC : ', data.fetchSetEtc)
    },
    async [UPDATE_SET_ETC]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetEtc[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetEtc($site: String, $messageDelete: Float, $pointMinExchange: Float, $userid: String, $userIp: String) {
            updateSetEtc(site: $site, messageDelete: $messageDelete, pointMinExchange: $pointMinExchange userid: $userid, userIp: $userIp) {
              site
              messageDelete
              pointMinExchange
            }
          }
        `,
        variables: {
          site: payload.site,
          messageDelete: Number(state.fetchSetEtc[0].messageDelete),
          pointMinExchange: Number(state.fetchSetEtc[0].pointMinExchange),
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_ETC, data.updateSetEtc)
      console.log('UPDATE_SET_ETC : ', data.updateSetEtc)
    },
    async [FETCH_SET_ACCOUNT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetAccount($site: String) {
            fetchSetAccount(site: $site) {
              site
              mainNotice
              subNotice
              accountNo
              accountBank
              accountName
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_ACCOUNT, data.fetchSetAccount)
      console.log('FETCH_SET_ACCOUNT : ', data.fetchSetAccount)
    },
    async [UPDATE_SET_ACCOUNT]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetAccount[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetAccount($site: String, $mainNotice: String, $subNotice: String, $accountNo: String, $accountBank: String, $accountName: String, $userid: String, $userIp: String) {
            updateSetAccount(site: $site, mainNotice: $mainNotice, subNotice: $subNotice, accountNo: $accountNo, accountBank: $accountBank, accountName: $accountName, userid: $userid, userIp: $userIp) {
              site
              mainNotice
              subNotice
              accountNo
              accountBank
              accountName
            }
          }
        `,
        variables: {
          site: payload.site,
          mainNotice: state.fetchSetAccount[0].mainNotice,
          subNotice: state.fetchSetAccount[0].subNotice,
          accountNo: state.fetchSetAccount[0].accountNo,
          accountBank: state.fetchSetAccount[0].accountBank,
          accountName: state.fetchSetAccount[0].accountName,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_ACCOUNT, data.updateSetAccount)
      console.log('UPDATE_SET_ACCOUNT : ', data.updateSetAccount)
    },
    async [FETCH_SET_RANK]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetRank($site: String) {
            fetchSetRank(site: $site) {
              idx
              site
              rankOrder
              rankName
              rankImg
              rankDepositMax
              dayFirstDeposit
              dayFirstDepositMax
              everyDeposit
              everyDepositMax
              adminAccountBank
              adminAccountNumber
              adminAccountName
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_RANK, data.fetchSetRank)
      console.log('FETCH_SET_RANK : ', data.fetchSetRank)
    },
    async [UPSERT_SET_RANK]({ commit, state }, payload) {
      // console.log('update state : ', payload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertSetRank($idx: Float, $site: String, $rankOrder: Float, $rankName: String, $dayCancelLimit: Float, $dayFirstDeposit: Float, $dayCheckExchange: String, $everyDeposit: Float, $everyDepositMin: Float, $everyCheckExchange: String, $referralFirstDeposit: Float, $referralEveryDeposit: Float, $referral: String, $userid: String, $userIp: String) {
            upsertSetRank(idx: $idx, site: $site, rankOrder: $rankOrder, rankName: $rankName, dayCancelLimit: $dayCancelLimit, dayFirstDeposit: $dayFirstDeposit, dayCheckExchange: $dayCheckExchange, everyDeposit: $everyDeposit, everyDepositMin: $everyDepositMin, everyCheckExchange: $everyCheckExchange, referralFirstDeposit: $referralFirstDeposit, referralEveryDeposit: $referralEveryDeposit, referral: $referral, userid: $userid, userIp: $userIp) {
              site
              rankOrder
              rankName
              dayCancelLimit
              dayFirstDeposit
              dayCheckExchange
              everyDeposit
              everyDepositMin
              everyCheckExchange
              referralFirstDeposit
              referralEveryDeposit
              referral
            }
          }
        `,
        variables: {
          idx: state.fetchSetRank.idx,
          site: payload.site,
          rankName: payload.rankName,
          // 삭제금지
          // rankOrder: Number(payload.rankOrder),
          // dayCancelLimit: Number(payload.dayCancelLimit),
          // dayFirstDeposit: Number(payload.dayFirstDeposit),
          // dayCheckExchange: payload.dayCheckExchange,
          // everyDeposit: Number(payload.everyDeposit),
          // everyDepositMin: Number(payload.everyDepositMin),
          // everyCheckExchange: payload.everyCheckExchange,
          // referralFirstDeposit: Number(payload.referralFirstDeposit),
          // referralEveryDeposit: Number(payload.referralEveryDeposit),
          // referral: payload.referral,

          // 임시
          rankOrder: Number(0),
          dayCancelLimit: Number(0),
          dayFirstDeposit: Number(0),
          dayCheckExchange: 'true',
          everyDeposit: Number(0),
          everyDepositMin: Number(0),
          everyCheckExchange: 'true',
          referralFirstDeposit: Number(0),
          referralEveryDeposit: Number(0),
          referral: 'true',
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_SET_RANK, data.upsertSetRank)
      console.log('UPSERT_SET_RANK : ', data.upsertSetRank)
    },

    /* 등급별 계좌설정 */
    async [UPDATE_SET_RANK_ACCOUNT]({ commit, state }, payload) {
      // console.log('[UPDATE_SET_RANK_ACCOUNT] : ', JSON.stringify(payload))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetRankAccount($site: String, $rankName: String, $adminAccountBank: String, $adminAccountNumber: String, $adminAccountName: String, $userid: String, $userIp: String){
            updateSetRankAccount(site: $site, rankName: $rankName, adminAccountBank: $adminAccountBank, adminAccountNumber: $adminAccountNumber, adminAccountName: $adminAccountName, userid: $userid, userIp: $userIp) {
                site
                rankName
                adminAccountBank
                adminAccountNumber
                adminAccountName
            }
          }
        `,
        variables: {
          site: payload.site,
          rankName: payload.rankName,
          adminAccountBank: payload.adminAccountBank,
          adminAccountNumber: payload.adminAccountNumber,
          adminAccountName: payload.adminAccountName,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_RANK_ACCOUNT, data.updateSetRankAccount)
      // console.log('UPDATE_SET_RANK_ACCOUNT : ', data.updateSetRankAccount)
    },

    // updateMany로 변경
    async [UPDATEMANY_SET_RANK]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetRank)

      const formattedPayload = state.fetchSetRank.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        rankOrder: Number(item.rankOrder),
        rankName: item.rankName,
        rankDepositMax: Number(item.rankDepositMax),
        dayFirstDeposit: Number(item.dayFirstDeposit),
        dayFirstDepositMax: Number(item.dayFirstDepositMax),
        everyDeposit: Number(item.everyDeposit),
        everyDepositMax: Number(item.everyDepositMax),
      }))
      // console.log('formatted >> ', formattedPayload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManySetRank($setRankInputArgs: [SetRankInputArgs!]!, $userid: String!, $userIp: String!) {
            updateManySetRank(setRankInputArgs: $setRankInputArgs, userid: $userid, userIp: $userIp){
              site,
              rankName,
            }
          }
        `,
        variables: {
          setRankInputArgs: formattedPayload,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })

      commit(UPDATEMANY_SET_RANK, data.updateManySetRank)
      console.log('UPDATEMANY_SET_RANK : ', data.updateManySetRank)
    },
    async [DELETE_SET_RANK]({ commit, state }, payload) {
      // console.log('update state : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetRank($site: String, $rankName: String, $userid: String, $userIp: String) {
            deleteSetRank(site: $site, rankName: $rankName, userid: $userid, userIp: $userIp) {
              site
              rankName
            }
          }
        `,
        variables: {
          site: payload.site,
          rankName: payload.rankName,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_RANK, data.deleteSetRank)
      console.log('DELETE_SET_RANK : ', data.deleteSetRank)
    },
    async [FETCH_SET_RANK_DETAIL]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetRankDetail($site: String, $rankName: String) {
            fetchSetRankDetail(site: $site, rankName: $rankName) {
              idx
              site
              rankName
              gameName
              myWin1
              myWin2
              myWin3
              myWin4
              myWin5
              referralWin1
              referralWin2
              referralWin3
              referralWin4
              referralWin5
              myBet1
              myBet2
              myBet3
              myBet4
              myBet5
              referralBet1
              referralBet2
              referralBet3
              referralBet4
              referralBet5
            }
          }
        `,
        variables: {
          site: payload.site,
          rankName: payload.rankName,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_RANK_DETAIL, data.fetchSetRankDetail)
      console.log('FETCH_SET_RANK_DETAIL : ', data.fetchSetRankDetail)
    },
    async [UPSERT_SET_RANK_DETAIL]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetRankDetail)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertSetRankDetail($idx: Float, $site: String, $rankName: String, $gameName: String, $myWin1: Float, $myWin2: Float, $myWin3: Float, $myWin4: Float, $myWin5: Float, $referralWin1: Float, $referralWin2: Float, $referralWin3: Float, $referralWin4: Float, $referralWin5: Float, $myBet1: Float, $myBet2: Float, $myBet3: Float, $myBet4: Float, $myBet5: Float, $referralBet1: Float, $referralBet2: Float, $referralBet3: Float, $referralBet4: Float, $referralBet5: Float) {
            upsertSetRankDetail(idx: $idx, site: $site, rankName: $rankName, gameName: $gameName, myWin1: $myWin1, myWin2: $myWin2, myWin3: $myWin3, myWin4: $myWin4, myWin5: $myWin5, referralWin1: $referralWin1, referralWin2: $referralWin2, referralWin3: $referralWin3, referralWin4: $referralWin4, referralWin5: $referralWin5, myBet1: $myBet1, myBet2: $myBet2, myBet3: $myBet3, myBet4: $myBet4, myBet5: $myBet5, referralBet1: $referralBet1, referralBet2: $referralBet2, referralBet3: $referralBet3, referralBet4: $referralBet4, referralBet5: $referralBet5) {
              site
              rankName
              gameName
              myWin1
              myWin2
              myWin3
              myWin4
              myWin5
              referralWin1
              referralWin2
              referralWin3
              referralWin4
              referralWin5
              myBet1
              myBet2
              myBet3
              myBet4
              myBet5
              referralBet1
              referralBet2
              referralBet3
              referralBet4
              referralBet5
            }
          }
        `,
        variables: {
          idx: state.fetchSetRankDetail[0].idx,
          site: payload.site,
          rankName: payload.fetchSetRankDetail[0].rankName,
          gameName: payload.fetchSetRankDetail[0].gameName,
          myWin1: Number(state.fetchSetRankDetail[0].myWin1),
          myWin2: Number(state.fetchSetRankDetail[0].myWin2),
          myWin3: Number(state.fetchSetRankDetail[0].myWin3),
          myWin4: Number(state.fetchSetRankDetail[0].myWin4),
          myWin5: Number(state.fetchSetRankDetail[0].myWin5),
          referralWin1: Number(state.fetchSetRankDetail[0].referralWin1),
          referralWin2: Number(state.fetchSetRankDetail[0].referralWin2),
          referralWin3: Number(state.fetchSetRankDetail[0].referralWin3),
          referralWin4: Number(state.fetchSetRankDetail[0].referralWin4),
          referralWin5: Number(state.fetchSetRankDetail[0].referralWin5),
          myBet1: Number(state.fetchSetRankDetail[0].myBet1),
          myBet2: Number(state.fetchSetRankDetail[0].myBet2),
          myBet3: Number(state.fetchSetRankDetail[0].myBet3),
          myBet4: Number(state.fetchSetRankDetail[0].myBet4),
          myBet5: Number(state.fetchSetRankDetail[0].myBet5),
          referralBet1: Number(state.fetchSetRankDetail[0].referralBet1),
          referralBet2: Number(state.fetchSetRankDetail[0].referralBet2),
          referralBet3: Number(state.fetchSetRankDetail[0].referralBet3),
          referralBet4: Number(state.fetchSetRankDetail[0].referralBet4),
          referralBet5: Number(state.fetchSetRankDetail[0].referralBet5),
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_SET_RANK_DETAIL, data.upsertSetRankDetail)
      console.log('UPSERT_SET_RANK_DETAIL : ', data.upsertSetRankDetail)
    },
    // updateMany로 변경함
    async [UPDATEMANY_SET_RANK_DETAIL]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetRankDetail)
      const formattedPayload = state.fetchSetRankDetail.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        rankName: payload.rankName,
        gameName: item.gameName,
        myWin1: Number(item.myWin1),
        myWin2: Number(item.myWin2),
        myWin3: Number(item.myWin3),
        myWin4: Number(item.myWin4),
        myWin5: Number(item.myWin5),
        referralWin1: Number(item.referralWin1),
        referralWin2: Number(item.referralWin2),
        referralWin3: Number(item.referralWin3),
        referralWin4: Number(item.referralWin4),
        referralWin5: Number(item.referralWin5),
        myBet1: Number(item.myBet1),
        myBet2: Number(item.myBet2),
        myBet3: Number(item.myBet3),
        myBet4: Number(item.myBet4),
        myBet5: Number(item.myBet5),
        referralBet1: Number(item.referralBet1),
        referralBet2: Number(item.referralBet2),
        referralBet3: Number(item.referralBet3),
        referralBet4: Number(item.referralBet4),
        referralBet5: Number(item.referralBet5),
      }))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManySetRankDetail($setRankDetailInputArgs: [SetRankDetailInputArgs!]!, $userid: String!, $userIp: String!) {
            updateManySetRankDetail(setRankDetailInputArgs: $setRankDetailInputArgs, userid: $userid, userIp: $userIp){
              site,
              rankName,
              gameName,
            }
          }
        `,
        variables: {
          setRankDetailInputArgs: formattedPayload,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATEMANY_SET_RANK_DETAIL, data.updateManySetRankDetail)
      console.log('UPDATEMANY_SET_RANK_DETAIL : ', data.updateManySetRankDetail)
    },
    // async [CREATEMANY_SET_RANK_DETAIL]({ commit, state }, payload) {
    //   console.log('update state : ', state.fetchSetRankDetail)
    //   for (const i in payload.gameName) {
    //     const { data } = await apolloClient.mutate({
    //       mutation: gql`
    //         mutation createManySetRankDetail($site: String, $rankName: String, $gameName: String) {
    //           createManySetRankDetail(site: $site, rankName: $rankName, gameName: $gameName) {
    //             site
    //             rankName
    //             gameName
    //           }
    //         }
    //       `,
    //       variables: {
    //         site: payload.site,
    //         rankName: payload.rankName,
    //         gameName: payload.gameName[i],
    //       },
    //       fetchPolicy: 'no-cache',
    //     })
    //     commit(CREATEMANY_SET_RANK_DETAIL, data.createManySetRankDetail)
    //     console.log('CREATEMANY_SET_RANK_DETAIL : ', data.createManySetRankDetail)
    //   }
    // },

    // createMany로 변경함
    async [CREATEMANY_SET_RANK_DETAIL]({ commit, state }, payload) {
      // console.log('create state : ', payload)

      const formattedPayload = payload.gameName.map(item => ({
        site: payload.site,
        rankName: payload.rankName,
        gameName: item,
      }))

      // console.log('formatted :: ', formattedPayload)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation createManySetRankDetail($setRankDetailInputArgs: [SetRankDetailInputArgs!]!, $userid: String!, $userIp: String!) {
            createManySetRankDetail(setRankDetailInputArgs: $setRankDetailInputArgs, userid : $userid, userIp: $userIp) {
              site
              rankName
              gameName
            }
          }
        `,
        variables: {
          setRankDetailInputArgs: formattedPayload,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })

      commit(CREATEMANY_SET_RANK_DETAIL, data.createManySetRankDetail)
      console.log('CREATEMANY_SET_RANK_DETAIL:', data.createManySetRankDetail)
    },
    async [FETCH_SET_IP_BLOCK]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetIpBlock($site: String) {
            fetchSetIpBlock(site: $site) {
              idx
              site
              ip
              type
              memo
              enrollId
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_IP_BLOCK, data.fetchSetIpBlock)
      console.log('FETCH_SET_IP_BLOCK : ', data.fetchSetIpBlock)
    },
    async [INSERT_SET_IP_BLOCK]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetIpBlock[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertSetIpBlock($site: String, $ip: String, $type: String, $memo: String, $enrollId: String, $userIp: String) {
            insertSetIpBlock(site: $site, ip: $ip, type: $type, memo: $memo, enrollId: $enrollId, userIp: $userIp) {
              site
              ip
              type
              memo
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          ip: payload.ip,
          type: payload.type,
          memo: payload.memo,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(INSERT_SET_IP_BLOCK, data.insertSetIpBlock)
      console.log('INSERT_SET_IP_BLOCK : ', data.insertSetIpBlock)
    },
    async [DELETE_SET_IP_BLOCK]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetIpBlock[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetIpBlock($site: String, $ip: String, $enrollId: String, $userIp: String) {
            deleteSetIpBlock(site: $site, ip: $ip, enrollId: $enrollId, userIp: $userIp) {
              site
              ip
            }
          }
        `,
        variables: {
          site: payload.site,
          ip: payload.ip,
          enrollId: payload.enrollId,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_IP_BLOCK, data.deleteSetIpBlock)
      console.log('DELETE_SET_IP_BLOCK : ', data.deleteSetIpBlock)
    },
    async [FETCH_SET_IP_ADMIN]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetIpAdmin($site: String) {
            fetchSetIpAdmin(site: $site) {
              idx
              site
              ip
              adminRole
              memo
              enrollId
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_IP_ADMIN, data.fetchSetIpAdmin)
      // console.log('FETCH_SET_IP_ADMIN : ', data.fetchSetIpAdmin)
    },
    async [INSERT_SET_IP_ADMIN]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetIpAdmin[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertSetIpAdmin($site: String, $ip: String, $adminRole: String, $memo: String, $enrollId: String) {
            insertSetIpAdmin(site: $site, ip: $ip, adminRole: $adminRole, memo: $memo, enrollId: $enrollId) {
              site
              ip
              adminRole
              memo
              enrollId
            }
          }
        `,
        variables: {
          site: payload.site,
          ip: payload.ip,
          adminRole: payload.adminRole,
          memo: payload.memo,
          enrollId: payload.enrollId,
        },
        fetchPolicy: 'no-cache',
      })
      commit(INSERT_SET_IP_ADMIN, data.insertSetIpAdmin)
      console.log('INSERT_SET_IP_ADMIN : ', data.insertSetIpAdmin)
    },
    async [DELETE_SET_IP_ADMIN]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetIpAdmin[0])
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetIpAdmin($site: String, $ip: String) {
            deleteSetIpAdmin(site: $site, ip: $ip) {
              site
              ip
            }
          }
        `,
        variables: {
          site: payload.site,
          ip: payload.ip,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_IP_ADMIN, data.deleteSetIpAdmin)
      console.log('DELETE_SET_IP_ADMIN : ', data.deleteSetIpAdmin)
    },
    async [FETCH_SET_EVENT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetEvent($site: String) {
            fetchSetEvent(site: $site) {
              idx
              site
              gameName
              eventName
              minFolds
              bonusOdds
              active
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_EVENT, data.fetchSetEvent)
      console.log('FETCH_SET_EVENT : ', data.fetchSetEvent)
    },
    async [UPDATEMANY_SET_EVENT]({ commit, state }, payload) {
      const custom = state.fetchSetEvent.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        gameName: item.gameName,
        eventName: item.eventName,
        minFolds: Number(item.minFolds),
        bonusOdds: Number(item.bonusOdds),
        active: item.active,
      }))
      // console.log('custom:::::', custom)
      const { data } = await apolloClient.mutate({
        mutation: gql`
            mutation updateManySetEvent($setEventInputArgs: [SetEventInputArgs!]!, $userid: String!, $userIp: String!) {
              updateManySetEvent(setEventInputArgs: $setEventInputArgs, userid: $userid, userIp: $userIp)
            }
          `,
        variables: {
          setEventInputArgs: custom,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATEMANY_SET_EVENT, data.updateManySetEvent)
      console.log('UPDATEMANY_SET_EVENT : ', data.updateManySetEvent)
    },
    async [INSERT_SET_EVENT]({ commit, state }, payload) {
      // console.log('payload :::', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation insertSetEvent($site: String, $gameName: String, $eventName: String, $userid: String, $userIp: String ) {
            insertSetEvent(site : $site, gameName: $gameName, eventName: $eventName, userid: $userid, userIp: $userIp) {
              site
              gameName
              eventName
            }
          }
        `,
        variables: {
          site: payload.site,
          gameName: payload.gameName,
          eventName: payload.eventName,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(INSERT_SET_EVENT, data.insertSetEvent)
      console.log('INSERT_SET_EVENT : ', payload)
    },
    // async [INSERT_SET_EVENT]({ commit, state }, payload) {
    //   console.log('payload :::', payload)
    // console.log('123123123123123123123')
    // console.log('insert state : ', state.fetchSetEvent)
    // const { data } = await apolloClient.mutate({
    //   mutation: gql`
    //     mutation insertSetEvent($site: String, $gameName: String, $eventName: String ) {
    //       insertSetEvent(site : $site, gameName: $gameName, eventName: $eventName) {
    //         site
    //         gameName
    //       }
    //     }
    //   `,
    //   variables: {
    //     site: payload.site,
    //     gameName: payload.gameName,
    //   },
    //   fetchPolicy: 'no-cache',
    // })
    // commit(INSERT_SET_EVENT, data.insertSetEvent)
    //   console.log('INSERT_SET_EVENT : ', payload)
    // },
    // async [FETCH_SET_EVENT_DETAIL]({ commit }, payload) {
    //   const { data } = await apolloClient.query({
    //     query: gql`
    //       query fetchSetEventDetail($site: String, $gameName: String) {
    //         fetchSetEventDetail(site: $site, gameName: $gameName) {
    //           idx
    //           site
    //           gameName
    //           eventName
    //           minFolds
    //           homeName
    //           awayName
    //           homeOdds
    //           minOdds
    //           active
    //           createdAt
    //           updatedAt
    //         }
    //       }
    //     `,
    //     variables: {
    //       site: payload.site,
    //       gameName: payload.gameName,
    //     },
    //     fetchPolicy: 'no-cache',
    //   })
    //   commit(FETCH_SET_EVENT_DETAIL, data.fetchSetEventDetail)
    //   console.log('FETCH_SET_EVENT_DETAIL : ', data.fetchSetEventDetail)
    // },
    // async [UPDATE_SET_EVENT_DETAIL]({ commit, state }, payload) {
    //   console.log('update state : ', state.fetchSetEventDetail)
    //   for (const i in state.fetchSetEventDetail) {
    //     console.log(i)
    //     const { data } = await apolloClient.mutate({
    //       mutation: gql`
    //         mutation updateSetEventDetail($idx: Float, $site: String, $gameName: String, $eventName: String, $minFolds: Float, $homeName: String, $awayName: String, $homeOdds: Float, $minOdds: Float $active: String) {
    //           updateSetEventDetail(idx: $idx, site: $site, gameName: $gameName, eventName: $eventName, minFolds: $minFolds, homeName: $homeName, awayName: $awayName, homeOdds: $homeOdds, minOdds: $minOdds, active: $active) {
    //             idx
    //             site
    //             gameName
    //             eventName
    //             minFolds
    //             homeName
    //             awayName
    //             homeOdds
    //             minOdds
    //             active
    //           }
    //         }
    //       `,
    //       variables: {
    //         idx: Number(state.fetchSetEventDetail[i].idx),
    //         site: payload.site,
    //         gameName: payload.gameName,
    //         eventName: state.fetchSetEventDetail[i].eventName,
    //         minFolds: Number(state.fetchSetEventDetail[i].minFolds),
    //         homeName: state.fetchSetEventDetail[i].homeName,
    //         awayName: state.fetchSetEventDetail[i].awayName,
    //         homeOdds: Number(state.fetchSetEventDetail[i].homeOdds),
    //         minOdds: Number(state.fetchSetEventDetail[i].minOdds),
    //         active: state.fetchSetEventDetail[i].active,
    //       },
    //       fetchPolicy: 'no-cache',
    //     })
    //     commit(UPDATE_SET_EVENT_DETAIL, data.fetchSetEventDetail)
    //   }
    // },
    async [FETCH_SET_EVENT_DEPOSIT]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetEventDeposit($site: String) {
            fetchSetEventDeposit(site: $site) {
              idx
              site
              eventItem
              eventStartDate
              eventEndDate
              eventPercent
              minDeposit
              maxPoint
              active
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_EVENT_DEPOSIT, data.fetchSetEventDeposit)
      console.log('FETCH_SET_EVENT_DEPOSIT : ', data.fetchSetEventDeposit)
    },
    async [UPDATEMANY_SET_EVENT_DEPOSIT]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchSetEventDeposit)
      const custom = state.fetchSetEventDeposit.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        eventItem: item.eventItem,
        eventStartDate: item.eventStartDate,
        eventEndDate: item.eventEndDate,
        eventPercent: Number(item.eventPercent),
        minDeposit: Number(item.minDeposit),
        maxPoint: Number(item.maxPoint),
        active: item.active,
      }))
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManySetEventDeposit($setEventDepositInputArgs: [SetEventDepositInputArgs!]!, $userid: String!, $userIp: String!) {
            updateManySetEventDeposit(setEventDepositInputArgs: $setEventDepositInputArgs, userid: $userid, userIp: $userIp)
            }
          `,
        variables: {
          setEventDepositInputArgs: custom,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATEMANY_SET_EVENT_DEPOSIT, data.updateManySetEventDeposit)
      console.log('UPDATEMANY_SET_EVENT_DEPOSIT : ', data.updateManySetEventDeposit)
    },
    // async [UPDATE_SET_EVENT_DEPOSIT]({ commit, state }, payload) {
    //   // console.log('update state : ', state.fetchSetEventDeposit)
    //   for (const i in state.fetchSetEventDeposit) {
    //     console.log(i)
    //     const { data } = await apolloClient.mutate({
    //       mutation: gql`
    //         mutation updateSetEventDeposit($idx: Float, $site: String, $eventItem: String, $eventStartDate: String, $eventEndDate: String, $eventPercent: Float, $minDeposit: Float, $maxPoint: Float, $active: String, $userid: String, $userIp: String) {
    //           updateSetEventDeposit(idx: $idx, site: $site, eventItem: $eventItem, eventStartDate: $eventStartDate, eventEndDate: $eventEndDate, eventPercent: $eventPercent, minDeposit: $minDeposit, maxPoint: $maxPoint, active: $active, userid: $userid, userIp: $userIp) {
    //             site
    //             eventItem
    //             eventStartDate
    //             eventEndDate
    //             eventPercent
    //             minDeposit
    //             maxPoint
    //             active
    //           }
    //         }
    //       `,
    //       variables: {
    //         idx: state.fetchSetEventDeposit[i].idx,
    //         site: payload.site,
    //         eventItem: state.fetchSetEventDeposit[i].eventItem,
    //         eventStartDate: state.fetchSetEventDeposit[i].eventStartDate,
    //         eventEndDate: state.fetchSetEventDeposit[i].eventEndDate,
    //         eventPercent: Number(state.fetchSetEventDeposit[i].eventPercent),
    //         minDeposit: Number(state.fetchSetEventDeposit[i].minDeposit),
    //         maxPoint: Number(state.fetchSetEventDeposit[i].maxPoint),
    //         active: state.fetchSetEventDeposit[i].active,
    //         userid: payload.userid,
    //         userIp: payload.userIp,
    //       },
    //       fetchPolicy: 'no-cache',
    //     })
    //     commit(UPDATE_SET_EVENT_DEPOSIT, data.fetchSetEventDeposit)
    //     console.log('UPDATE_SET_EVENT_DEPOSIT : ', data.fetchSetEventDeposit)
    //   }
    // },
    async [FETCH_SET_SIGNUP]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSignup($site: String) {
            fetchSetSignup(site: $site) {
              idx
              site
              signupType
              signupPoint
              signupReferral
              signupNotice
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_SIGNUP, data.fetchSetSignup)
      console.log('FETCH_SET_SIGNUP : ', data.fetchSetSignup)
    },
    async [FETCH_SET_SIGNUP_PROHIBITS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSignupProhibits($site: String) {
            fetchSetSignupProhibits(site: $site) {
              idx
              site
              signupProhibits
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_SIGNUP_PROHIBITS, data.fetchSetSignupProhibits)
      console.log('FETCH_SET_SIGNUP_PROHIBITS : ', data.fetchSetSignupProhibits)
    },
    async [UPDATE_SET_SIGNUP]({ commit, state }, payload) {
      // console.log('updateSetSignup state::::', state.fetchSetSignup)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetSignup($site: String, $signupType: String, $signupPoint: Float, $signupReferral: String, $signupNotice: String, $userid: String, $userIp: String) {
            updateSetSignup(site: $site, signupType: $signupType, signupPoint: $signupPoint, signupReferral: $signupReferral, signupNotice: $signupNotice, userid: $userid, userIp: $userIp) {
              site
              signupType
              signupPoint
              signupReferral
              signupNotice
            }
          }
        `,
        variables: {
          site: payload.site,
          signupType: state.fetchSetSignup[0].signupType,
          signupPoint: Number(state.fetchSetSignup[0].signupPoint),
          signupReferral: state.fetchSetSignup[0].signupReferral,
          signupNotice: state.fetchSetSignup[0].signupNotice,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_SIGNUP, data.updateSetSignup)
      console.log('UPDATE_SET_SIGNUP : ', data.updateSetSignup)
    },

    // createMany 로 변경 Global 상수 CREATE_SET_SIGNUP_PROHIBITS 는 그대로 사용함
    async [CREATE_MANY_SET_SIGNUP_PROHIBITS]({ commit, state }, payload) {
      // map 메소드를 사용하여 배열의 각 요소를 { site, signupProhibits } 형태로 변환하여 data 배열에 저장
      const list = payload.signupProhibits.map(prohibit => ({
        site: payload.site,
        signupProhibits: prohibit,
      }))

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation createManySetSignupProhibits($setSignupProhibitsCreateManyInputs: [SetSignupProhibitsCreateManyInput!]!, $userid: String!, $userIp: String!) {
            createManySetSignupProhibits(setSignupProhibitsCreateManyInputs: $setSignupProhibitsCreateManyInputs, userid: $userid, userIp: $userIp) {
              site
              signupProhibits
            }
          }
        `,
        variables: {
          setSignupProhibitsCreateManyInputs: list,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(CREATE_MANY_SET_SIGNUP_PROHIBITS, data.createManySetSignupProhibits)
      console.log('CREATE_MANY_SET_SIGNUP_PROHIBITS : ', data.createManySetSignupProhibits)
    },

    async [DELETE_SET_SIGNUP_PROHIBITS]({ commit, state }, payload) {
      // console.log('delete state : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetSignupProhibits($site: String, $userid: String, $userIp: String) {
            deleteSetSignupProhibits(site: $site, userid: $userid, userIp: $userIp) {
              site
            }
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_SIGNUP_PROHIBITS, data.deleteSetSignupProhibits)
      console.log('DELETE_SET_SIGNUP_PROHIBITS : ', data.deleteSetSignupProhibits)
    },

    async [UPSERT_REQUEST_ALARMS]({ commit, state }, payload) {
      // console.log('update payload : ', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation upsertRequestAlarms($idx: Float, $site: String, $category: String, $title: String, $content: String, $requestId: String, $read: String, $readId: String, $readAt: String, $confirm: String, $confirmId: String, $confirmAt: String, $result: String, $uid: String){
            upsertRequestAlarms(idx: $idx, site: $site, category: $category, title: $title, content: $content, requestId: $requestId, read: $read, readId: $readId, readAt: $readAt, confirm: $confirm, confirmId: $confirmId, confirmAt: $confirmAt, result: $result, uid: $uid) {
              idx
              site
              category
              title
              content
              requestId
              read
              readId
              readAt
              confirm
              confirmId
              confirmAt
              result
              uid
            }
          }
        `,
        variables: {
          idx: payload.idx,
          site: payload.site,
          category: payload.category,
          title: payload.title,
          content: payload.content,
          requestId: payload.requestId,
          read: payload.read,
          readId: payload.readId,
          readAt: payload.readAt,
          confirm: payload.confirm,
          confirmId: payload.confirmId,
          confirmAt: payload.confirmAt,
          result: payload.result,
          uid: payload.uid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPSERT_REQUEST_ALARMS, data.upsertRequestAlarms)
      console.log('UPSERT_REQUEST_ALARMS : ', data.upsertRequestAlarms)
    },
    async [FETCH_SET_ALARMS]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetAlarms($site: String) {
            fetchSetAlarms(site: $site) {
              idx
              site
              title
              comment
              alarmsSound
              alarmsCnt
              alarmsInterval
              useYn
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_ALARMS, data.fetchSetAlarms)
      console.log('FETCH_SET_ALARMS : ', data.fetchSetAlarms)
    },
    async [FETCH_SET_ALARMS_ARRAY]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
            query fetchSetAlarmsArray($site: [String!]) {
                fetchSetAlarmsArray(site: $site) {
                    idx
                    site
                    title
                    comment
                    alarmsSound
                    alarmsCnt
                    alarmsInterval
                    useYn
                    createdAt
                    updatedAt
                }
            }
        `,
        variables: {
          site: payload.site,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_ALARMS_ARRAY, data.fetchSetAlarmsArray)
      console.log('FETCH_SET_ALARMS_ARRAY : ', data.fetchSetAlarmsArray)
    },
    async [UPDATE_SET_ALARMS]({ commit, state }, payload) {
      // console.log('update state : ', state.fetchGameSetLeague)
      // console.log('payload : ', payload)
      const custom = state.fetchSetAlarms.map(item => ({
        idx: Number(item.idx),
        site: payload.site,
        title: item.title,
        comment: item.comment,
        alarmsSound: item.alarmsSound,
        updateAlarmsSound: item.updateAlarmsSound,
        alarmsCnt: item.alarmsCnt,
        alarmsInterval: item.alarmsInterval,
        useYn: item.useYn,
      }))
      // console.log('custom state : ', custom)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateSetAlarms($setAlarmsInputArgs: [SetAlarmsInputArgs!]!, $userid: String!, $userIp: String!) {
            updateSetAlarms(setAlarmsInputArgs: $setAlarmsInputArgs, userid: $userid, userIp: $userIp)
          }
        `,
        variables: {
          setAlarmsInputArgs: custom, // 변수 이름을 올바르게 수정합니다.
          userid: payload.userid,
          userIp: payload.userIp,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_SET_ALARMS, data.updateSetAlarms)
      console.log('UPDATE_SET_ALARMS : ', data.updateSetAlarms)
    },
    async [FETCH_SET_SITE_ADMIN]({ commit }, payload) {
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSiteAdmin($userid: String) {
            fetchSetSiteAdmin(userid: $userid) {
              idx
              userid
              authSite
              enrollId
              memo
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_SITE_ADMIN, data.fetchSetSiteAdmin)
      console.log('FETCH_SET_SITE_ADMIN : ', data.fetchSetSiteAdmin)
    },
    async [CREATEMANY_SET_SITE_ADMIN]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation createManySetSiteAdmin($setSiteAdminInputArgs: [SetSiteAdminInputArgs!]!) {
            createManySetSiteAdmin(setSiteAdminInputArgs: $setSiteAdminInputArgs) {
              userid
              authSite
              enrollId
              memo
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          setSiteAdminInputArgs: payload,
        },
        fetchPolicy: 'no-cache',
      })
      commit(CREATEMANY_SET_SITE_ADMIN, data.createManySetSiteAdmin)
      console.log('CREATEMANY_SET_SITE_ADMIN : ', data.createManySetSiteAdmin)
    },

    async [UPDATEMANY_SET_SITE_ADMIN]({ commit }, payload) {
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManySetSiteAdmin($setSiteAdminInputArgs: [SetSiteAdminInputArgs!]!) {
            updateManySetSiteAdmin(setSiteAdminInputArgs: $setSiteAdminInputArgs) {
              idx
              enrollId
              memo
              updatedAt
            }
          }
        `,
        variables: {
          setSiteAdminInputArgs: payload,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATEMANY_SET_SITE_ADMIN, data.updateManySetSiteAdmin)
      console.log('UPDATEMANY_SET_SITE_ADMIN : ', data.updateManySetSiteAdmin)
    },

    async [DELETE_SET_SITE_ADMIN]({ commit }, payload) {
      // console.log('payload:::', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation deleteSetSiteAdmin($idx: Float) {
            deleteSetSiteAdmin(idx: $idx) {
              idx
            }
          }
        `,
        variables: {
          idx: payload.idx,
        },
        fetchPolicy: 'no-cache',
      })
      commit(DELETE_SET_SITE_ADMIN, data.deleteSetSiteAdmin)
      console.log('DELETE_SET_SITE_ADMIN : ', data.deleteSetSiteAdmin)
    },

    // 권한 menu Setting 용도, 다른곳에서 사용하지 말것 (store 공유로 메뉴 사용불가함)
    async [MENU_SET_SITE_ADMIN_MENU_PERMISSION]({ commit }, payload) {
      console.log('payload @@@@@ ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSiteAdminMenuPermission($userid: String) {
            fetchSetSiteAdminMenuPermission(userid: $userid) {
              idx
              userid
              authBasicAdmin
              authMasterAdmin
              authSuperAdmin
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(MENU_SET_SITE_ADMIN_MENU_PERMISSION, data.fetchSetSiteAdminMenuPermission)
      await commit('rootStore/SET_MENU_PERMISSION', data.fetchSetSiteAdminMenuPermission, { root: true })
      console.log('MENU_SET_SITE_ADMIN_MENU_PERMISSION : ', data.fetchSetSiteAdminMenuPermission)
    },

    // 권한 menu 조회 (다른곳에서 사용 가능)
    async [FETCH_SET_SITE_ADMIN_MENU_PERMISSION]({ commit }, payload) {
      console.log('payload @@@@@222 ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchSetSiteAdminMenuPermission($userid: String) {
            fetchSetSiteAdminMenuPermission(userid: $userid) {
              idx
              userid
              authBasicAdmin
              authMasterAdmin
              authSuperAdmin
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_SET_SITE_ADMIN_MENU_PERMISSION, data.fetchSetSiteAdminMenuPermission)
      console.log('FETCH_SET_SITE_ADMIN_MENU_PERMISSION : ', data.fetchSetSiteAdminMenuPermission)
    },

    async [UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION]({ commit, state }, payload) {

      const custom = state.fetchSetSiteAdminMenuPermission.map(item => ({
        userid: item.userid,
        authBasicAdmin: item.authBasicAdmin,
        authMasterAdmin: item.authMasterAdmin,
        authSuperAdmin: item.authSuperAdmin,
      }))
      console.log('custom ::', custom)

      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateManySetSiteAdminMenuPermission($setSiteAdminMenuPermissionInputArgs: [SetSiteAdminMenuPermissionInputArgs!]!) {
            updateManySetSiteAdminMenuPermission(setSiteAdminMenuPermissionInputArgs: $setSiteAdminMenuPermissionInputArgs)
          }
        `,
        variables: {
          setSiteAdminMenuPermissionInputArgs: custom,
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION, data.updateManySetSiteAdminMenuPermission)
      console.log('UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION : ', data.updateManySetSiteAdminMenuPermission)
    },
  },
}

export default settingsStore
