export const SIGNIN_LOGS = 'SIGNIN_LOGS'
export const FETCH_SIGNIN_LOGS = 'FETCH_SIGNIN_LOGS'
export const FETCH_SET_BATCH_SCHEDULE_LOGS = 'FETCH_SET_BATCH_SCHEDULE_LOGS'
export const FETCH_SET_BATCH_SCHEDULE_TASKS = 'FETCH_SET_BATCH_SCHEDULE_TASKS'
export const FETCH_APPROVAL_LOGS = 'FETCH_APPROVAL_LOGS'
export const FETCH_LOGS_ADMIN = 'FETCH_LOGS_ADMIN'
export const FETCH_LOGS_USERS = 'FETCH_LOGS_USERS'
export const FETCH_LOGS_USERS_SIGNIN = 'FETCH_LOGS_USERS_SIGNIN'
export const FETCH_LOGS_BATCH = 'FETCH_LOGS_BATCH'
export const FETCH_LOGS_ADMIN_VUE = 'FETCH_LOGS_ADMIN_VUE'
export const FETCH_LOGS_USERS_VUE = 'FETCH_LOGS_USERS_VUE'
export const FETCH_LOGS_USERS_DETAIL_POINT = 'FETCH_LOGS_USERS_DETAIL_POINT'
export const FETCH_LOGS_USERS_DETAIL_INFO = 'FETCH_LOGS_USERS_DETAIL_INFO'
