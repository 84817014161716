export const FETCH_USER = 'FETCH_USER'
export const FETCH_BRANCH = 'FETCH_BRANCH'
export const FETCH_BRANCH_RATE = 'FETCH_BRANCH_RATE'
export const FETCH_BRANCH_SEARCH_USER = 'FETCH_BRANCH_SEARCH_USER'
export const FETCH_BRANCH_SELECT_OPTIONS = 'FETCH_BRANCH_SELECT_OPTIONS'
export const FETCH_BRANCH_ORGANIZATION_CHART = 'FETCH_BRANCH_ORGANIZATION_CHART'
export const FETCH_BRANCH_ORGANIZATION_CHART_SUB = 'FETCH_BRANCH_ORGANIZATION_CHART_SUB'
export const FETCH_BRANCH_BALANCE = 'FETCH_BRANCH_BALANCE'
export const FETCH_BRANCH_WITHDRAWABLE_DAY = 'FETCH_BRANCH_WITHDRAWABLE_DAY'
export const FETCH_BALANCE_BRANCH_DAILY_PROFIT = 'FETCH_BALANCE_BRANCH_DAILY_PROFIT'
export const FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER = 'FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER'
export const FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER_ROLLING = 'FETCH_BALANCE_BRANCH_DAILY_PROFIT_USER_ROLLING'
export const FETCH_BRANCH_RECOMMEND_CODE = 'FETCH_BRANCH_RECOMMEND_CODE'
export const FETCH_BALANCE_ROLLING = 'FETCH_BALANCE_ROLLING'
export const FETCH_BRANCH_BALANCE_GROUP = 'FETCH_BRANCH_BALANCE_GROUP'

export const UPDATE_STATUS_COMMISSION = 'UPDATE_STATUS_COMMISSION'
