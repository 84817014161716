<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href=""
        target="_blank"
      >TEAM SEVEN</b-link>
      <span class="d-none d-sm-inline-block">, All rights Reserved</span>
    </span>
    <span class="float-md-right d-none d-md-block text-primary">
      <b-badge
        v-for="(site, index) in authSite"
        :key="index"
        type="button"
        :variant="siteSelected === site ? 'success' : 'dark'"
        style="margin-right: 3px; font-size: xx-small; padding: 8px; width: 5rem"
      >
        {{ site }}
      </b-badge>
    </span>
  </p>
</template>

<script>
import { BLink, BBadge, BButton } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
// const footerStore = createNamespacedHelpers('footerStore')

export default {
  components: {
    BLink,
    BBadge,
  },
  data() {
    return {
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>
