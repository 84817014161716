<template>
  <div class="font-small-3">
    <div
      class="text-left"
    >
      <p class="mb-0 font-small-1">
        {{ record.groupId }}
      </p>
    </div>
    <div>
      <b-table
        responsive
        show-empty
        empty-text="No matching records found"
        :per-page="perPage"
        :filter="filter"
        :filter-included-fields="filterOn"
        :items="groupByData.map(item => ({ ...item, _showDetails: true }))"
        :fields="fields1"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        :row-details-provider="rowDetailsProvider"
        style="text-align: center; font-size: small;"
        class="main-table"
        header-class="main-table-header"
        @filtered="onFiltered"
      >
        <template #row-details="row">
          <b-table
            :items="row.item.games"
            :fields="fields2"
            responsive
            small
            class="mb-1"
          >
            <template #cell(gameId)="data">
              <div>{{ data.value }}</div>
            </template>
            <template #cell(seq)="data">
              <div style="text-align: center; width: 2rem;">
                {{ data.item.seq }}
              </div>
            </template>
            <template #cell(gameDate)="data">
              <div style="text-align: center; width: 7rem;">
                {{ data.item.gameDate.slice(2) }}
              </div>
            </template>
            <template #cell(leagueName)="data">
              <div
                style="text-align: center; width: 20rem;"
              >
                {{ data.item.leagueName }}
              </div>
            </template>
            <!-- 필요한 추가 필드 템플릿을 여기에 추가 -->
            <template #cell(handicap)="data">
              <div
                :class="{'highlighted-cell': data.item.homeAway === 'Draw'}"
                style="text-align: center; min-width: 5rem"
              >
                <div v-if="data.item.handicap === null">
                  VS
                </div>
                <div v-else>
                  {{ data.item.handicap }}
                </div>
              </div>
            </template>
            <template #cell(homeName)="data">
              <div
                :class="{'highlighted-cell': data.item.homeAway === 'Home' || data.item.homeAway === 'Over'}"
                style="text-align: center; min-width: 15rem"
              >
                {{ data.item.homeName }}
              </div>
            </template>
            <template #cell(awayName)="data">
              <div
                :class="{'highlighted-cell': data.item.homeAway === 'Away' || data.item.homeAway === 'Under'}"
                style="text-align: center; min-width: 15rem"
              >
                {{ data.item.awayName }}
              </div>
            </template>
            <template #cell(oddsType)="data">
              <div style="text-align: center; width: 10rem;">
                {{ data.item.oddsType }}
              </div>
            </template>
            <template #cell(odds)="data">
              <div style="text-align: center; width: 3rem; color: #ffcd00;">
                {{ data.item.odds }}
              </div>
            </template>
            <template #cell(homeTeamResult)="data">
              <div style="text-align: center; width: 2rem;">
                <div v-if="!data.item.homeTeamResult">
                  -
                </div>
                <div v-else>
                  {{ data.item.homeTeamResult }}
                </div>
              </div>
            </template>
            <template #cell()="data">
              <div style="text-align: center; width: 2rem;">
                <div v-if="!data.item.awayTeamResult">
                  -
                </div>
                <div v-else>
                  {{ data.item.awayTeamResult }}
                </div>
              </div>
            </template>
            <template #cell(betResult)="data">
              <div style="text-align: center;">
                <div
                  v-if="data.item.betResult === 'WIN'"
                  style="color: limegreen; min-width: 5rem"
                >
                  적중
                </div>
                <div
                  v-else-if="data.item.betResult === 'LOSS'"
                  style="color: red; min-width: 5rem"
                >
                  미적중
                </div>
                <div
                  v-else-if="data.item.betResult === 'TIE'"
                  style="color: yellow; min-width: 5rem"
                >
                  적중특례
                </div>
                <div v-else-if="!data.item.betResult">
                  <b-button
                    size="sm"
                    style="font-size: x-small; border: 1px solid yellow; background-color: transparent !important; color: yellow !important; min-width: 5rem"
                    @click="submit('updateMatchCustomTie', data.item.idx)"
                  >
                    개별적특
                  </b-button>
                </div>
                <div
                  v-else-if="data.item.betResult === 'CUSTOM TIE'"
                  style="color: yellow; font-size: x-small; min-width: 5rem"
                >
                  수동 적중특례
                </div>
                <div
                  v-else-if="data.item.betResult === 'CUSTOM CANCELED'"
                  style="color: yellow; font-size: x-small; min-width: 5rem"
                >
                  수동 배팅취소
                </div>
              </div>
            </template>
          </b-table>
          <div
            class="row"
            style="color: white; background-color: rgb(21 28 56 / 69%); height: 3rem; margin-left: 8rem; margin-right: 8rem;"
          >
            <div
              class="col"
              style="align-self: center"
            >
              배팅시간 : {{ row.item.games[0].createdAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
            <div
              class="col"
              style="align-self: center; color: #ffcd00;"
            >
              베팅금액 : {{ Number(row.item.games[0].betCash).toLocaleString() }}
            </div>
            <div
              class="col"
              style="align-self: center; color: #ffcd00;"
            >
              총배당 : {{ Number(row.item.totalOdds).toLocaleString() }}
            </div>
            <div
              class="col"
              style="align-self: center; color: #ffcd00;"
            >
              적중금 : {{ Number(row.item.totalHitCash).toLocaleString() }}
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div
      v-if="record.confirmed === 'REQUEST'"
      class="text-right mb-1 mr-2"
    >
      <b-button
        class="mr-1"
        size="sm"
        variant="outline-success"
        @click="submit('updateManyGameConfirm', record.groupId)"
      >
        적중 승인
      </b-button>
      <b-button
        class=""
        size="sm"
        variant="outline-primary"
        @click="submit('updateManyGameReject', record.groupId)"
      >
        적중 부결
      </b-button>
    </div>
    <!--
    <div
      v-if="record.confirmed === 'YET'"
      class="text-left mb-1 ml-2"
    >
      <b-button
        class="mr-1"
        size="sm"
        style="font-size: small; border: 1px solid yellow; background-color: transparent !important; color: yellow !important;"
        @click="submit('updateGameCustomCancel', record.groupId)"
      >
        배팅 취소
      </b-button>
    </div>
    <div
      v-if="record.confirmed === 'CUSTOM CANCELED'"
      class="text-left mb-1 ml-2"
      style="font-size: small; color: yellow !important;"
    >
      배팅취소 완료
    </div>
     -->
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_USER_BET_HISTORY_CONFIRM } from '@/store/result/action'
import {
  UPDATE_GAME_CUSTOM_CANCEL,
  UPDATE_MANY_GAME_CONFIRM,
  UPDATE_MANY_GAME_REJECT,
  UPDATE_MATCH_CUSTOM_TIE,
} from '@/store/result/mutation'

import * as _ from 'lodash'

const authStore = createNamespacedHelpers('authStore')
const resultStore = createNamespacedHelpers('resultStore')

export default {
  name: 'RefundHitResultDetails',
  components: {
    BTable,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      searchText: '',
      groupByData: [],
      perPage: 10,
      pageOptions: [5, 10, 20],
      currentPage: 1,
      totalRows: 0,
      sortBy: 'createdAt',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'd-none',
        },
      ],
      fields2: [
        {
          key: 'seq', sortable: false, label: '폴드', thClass: 'col5',
        },
        {
          key: 'gameDate', sortable: false, label: '경기일시', thClass: 'col3',
        },
        {
          key: 'leagueName', sortable: false, label: '리그', thClass: 'col3',
        },
        {
          key: 'homeName', sortable: false, label: '홈', thClass: 'col3',
        },
        {
          key: 'handicap', sortable: false, label: '무/기준', thClass: 'col5',
        },
        {
          key: 'awayName', sortable: false, label: '어웨이', thClass: 'col3',
        },
        {
          key: 'oddsType', sortable: false, label: '유형', thClass: 'col4',
        },
        {
          key: 'odds', sortable: false, label: '배당', thClass: 'col5',
        },
        {
          key: 'homeAway', sortable: false, label: '베팅팀', thClass: 'col5',
        },
        {
          key: 'homeTeamResult', sortable: false, label: '홈팀', thClass: 'col5',
        },
        {
          key: 'awayTeamResult', sortable: false, label: '원정팀', thClass: 'col5',
        },
        // {
        //   key: 'betResult', sortable: false, label: '적중', thClass: 'col5',
        // },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...authStore.mapGetters([
      'user',
    ]),
    ...resultStore.mapGetters({
      fetchUserBetHistoryConfirm: 'clientFetchUserBetHistoryConfirm',
    }),
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...resultStore.mapActions({
      $fetchClientUserBetHistoryConfirm: FETCH_USER_BET_HISTORY_CONFIRM,
      $updateMatchCustomTie: UPDATE_MATCH_CUSTOM_TIE,
      $updateGameCustomCancel: UPDATE_GAME_CUSTOM_CANCEL,
      $updateManyGameConfirm: UPDATE_MANY_GAME_CONFIRM,
      $updateManyGameReject: UPDATE_MANY_GAME_REJECT,
    }),
    onKeyupSearchText() {
      this.searchText = document.querySelector('#searchTextInput').value
    },
    async fetchData() {
      await this.$fetchClientUserBetHistoryConfirm({
        site: this.record.site,
        userid: this.record.requestId,
        groupId: this.record.uid.split(':')[2],
      })
      this.totalRows = this.groupBy(this.fetchUserBetHistoryConfirm).length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    groupBy(data) {
      const processedData = _.chain(data)
        .groupBy('groupId')
        .map((groupedData, groupId) => ({
          groupId,
          site: _.get(groupedData, '[0].site'),
          userid: _.get(groupedData, '[0].userid'),
          category: _.get(groupedData, '[0].category'),
          sports: _.get(groupedData, '[0].sports'),
          betCash: _.get(groupedData, '[0].betCash'),
          totalBetResult: _.get(groupedData, '[0].totalBetResult'),
          hitCash: _.get(groupedData, '[0].hitCash'),
          createdAt: _.get(groupedData, '[0].createdAt'),
          totalOdds: _.get(groupedData, '[0].totalOdds'),
          totalHitCash: _.get(groupedData, '[0].totalHitCash'),
          games: _.map(groupedData, game => _.pick(game, ['idx', 'gameId', 'gameDate', 'leagueName', 'homeName', 'awayName', 'oddsType', 'oddsId', 'odds', 'handicap', 'homeAway', 'seq', 'betCash', 'homeTeamResult', 'awayTeamResult', 'status', 'betResult', 'hitCash', 'totalBetResult', 'totalOdds', 'totalHitCash', 'createdAt'])),
        }))
        .value()

      this.groupByData = processedData
      return processedData
    },
    rowDetailsProvider(row) {
      return {
        template: '',
        toggleDetails: () => {
          row.toggleDetails()
        },
        visible: row.detailsShowing,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.per-page-selector {
  width: 90px;
}
.highlighted-cell {
  background-color: rgb(156, 189, 71);
  color: #ffffff;
}
#row-details {
  background-color: #00000c !important;
}
</style>
