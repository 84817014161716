<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4>
        <feather-icon icon="BoxIcon" style="width: 18px; height: 18px" />
        게임 사용 설정
      </h4>
      <hr />
      <div class="m-1">
        <!-- Per Page -->
        <b-row class="align-items-center">
          <b-col cols="6" md="3" class="d-flex align-items-center">
            <label class="mb-0 mr-1">Show</label>
            <b-form-select
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="w-auto"
            />
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6" class="ml-auto">
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <b-table
        responsive
        show-empty
        hover
        small
        striped
        empty-text="No matching records found"
        :items="fetchSetBasicGameSettings"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @filtered="onFiltered"
        style="text-align: center !important; font-size: small"
      >
        <!-- Index Column -->
        <template #cell(index)="data">
          <div style="min-width: 1rem">
            {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
          </div>
        </template>

        <!-- Site Column -->
        <template #cell(site)="data">
          <div class="text-info" style="min-width: 5rem">
            {{ data.item.site }}
          </div>
        </template>

        <!-- Casino Checkbox -->
        <template #cell(casino)="data">
          <b-form-checkbox
            :checked="data.item.casino === 'Y'"
            class="custom-control-primary d-inline"
            name="check-button"
            @change="updateUseYn($event, data.index, 'casino')"
            switch
          >
            <span class="switch-icon-left"><feather-icon icon="CheckIcon" /></span>
            <span class="switch-icon-right"><feather-icon icon="XIcon" /></span>
          </b-form-checkbox>
        </template>

        <!-- Sports Checkbox -->
        <template #cell(sports)="data">
          <b-form-checkbox
            :checked="data.item.sports === 'Y'"
            class="custom-control-primary d-inline"
            name="check-button"
            @change="updateUseYn($event, data.index, 'sports')"
            switch
          >
            <span class="switch-icon-left"><feather-icon icon="CheckIcon" /></span>
            <span class="switch-icon-right"><feather-icon icon="XIcon" /></span>
          </b-form-checkbox>
        </template>

        <!-- Slot Checkbox -->
        <template #cell(slot)="data">
          <b-form-checkbox
            :checked="data.item.slot === 'Y'"
            class="custom-control-primary d-inline"
            name="check-button"
            @change="updateUseYn($event, data.index, 'slot')"
            switch
          >
            <span class="switch-icon-left"><feather-icon icon="CheckIcon" /></span>
            <span class="switch-icon-right"><feather-icon icon="XIcon" /></span>
          </b-form-checkbox>
        </template>

        <!-- Minigame Checkbox -->
        <template #cell(minigame)="data">
          <b-form-checkbox
            :checked="data.item.minigame === 'Y'"
            class="custom-control-primary d-inline"
            name="check-button"
            @change="updateUseYn($event, data.index, 'minigame')"
            switch
          >
            <span class="switch-icon-left"><feather-icon icon="CheckIcon" /></span>
            <span class="switch-icon-right"><feather-icon icon="XIcon" /></span>
          </b-form-checkbox>
        </template>

        <template #cell(createdAt)="data">
          <div
            style="min-width: 11rem;"
            size="sm"
          >
            {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
          </div>
        </template>

        <template #cell(updatedAt)="data">
          <div
            style="min-width: 11rem;"
            size="sm"
          >
            {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm:ss') }}
          </div>
        </template>
      </b-table>

      <!-- Pagination -->
      <div class="d-flex justify-content-end mt-2">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>

      <!-- Save Button -->
      <div class="d-flex justify-content-end mt-2">
        <b-button
          ref="submit"
          type="submit"
          variant="primary"
          size="sm"
          @click="submit('updateSetBasicGameSettings')"
        >
          저장
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BFormSelect,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_SET_BASIC_GAME_SETTINGS } from '@/store/settings/action'
import { UPDATE_SET_BASIC_GAME_SETTINGS} from '@/store/settings/mutation'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'GameUseSettings',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 100,
      pageOptions: [50, 100, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      filterByUseVendor: true,
      // option1: [
      //   { value: 'card', text: '카드' },
      //   { value: 'slot', text: '슬롯' },
      // ],
      fields: [
        // {
        //   key: 'index', sortable: false, label: 'No.',
        // },
        {
          key: 'idx',
        },
        {
          key: 'site', sortable: false, label: '사이트',
        },
        {
          key: 'casino', sortable: false, label: '카지노',
        },
        {
          key: 'sports', sortable: false, label: '스포츠',
        },
        {
          key: 'slot', sortable: false, label: '슬롯',
        },
        {
          key: 'minigame', sortable: false, label: '미니게임',
        },
        {
          key: 'createdAt', sortable: false, label: '생성일',
        },
        {
          key: 'updatedAt', sortable: false, label: '수정일',
        },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...settingsStore.mapGetters({
      fetchSetBasicGameSettings: 'fetchSetBasicGameSettings',
      updateSetBasicGameSettings: 'updateSetBasicGameSettings',
    }),
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  setup() {
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetBasicGameSettings: FETCH_SET_BASIC_GAME_SETTINGS,
      $updateSetBasicGameSettings: UPDATE_SET_BASIC_GAME_SETTINGS,
    }),
    updateParam(t, e, v, i) {
      // console.log('t >', t)
      // console.log('e >', e)
      // console.log('v >', v)
      // console.log('i >', i)

      const events = {
        t, e, v, i,
      }
      this.$store.commit('settingsStore/updateManyParam', events)
    },
    async fetchData() {
      // const mySite = this.siteSelected
      // console.log('mySite:::', mySite)
      await this.$fetchSetBasicGameSettings({
        // site: mySite,
      })
      // this.fetchSetBasic = [
      //   { site: '사이트1', casino: 'Y', sports: 'N', slot: 'Y', minigame: 'N' },
      //   { site: '사이트2', casino: 'N', sports: 'Y', slot: 'N', minigame: 'Y' },
      // ]
      this.totalRows = this.fetchSetBasicGameSettings.length
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.siteSelected
      try {
        if (target === 'updateSetBasicGameSettings') {
          await this.$updateSetBasicGameSettings({
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateUseYn(event, item, field) {
      // Get the new value from event
      // console.log('event::', event)
      // console.log('index:::', item)
      // console.log('field:::', field)

      const newValue = event === true ? 'Y' : 'N'
      // this.$set(item, field, newValue) // Update the specific field dynamically

      // Call the update function with the relevant parameters
      this.updateParam('fetchSetBasicGameSettings', field, newValue, item)
      // updateParam(t, e, v, i)
      // state[events.t][events.i][events.e] = events.v

      // Debugging (optional)
      // console.log(`Field: ${field}, New Value: ${newValue}`)
    },
    changeText(text) {
      const option = this.option1.find(option => option.value === text)
      return option ? option.text : text
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
