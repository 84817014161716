export const UPDATE_SET_BASIC = 'UPDATE_SET_BASIC'
export const UPDATE_SET_BASIC_GAME_SETTINGS = 'UPDATE_SET_BASIC_GAME_SETTINGS'
export const UPDATE_SET_SIGNIN = 'UPDATE_SET_SIGNIN'
export const UPDATE_SET_DEPOSIT_EXCHANGE = 'UPDATE_SET_DEPOSIT_EXCHANGE'
export const UPDATE_SET_ETC = 'UPDATE_SET_ETC'
export const UPDATE_SET_ACCOUNT = 'UPDATE_SET_ACCOUNT'
export const UPSERT_SET_RANK = 'UPSERT_SET_RANK'
export const UPDATE_SET_RANK_ACCOUNT = 'UPDATE_SET_RANK_ACCOUNT'
export const UPDATEMANY_SET_RANK = 'UPDATEMANY_SET_RANK'
export const DELETE_SET_RANK = 'DELETE_SET_RANK'
export const CREATEMANY_SET_RANK_DETAIL = 'CREATEMANY_SET_RANK_DETAIL'
export const UPSERT_SET_RANK_DETAIL = 'UPSERT_SET_RANK_DETAIL'
export const UPDATEMANY_SET_RANK_DETAIL = 'UPDATEMANY_SET_RANK_DETAIL'
export const INSERT_SET_IP_BLOCK = 'INSERT_SET_IP_BLOCK'
export const DELETE_SET_IP_BLOCK = 'DELETE_SET_IP_BLOCK'
export const INSERT_SET_IP_ADMIN = 'INSERT_SET_IP_ADMIN'
export const DELETE_SET_IP_ADMIN = 'DELETE_SET_IP_ADMIN'
export const UPDATEMANY_SET_EVENT = 'UPDATEMANY_SET_EVENT'
export const INSERT_SET_EVENT = 'INSERT_SET_EVENT'
export const UPDATEMANY_SET_EVENT_DEPOSIT = 'UPDATEMANY_SET_EVENT_DEPOSIT'
export const UPDATE_SET_SIGNUP = 'UPDATE_SET_SIGNUP'
export const CREATE_MANY_SET_SIGNUP_PROHIBITS = 'CREATE_MANY_SET_SIGNUP_PROHIBITS'
export const DELETE_SET_SIGNUP_PROHIBITS = 'DELETE_SET_SIGNUP_PROHIBITS'
export const UPSERT_REQUEST_ALARMS = 'UPSERT_REQUEST_ALARMS'
export const UPDATE_SET_ALARMS = 'UPDATE_SET_ALARMS'
export const CREATEMANY_SET_SITE_ADMIN = 'CREATEMANY_SET_SITE_ADMIN'
export const UPDATEMANY_SET_SITE_ADMIN = 'UPDATEMANY_SET_SITE_ADMIN'
export const DELETE_SET_SITE_ADMIN = 'DELETE_SET_SITE_ADMIN'
export const UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION = 'UPDATEMANY_SET_SITE_ADMIN_MENU_PERMISSION'
