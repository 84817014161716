<template>
  <b-card>
    <div>
      <b-tabs
        content-class="mt-3"
      >

        <b-tab
          title="수신함"
          active
        >
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            비회원 문의 수신함
            <b-badge
              variant="light-primary"
              style="font-size: small; margin-left: 5px; margin-right: 3px"
              class="badge-glow"
            >
              {{ notReadCnt }}건
            </b-badge>
            <b-badge
              variant="light-info"
              style="font-size: small; margin-right: 3px"
            >
              실시간
            </b-badge>
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
          <hr>
          <div class="m-2">
            <!-- Per Page -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="13"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <b-form-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  size="sm"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <!--                </div>-->
              </b-col>
            </b-row>
          </div>
          <!-- Table -->
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :items="fetchRequestAlarms"
            :fields="fields1"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            style="text-align: center; font-size: small"
            :current-page="currentPage"
            @filtered="onFiltered"
          >
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
            </template>
            <template #cell(site)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.site }}
              </div>
            </template>
            <template #cell(category)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.category }}
              </div>
            </template>
            <template #cell(title)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 8rem"
              >
                {{ data.item.title }}
              </div>
            </template>
            <template #cell(content)="data">
              <div
                size="sm"
                style="text-align: left; min-width: 15rem"
              >
                {{ data.item.content }}
              </div>
            </template>
            <template #cell(requestId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.requestId }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 9rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(confirm)="data">
              <div
                v-if="data.item.confirm !== 'true'"
                size="sm"
                style="text-align: center; min-width: 4rem;"
              >
                대기
              </div>
            </template>
            <template #cell(readId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.readId }}
              </div>
            </template>
            <template
              #cell(edit)="data"
            >
              <div
                class="editClass"
                style="min-width: 12rem;"
                @click.stop
              >
                <span class="d-inline-flex">
                  <div v-if="data.item.category === '비회원'">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="outline-success"
                      style="font-size: smaller"
                      @click="submit('upsertRequestAlarmsOk', data)"
                    >
                      처리완료
                    </b-button>
                  </div>
                  <div v-else>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mr-1"
                      size="sm"
                      variant="outline-success"
                      style="font-size: smaller"
                      @click="submit('upsertRequestAlarmsOk', data)"
                    >
                      승인
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="outline-primary"
                      style="font-size: smaller;"
                      @click="submit('upsertRequestAlarmsReject', data)"
                    >
                      부결
                    </b-button>
                  </div>
                </span>
              </div>
            </template>
          </b-table>
          <b-modal
            id="approval-modal"
            title="적중 승인 상세"
            centered
            button-size="sm"
            size="xl"
            ok-title="확인"
            ok-variant="secondary"
            auto-focus-button="ok"
            ok-only
          >
            <RequestAlarmsDetailResult :record="selectedRecord"/>
          </b-modal>
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <b-tab title="완료함">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            비회원 문의 완료함
            <b-badge
              variant="light-primary"
              style="font-size: small; margin-left: 5px; margin-right: 3px"
              class="badge-glow"
            >
              {{ totalRowsFinish }}건
            </b-badge>
            <b-badge
              variant="light-info"
              style="font-size: small; margin-right: 3px"
            >
              실시간
            </b-badge>
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
          <hr>

          <div class="m-2">
            <!-- Per Page -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="6"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <b-form-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  size="sm"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput2"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>

          <!-- Table -->
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :items="fetchRequestAlarmsFinish"
            :fields="fields2"
            :filter="filter"
            :filter-included-fields="filterOn"
            style="text-align: center; font-size: small"
            :sort-by.sync="sortByFinish"
            :sort-desc.sync="sortDescFinish"
            :sort-direction="sortDirectionFinish"
            :current-page="currentPageFinish"
            @filtered="onFiltered"
          >
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.index+1 }}
            </template>
            <template #cell(site)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.site }}
              </div>
            </template>
            <template #cell(title)="data">
              <div
                size="sm"
                style="text-align: center; width: 7rem"
              >
                {{ data.item.title }}
              </div>
            </template>
            <template #cell(content)="data">
              <div
                size="sm"
                style="text-align: left; width: 18rem"
              >
                {{ data.item.content }}
              </div>
            </template>
            <template #cell(requestId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.requestId }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 9rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(confirm)="data">
              <div
                v-if="data.item.confirm === 'true'"
                size="sm"
                style="text-align: center; min-width: 4rem; color: limegreen"
              >
                처리완료
              </div>
              <div
                v-if="data.item.confirm === 'false'"
                size="sm"
                style="text-align: center; min-width: 4rem; color: orangered"
              >
                부결
              </div>
            </template>
            <template #cell(confirmAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 9rem"
              >
                {{ data.item.confirmAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinish"
                  :total-rows="totalRowsFinish"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-card>

</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_REQUEST_ALARMS,
  FETCH_REQUEST_ALARMS_FINISH,
} from '@/store/notice/action'
import {
  UPSERT_REQUEST_ALARMS, UPSERT_DEPOSIT, UPSERT_EXCHANGE, UPDATE_USERS_CASH_POINT,
} from '@/store/notice/mutation'
import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
let readAt
let confirmAt

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 30,
      pageOptions: [10, 30, 50],
      totalRowsFinish: 1,
      totalRows: 1,
      notReadCnt: 0,
      currentPage: 1,
      currentPageFinish: 1,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      sortByFinish: 'updatedAt',
      sortDescFinish: true,
      sortDirectionFinish: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      check1: 'false',
      isHidden1: true,
      selectedRecord: null, // 선택된 record를 저장하기 위한 변수
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'requestId', sortable: false, label: '아이디', thClass: 'col5',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col3',
        },
        {
          key: 'content', sortable: false, label: '요청내용', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '요청일시', thClass: 'col6',
        },
        {
          key: 'confirm', sortable: false, label: '처리결과', thClass: 'col7',
        },
        {
          key: 'edit', sortable: false, label: '처리', thClass: 'col10',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'requestId', sortable: false, label: '아이디', thClass: 'col5',
        },
        {
          key: 'title', sortable: false, label: '제목', thClass: 'col3',
        },
        {
          key: 'content', sortable: false, label: '내용', thClass: 'col4',
        },
        {
          key: 'createdAt', sortable: false, label: '요청일시', thClass: 'col6',
        },
        {
          key: 'confirm', sortable: false, label: '처리결과', thClass: 'col7',
        },
        {
          key: 'confirmAt', sortable: false, label: '처리일시', thClass: 'col8',
        },
      ],
    }
  },
  created() {
    this.$socket.on('requestAlarms', data => {
      this.$store.dispatch('noticeStore/socketFetchRequestAlarms', data)
    })
    this.$socket.on('requestAlarmsFinish', data => {
      this.$store.dispatch('noticeStore/socketFetchRequestAlarmsFinish', data)
    })
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...noticeStore.mapGetters({
      fetchRequestAlarms: 'fetchRequestAlarms',
      fetchRequestAlarmsFinish: 'fetchRequestAlarmsFinish',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchRequestAlarms
    },
  },
  mounted() {
    this.fetchData('all')
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarms: FETCH_REQUEST_ALARMS,
      $fetchRequestAlarmsFinish: FETCH_REQUEST_ALARMS_FINISH,
      $upsertRequestAlarms: UPSERT_REQUEST_ALARMS,
    }),
    async fetchData(Gbn) {
      // const mySite = this.userData.site
      if (Gbn === 'all') {
        await this.$fetchRequestAlarms({
          site: this.authSite,
          result: '대기',
        })
      }
      if (Gbn === 'notall') {
        await this.$fetchRequestAlarms({
          site: this.authSite,
          result: '대기',
          read: 'false',
        })
      }
      await this.$fetchRequestAlarmsFinish({
        site: this.authSite,
        result: '완료',
      })
      this.totalRowsFinish = this.fetchRequestAlarmsFinish.length
      this.totalRows = this.fetchRequestAlarms.length
      this.notRead()
    },
    notRead() {
      this.notReadCnt = 0
      for (const i in this.fetchRequestAlarms) {
        if (this.fetchRequestAlarms[i].read === 'false') this.notReadCnt++
      }
    },
    // submit
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      const myId = this.userData.userid
      readAt = moment(new Date())
      confirmAt = moment(new Date())
      try {
        if (target === 'upsertRequestAlarmsOk') {
          await this.$upsertRequestAlarms({
            idx: Number(data.item.idx),
            site: data.item.site,
            category: data.item.category,
            title: data.item.title,
            content: data.item.content,
            requestId: data.item.requestId,
            read: 'true',
            readAt: readAt.format(fmt1),
            readId: data.item.readId,
            confirm: 'true',
            confirmId: myId,
            confirmAt: confirmAt.format(fmt1),
            result: '완료',
          })
        }
        if (target === 'upsertRequestAlarmsReject') {
          await this.$upsertRequestAlarms({
            idx: Number(data.item.idx),
            site: data.item.site,
            category: data.item.category,
            title: data.item.title,
            content: data.item.content,
            requestId: data.item.requestId,
            read: 'true',
            readAt: readAt.format(fmt1),
            readId: data.item.readId,
            confirm: 'false',
            confirmAt: confirmAt.format(fmt1),
            confirmId: myId,
            result: '완료',
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData('all')
        })
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.totalRowsFinish = filteredItems.length
      this.currentPageFinish = 1
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
</style>
