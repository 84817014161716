<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <GameSettingsTabMenu
      ref="menuTab"
      :site="siteSelected"
    />
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import GameSettingsTabMenu from '@/views/game/gameSubTabs/GameSettingsTabMenu'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    GameSettingsTabMenu,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.$refs.menuTab.fetchData()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
