<template>
  <div>
    <b-card no-body>
      <h1>{{ title }}</h1>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol,
  BTabs, BTab,
  VBToggle,
  BBadge,
  BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BButton, BFormSelect, BTable, BPagination, BLink
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    BCard, BRow, BCol,
    BTabs, BTab,
    VBToggle,
    BBadge,
    BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BButton, BFormSelect, BTable, BPagination, BLink,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return {
      // userData: JSON.parse(localStorage.getItem('selectedUserViewInfo')),
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
  },
  created() {
    // console.log(this.title, "created()")
  },
  mounted() {
    // console.log(this.title, "mounted()")
    this.fetchData()
  },
  methods: {
    async fetchData() {
      // console.log(this.title, "fetchData()")
    },
  },
}
</script>
