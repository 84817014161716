<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <b-row>
        <!-- left -->
        <b-col
          lg="7"
        >
          <!-- header -->
          <h4>
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            조합설정
          </h4>
          <hr>

          <!-- contents -->
          <div>
            <!-- search conditions -->
            <div>
              <b-form-group
                label="메뉴"
                label-for="search-menu"
              >
                <b-button-group size="sm">
                  <b-button
                    v-for="menu in menuItems"
                    :key="menu.idx"
                    size="sm"
                    style="width: 6rem"
                    :variant="selectedMenu.idx === menu.idx ? 'primary' : 'outline-secondary'"
                    @click="selectMenu(menu.idx, menu.type)"
                  >
                    {{ menu.label }}
                  </b-button>
                </b-button-group>
              </b-form-group>
            </div>
            <div>
              <b-form-group
                label="종목"
                label-for="search-sport"
              >
                <b-button-group
                  size="sm"
                  class="flex-wrap"
                >
                  <b-button
                    v-for="sport in sportItems"
                    :key="sport.idx"
                    size="sm"
                    style="width: 6rem"
                    :variant="selectedSport.idx === sport.idx ? 'primary' : 'outline-secondary'"
                    @click="selectSport(sport.idx, sport.type)"
                  >
                    {{ sport.label }}
                  </b-button>
                </b-button-group>
              </b-form-group>

            </div>
          </div>
          <div>
            <!-- search Results -->
            <b-table
              responsive
              show-empty
              hover
              small
              striped
              empty-text="No matching records found"
              class="text-center"
              :items="filteredItems"
              :fields="fields"
              :tbody-tr-class="rowClass"
              style="user-select: none;"
              @row-clicked="toggleRowItems"
            >
              <template #cell(no)="data">
                {{ data.item.idx }}
              </template>
            </b-table>
          </div>
        </b-col>
        <b-col
          lg="5"
          class="order-first order-lg-2"
        >
          <!-- header -->
          <h4>
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            조합배팅 제한설정
          </h4>
          <hr>
          <!-- contents -->
          <div class="mb-1">
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label="마켓조합"
                  label-for="marketId"
                >
                  <b-form-input
                    id="marketId"
                    ref="marketId"
                    v-model="marketId"
                    placeholder="마켓조합"
                    size="sm"
                    class="cursor-pointer font-small-3"
                    style="color:rgb(229,166,48); opacity: 1;"
                    :state="validationState['marketId']"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="동일경기 적용여부"
                  label-for="marketAllow"
                  label-class="text-truncate"
                >
                  <b-form-select
                    id="marketAllow"
                    ref="marketAllow"
                    v-model="selectedAllow"
                    size="sm"
                    class="cursor-pointer font-small-3"
                    style="color:rgb(229,166,48)"
                    :state="validationState['marketAllow']"
                  >
                    <template #first>
                      <option
                        disabled
                        value="null"
                        style="color: white;"
                      >
                        == 선택 ==
                      </option>
                    </template>
                    <b-form-select-option
                      v-for="option in allowItems"
                      :key="option.idx"
                      :value="option"
                      :disabled="option.disabled"
                    >
                      {{ option.text }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="마켓조합설명"
                  label-for="marketDesc"
                >
                  <b-form-textarea
                    id="marketDesc"
                    ref="marketDesc"
                    v-model="marketDesc"
                    placeholder="마켓조합설명"
                    class="cursor-pointer font-small-3"
                    style="color:rgb(229,166,48)"
                    rows="3"
                    :state="validationState['marketDesc']"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  size="sm"
                  class="float-none mx-auto d-block"
                  @click.prevent="submit"
                >
                  추가
                </b-button>
              </b-col>
            </b-row>
          </div>

          <!-- header -->
          <h4>
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            조합배팅목록
          </h4>
          <hr>
          <!-- contents -->
          <div>
            <b-row>
              <b-col>
                <b-table
                  responsive
                  show-empty
                  hover
                  small
                  striped
                  empty-text="No matching records found"
                  class="text-center"
                  :items="filteredSetCombinationItems"
                  :fields="fields2"
                >
                  <template #cell(combinationName)="data">
                    {{
                      data.item.combination
                        .split(',')
                        .map((id) => {
                          const market = fetchGameSetOddsMarket.find((market) => market.marketId === id);
                          return market ? market.marketNameCustom || market.marketName : null;
                        })
                        .join(', ')
                    }}
                  </template>

                  <template #cell(allow)="data">
                    {{ allowItems.find((item) => item.value === data.item.allow).text }}
                  </template>
                  <template #cell(edit)="data">
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-1"
                      style="color:rgb(229,166,48);"
                      @click.stop="handleCombinationEdit('edit', data.item)"
                    />
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-1"
                      style="color:rgb(229,166,48);"
                      @click.stop="handleCombinationEdit('delete', data.item)"
                    />
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormSelect, BFormSelectOption,
  BFormTextarea,
  BButtonGroup, BButton,

  BTable,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_ODDS_MARKET, FETCH_GAME_SET_MENU, FETCH_GAME_SET_ITEM, FETCH_GAME_SET_ODDS_CUSTOM, FETCH_GAME_SET_COMBINATION } from '@/store/game/action'
import { UPDATE_GAME_SET_COMBINATION, DELETE_GAME_SET_COMBINATION } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabCombination',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BButtonGroup,
    BButton,

    BTable,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentURL: window.location.href,
      componentName: this.$options.name,

      selectedMenu: { type: 'Sports-Prematch', idx: 1 }, // 기본 선택된 메뉴
      menuItems: [
        { idx: 1, type: 'Sports-Prematch', label: '프리매치' },
        { idx: 2, type: 'Sports-Special', label: '스페셜' },
        { idx: 3, type: 'Sports-Inplay', label: '인플레이' },
      ],
      selectedSport: { type: 'soccer', idx: 1 }, // 기본 선택된 종목
      sportItems: [
        { idx: 1, type: 'soccer', label: '축구' },
        { idx: 2, type: 'basketball', label: '농구' },
        { idx: 3, type: 'baseball', label: '야구' },
        { idx: 4, type: 'tennis', label: '테니스' },
        { idx: 5, type: 'volleyball', label: '배구' },
        { idx: 6, type: 'football', label: '풋볼' },
        { idx: 7, type: 'hockey', label: '하키' },
        { idx: 8, type: 'esports', label: 'E스포츠' },
      ],
      selectedAllow: null,
      allowItems: [
        { idx: 1, value: 1, text: '전체적용' },
        { idx: 2, value: 2, text: '동일경기 적용' },
      ],
      selectedUse: { idx: 1, value: 'Y', text: '사용' },
      useItems: [
        { idx: 1, value: 'Y', text: '사용' },
        { idx: 2, value: 'N', text: '미사용' },
      ],
      selectedRows: [],
      items: [
        { marketId: 'MKT001', marketName: 'Match Winner + Asian Handicap', marketNameCustom: '승무패 + 핸디캡' },
        { marketId: 'MKT002', marketName: 'Match Winner + Goals Over/Under', marketNameCustom: '승무패 + 오버언더' },
        { marketId: 'MKT003', marketName: 'Asian Handicap + Goals Over/Under', marketNameCustom: '핸디캡 + 오버언더' },
        { marketId: 'MKT004', marketName: 'Double Chance', marketNameCustom: '더블 찬스' },
        { marketId: 'MKT005', marketName: 'Correct Score', marketNameCustom: '정확한 점수' },
      ],
      fields: [
        { key: 'marketId', label: 'CODE' },
        { key: 'marketName', label: '유형명', tdClass: 'text-left' },
        { key: 'marketNameCustom', label: '유형명(한글)', tdClass: 'text-left' },
      ],
      fields2: [
        { key: 'combination', label: '조합' },
        { key: 'combinationName', label: '조합명', tdClass: 'text-left' },
        { key: 'allow', label: '동일경기여부', thStyle: { width: '7rem' } },
        { key: 'edit', label: '', thStyle: { width: '7rem' } },
      ],

      marketId: null,
      marketDesc: null,

      validateFields: ['marketId', 'marketDesc', 'marketAllow'],
      validationState: {},
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...gameStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
      fetchGameSetItem: 'fetchGameSetItem',
      fetchGameSetOddsMarket: 'fetchGameSetOddsMarket',
      fetchGameSetOddsCustom1: 'fetchGameSetOddsCustom',
      fetchGameSetOddsCustom2: 'fetchGameSetOddsCustom',
      fetchGameSetCombination: 'fetchGameSetCombination',
      updateGameSettCombination: 'updateGameSettCombination',
    }),
    filteredItems() {
      // menuName과 itemName의 우선순위 배열 정의
      const menuNameOrder = ['Sports-Prematch', 'Sports-Inplay', 'Sports-Special']
      const itemNameOrder = ['soccer', 'basketball', 'baseball', 'tennis', 'volleyball', 'football', 'hockey', 'esports']

      // selected 상태 설정
      const filteredData = this.fetchGameSetOddsMarket
        .map(item => ({
          ...item,
          selected: this.selectedRows.some(row => row.idx === item.idx),
        }))
        .sort((a, b) => {
        // 1. selected가 true인 항목을 먼저 정렬
          if (b.selected !== a.selected) return b.selected - a.selected

          // 2. menuName을 우선순위 배열에 따라 정렬
          const menuAIndex = menuNameOrder.indexOf(a.menuName)
          const menuBIndex = menuNameOrder.indexOf(b.menuName)
          if (menuAIndex !== menuBIndex) return menuAIndex - menuBIndex

          // 3. itemName을 우선순위 배열에 따라 정렬
          const itemAIndex = itemNameOrder.indexOf(a.itemName.toLowerCase())
          const itemBIndex = itemNameOrder.indexOf(b.itemName.toLowerCase())
          if (itemAIndex !== itemBIndex) return itemAIndex - itemBIndex

          // 4. itemName이 같다면 marketId로 오름차순 정렬
          return a.marketId - b.marketId
        })

      return filteredData
    },
    filteredSetCombinationItems() {
      const filteredData = this.fetchGameSetCombination
      return filteredData
    },
  },
  watch: {
    selectedMenu: {
      handler: 'fetchData',
      immediate: true,
    },
    selectedSport: {
      handler: 'fetchData',
      immediate: true,
    },
    selectedRows: {
      handler() {
        this.handleMarketInfo()
      },
      immediate: true,
    },
    selectedAllow: {
      handler: 'handleMarketInfo',
      immediate: true,
    },
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $fetchGameSetItem: FETCH_GAME_SET_ITEM,
      $fetchGameSetOddsMarket: FETCH_GAME_SET_ODDS_MARKET,
      $fetchGameSetOddsCustom1: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSetOddsCustom2: FETCH_GAME_SET_ODDS_CUSTOM,
      $fetchGameSetCombination: FETCH_GAME_SET_COMBINATION,
      $updateGameSetCombination: UPDATE_GAME_SET_COMBINATION,
      $deleteGameSetCombination: DELETE_GAME_SET_COMBINATION,
    }),
    async fetchData() {
      this.clearSelectedRows()

      const mySite = this.site
      await this.$fetchGameSetOddsMarket({
        site: mySite,
        menuName: this.selectedMenu.type,
        sports: this.selectedSport.type,
        useYn: false,
      })
      this.fetchDataGameSetCombination()
    },
    async fetchDataGameSetCombination() {
      await this.$fetchGameSetCombination({
        site: this.site,
        menuName: this.selectedMenu.type,
        sports: this.selectedSport.type,
      })
    },
    async deleteGameSetCombination() {
      await this.$deleteGameSetCombination({
        site: this.site,
        menu: this.selectedMenu.type,
        sport: this.selectedSport.type,
        marketId: this.marketId,
      })
    },
    clearSelectedRows() {
      this.selectedRows = []
    },
    selectMenu(idx, type) {
      this.selectedMenu = { idx, type }
    },
    selectSport(idx, type) {
      this.selectedSport = { idx, type }
    },
    handleMarketInfo() {
      //
      const sortedRows = [...this.selectedRows].sort((a, b) => a.marketId - b.marketId)

      this.marketId = sortedRows.map(item => item.marketId).join(',') // 문자열로 합치기
      const selectedNames = sortedRows.map(item => item.marketNameCustom || item.marketName).join(', ')
      console.log(selectedNames)
      if (this.selectedAllow?.idx === 1) {
        this.marketDesc = `"${selectedNames}"의 조합은 불가능합니다.`
      } else if (this.selectedAllow?.idx === 2) {
        this.marketDesc = `"${selectedNames}" 의 조합은 동일 경기 내 조합이 불가능합니다.`
      }
      if (this.selectedRows.length <= 1) {
        this.marketDesc = ''
        // this.clearValidation()
      }

      //
      // this.clearValidation()
    },
    async handleCombinationEdit(target, item) {
      // combination 분리하여 배열로 변환
      const marketIds = item.combination.split(',')
      // 각 marketId에 대해 toggleRowItems 호출
      marketIds.forEach(marketId => {
        this.toggleRowItems({ marketId })
      })
      this.marketId = item.combination
      this.selectedAllow = this.allowItems.find(allowItem => allowItem.value === item.allow)
      this.marketDesc = item.alertMessage

      if (target === 'edit') {
        //
      } else if (target === 'delete') {
        await Swal
          .fire({
            title: '삭제 확인',
            text: '정말로 삭제하시겠습니까?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            background: 'rgb(3,11,37)',
            customClass: {
              cancelButton: 'btn btn-dark',
              confirmButton: 'btn btn-primary mr-1',
            },
          })
          .then(result => {
            if (result.isConfirmed) {
              // 확인 버튼을 눌렀을 때 삭제 작업 수행
              this.marketId = item.combination
              this.deleteGameSetCombination()
                .then(() => {
                  Swal.fire({
                    title: '삭제 완료',
                    text: '선택한 조합이 성공적으로 삭제되었습니다.',
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: '확인',
                    background: 'rgb(3,11,37)',
                  })
                })
                .catch(error => {
                  console.error('삭제 실패:', error)
                  Swal.fire({
                    title: '삭제 실패',
                    text: '삭제 처리 중 문제가 발생했습니다. 다시 시도해주세요.',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: '확인',
                    background: 'rgb(3,11,37)',
                  })
                })
                .finally(() => {
                  // 항상 실행되는 후속 작업
                  this.clearSelectedRows()
                  this.fetchDataGameSetCombination()
                })
            }
          })
      }
    },
    toggleRowItems(item) {
      console.log(item)
      const index = this.selectedRows.findIndex(row => row.marketId === item.marketId)

      if (index !== -1) {
        // 이미 존재하면 제거
        this.selectedRows.splice(index, 1)
      } else {
        // marketId를 기준으로 this.fetchGameSetOddsMarket에서 데이터 검색
        const marketData = this.fetchGameSetOddsMarket.find(
          market => market.marketId === item.marketId,
        )

        if (marketData) {
          // 검색된 데이터를 selectedRows에 추가
          this.selectedRows.push(marketData)
        } else {
          console.error(`Market data not found for marketId: ${item.marketId}`)
        }
      }
    },

    rowClass(item) {
      return this.selectedRows.some(row => row.marketId === item.marketId) ? 'selected-row' : ''
    },
    validate() {
      // marketId 유효성 검사
      if (this.marketId === null || this.marketId.trim() === '') {
        this.$set(this.validationState, 'marketId', false)
      }
      // marketDesc 유효성 검사
      if (this.marketDesc === null || this.marketDesc.trim() === '') {
        this.$set(this.validationState, 'marketDesc', false)
      }
      // selectedAllow 유효성 검사
      // if (Object.keys(this.selectedAllow).length === 0) {
      if (this.selectedAllow === null) {
        this.$set(this.validationState, 'marketAllow', false)
      }
    },
    clearValidation() {
      // this.validateFields.forEach(field => {
      //   const fieldValue = this[field]
      //   if (!fieldValue || fieldValue.length > 0) {
      //     delete this.validationState[field]
      //   }
      // })
      // marketId 유효성 상태 삭제
      if (!this.marketId || this.marketId.trim() === '') {
        delete this.validationState.marketId
      }

      // marketDesc 유효성 상태 삭제
      if (!this.marketDesc || this.marketDesc.trim() === '') {
        delete this.validationState.marketDesc
      }

      // selectedAllow 유효성 상태 삭제
      if (this.selectedAllow && Object.keys(this.selectedAllow).length !== 0) {
        delete this.validationState.selectedAllow
      }
    },

    async submit(target) {
      // const isValid = this.marketId?.trim() && this.marketDesc?.trim() && Object.keys(this.selectedAllow || {}).length
      // if (!isValid) {
      //   return false
      // }

      await Swal.fire({
        title: '조합 내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      try {
        await this.$updateGameSetCombination({
          site: this.site,
          menu: this.selectedMenu.type,
          sport: this.selectedSport.type,
          allow: this.selectedAllow.value,
          marketId: this.marketId,
          marketDesc: this.marketDesc,
          useYn: this.selectedUse.value,
        })
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
          .then(() => {
            this.clearSelectedRows()
            this.fetchDataGameSetCombination()
          })
      }
    },

    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'Prmiary',
        },
      })
    },
  },
}
</script>

<style>
.selected-row {
  background-color: #464251 !important; /* 선택된 행에 표시할 배경색 */
}
</style>
