import gql from 'graphql-tag'
import { apolloClient } from '@/vue-apollo'

import {
  FETCH_MESSAGES_USER_FROM_ADMIN,
  FETCH_MESSAGES_USER_TO_ADMIN,
  FETCH_MESSAGES_ADMIN_FROM_USER,
  FETCH_MESSAGES_ADMIN_TO_USER,
  FETCH_MESSAGES_ADMIN_FROM_MASTER,
  FETCH_MESSAGES_ADMIN_TO_MASTER,
  FETCH_MESSAGES_MASTER_FROM_ADMIN,
  FETCH_MESSAGES_MASTER_TO_ADMIN,
  FETCH_MESSAGES_CNT,
  FETCH_MESSAGES_SEND_FORM_SEARCH_USERS,
} from '@/store/messages/action'
import {
  CREATE_MESSAGES,
  UPDATE_MESSAGES_READ,
  UPDATE_MESSAGES_DELETE,
} from '@/store/messages/mutation'

const messagesStore = {
  namespaced: true,
  state: () => ({
    fetchMessagesUserFromAdmin: [],
    fetchMessagesUserToAdmin: [],
    fetchMessagesAdminFromUser: [],
    fetchMessagesAdminToUser: [],
    fetchMessagesAdminFromMaster: [],
    fetchMessagesAdminToMaster: [],
    fetchMessagesMasterFromAdmin: [],
    fetchMessagesMasterToAdmin: [],
    fetchMessagesCnt: [],
    fetchMessagesSendFormSearchUsers: [],
    updateMessagesRead: [],
    updateMessagedDelete: [],
  }),
  getters: {
    fetchMessagesUserFromAdmin: state => state.fetchMessagesUserFromAdmin,
    fetchMessagesUserToAdmin: state => state.fetchMessagesUserToAdmin,
    fetchMessagesAdminFromUser: state => state.fetchMessagesAdminFromUser,
    fetchMessagesAdminToUser: state => state.fetchMessagesAdminToUser,
    fetchMessagesAdminFromMaster: state => state.fetchMessagesAdminFromMaster,
    fetchMessagesAdminToMaster: state => state.fetchMessagesAdminToMaster,
    fetchMessagesMasterFromAdmin: state => state.fetchMessagesMasterFromAdmin,
    fetchMessagesMasterToAdmin: state => state.fetchMessagesMasterToAdmin,
    fetchMessagesCnt: state => state.fetchMessagesCnt,
    fetchMessagesSendFormSearchUsers: state => state.fetchMessagesSendFormSearchUsers,
    updateMessagesRead: state => state.updateMessagesRead,
    updateMessagesDelete: state => state.updateMessagesDelete,
  },
  mutations: {
    [FETCH_MESSAGES_USER_FROM_ADMIN](state, fetchMessagesUserFromAdmin) {
      state.fetchMessagesUserFromAdmin = fetchMessagesUserFromAdmin
    },
    [FETCH_MESSAGES_USER_TO_ADMIN](state, fetchMessagesUserToAdmin) {
      state.fetchMessagesUserToAdmin = fetchMessagesUserToAdmin
    },
    [FETCH_MESSAGES_ADMIN_FROM_USER]: (state, payload) => {
      state.fetchMessagesAdminFromUser = payload
    },
    [FETCH_MESSAGES_ADMIN_TO_USER]: (state, payload) => {
      state.fetchMessagesAdminToUser = payload
    },
    [FETCH_MESSAGES_ADMIN_FROM_MASTER]: (state, payload) => {
      state.fetchMessagesAdminFromMaster = payload
    },
    [FETCH_MESSAGES_ADMIN_TO_MASTER]: (state, payload) => {
      state.fetchMessagesAdminToMaster = payload
    },
    [FETCH_MESSAGES_MASTER_FROM_ADMIN]: (state, payload) => {
      state.fetchMessagesMasterFromAdmin = payload
    },
    [FETCH_MESSAGES_MASTER_TO_ADMIN]: (state, payload) => {
      state.fetchMessagesMasterToAdmin = payload
    },
    [FETCH_MESSAGES_SEND_FORM_SEARCH_USERS]: (state, payload) => {
      state.fetchMessagesSendFormSearchUsers = payload
    },
    [FETCH_MESSAGES_CNT]: (state, payload) => {
      state.fetchMessagesCnt = payload
    },
    [UPDATE_MESSAGES_READ](state, updateMessagesRead) {
      state.updateMessagesRead = updateMessagesRead
    },
    [UPDATE_MESSAGES_DELETE](state, updateMessagesDelete) {
      state.updateMessagesDelete = updateMessagesDelete
    },
  },
  actions: {
    async [CREATE_MESSAGES]({ commit }, payload) {
      // console.log('payload :::', payload)
      return apolloClient
        .mutate({
          mutation: gql`
            mutation createManyMessages($data: MessagesInput!) {
              createManyMessages(data: $data)
            }
          `,
          variables: {
            data: { messages: payload.messages },
          },
          fetchPolicy: 'no-cache',
        })
        .then(res => {
          const { data } = res
          const rsltMsg = {
            true: {
              status: true,
              statusMsg: `Messages send successfully! ${data.createManyMessages}`,
            },
            false: { status: false, statusMsg: 'Messages send failed' },
          }[data.createManyMessages > 0 ? 'true' : 'false']
          // commit('SEND_MESSAGES_STATUS', rsltMsg)
          return rsltMsg
        })
    },
    async [FETCH_MESSAGES_CNT]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesCnt($site: [String!], $userid: String) {
            fetchMessagesCnt(site: $site, userid: $userid)
          }
        `,
        variables: {
          site: payload.site,
          userid: payload.userid,
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_CNT, data.fetchMessagesCnt)
      console.log('FETCH_MESSAGES_CNT : ', data.fetchMessagesCnt)
    },
    // refactoring
    async [FETCH_MESSAGES_USER_FROM_ADMIN]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesUserFromAdmin($messagesUserFromAdminArgs: MessagesUserFromAdminArgs!) {
            fetchMessagesUserFromAdmin(messagesUserFromAdminArgs: $messagesUserFromAdminArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesUserFromAdminArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            receiverId: payload.userid,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_USER_FROM_ADMIN, data.fetchMessagesUserFromAdmin)
      console.log('FETCH_MESSAGES_USER_FROM_ADMIN : ', data.fetchMessagesUserFromAdmin)
    },
    async [FETCH_MESSAGES_USER_TO_ADMIN]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesUserToAdmin($messagesUserToAdminArgs: MessagesUserToAdminArgs!) {
            fetchMessagesUserToAdmin(messagesUserToAdminArgs: $messagesUserToAdminArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesUserToAdminArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            senderId: payload.userid,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_USER_TO_ADMIN, data.fetchMessagesUserToAdmin)
      console.log('FETCH_MESSAGES_USER_TO_ADMIN : ', data.fetchMessagesUserToAdmin)
    },
    async [FETCH_MESSAGES_ADMIN_FROM_USER]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesAdminFromUser($messagesAdminFromUserArgs: MessagesAdminFromUserArgs!) {
            fetchMessagesAdminFromUser(messagesAdminFromUserArgs: $messagesAdminFromUserArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesAdminFromUserArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_ADMIN_FROM_USER, data.fetchMessagesAdminFromUser)
      console.log('FETCH_MESSAGES_ADMIN_FROM_USER : ', data.fetchMessagesAdminFromUser)
    },
    async [FETCH_MESSAGES_ADMIN_TO_USER]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesAdminToUser($messagesAdminToUserInputArgs: MessagesAdminToUserInputArgs!) {
            fetchMessagesAdminToUser(messagesAdminToUserInputArgs: $messagesAdminToUserInputArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              sendCnt
              sendAt
              receivers {
                receiverId
                receiverIp
                isRead
                readAt
              }
              readStatus
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesAdminToUserInputArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_ADMIN_TO_USER, data.fetchMessagesAdminToUser)
      console.log('FETCH_MESSAGES_ADMIN_TO_USER : ', data.fetchMessagesAdminToUser)
    },

    async [FETCH_MESSAGES_ADMIN_FROM_MASTER]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesAdminFromMaster($messagesAdminFromMasterArgs: MessagesAdminFromMasterArgs!) {
            fetchMessagesAdminFromMaster(messagesAdminFromMasterArgs: $messagesAdminFromMasterArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesAdminFromMasterArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_ADMIN_FROM_MASTER, data.fetchMessagesAdminFromMaster)
      console.log('FETCH_MESSAGES_ADMIN_FROM_MASTER : ', data.fetchMessagesAdminFromMaster)
    },

    async [FETCH_MESSAGES_ADMIN_TO_MASTER]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesAdminToMaster($messagesAdminToMasterArgs: MessagesAdminToMasterArgs!) {
            fetchMessagesAdminToMaster(messagesAdminToMasterArgs: $messagesAdminToMasterArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesAdminToMasterArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_ADMIN_TO_MASTER, data.fetchMessagesAdminToMaster)
      console.log('FETCH_MESSAGES_ADMIN_TO_MASTER : ', data.fetchMessagesAdminToMaster)
    },

    async [FETCH_MESSAGES_MASTER_FROM_ADMIN]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesMasterFromAdmin($messagesMasterFromAdminArgs: MessagesMasterFromAdminArgs!) {
            fetchMessagesMasterFromAdmin(messagesMasterFromAdminArgs: $messagesMasterFromAdminArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesMasterFromAdminArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_MASTER_FROM_ADMIN, data.fetchMessagesMasterFromAdmin)
      console.log('FETCH_MESSAGES_MASTER_FROM_ADMIN : ', data.fetchMessagesMasterFromAdmin)
    },

    async [FETCH_MESSAGES_MASTER_TO_ADMIN]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesMasterToAdmin($messagesMasterToAdminArgs: MessagesMasterToAdminArgs!) {
            fetchMessagesMasterToAdmin(messagesMasterToAdminArgs: $messagesMasterToAdminArgs) {
              idx
              mId
              site
              title
              message
              senderId
              senderIp
              receiverId
              receiverIp
              isRead
              readAt
              isDeleted
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          messagesMasterToAdminArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            periodFrom: payload.periodFrom,
            periodTo: payload.periodTo,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_MASTER_TO_ADMIN, data.fetchMessagesMasterToAdmin)
      console.log('FETCH_MESSAGES_MASTER_TO_ADMIN : ', data.fetchMessagesMasterToAdmin)
    },

    async [FETCH_MESSAGES_SEND_FORM_SEARCH_USERS]({ commit }, payload) {
      // console.log('payload :::: ', payload)
      const { data } = await apolloClient.query({
        query: gql`
          query fetchMessagesSendFormSearchUsers($messagesSendFormSearchUsersArgs: MessagesSendFormSearchUsersArgs!) {
            fetchMessagesSendFormSearchUsers(messagesSendFormSearchUsersArgs: $messagesSendFormSearchUsersArgs) {
              site
              userid
              username
              nickname
              recommendNickname
              email
              mobile
              cash
              cashCasino
              point
              rank
              role
              status
            }
          }
        `,
        variables: {
          messagesSendFormSearchUsersArgs: { // 'data' 객체 안에 필요한 변수들을 포함
            site: payload.site,
            userid: payload.userid,
            role: payload.role,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(FETCH_MESSAGES_SEND_FORM_SEARCH_USERS, data.fetchMessagesSendFormSearchUsers)
      console.log('FETCH_MESSAGES_SEND_FORM_SEARCH_USERS : ', data.fetchMessagesSendFormSearchUsers)
    },

    async [UPDATE_MESSAGES_READ]({ commit }, payload) {
      console.log('payload ::', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateMessagesRead($messagesUpdateReadArgs: MessagesUpdateReadArgs!) {
            updateMessagesRead(messagesUpdateReadArgs: $messagesUpdateReadArgs)
          }
        `,
        variables: {
          messagesUpdateReadArgs: {
            idx: Number(payload.idx),
            site: payload.site,
            receiverIp: payload.receiverIp,
            receiverId: payload.receiverId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MESSAGES_READ, data.updateMessagesRead)
      console.log('UPDATE_MESSAGES_READ : ', data.updateMessagesRead)
    },

    async [UPDATE_MESSAGES_DELETE]({ commit }, payload) {
      console.log('payload ::', payload)
      const { data } = await apolloClient.mutate({
        mutation: gql`
          mutation updateMessagesDelete($messagesUpdateDeleteArgs: MessagesUpdateDeleteArgs!) {
            updateMessagesDelete(messagesUpdateDeleteArgs: $messagesUpdateDeleteArgs)
          }
        `,
        variables: {
          messagesUpdateDeleteArgs: {
            mId: payload.mId,
            site: payload.site,
          },
        },
        fetchPolicy: 'no-cache',
      })
      commit(UPDATE_MESSAGES_DELETE, data.updateMessagesDelete)
      console.log('UPDATE_MESSAGES_DELETE : ', data.updateMessagesDelete)
    },
  },
}

export default messagesStore
