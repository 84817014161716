<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          접속로그조회
        </h4>
        <div class="m-2">
          <!-- Per Page -->
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="6"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <b-form-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="pageOptions"
                size="sm"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
            </b-col>
            <b-col
              cols="6"
              lg="6"
              md="6"
              sm="12"
              class=""
            >
              <b-form-group
                class="font-small-3"
                label="접속일시"
                label-for="period"
                label-cols-md="2"
              >
                <b-input-group
                  name="period"
                  class="calendar-size font-small-3"
                >
                  <b-form-datepicker
                    v-model="periodFrom"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="시작일"
                    aria-controls="example-input"
                    size="sm"
                    :max="periodTo"
                  />
                  <div class="pl-1 pr-1">
                    ~
                  </div>
                  <b-form-datepicker
                    v-model="periodTo"
                    class=""
                    boundary="window"
                    locale="ko"
                    placeholder="종료일"
                    aria-controls="example-input"
                    size="sm"
                    :min="periodFrom"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Search -->
            <b-col md="6">
            </b-col>
            <b-col
              class="right"
              cols="12"
              md="6"
            >
              <b-form-group
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                    >
                      Clear
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- Table -->
        <b-table
          responsive
          show-empty
          empty-text="No matching records found"
          :per-page="perPage"
          :items="fetchSigninLogsItems"
          :fields="fields1"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
          small
          style="text-align: center !important; font-size: small"
          :current-page="currentPage"
        >
          <template #cell(index)="data">
            {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
          </template>
          <template #cell(site)="data">
            <div>
              {{ data.item.site }}
            </div>
          </template>
          <template #cell(userid)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 5rem; text-decoration: underline; text-underline-offset: 5px;"
              @click="userClicked(data)"
            >
              {{ data.item.userid }}
            </div>
          </template>
          <template #cell(ip)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.ip"
            >
              {{ truncateText(data.item.ip, 15) }}
            </div>
          </template>
          <template #cell(browser)="data">
            <div>
              {{ data.item.browser }}/{{ data.item.browserVer }}
            </div>
          </template>
          <template #cell(networkCo)="data">
            <div style="min-width: 10rem">
              <div
                v-b-tooltip.hover.bottom.v-danger="data.item.networkCo"
              >
                {{ truncateText(data.item.networkCo, 15) }}/{{ data.item.networkType }}
              </div>
            </div>
          </template>
          <template #cell(asn)="data">
            <div
              v-b-tooltip.hover.bottom.v-danger="data.item.route"
            >
              {{ data.item.asn }}/{{ truncateText(data.item.route, 15) }}
            </div>
          </template>
          <template #cell(os)="data">
            <div>
              {{ data.item.os }}/{{ data.item.osVer }}
            </div>
          </template>
          <template #cell(latitude)="data">
            <b-link>
              <div
                class="text-info"
                style="text-align: center !important"
                @click="googleMap(data.item.latitude.toLocaleString(), data.item.longitude.toLocaleString())"
              >
                {{ data.item.latitude }}
              </div>
<!--              <div-->
<!--                class="text-info"-->
<!--                style="text-align: center !important"-->
<!--                @click="googleMap(data.item.latitude.toLocaleString(), data.item.longitude.toLocaleString())"-->
<!--              >-->
<!--                {{ data.item.longitude }}-->
<!--              </div>-->
            </b-link>
          </template>
          <template #cell(flag)="data">
            <div style="min-width: 1rem">
              <img
                class="flag"
                style="width: 20px;"
                :src="data.item.flag"
              >
            </div>
          </template>
          <template #cell(country)="data">
            <div style="min-width: 7rem">
              <div v-if="data.item.country !== 'South Korea'">
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.city"
                  class="text-primary"
                >
                  {{ data.item.country }}/{{ truncateText(data.item.city, 15) }}
                </div>
              </div>
              <div v-else>
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.city"
                >
                  {{ data.item.country }}/{{ truncateText(data.item.city, 15) }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div style="min-width: 7rem">
              {{ data.value | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="12"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                align="center"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BLink, VBTooltip, BFormDatepicker,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { FETCH_SIGNIN_LOGS } from '@/store/logs/action'
import { createNamespacedHelpers } from 'vuex'
import * as moment from 'moment-timezone'
import router from "@/router";
moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const userSigninLogStore = createNamespacedHelpers('logStore')

export default {
  components: {
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BLink,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  setup() {

  },
  data() {
    return {
      options: [
        { value: '', text: '선택' },
        { value: 'admin', text: '어드민' },
        { value: 'branch', text: '총판' },
      ],
      periodFrom: '',
      periodTo: '',
      totalRows: 0,
      perPage: 20,
      pageOptions: [20, 50, 100],
      totalUsers: 1,
      currentPage: 1,
      sortBy: 'id',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      fields1: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'site', sortable: false, label: 'Site' },
        { key: 'userid', sortable: false, label: '아이디' },
        { key: 'ip', sortable: false, label: '접속IP' },
        { key: 'browser', sortable: false, label: '브라우저' },
        { key: 'os', sortable: false, label: 'OS' },
        { key: 'platform', sortable: false, label: '플랫폼' },
        { key: 'networkCo', sortable: false, label: '통신사' },
        { key: 'asn', sortable: false, label: 'ASN/route' },
        { key: 'flag', sortable: false, label: '국가' },
        { key: 'country', sortable: false, label: '도시' },
        { key: 'latitude', sortable: false, label: '위도/경도' },
        { key: 'createdAt', sortable: false, label: '접속일시' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...userSigninLogStore.mapGetters([
      'fetchSigninLogsItems',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.init()
  },
  watch: {
    fetchSelected(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected = newVal
      this.fetchData(newVal)
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...userSigninLogStore.mapActions({
      $fetchSigninLogs: FETCH_SIGNIN_LOGS,
    }),
    init() {
      this.periodFrom = moment(new Date()).startOf('month').format(fmt1)
      this.periodTo = moment(new Date()).endOf('month').format(fmt1)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      } else {
        return text.substring(0, maxLength) + '...'
      }
    },
    // ##.fetchSigninLogs init
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchSigninLogs({
        site: mySite,
        periodFrom: this.periodFrom ? (this.periodFrom).split(' ')[0] : '',
        periodTo: this.periodTo ? (this.periodTo).split(' ')[0] : '',
      })
      this.totalRows = this.fetchSigninLogsItems.length
      // console.log('fetchSigninLogsItems::', this.fetchSigninLogsItems)
    },

    // ##.googleMap init
    async googleMap(la, lo) {
      const vla = parseFloat(la).toFixed(6)
      const vlo = parseFloat(lo).toFixed(6)
      const url = `https://maps.google.com/maps?q=${vla},+${vlo}`
      window.open(url)
    },
    userClicked(data) {
      // localStorage.setItem('userView', JSON.stringify({ userid: data.item.userid, site: data.item.site}))
      // this.$store.commit('rootStore/SET_USER_VIEW', JSON.stringify({ userid: data.item.userid, site: data.item.site }))

      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: data.item.userid,
        site: data.item.site,
      }
      const url = router.resolve({
        name: 'apps-user-view',
        query: queryParams,
      }).href
      // const url = router.resolve({ name: 'apps-user-view' }).href
      // window.open(url, '_blank')
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions)
    },
  },
}

</script>

<style>
.per-page-selector {
  width: 90px;
}
</style>
