import router from '@/router'
import { apolloClient } from '@/vue-apollo'
import gql from 'graphql-tag'
import {
  CHECK_EXPIRED_JWT, LOGIN, LOGOUT, CODE_CONFIRM, VALIDATE_TOKEN, VALIDATE_AUTH_SITE,
} from './action'
import {
  SET_TOKEN, SET_USER, CLEAR_ALL, RE_SET_FACTOR,
} from './mutation'

import store from '..'

const AUTH_TOKEN_KEY = 'accessToken'
const AUTH_USER = 'userData'
const MOBILE = 'mobile'
const FACTOR = 'factor'
const AUTH_SITE_SELECTED = 'siteSelected'

export const parseJwt = token => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''))

  return JSON.parse(jsonPayload)
}

const authStore = {
  namespaced: true,
  // TODO: init localstorage
  state: () => ({
    factor: localStorage.getItem(FACTOR),
    token: sessionStorage.getItem(AUTH_TOKEN_KEY),
    user: [],
    mobile: '',
    validateToken: [],
    validateAuthSite: [],
  }),
  getters: {
    token: state => state.token,
    user: state => state.user,
    mobile: state => state.mobile,
    factor: state => state.factor,
    isAuthenticated: state => !!state.token,
    isUser: state => !!state.user,
    isMobile: state => !!state.mobile,
    isFactor: state => !!state.factor,
    validateToken: state => state.validateToken,
    validateAuthSite: state => state.validateAuthSite,
  },
  mutations: {
    [SET_TOKEN](state, token) {
      state.token = token
      sessionStorage.setItem(AUTH_TOKEN_KEY, token)
    },
    [SET_USER](state, user) {
      state.user = user
    },
    [CLEAR_ALL](state) {
      state.token = ''
      state.user = null
      state.mobile = ''
      sessionStorage.removeItem(AUTH_TOKEN_KEY)
      localStorage.removeItem(AUTH_USER)
      localStorage.removeItem(MOBILE)
      localStorage.removeItem(FACTOR)
      localStorage.removeItem(AUTH_SITE_SELECTED)
      localStorage.removeItem('menuPermission')
      localStorage.removeItem('ip')
      localStorage.removeItem('selectedUserViewInfo')
      localStorage.removeItem('alarmCnt7')
    },
    [RE_SET_FACTOR](state) {
      localStorage.setItem(FACTOR, 'true')
      state.factor = 'true'
    },
    [VALIDATE_TOKEN](state, validateToken) {
      state.validateToken = validateToken
    },
    [VALIDATE_AUTH_SITE](state, validateAuthSite) {
      state.validateAuthSite = validateAuthSite
    },
  },
  actions: {
    async [VALIDATE_TOKEN]({ commit, state }) {
      // 저장된 토큰 확인
      const { token } = state
      if (!token) {
        throw new Error('토큰이 존재하지 않습니다.')
      }
      // GraphQL 요청
      const { data } = await apolloClient.query({
        query: gql`
          query validateToken($validateToken: String!) {
            validateToken(validateToken: $validateToken) {
              id
              site
              userid
              username
              nickname
              recommendNickname
              email
              mobile
              cash
              point
              signinTotal
              betTotal
              depositTotal
              exchangeTotal
              rank
              role
              memo
              enrollIp
              enrollDate
              status
              updatedAt
              createdAt
            }
          }
        `,
        variables: {
          validateToken: token, // 변수 이름 수정
        },
        fetchPolicy: 'no-cache',
      })
      commit(VALIDATE_TOKEN, data.validateToken)
      commit('rootStore/SET_USER_DATA', data.validateToken, { root: true })
      console.log('VALIDATE_TOKEN : ', data.validateToken)
    },
    async [VALIDATE_AUTH_SITE]({ commit, state }, payload) {
      // 저장된 토큰 확인
      const { token } = state
      if (!token) {
        throw new Error('토큰이 존재하지 않습니다.')
      }
      // Admin 멀티 사이트 권한 설정
      const data = await apolloClient.query({
        query: gql`
          query fetchSetSiteAdminInit($userid: String) {
            fetchSetSiteAdminInit(userid: $userid) {
              idx
              userid
              authSite
              enrollId
              memo
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          userid: payload,
        },
        fetchPolicy: 'no-cache',
      })
      const siteAdminInit = data.data.fetchSetSiteAdminInit.map(item => item.authSite)

      commit(VALIDATE_AUTH_SITE, siteAdminInit)
      commit('rootStore/SET_AUTH_SITE', siteAdminInit, { root: true })
      console.log('VALIDATE_AUTH_SITE : ', siteAdminInit)
    },
    async [LOGIN]({ commit }, userData) {
      try {
        const { data } = await apolloClient.mutate({
          mutation: gql`
              mutation($site: String!, $userid: String!, $password: String!) {
              signin(site: $site, userid: $userid, password: $password){
                token
                user {
                  id
                  site
                  userid
                  username
                  nickname
                  recommendNickname
                  email
                  mobile
                  cash
                  point
                  signinTotal
                  betTotal
                  depositTotal
                  exchangeTotal
                  rank
                  role
                  memo
                  enrollIp
                  enrollDate
                  status
                  password
                  outcomePassword
                  accountBank
                  accountNumber
                  updatedAt
                  createdAt
                }
              }
            }`,
          variables: {
            site: userData.site,
            userid: userData.userid,
            password: userData.password,
          },
        })
        if (data === undefined || data === null) {
          throw new Error('login fail')
        }

        // 최초 로그인 권한 체크 및 토큰 발급
        const { role } = data.signin.user
        if (role === 'godfather' || role === 'admin' || role === 'branch') {
          const { token } = data.signin
          await commit(SET_TOKEN, token)
          await store.dispatch('rootStore/fetchInitialize')
          await commit('rootStore/SET_MOBILE', null, { root: true })
          await router.replace('/login-2factor')
        } else {
          throw new Error('forbidden role')
          // return Promise.reject()
        }
      } catch (error) {
        console.log(error.message)
        // this.logger.error(error.message)
        throw error
      }
    },
    [CODE_CONFIRM]({ commit }) {
      commit(RE_SET_FACTOR)
      // commit(RE_SET_TOKEN)
      // commit(RE_SET_USER)

      router.replace(router.currentRoute.query.returnPath || '/')
    },
    [LOGOUT]({ commit }) {
      commit(CLEAR_ALL)
      // router.replace('/login')

      // 현재 경로가 '/login'이 아닌 경우에만 이동
      const targetPath = '/login'
      if (router.currentRoute.fullPath !== targetPath) {
        router.replace(targetPath).catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error(err)
          }
        })
      } else {
        console.log('Already at /login, avoiding redundant navigation')
      }
    },
    [CHECK_EXPIRED_JWT]({ dispatch, state }) {
      const expired = state.token ? parseJwt(state.token).exp < Date.now()/1000 : false;
      // console.log('parseJwt(state.token).exp > ', parseJwt(state.token).exp)
      // console.log('Date.now()/1000  > ', Date.now()/1000)

      // 만료시 로그아웃 합니다.
      if (expired) dispatch(LOGOUT)
    },
  },
}

export default authStore
