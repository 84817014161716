<template>
  <b-card>
    <div>
      <b-tabs
        content-class="mt-3"
        @input="handleTabChange"
      >

        <b-tab
          title="배치 스케줄 로그"
          active
        >
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            배치 스케줄 로그
            <b-badge
              variant="light-primary"
              style="font-size: small; margin-left: 5px; margin-right: 3px"
              class="badge-glow"
            >
              {{ totalRowsLogs }}건
            </b-badge>
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
          <hr>
          <div class="ml-2 mr-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1"
              >
                <label style="width: 41px">상태</label>
                <b-form-select
                  id="site-select"
                  v-model="selectOption"
                  :options="option"
                  class="divSelecter font-small-3"
                  size="sm"
                />
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="mb-1"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <!--                </div>-->
              </b-col>
            </b-row>
          </div>
          <div class="ml-2 mr-2 pb-1">
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="6"
                md="6"
                class="col-sm-12"
              >
                <label>Show</label>
                <b-form-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  size="sm"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
              <b-col
                cols="6"
                lg="6"
                md="12"
                sm="12"
                class=""
              >
                <b-form-group
                  class="font-small-3"
                  label="시작일시"
                  label-for="period"
                  label-cols-md="2"
                >
                  <b-input-group
                    name="period"
                    class="calendar-size font-small-3"
                  >
                    <b-form-datepicker
                      v-model="periodFrom"
                      class=""
                      boundary="window"
                      locale="ko"
                      placeholder="시작일"
                      aria-controls="example-input"
                      size="sm"
                      :max="periodTo"
                    />
                    <div class="pl-1 pr-1">
                      ~
                    </div>
                    <b-form-datepicker
                      v-model="periodTo"
                      class=""
                      boundary="window"
                      locale="ko"
                      placeholder="종료일"
                      aria-controls="example-input"
                      size="sm"
                      :min="periodFrom"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-table
            v-model="visibleRows"
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            :items="fetchSetBatchScheduleLogs"
            :fields="fields1"
            style="text-align: center; font-size: small;"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :current-page="currentPageLogs"
            @filtered="onFiltered"
          >
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.item.idx }}
            </template>
            <template #cell(taskId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 3rem"
              >
                {{ data.item.taskId }}
              </div>
            </template>
            <template #cell(taskName)="data">
              <div
                size="sm"
                style="text-align: left; min-width: 4rem"
              >
                {{ data.item.taskName }}
              </div>
            </template>
            <template #cell(startAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 9rem"
              >
                {{ data.item.startAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(endAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 9rem"
              >
                {{ data.item.endAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(runTime)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.runTime }}
              </div>
            </template>
            <template #cell(result)="data">
              <div
                v-if="data.item.result === 'success'"
                size="sm"
                style="text-align: center; min-width: 7rem; color: #28c76f"
              >
                {{ data.item.result }}
              </div>
              <div
                v-if="data.item.result === 'fail'"
                size="sm"
                style="text-align: center; min-width: 7rem; color: #cb2027"
              >
                {{ data.item.result }}
              </div>
            </template>
            <template #cell(response)="data">
              <div
                v-if="data.item.response"
                v-b-tooltip.hover.bottom.v-danger="data.item.response"
                size="sm"
                class="text-truncate"
                style="text-align: left; max-width: 20rem"
              >
                {{ data.item.response }}
              </div>
              <div
                v-if="data.item.error"
                v-b-tooltip.hover.bottom.v-danger="data.item.error"
                size="sm"
                style="text-align: left; min-width: 20rem"
              >
                {{ truncateText(data.item.error, 40) }}
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageLogs"
                  :total-rows="totalRowsLogs"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <b-tab title="배치 리스트">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            배치 리스트
            <b-badge
              variant="light-primary"
              style="font-size: small; margin-left: 5px; margin-right: 3px"
              class="badge-glow"
            >
              {{ totalRowsTasks }}건
            </b-badge>
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
          <hr>

          <div class="m-2">
            <!-- Per Page -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="6"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <b-form-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  size="sm"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <!--                <div class="d-flex justify-content-end">-->
                <b-form-group
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput2"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <!--                </div>-->
              </b-col>
            </b-row>
          </div>

          <!-- Table -->
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :items="fetchSetBatchScheduleTasks"
            :fields="fields2"
            :filter="filter"
            :filter-included-fields="filterOn"
            style="text-align: center; font-size: small"
            :sort-by.sync="sortBy2"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :current-page="currentPageTasks"
            @filtered="onFiltered"
          >
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ data.index+1 }}
            </template>
            <template #cell(taskId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.taskId }}
              </div>
            </template>
            <template #cell(taskName)="data">
              <div
                size="sm"
                style="text-align: left; min-width: 10rem"
              >
                {{ data.item.taskName }}
              </div>
            </template>
            <template #cell(taskDesc)="data">
              <div
                size="sm"
                style="text-align: left; min-width: 15rem"
              >
                {{ data.item.taskDesc }}
              </div>
            </template>
            <template #cell(schedule)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.schedule }}
              </div>
            </template>
            <template #cell(active)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.active }}
              </div>
            </template>
            <template #cell(createdAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template #cell(updatedAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
              </div>
            </template>
            <template
              #cell(edit)="data"
            >
              <div
                class="editClass"
                style="min-width: 12rem;"
                @click.stop
              >
                <span class="d-inline-flex">
                  <b-spinner
                    v-if="!complete"
                    class="mr-1"
                  />
                  <b-button
                    v-if="complete"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-0"
                    size="sm"
                    style="font-size: smaller"
                    @click="submit(data.item.taskId === 'B000000' ? 'triggerTaskNewSite' : 'triggerTask', data)"
                  >
                    수행
                  </b-button>
                </span>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageTasks"
                  :total-rows="totalRowsTasks"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <b-modal
      id="siteCreateInfoModal"
      title="사이트 정보 입력"
      size="lg"
      ok-title="확인"
      cancel-title="취소"
      button-size="sm"
      @ok="submitData"
      @hide="resetModal"
    >
      <div class="modal-content">
        <div class="modal-body">
          <!-- 사이트명 입력 -->
          <div class="form-group">
            <label for="siteName">신규 생성 사이트명:</label>
            <b-form-input
              id="siteName"
              v-model="siteName"
              type="text"
              placeholder="사이트명을 입력하세요"
            />
          </div>
          <!-- 테이블 선택 -->
          <div>
            <b-table
              id="tbSetRankDetail"
              small
              responsive
              bordered
              show-empty
              empty-text="No matching records found"
              :items="modalItem"
              :fields="modalFields"
              style="text-align: center; font-size: small;"
            >
              <template #cell(tableName)="data">
                <div
                  size="sm"
                  style="text-align: left; min-width: 5rem"
                >
                  {{ data.item.tableName }}
                </div>
              </template>
              <template #cell(tableNameKor)="data">
                <div
                  size="sm"
                  style="text-align: left; min-width: 5rem"
                >
                  {{ data.item.tableNameKor }}
                </div>
              </template>
              <template #cell(useYn)="data">
                <div
                  size="sm"
                  style=""
                >
                  <b-form-checkbox
                    :checked="data.item.useYn === 'Y'"
                    class="custom-control-primary d-inline"
                    name="check-button"
                    switch
                    @change="updateTableUseYn($event, data)"
                  >
                    <span
                      class="switch-icon-left"
                      style="font-size: 10px"
                    >
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span
                      class="switch-icon-right"
                      style="font-size: 10px"
                    >
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCard, BTable, BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  VBTooltip,
  BSpinner, BFormDatepicker,
  VBModal, BModal, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

import { createNamespacedHelpers } from 'vuex'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { FETCH_SET_BATCH_SCHEDULE_LOGS, FETCH_SET_BATCH_SCHEDULE_TASKS } from '@/store/logs/action'
import { TRIGGER_TASK, TRIGGER_TASK_NEW_SITE } from '@/store/logs/mutation'
import Swal from 'sweetalert2'
import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'
const fmt3 = 'YYYY-MM-DD HH:mm:ss'

const logStore = createNamespacedHelpers('logStore')

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BSpinner,
    BFormDatepicker,
    BModal,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      complete: true,
      perPage: 30,
      visibleRows: [],
      pageOptions: [30, 50, 100],
      currentPageLogs: 1,
      currentPageTasks: 1,
      totalRowsLogs: 0,
      totalRowsTasks: 0,
      periodFrom: '',
      periodTo: '',
      selectOption: null,
      option: [
        { value: null, text: '전체' },
        { value: 'success', text: '성공' },
        { value: 'fail', text: '실패' },
      ],
      sortBy: 'createdAt',
      sortBy2: 'taskId',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'taskId', sortable: false, label: '배치ID', thClass: 'col2',
        },
        {
          key: 'taskName', sortable: false, label: '배치명', thClass: 'col2',
        },
        {
          key: 'startAt', sortable: false, label: '시작일시', thClass: 'col3',
        },
        {
          key: 'endAt', sortable: false, label: '종료일시', thClass: 'col4',
        },
        {
          key: 'runTime', sortable: false, label: '런타임', thClass: 'col5',
        },
        {
          key: 'result', sortable: false, label: '결과', thClass: 'col7',
        },
        {
          key: 'response', sortable: false, label: '결과값', thClass: 'col7',
        },
        // {
        //   key: 'error', sortable: false, label: '에러', thClass: 'col7',
        // },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'taskId', sortable: false, label: '배치ID', thClass: 'col2',
        },
        {
          key: 'taskName', sortable: false, label: '배치명', thClass: 'col2',
        },
        {
          key: 'taskDesc', sortable: false, label: '배치설명', thClass: 'col3',
        },
        {
          key: 'schedule', sortable: false, label: '스케줄', thClass: 'col4',
        },
        {
          key: 'active', sortable: false, label: '상태', thClass: 'col5',
        },
        {
          key: 'createdAt', sortable: false, label: '생성일', thClass: 'col7',
        },
        {
          key: 'updatedAt', sortable: false, label: '변경일', thClass: 'col8',
        },
        {
          key: 'edit', sortable: false, label: '수행', thClass: 'col9',
        },
      ],
      siteName: '', // 입력된 사이트명을 저장할 변수
      modalFields: [
        {
          key: 'no', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'tableName', sortable: false, label: '테이블명', thClass: 'col2',
        },
        {
          key: 'tableNameKor', sortable: false, label: '테이블설명', thClass: 'col2',
        },
        {
          key: 'useYn', sortable: false, label: '적용여부', thClass: 'col2',
        },
      ],
      modalItem: [
        {
          no: 1, tableName: 'noticeAdmin', tableNameKor: '관리자공지사항', useYn: 'Y',
        },
        {
          no: 2, tableName: 'setBasic', tableNameKor: '기본설정', useYn: 'Y',
        },
        {
          no: 3, tableName: 'setSignup', tableNameKor: '회원가입설정', useYn: 'Y',
        },
        {
          no: 4, tableName: 'setSignin', tableNameKor: '로그인설정', useYn: 'Y',
        },
        {
          no: 5, tableName: 'setDepositExchange', tableNameKor: '충환전설정', useYn: 'Y',
        },
        {
          no: 6, tableName: 'setEtc', tableNameKor: '기타설정', useYn: 'Y',
        },
        {
          no: 7, tableName: 'setRank', tableNameKor: '등급관리', useYn: 'Y',
        },
        {
          no: 8, tableName: 'setRankDetail', tableNameKor: '등급관리상세', useYn: 'Y',
        },
        {
          no: 9, tableName: 'setEvent', tableNameKor: '보너스배당', useYn: 'Y',
        },
        {
          no: 10, tableName: 'setEventDeposit', tableNameKor: '충전이벤트', useYn: 'Y',
        },
        {
          no: 11, tableName: 'setAccount', tableNameKor: '계좌관리', useYn: 'Y',
        },
        {
          no: 12, tableName: 'setAlarms', tableNameKor: '알람관리', useYn: 'Y',
        },
        {
          no: 13, tableName: 'gameSetBasic', tableNameKor: '기본설정', useYn: 'Y',
        },
        {
          no: 14, tableName: 'gameSetMenu', tableNameKor: '메뉴설정', useYn: 'Y',
        },
        {
          no: 15, tableName: 'gameSetItem', tableNameKor: '종목설정', useYn: 'Y',
        },
        {
          no: 16, tableName: 'gameSetOddsMarket', tableNameKor: '게임유형설정', useYn: 'Y',
        },
        {
          no: 17, tableName: 'gameSetOddsCustom', tableNameKor: '배당상세설정', useYn: 'Y',
        },
        {
          no: 18, tableName: 'gameSetVendor', tableNameKor: '카지노설정', useYn: 'Y',
        },
      ],
    }
  },
  watch: {
    selectOption(newVal) {
      this.selectOption = newVal
      this.fetchData(newVal)
    },
    periodFrom() {
      this.fetchData(this.selectOption)
    },
    periodTo() {
      this.fetchData(this.selectOption)
    },
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...logStore.mapGetters({
      fetchSetBatchScheduleLogs: 'fetchSetBatchScheduleLogs',
      fetchSetBatchScheduleTasks: 'fetchSetBatchScheduleTasks',
    }),
    logStore() {
      return this.$store.state.logStore.fetchSetBatchScheduleTasks
    },
  },
  mounted() {
    this.init()
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...logStore.mapActions({
      $fetchSetBatchScheduleLogs: FETCH_SET_BATCH_SCHEDULE_LOGS,
      $fetchSetBatchScheduleTasks: FETCH_SET_BATCH_SCHEDULE_TASKS,
      $triggerTask: TRIGGER_TASK,
      $triggerTaskNewSite: TRIGGER_TASK_NEW_SITE,
    }),
    updateManyParam(t, f, e, v, i) {
      const events = {
        t, f, e, v, i,
      }
      this.$store.commit('logStore/updateManyParam', events)
    },
    handleTabChange(newTab) {
      this.fetchData(newTab)
    },
    init() {
      this.selectOption = null
      this.periodFrom = moment().subtract(7, 'days').format(fmt3)
      this.periodTo = moment().format(fmt3)
    },
    async fetchData(newTab) {
      // const mySite = this.userData.site
      // console.log('newTab:::', newTab)
      if (newTab === 0 || newTab === null) {
        await this.$fetchSetBatchScheduleLogs({
          // site: mySite,
          result: this.selectOption,
          periodFrom: this.periodFrom,
          periodTo: this.periodTo,
        })
      } else if (newTab === 1) {
        await this.$fetchSetBatchScheduleTasks({
          // site: mySite,
        })
      }
      this.totalRowsLogs = this.fetchSetBatchScheduleLogs.length
      this.totalRowsTasks = this.fetchSetBatchScheduleTasks.length
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRowsLogs = filteredItems.length
      this.currentPageLogs = 1
      this.totalRowsTasks = filteredItems.length
      this.currentPageTasks = 1
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    async submit(target, data) {
      if (data.item.taskId === 'B000000') {
        this.openModal()
        return
      }

      await Swal.fire({
        title: '배치를 수행 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      try {
        if (target === 'triggerTask') {
          this.complete = false
          await this.$triggerTask({
            taskId: data.item.taskId,
          })
        }
      } catch {
        console.error()
      } finally {
        await this.fetchData()
        this.complete = true
      }
    },
    openModal() {
      this.modalItem.forEach((item, index) => {
        const newValue = item.useYn === 'Y' ? 'Y' : 'N'

        const events = {
          t: 'triggerTaskNewSite',
          f: 'useYn',
          e: newValue,
          v: item,
          i: index,
        }

        this.$store.commit('logStore/updateManyFirstPush', events)
      })

      this.$bvModal.show('siteCreateInfoModal')
    },
    async submitData() {
      if (!this.siteName) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '사이트명을 입력해주세요',
            icon: 'CheckIcon',
            variant: 'light-danger',
          },
        })
        return
      }
      await this.$triggerTaskNewSite({
        taskId: 'B000000',
        siteName: this.siteName,
      })
      await this.resetModal()
    },
    async resetModal() {
      this.siteName = ''
      const events = {
        t: 'triggerTaskNewSite',
      }
      this.$store.commit('logStore/updateManyLastPush', events)
      this.$bvModal.hide('siteCreateInfoModal')
    },
    updateTableUseYn(event, item) {
      // Get the new value from event
      // console.log('event::', event)
      // console.log('item:::', item)
      const newValue = event === true ? 'Y' : 'N'
      this.$set(item, 'useYn', newValue)
      // console.log(newValue)
      // console.log(item.useYn)
      this.updateManyParam('triggerTaskNewSite', 'useYn', newValue, item.item, item.index)
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.tooltip .tooltip-inner{
  max-width: 500px !important;
  width: 400px !important;
}
.divSelecter {
  width: 90px;
}
.custom-modal-position .modal-dialog {
  max-width: 500px; /* 모달의 너비를 설정 */
}
/* 체크박스를 Grid로 배치 */
.checkbox-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5개의 열 */
  gap: 10px; /* 체크박스 간 간격 */
}
/* 체크박스 스타일 */
.custom-checkbox {
  margin: 5px 0;
  display: flex;
  align-items: center;
}
/* 체크박스 라벨 줄바꿈 처리 */
.checkbox-label {
  display: block; /* 블록 요소로 변경 */
  word-break: break-word; /* 긴 단어가 줄바꿈될 수 있도록 설정 */
  white-space: normal; /* 기본 줄바꿈 동작 */
  line-height: 1.2; /* 줄 간격 조정 */
}
</style>
