<template>
  <div>
    <!-- Contents -->
    <b-card>
      <!-- Contents Title -->
      <b-row>
        <b-col cols="12">
          <div>
            <b-form-group
              class="font-small-3"
              label="기준 사이트"
              label-for="site-select"
              style="max-width: 500px"
            >
              <b-form-select
                id="site-select"
                v-model="siteSelected"
                :options="authSite"
                class="font-small-3"
                size="sm"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Contents -->
    <b-card>
      <!-- Contents Title -->
      <b-row>
        <b-col>
          <div>
            <h4>
              <feather-icon
                icon="BoxIcon"
                style="width: 18px; height: 18px"
              />
              등급 관리
              <b-badge
                variant="primary"
                style="font-size: xx-small"
              >
                DB 연계
              </b-badge>
            </h4>
          </div>
        </b-col>
      </b-row>
      <hr>

      <!-- Contents Body -->
      <b-row>
        <b-col>
          <div>
            <b-table
              responsive
              show-empty
              small
              empty-text="No matching records found"
              :items="fetchSetRank"
              :fields="setRankFields"
              :sort-direction="fetchSetRank.rankOrder"
              style="text-align: center !important; font-size: small"
            >
              <template #cell(rankImg)="data">
                <span class="d-inline-flex">
                  <div
                    style="min-width: 20px"
                    class="cursor-pointer"
                    @click="$bvModal.show(`modalRankImageUpload${data.index}`)"
                  >
                    <b-avatar
                      size="28"
                      style="text-align: center;"
                      :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_rank/` + siteSelected + `_` + data.item.rankOrder + `.png?timestamp=` + new Date().getTime()"
                    />
                  </div>
                </span>
                <template>
                  <!-- Modal -->
                  <b-modal
                    :id="`modalRankImageUpload${data.index}`"
                    small
                    no-close-on-backdrop
                    title="파일 업로드"
                    centered
                    button-size="sm"
                    cancel-title="취소"
                    ok-title="저장"
                    :ok-disabled="fileTooLarge || !uploadFile"
                    @ok="fncUploadFile(data.item.rankOrder)"
                    @cancel="closeModal()"
                  >
                    <!-- Accept specific image formats by extension -->
                    <label
                      class="mt-1"
                    >
                      3MB 이하의 이미지 파일(.png)만 업로드 가능합니다.
                    </label>
                    <b-form-file
                      id="extension"
                      v-model="uploadFile"
                      accept=".png"
                      @change="checkFileSize"
                    />
                    <div
                      class="text-success small"
                    >
                      파일 크기: {{ fileSizeString }}
                    </div>
                    <div
                      v-if="fileTooLarge"
                      class="text-primary small mb-1"
                    >
                      파일 크기가 3MB를 초과합니다.
                    </div>
                    <div>
                    </div>
                  </b-modal>

                </template>
              </template>
              <template #cell(rankName)="data">
                <div class="text-info">
                  {{ data.item.rankName }}
                </div>
              </template>
              <template #cell(rankOrder)="data">
                <b-form-select
                  v-if="fetchSetRank"
                  id="rank-order"
                  :value="data.item.rankOrder"
                  :options="options1"
                  type="number"
                  size="sm"
                  class="center text-info"
                  style="text-align: right; min-width: 3rem"
                  @input="updateParam('fetchSetRank','rankOrder', $event, data.index)"
                />
              </template>
              <template #cell(dayFirstDeposit)="data">
                <b-form-input
                  v-if="fetchSetRank"
                  id="day-first-deposit"
                  :value="data.item.dayFirstDeposit"
                  type="number"
                  size="sm"
                  class="center"
                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"
                  @input="updateParam('fetchSetRank','dayFirstDeposit', $event, data.index)"
                />
              </template>
              <template #cell(dayFirstDepositMax)="data">
                <b-form-input
                  v-if="fetchSetRank"
                  id="every-deposit"
                  :value="data.item.dayFirstDepositMax"
                  type="number"
                  size="sm"
                  class="center"
                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"
                  @input="updateParam('fetchSetRank','dayFirstDepositMax', $event, data.index)"
                />
              </template>
              <template #cell(everyDeposit)="data">
                <b-form-input
                  v-if="fetchSetRank"
                  id="every-deposit"
                  :value="data.item.everyDeposit"
                  type="number"
                  size="sm"
                  class="center"
                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"
                  @input="updateParam('fetchSetRank','everyDeposit', $event, data.index)"
                />
                <cleave
                  v-if="isCleave"
                  id="rank-deposit-max"
                  class="center form-control form-control-sm"
                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"
                  placeholder="100%"
                  :value="data.item.everyDeposit"
                  :options="inputOptions.percent"
                  @input="updateParam('fetchSetRank','everyDeposit', $event, data.index)"
                />
              </template>
              <template #cell(everyDepositMax)="data">
                <b-form-input
                  v-if="fetchSetRank"
                  id="every-deposit-min"
                  :value="data.item.everyDepositMax"
                  type="number"
                  size="sm"
                  class="center"
                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"
                  @input="updateParam('fetchSetRank','everyDepositMax', $event, data.index)"
                />
              </template>
<!--              <template #cell(rankDepositMax)="data">-->

<!--                <b-form-input-->
<!--                  v-if="fetchSetRank"-->
<!--                  id="every-deposit-min"-->
<!--                  :value="data.item.rankDepositMax"-->
<!--                  type="number"-->
<!--                  size="sm"-->
<!--                  class="center"-->
<!--                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"-->
<!--                  @input="updateParam('fetchSetRank','rankDepositMax', $event, data.index)"-->
<!--                />-->
<!--                <cleave-->
<!--                  v-if="isCleave"-->
<!--                  id="rank-deposit-max"-->
<!--                  class="center form-control form-control-sm"-->
<!--                  style="text-align: right; color:rgb(229,166,48); min-width: 3rem"-->
<!--                  placeholder="10,000"-->
<!--                  :value="data.item.rankDepositMax"-->
<!--                  :options="inputOptions.number"-->
<!--                  @input="updateParam('fetchSetRank','rankDepositMax', $event, data.index)"-->
<!--                />-->
<!--              </template>-->
              <template #cell(edit)="data">
                <span class="d-inline-flex">
                  <feather-icon
                    icon="Edit2Icon"
                    class="ml-2"
                    style="color:rgb(229,166,48);"
                    type="button"
                    @click="btnModalDetatil(data)"
                  />
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-1 ml-1"
                    style="color:rgb(229,166,48);"
                    @click="submit('deleteSetRank', data)"
                  />
                </span>
              </template>

            </b-table>
          </div>
        </b-col>
      </b-row>

      <!-- Contents Button -->
      <b-row>
        <b-col
          cols="8"
        >
          <div class="d-flex align-items-center">
            <b-form-input
              id="rank-name"
              v-model="addRankName"
              class="w-auto"
              style="margin-right: 0.5rem"
              type="text"
              size="sm"
              placeholder="등급이름"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              size="sm"
              @click="submit('upsertSetRank')"
              :disabled="!addRankName"
            >
              추가
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="4"
        >
          <div
            class="pr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              size="sm"
              class="float-right"
              @click="submit('updateManySetRank')"
            >
              저장
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>

    <!-- Components -->
    <rank-settings-detail
      ref="RankSettingsDetail"
      modal-id="RankSettingsDetail"
      :selected-rank.sync="selectedRank"
      @updateParam="updateParam"
    />

    <!-- Check data -->
    <div v-if="false">
      <hr>
      <p><b>siteSelected: </b> {{ siteSelected }} </p>
      <p><b>fetchSetRank: </b> {{ fetchSetRank }} </p>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,

  BFormGroup, BButton,
  BFormInput,
  BFormSelect,

  BTable,
  BBadge, BAvatar,
  BFormFile,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_SET_RANK,
  FETCH_SET_RANK_DETAIL,
} from '@/store/settings/action'

import {
  CREATEMANY_SET_RANK_DETAIL,
  UPDATEMANY_SET_RANK,
  UPSERT_SET_RANK,
  DELETE_SET_RANK,
} from '@/store/settings/mutation'

import RankSettingsDetail from '@/views/settings/RankSettingsDetail.vue'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'RankSetting',
  components: {
    BCard,
    BRow,
    BCol,

    BFormGroup,
    BButton,
    BFormInput,
    BFormSelect,

    BTable,
    BBadge,
    BAvatar,
    BFormFile,
    Cleave,

    RankSettingsDetail,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,

      // userIp: localStorage.getItem('ip'),
      // Tablefields
      setRankFields: [
        { key: 'rankImg', sortable: false, label: '아이콘', thStyle: { minWidth: '12rem' } },
        { key: 'rankName', sortable: false, label: '등급이름', thStyle: { minWidth: '10rem' } },
        { key: 'rankOrder', sortable: false, label: '랭크레벨', thStyle: { minWidth: '8rem' } },
        { key: 'dayFirstDeposit', sortable: false, label: '당일 첫충전(%)' },
        { key: 'dayFirstDepositMax', sortable: false, label: '첫충최대지급(포인트)' },
        { key: 'everyDeposit', sortable: false, label: '매충전(%)' },
        { key: 'everyDepositMax', sortable: false, label: '매충최대지급(포인트)' },
        // { key: 'rankDepositMax', sortable: false, label: '최대보너스지급(원)' },
        { key: 'edit', sortable: false, label: '관리', thClass: 'col12' },
      ],

      // rankOrderOptions
      options1: [
        { value: 0, text: '0' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 9, text: '9' },
        { value: 90, text: 'VIP-1' },
        { value: 91, text: 'VIP-2' },
        { value: 99, text: 'VIP-3' },
      ],

      // Cleave
      isCleave: false,
      inputOptions: {
        number: {
          numeral: true,
          numeralPositiveOnly: true, // 양수만
          numeralThousandsGroupStyle: 'thousand',
          // prefix: ' ₩',
          // tailPrefix: false,
        },
        percent: {
          numeral: true,
          prefix: ' %',
          tailPrefix: true,
          numeralPositiveOnly: true, // 양수만
          numeralIntegerScale: 3, // 최대 3자리 정수를 허용 (0 ~ 100)
          numeralDecimalScale: 0, // 소수점 이하 자리수를 0으로 설정
          // onValueChanged: e => {
          //   debugger;
          // // 입력된 값이 100을 초과하는 경우, 입력 필드의 값을 100으로 조정합니다.
          //   if (parseInt(e.target.rawValue, 10) > 100) {
          //     e.target.element.value = '100' // Cleave 인스턴스의 값을 직접 조정
          //   }
          // },
        },
      },

      // TableModal
      selectedRank: {},

      // fileUpload
      uploadFile: null,
      fileTooLarge: false,
      fileSizeString: '',

      // addRank
      addRankName: '',
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...settingsStore.mapGetters({
      fetchSetRank: 'fetchSetRank',
      fetchSetRankDetail: 'fetchSetRankDetail',
    }),

  },
  watch: {

  },
  beforeCreate() {
    // console.clear()
    // console.log('컴포넌트가 생성되기 전에 콘솔이 클리어됩니다.')
    // console.log(this.componentName, 'beforeCreate()')
  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  beforeDestroy() {
    // console.clear()
    // console.log(this.componentName, 'beforeDestroy()')
    // console.log('컴포넌트가 제거된 후에 콘솔이 클리어됩니다.')
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchSetRank: FETCH_SET_RANK,
      $fetchSetRankDetail: FETCH_SET_RANK_DETAIL,

      $upsertSetRank: UPSERT_SET_RANK,
      $createManySetRankDetail: CREATEMANY_SET_RANK_DETAIL,

      $updateManySetRank: UPDATEMANY_SET_RANK,
      $deleteSetRank: DELETE_SET_RANK,
    }),
    async fetchData() {
      await this.$fetchSetRank({
        site: this.siteSelected,
      })
    },
    updateParam(t, e, v, i) {
      // console.log(t)
      // console.log(e)
      // console.log(v)
      const events = {
        t, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('settingsStore/updateManyParam', events)
    },
    fncUploadFile(data) {
      console.log(this.uploadTarget)
      console.log(this.uploadFile)
      const params = {
        Bucket: 'agress-assets', // S3 버킷 이름
        // Key: `img_rank/${this.uploadTarget}.png`, // 업로드될 이미지 파일 이름. 실제 파일 형식에 맞게 확장자 변경 필요
        Key: `img_rank/${this.siteSelected}_${data}.png`, // 업로드될 이미지 파일 이름. 실제 파일 형식에 맞게 확장자 변경 필요
        Body: this.uploadFile, // 업로드할 파일 데이터
        ContentType: 'image/png', // 파일 형식에 맞는 MIME 타입. 'image/jpeg', 'image/gif' 등으로 변경 가능
      }
      console.log(params)
      this.$s3.upload(params, (err, data) => {
        if (err) {
          console.error('업로드 실패:', err)
          Swal.fire({
            title: '업로드 실패',
            icon: 'warning',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.fetchData(); // Re-fetch data even if the upload fails
          })
        } else {
          console.log('업로드 성공:', data.Location)
          Swal.fire({
            title: '저장 완료',
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            this.fetchData() // Re-fetch data after a successful upload
            this.$forceUpdate() // Force a component update to re-render with the latest data
          })
        }
      })
    },
    checkFileSize(event) {
      const selectedFile = event.target.files[0]
      console.log(selectedFile)
      if (selectedFile) {
        this.uploadFile = selectedFile
        const fileSize = selectedFile.size
        this.fileSizeString = this.formatFileSize(fileSize)
        if (fileSize > 3 * 1024 * 1024) { // 3MB 이상인 경우
          this.fileTooLarge = true
        } else {
          this.fileTooLarge = false
        }
      }
    },
    formatFileSize(size) {
      if (size < 1024) {
        return `${size} bytes`
      } if (size < 1048576) {
        return `${(size / 1024).toFixed(2)} KB`
      }
      return `${(size / 1048576).toFixed(2)} MB`
    },
    closeModal() {
      this.fileSizeString = ''
    },

    // add rank
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, data)
        }
      })
    },

    async confirmYes(target, data) {
      const showSuccessAlert = async successCode => {
        const messages = {
          updateSuccess: '성공적으로 업데이트되었습니다.',
          deleteSuccess: '성공적으로 삭제되었습니다.',
        }[successCode]

        return Swal.fire({
          title: '성공',
          text: messages,
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          confirmButtonText: '확인',
        })
      }

      // 내부 함수로 실패 알림을 표시하는 함수 정의
      const showErrorAlert = async errorCode => {
        const messages = {
          saveError: '작업을 완료하는 도중 문제가 발생했습니다. 시스템 관리자에게 문의해주세요.',
        }[errorCode]

        await Swal.fire({
          title: '저장 실패',
          text: messages,
          icon: 'error',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          confirmButtonText: '확인',
        })
      }

      try {
        if (target === 'upsertSetRank') {
          await this.$upsertSetRank({
            site: this.siteSelected,
            rankName: this.addRankName,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
          await this.$createManySetRankDetail({
            site: this.siteSelected,
            rankName: this.addRankName,
            userid: this.userData.userid,
            userIp: this.userIp,
            gameName: ['Sports-Prematch', 'Sports-Special', 'Sports-Inplay'],
          })
        }
        if (target === 'updateManySetRank') {
          await this.$updateManySetRank({
            site: this.siteSelected,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
          await showSuccessAlert('updateSuccess')
        }
        if (target === 'deleteSetRank') {
          await this.$deleteSetRank({
            site: this.siteSelected,
            rankName: data.item.rankName,
            userid: this.userData.userid,
            userIp: this.userIp,
          })
          await showSuccessAlert('deleteSuccess')
        }
      } catch (error) {
        // 실패 알림 표시
        await showErrorAlert('saveError')
      } finally {
        await this.fetchData()
        this.addRankName = ''
      }
    },

    async btnModalDetatil(data) {
      // 삭제금지
      await this.$fetchSetRankDetail({
        site: data.item.site,
        rankName: data.item.rankName,
      })
      // this.selectedRank = this.fetchSetRankDetail
      // console.log({ index: data.index, ...data.item })
      this.selectedRank = { index: data.index, ...data.item }
      this.$refs.RankSettingsDetail.$bvModal.show('RankSettingsDetail')
    },
  },

}
</script>

<style>

</style>
