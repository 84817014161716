<template>
  <div>
    <b-card
      v-if="fetchUser"
    >
      <span class="pl-1 row justify-content-between">
        <h4>
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          회원정보수정
        </h4>
        <b-form-checkbox
          v-model="check"
          checked="false"
          name="check-button"
          switch
          inline
          @change="isHidden = !isHidden"
        >
          수정
        </b-form-checkbox>
      </span>
      <hr class="pb-1">
      <b-form class="pb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="사이트"
              label-for="user-site"
            >
              <b-form-text
                id="user-site"
                class="font-small-3"
                placeholder=""
                style="color:rgb(112,201,120) !important"
              >
                {{ user.site }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="아이디"
              label-for="user-userid"
            >
              <b-form-text
                id="user-userid"
                class="font-small-3"
                placeholder=""
                style="color:rgb(112,201,120) !important"
              >
                {{ user.userid }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="닉네임"
              label-for="user-nickname"
            >
              <b-form-input
                v-if="false"
                id="user-nickname"
                v-model="user.nickname"
                class="font-small-3"
                placeholder=""
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
              <b-form-text
                id="user-nickname"
                class="font-small-3"
                placeholder=""
                style="color:rgb(112,201,120) !important"
              >
                {{ user.nickname }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="메일"
              label-for="user-email"
            >
              <b-form-text
                id="user-email"
                class="font-small-3"
                placeholder=""
                style="color:rgb(112,201,120) !important"
              >
                {{ user.email }}
              </b-form-text>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              v-if="['admin'].includes(user.role)"
              label="상위/총판"
              label-for="user-recommendNickname"
            >
              <b-form-input
                v-if="false"
                id="user-recommendNickname"
                v-model="user.recommendNickname"
                class="font-small-3"
                placeholder=""
                style="color:rgb(229,166,48)"
                disabled="true"
              />
              <b-form-text
                id="user-recommendNickname"
                class="font-small-3"
                placeholder=""
                style="color:rgb(112,201,120) !important"
              >
                {{ user.recommendNickname }}
              </b-form-text>
            </b-form-group>
            <b-form-group
              v-if="['branch','user'].includes(user.role)"
              label="상위/총판"
              label-for="user-recommendNickname"
            >
              <b-form-select
                v-model="user.recommendNickname"
                label-for="user-recommendNickname"
                class="font-small-3"
                :options="optionsBranch"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              >
                <template #first>
                  <option
                    disabled
                    value="null"
                    style="color: white;"
                  >
                    == 선택 ==
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="전화번호"
              label-for="user-mobile"
            >
              <b-form-input
                id="user-mobile"
                v-model="user.mobile"
                class="font-small-3"
                placeholder="ex)123-456-789"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="이름"
              label-for="user-username"
            >
              <b-form-text
                v-if="false"
                id="user-username"
                class="font-small-3"
                placeholder="ex) 홍길동"
                style="color:rgb(112,201,120) !important"
              >
                {{ user.username }}
              </b-form-text>
              <b-form-input
                v-if="true"
                id="user-username"
                v-model="user.username"
                class="font-small-3"
                placeholder=""
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="계좌은행"
              label-for="user-accountBank"
            >
              <b-form-input
                id="user-accountBank"
                v-model="user.accountBank"
                class="font-small-3"
                placeholder="은행명만 기입"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="계좌번호"
              label-for="user-accountNumber"
            >
              <b-form-input
                id="user-accountNumber"
                v-model="user.accountNumber"
                class="font-small-3"
                type="text"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="권한"
              label-for="user-role"
            >
              <b-form-select
                id="user-role"
                v-model="user.role"
                :options="options1"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
                @change="handleRoleChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="랭크"
              label-for="user-rank"
            >
              <b-form-select
                id="user-rank"
                v-model="user.rank"
                :options="optionsSetRank"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              >
                <template #first>
                  <option
                    disabled
                    value="null"
                    style="color: white;"
                  >
                    == 선택 ==
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="상태"
              label-for="user-status"
            >
              <b-form-select
                id="user-status"
                v-model="user.status"
                :options="options2"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <span class="pl-1 row justify-content-between">
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          사용자별 충전 계좌 설정
        </h4>
      </span>
      <hr class="pb-1">
      <b-form class="pb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="계좌은행"
              label-for="user-adminAccountBank"
            >
              <b-form-input
                id="user-accountBank"
                v-model="user.adminAccountBank"
                class="font-small-3"
                placeholder="은행명만 기입"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="계좌번호"
              label-for="user-adminAccountNumber"
            >
              <b-form-input
                id="user-accountNumber"
                v-model="user.adminAccountNumber"
                class="font-small-3"
                placeholder="ex)123-456-789"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="예금주"
              label-for="user-adminAccountName"
            >
              <b-form-text
                v-if="false"
                id="user-username"
                class="font-small-3"
                placeholder="홍길동"
                style="color:rgb(112,201,120) !important"
              >
                {{ user.adminAccountName }}
              </b-form-text>
              <b-form-input
                v-if="true"
                id="user-username"
                v-model="user.adminAccountName"
                class="font-small-3"
                placeholder="홍길동"
                style="color:rgb(229,166,48)"
                :disabled="isHidden ? true : false"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div
        v-if="user.site === user.recommendNickname && user.role === 'user'"
      >
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            롤링 비율 설정 [스포츠/카지노/슬롯]
          </h4>
        </span>
        <hr class="pb-1">
        <b-form class="pb-2">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="font-small-3 text-success mb-1">
                설명: 내용을 추가해주세요.
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="스포츠 롤링 비율(%)"
                label-for="user-rateSportRolling"
              >
                <b-form-input
                  id="user-rateSportRolling"
                  v-model="user.rateSportRolling"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  :disabled="isHidden ? true : false"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="카지노 롤링 비율(%)"
                label-for="user-rateCasinoRolling"
              >
                <b-form-input
                  id="user-rateCasinoRolling"
                  v-model="user.rateCasinoRolling"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  :disabled="isHidden ? true : false"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="슬롯 롤링 비율(%)"
                label-for="user-rateSlotRolling"
              >
                <b-form-input
                  v-if="true"
                  id="user-rateSlotRolling"
                  v-model="user.rateSlotRolling"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  :disabled="isHidden ? true : false"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <!-- submit and reset -->
      <div class="row">
        <b-col cols="12">
          <b-button
            v-if="!isHidden"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right"
            @click="btnSave"
          >
            저장
          </b-button>
          <b-button
            v-if="false"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            class="float-right mr-1"
            @click="submit('reset1')"
          >
            초기화
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            class="float-right mr-1"
            @click="btnCancel"
          >
            취소
          </b-button>
        </b-col>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BFormCheckbox, BFormGroup, BFormInput, BForm, BFormText, BFormSelect, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_USER } from '@/store/users/action'
import { UPDATE_USER } from '@/store/users/mutation'
import { FETCH_BRANCH_SELECT_OPTIONS } from '@/store/branch/action'
import { FETCH_SET_RANK } from '@/store/settings/action'

const userStore = createNamespacedHelpers('userStore')
const branchStore = createNamespacedHelpers('branchStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BForm,
    BFormText,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isHidden: true,
      check: 'false',
      // userData: JSON.parse(localStorage.getItem('userView')),
      // adminId: JSON.parse(localStorage.getItem('userData')).userid,
      // adminSite: localStorage.getItem('siteSelected'),
      // adminIp: localStorage.getItem('ip'),
      user: {},

      options1: [
        { value: 'user', text: '유저' },
        { value: 'branch', text: '총판' },
        { value: 'admin', text: '어드민' },
      ],
      options2: [
        { value: 'apply', text: '가입신청' },
        { value: 'additional', text: '정보추가요청' },
        { value: 'await', text: '가입최종신청' },
        { value: 'active', text: '정상' },
        { value: 'sleep', text: '휴면' },
        { value: 'block', text: '차단' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userView() {
      return this.$store.getters['rootStore/userView']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...userStore.mapGetters({
      fetchUser: 'fetchUser',
    }),
    ...branchStore.mapGetters({
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
    }),
    ...settingsStore.mapGetters({
      fetchSetRank: 'fetchSetRank',
    }),
    optionsBranch() {
      const resultData = this.fetchBranchSelectOptions
        .reduce((acc, option) => {
          if (option.role === 'branch' && option.nickname !== this.user.nickname) {
            acc.push({
              text: option.status === 'active'
                ? `[${option.role}] ${option.nickname}`
                : `[${option.role}] ${option.nickname} (${option.status})`,
              value: option.nickname,
            })
          }
          return acc
        // }, JSON.parse(localStorage.getItem('userData')).role === 'admin' ? [{
        }, this.$store.getters['rootStore/userData'].role === 'admin' ? [{
          text: `[site] ${this.siteSelected}`,
          value: this.siteSelected,
        }] : [])
      return resultData
    },
    optionsSetRank() {
      const formattedOptions = this.fetchSetRank.map(rank => ({
        text: `${rank.rankOrder} :: ${rank.rankName}`,
        value: rank.rankOrder,
      })).reverse()
      return formattedOptions
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.init()
  },
  mounted() {
    // console.log('[UserViewUserInfoCard.vue]', 'mounted()')
  },
  methods: {
    ...userStore.mapActions({
      $fetchUser: FETCH_USER,
      $updateUser: UPDATE_USER,
    }),
    ...branchStore.mapActions({
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
    }),
    ...settingsStore.mapActions({
      $fetchSetRank: FETCH_SET_RANK,
    }),

    async init() {
      await this.$fetchBranchSelectOptions({
        site: this.siteSelected,
        userid: this.userData.userid,
      })
      await this.$fetchSetRank({
        site: this.siteSelected,
      })
      await this.fetchData()
    },
    async fetchData() {
      await this.$fetchUser(this.userView)
      this.user = { ...this.fetchUser }
    },
    updateParam(t, e, v) {
      // store에 param을 update 한다
      // console.log({ t, e, v })
      this.$store.commit('userStore/updateParam', { t, e, v })
    },

    handleRoleChange(role) {
      if (role === 'branch') {
        Swal.fire({
          title: '총판등록 화면이동',
          text: '총판으로 승격 할 경우, 총판관리에서 총판등록을 하셔야합니다.\n총판등록 화면으로 이동하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          cancelButtonText: '취소',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        }).then(result => {
          if (result.isConfirmed) {
            // 확인 버튼 클릭 시 총판등록 화면으로 이동
            this.$router.push({ name: 'branch-create', params: { site: this.user.site, userid: this.user.userid } })
          } else {
            // 취소 버튼 클릭 시 롤백
            this.user.role = 'user'
          }
        })
      }
      if (role === 'admin') {
        Swal.fire({
          title: '추가 정보 필요',
          text: '어드민으로 승격 할 경우, 추가정보 입력은 마스터 관리자에게 요청 바랍니다.',
          icon: 'warning',
          showCancelButton: false, // 취소 버튼 숨김
          confirmButtonColor: '#3085d6',
          confirmButtonText: '확인',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary mr-1',
          },
        }).then(() => {
          // 확인 버튼 클릭 후 동작
          this.user.role = role
        })
      }
    },

    // button
    async btnSave() {
      const changedItems = Object.keys(this.fetchUser).filter(item => this.fetchUser[item] !== this.user[item])
      if (changedItems.length > 0) {
        await this.fncShowSwal('confirm')
          .then(result => {
            if (result.isConfirmed) {
              try {
                Object.keys(this.fetchUser).forEach(field => {
                  this.updateParam('fetchUser', field, this.user[field])
                })
                this.$updateUser({
                  adminId: this.userData.userid,
                  adminIp: this.userIp,
                })
              } catch {
                console.error()
              } finally {
                // this.fetchData()
                this.fncShowSwal('success')
              }
            }
          })
      } else {
        this.fncShowSwal('check')
      }
    },
    async btnCancel() {
      const isChanged = JSON.stringify(this.user) !== JSON.stringify(this.fetchUser)
      if (isChanged) {
        await this.fncShowSwal('cancel')
          .then(result => {
            if (result.isConfirmed) {
              this.$router.back()
            }
          })
      } else {
        this.$router.back()
      }
    },

    // Swal
    fncShowSwal(type, title = null, text = null) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        check: {
          title: '변경된 내용이 없습니다. \n 다시 확인바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        cancel: {
          title: '변경된 내용이 저장되지 않습니다. 정말 나가시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 title 있다면 해당 설정을 덮어씁니다.
      if (title) config.title = title

      // 추가적으로 전달된 text 있다면 해당 설정을 덮어씁니다.
      if (text === null) delete config.text

      // Swal을 실행합니다.
      return Swal.fire(config)
    },
  },
}
</script>
