<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Betting Chart
        </b-card-title>
        <b-card-sub-title>배팅 바차트</b-card-sub-title>
      </div>

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <feather-icon
          icon="CalendarIcon"
          size="16"
        />
        <flat-pickr
          v-model="rangePicker"
          :config="{ mode: 'range', onChange: handleDateChange }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          style="width: 210px;"
          placeholder="YYYY-MM-DD"
        />
      </div>
      <!-- datepicker -->
    </b-card-header>
    <!--/ title and subtitle -->

    <b-card-body>
      <app-echart-bar
          type="area"
          height="400"
        :option-data="lineChart"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import AppEchartBar from '@core/components/charts/echart/AppEchartBar.vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

import * as moment from 'moment-timezone'

import { createNamespacedHelpers } from 'vuex'

import {
  FETCH_REQUEST_ALARMS_BET_FINISH,
} from '@/store/notice/action'
import { avatarText } from '@core/utils/filter'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    AppEchartBar,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    // siteSelected: String,
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const startOfMonth = moment(new Date()).startOf('month').format(fmt1)
    const endOfMonth = moment(new Date()).endOf('month').format(fmt1)
    const monthDays = []

    // 'startOfMonth'부터 'endOfMonth'까지의 각 날짜를 'MM-DD' 형식으로 배열에 추가
    for (let date = moment(startOfMonth); date.isSameOrBefore(endOfMonth); date.add(1, 'day')) {
      monthDays.push(date.format('M/D'))
    }
    return {
      periodFrom: startOfMonth,
      periodTo: endOfMonth,
      lineChart: {
        xAxis: [
          {
            type: 'category',
            data: monthDays,
            splitLine: { show: false },
            axisLine: { show: false },
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              lineStyle: {
                // Dark and light colors will be used in turns
                color: ['rgba(110,107,123,0.4)'],
              },
            },
            axisLine: { show: false },
            boundaryGap: ['0%', '10%'],
          },
        ],
        grid: {
          left: '30px',
          right: 'auto',
          bottom: '30px',
        },
        series: [
          {
            name: '',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            // itemStyle: {
            //   barBorderRadius: [10, 10, 0, 0],
            // },
          },
          {
            name: '',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            // itemStyle: {
            //   barBorderRadius: [10, 10, 0, 0],
            // },
          },
          {
            name: '',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            // itemStyle: {
            //   barBorderRadius: [10, 10, 0, 0],
            // },
          },
          {
            name: '',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
            itemStyle: {
              barBorderRadius: [0, 0, 0, 0],
            },
          },
        ],
      },
      rangePicker: [startOfMonth, endOfMonth],
    }
  },
  computed: {
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBetFinish: 'fetchRequestAlarmsBetFinish',
    }),
  },
  watch: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    rangePicker() {
      const range = this.rangePicker.split(' to ')
      this.periodFrom = range[0]
      this.periodTo = range[1]
      // this.fetchData()
    },
  },
  created() {
    // Betting Chart Dashboard
    this.$socket.on('requestAlarmsBetBettingDashboard', data => {
      // console.log('requestAlarmsBetBettingDashboard:::', data)
      this.processNextChartData(data)
    })
    this.$socket.on('requestAlarmsBetBettingCancelDashboard', data => {
      // console.log('requestAlarmsBetBettingCancelDashboard:::', data)
      this.processNextChartData(data)
    })
  },
  mounted() {
    this.fetchData()
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBetFinish: FETCH_REQUEST_ALARMS_BET_FINISH,
    }),
    async fetchData() {
      if (this.periodTo === undefined) {
        this.periodTo = this.periodFrom
      }
      const periodFromWithTime = moment(this.periodFrom).startOf('day').format('YYYY-MM-DD HH:mm:ss')
      const periodToWithTime = moment(this.periodTo).endOf('day').format('YYYY-MM-DD HH:mm:ss')

      // 시, 분, 초가 추가된 날짜를 다시 변수에 할당
      this.periodFrom = periodFromWithTime
      this.periodTo = periodToWithTime

      await this.fetchRequestAlarmsBetFinishData()
      await this.sprepareChartData()
    },
    async fetchRequestAlarmsBetFinishData() {
      const mySite = this.siteSelected
      await this.$fetchRequestAlarmsBetFinish({
        site: mySite,
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
        confirmed: 'BETTING',
      })
      // console.log('$fetchRequestAlarmsBetFinish:::', this.fetchRequestAlarmsBetFinish)
    },
    async sprepareChartData() {
      // startOfMonth부터 endOfMonth까지의 데이터 필터링
      const filteredData = this.fetchRequestAlarmsBetFinish.filter(entry => moment(entry.createdAt).isBetween(this.periodFrom, this.periodTo, null, '[]'))
      // console.log('filteredData:::', filteredData)
      // 모든 날짜를 포함하는 날짜 배열 생성
      const startDate = moment(this.periodFrom)
      const endDate = moment(this.periodTo)
      const xAxisData = []
      while (startDate.isSameOrBefore(endDate)) {
        xAxisData.push(startDate.format('M/D'))
        startDate.add(1, 'day')
      }

      // 카테고리별 데이터 매핑
      const categoryDataMap = new Map() // { '카테고리1': [count1, count2, ...], '카테고리2': [count1, count2, ...], ... }
      filteredData.forEach(entry => {
        const { category } = entry
        if (!categoryDataMap.has(category)) {
          categoryDataMap.set(category, [])
        }
        categoryDataMap.get(category).push(entry)
      })

      // 주어진 순서대로 categoryDataMap을 정렬
      const order = ['Sports-Prematch', 'Sports-Inplay', 'Casino', 'Casino-Slot']
      const sortedCategoryDataMap = new Map(order.map(category => [category, categoryDataMap.get(category)]))

      // series 설정
      const series = Array.from(sortedCategoryDataMap.entries()).map(([category, data]) => {
        if (!data) {
          // 데이터가 없을 경우 처리
          return {
            name: category,
            data: [],
          }
        }

        const categoryCounts = xAxisData.map(date => {
          // date에 해당하는 데이터만 필터링
          const filteredEntry = data.filter(entry => moment(entry.createdAt).format('M/D') === date)
          return filteredEntry.length
        })

        return {
          name: category,
          data: categoryCounts,
        }
      })
      this.lineChart.xAxis[0].data = xAxisData

      // console.log('series ::', series)

      // series 설정
      for (let i = 0; i < series.length; i++) {
        this.lineChart.series[i].data = series[i].data
        this.lineChart.series[i].name = series[i].name
        // console.log('series[i].data', series[i].data)
        // if (i === series.length) {
        //   console.log('i last ::', series.length)
        //   this.lineChart.series[i].data = series[i].data
        //   this.lineChart.series[i].name = series[i].name
        //   this.lineChart.series[i].itemStyle = { barBorderRadius: [10, 10, 0, 0] }
        // } else {
        //   console.log('i else ::', series.length)
        //   this.lineChart.series[i].data = series[i].data
        //   this.lineChart.series[i].name = series[i].name
        // }
      }
    },
    async processNextChartData(data) {
      if (data.confirmed === 'YET' && data.betResult === null && data.confirmAt === null) {
        this.fetchRequestAlarmsBetFinish.push(data)
      }
      if (data.confirmed === 'USER CANCELED' || data.confirmed === 'CUSTOM CANCELED' || data.confirmed === 'REJECT') {
        for (let i = this.fetchRequestAlarmsBetFinish.length - 1; i >= 0; i -= 1) {
          if (this.fetchRequestAlarmsBetFinish[i].groupId === data.groupId) {
            this.fetchRequestAlarmsBetFinish.splice(i, 1)
          }
        }
      }
      await this.sprepareChartData()
    },
    handleDateChange(selectedDates, dateStr, instance) {
      this.$nextTick(() => {
        // selectedDates 배열의 길이가 2인 경우에만 fetchData 함수 호출
        if (selectedDates.length === 2) {
          this.fetchData()
        }
      })
    },
  },
}
</script>
