<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div>
        <h4 class="">
          <feather-icon
            icon="BoxIcon"
            style="width: 18px; height: 18px"
          />
          FAQ관리
          <b-badge
            variant="primary"
            style="font-size: xx-small"
          >
            완료
          </b-badge>
        </h4>
        <hr>
  <!--      @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"-->
  <!--      @row-clicked="rowClickHandler"-->
        <b-table
          responsive
          show-empty
          hover
          empty-text="No matching records found"
          :items="fetchFaq"
          :fields="fields1"
          style="text-align: center; font-size: small;"
          @row-clicked="item=>$set(item, '_showDetails', !item._showDetails)"
        >
          <template #cell(index)="data"
                    size="sm"
                    style="text-align: center; min-width: 3rem"
          >
            {{ data.index + 1 }}
          </template>
  <!--        <template #cell(index)="data">-->
  <!--          <div-->
  <!--            size="sm"-->
  <!--            style="text-align: center; min-width: 3rem"-->
  <!--          >-->
  <!--            {{ data.item.idx }}-->
  <!--          </div>-->
  <!--        </template>-->
          <template #cell(category)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 5rem"
            >
              {{ data.item.category }}
            </div>
          </template>
          <template #cell(title)="data">
            <div
              size="sm"
              style="text-align: left; min-width: 10rem"
            >
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(createdAt)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 7rem"
            >
              {{ data.item.createdAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
          <template #cell(updatedAt)="data">
            <div
              size="sm"
              style="text-align: center; min-width: 7rem"
            >
              {{ data.item.updatedAt | formatDate('YYYY-MM-DD HH:mm') }}
            </div>
          </template>
          <template #cell(edit)="data">
            <div style="min-width: 6rem"
                 @click.stop>
              <feather-icon
                v-b-modal.modal-lg
                icon="Edit2Icon"
                class="mr-1"
                style="color:rgb(229,166,48);"
                type="button"
                @click="faqDetail(data)"
              />
              <feather-icon
                icon="TrashIcon"
                class=""
                style="color:rgb(229,166,48);"
                @click="submit('deleteFaq', data)"
              />
            </div>
          </template>
          <template #row-details="row">
            <b-card style="background-color: #1b2337">
              <b-row class="mb-2">
                <b-col cols="12">
                  <b-form-group
                    class="font-small-3"
                    label="FAQ상세"
                    label-for="main-notice"
                    label-cols-md="1"
                  >
                    <b-form-textarea
                      id="faq-detail"
                      v-model="row.item.content"
                      class="font-small-3"
                      size="sm"
                      placeholder="FAQ 상세내용"
                      style="color:rgb(229,166,48)"
                      rows="9"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-button
                size="sm"
                variant="primary"
                class=""
                @click="submit(row.item.no)"
              >
                저장
              </b-button>
            </b-card>
          </template>

        </b-table>
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="content-right w-auto mr-1 mt-1"
          size="sm"
          v-b-modal.modal-lg
          @click="faqNew()"
        >
          FAQ 생성
  <!--                    <feather-icon-->
  <!--                      icon="Edit2Icon"-->
  <!--                      class="ml-1"-->
  <!--                      style="color:rgb(229,166,48);"-->
  <!--                      type="button"-->
  <!--                      @click="handleClick(row)"-->
  <!--                      v-b-modal.modal-lg-->
  <!--                    />-->
        </b-button>
        <b-modal
          id="modal-lg"
          ref="my-modal"
          title="상세설정"
          centered
          button-size="sm"
          size="lg"
          ok-title="저장"
          @ok="validationForm('upsertFaq')"
        >
  <!--        <b-card-text class="font-small-3">카테고리 : {{ faqDetailCategory }}</b-card-text>-->
  <!--        <b-card-text class="font-small-3">제목 : {{ faqDetailTitle }}</b-card-text>-->
          <b-form-group
            class="font-small-3"
            label="카테고리"
            label-for="main-notice"
            label-cols-md="2"
          >
            <b-form-input
              id="main-notice"
              class="font-small-3"
              size="sm"
              placeholder="카테고리를 입력하세요"
              style="color:rgb(229,166,48)"
              rows="6"
              v-model="faqDetailCategory"
              :disabled="faqDetailDisabled"
            />
          </b-form-group>
          <b-form-group
            class="font-small-3"
            label="제목"
            label-for="main-notice"
            label-cols-md="2"
          >
            <b-form-input
              id="main-notice"
              class="font-small-3"
              size="sm"
              placeholder="제목을 입력하세요"
              style="color:rgb(229,166,48)"
              rows="6"
              v-model="faqDetailTitle"
              :disabled="faqDetailDisabled"
            />
          </b-form-group>
          <label for="faq-detail">상세내용</label>
          <b-row class="mb-2">
            <b-col cols="12">
              <b-form-textarea
                id="faq-detail"
                class="font-small-3"
                size="sm"
                placeholder="FAQ상세내용"
                style="color:rgb(229,166,48)"
                rows="9"
                v-model="faqDetailContent"
              />
            </b-col>
          </b-row>
        </b-modal>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BModal,
  BCardText,
  BFormInput,
  BBadge,
  VBModal,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { createNamespacedHelpers } from 'vuex'

const noticeStore = createNamespacedHelpers('noticeStore')
const settingsStore = createNamespacedHelpers('settingsStore')

import { FETCH_FAQ } from '@/store/notice/action'
import { DELETE_FAQ, UPSERT_FAQ } from '@/store/notice/mutation'

import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BModal,
    BCardText,
    BFormInput,
    BBadge,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      faqDetailRow: 0,
      faqDetailCategory: '',
      faqDetailTitle: '',
      faqDetailContent: '',
      faqDetailDisabled: false,
      fields1: [
        { key: 'index', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'category', sortable: false, label: '카테고리', thClass: 'col2' },
        { key: 'title', sortable: false, label: '제목', thStyle: 'col3' },
        { key: 'enrollId', sortable: false, label: '등록자', thClass: 'col4' },
        { key: 'createdAt', sortable: false, label: '등록일', thClass: 'col5' },
        { key: 'updatedAt', sortable: false, label: '수정일', thClass: 'col6' },
        { key: 'edit', sortable: false, label: '상세', thClass: 'col7' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...noticeStore.mapGetters({
      fetchFaq: 'fetchFaq',
    }),
    ...settingsStore.mapGetters({
      fetchSetSiteAdmin: 'fetchSetSiteAdmin',
    }),
    noticeStore() {
      return this.$store.state.noticeStore.fetchFaq
    },
  },
  watch: {

  },
  mounted() {
    // this.siteAdminData = this.fetchSetSiteAdmin
    // this.authSite = this.fetchSetSiteAdmin.map(item => item.authSite)
    // this.siteSelected = this.authSite[0]
    this.fetchData()
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...noticeStore.mapActions({
      $fetchFaq: FETCH_FAQ,
      $upsertFaq: UPSERT_FAQ,
      $deleteFaq: DELETE_FAQ,
    }),
    async fetchData() {
      const mySite = this.siteSelected
      await this.$fetchFaq({
        site: mySite,
      })
    },

    validationForm(target, row) {
      if (this.faqDetailCategory === '' || this.faqDetailTitle === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '카테고리, 제목을 입력해주세요',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        this.submit(target, row)
      }
    },
    async submit(target, row) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, row)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, row) {
      const mySite = this.siteSelected
      const adminId = this.userData.userid
      try {
        if (target === 'upsertFaq') {
          await this.$upsertFaq({
            site: mySite,
            category: this.faqDetailCategory,
            title: this.faqDetailTitle,
            content: this.faqDetailContent,
            enrollId: adminId,
            userIp: this.userIp,
          })
        }
        if (target === 'deleteFaq') {
          await this.$deleteFaq({
            site: mySite,
            category: row.item.category,
            title: row.item.title,
            enrollId: adminId,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    // delete row
    deleteRow(type, row) {
      Swal.fire({
        title: '선택한 항목을 삭제하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(type, row)
        }
      })
    },
    faqDetail(data) {
      this.faqDetailRow = data.item.no
      this.faqDetailCategory = data.item.category
      this.faqDetailTitle = data.item.title
      this.faqDetailContent = data.item.content
      this.faqDetailDisabled = true
    },
    faqNew() {
      this.faqDetailRow = ''
      this.faqDetailCategory = ''
      this.faqDetailTitle = ''
      this.faqDetailContent = ''
      this.faqDetailDisabled = false
    },
    rowClickHandler(record, index, type) {
      // console.log(row.item.idx)
      // console.log(row.item.category)
      // console.log(row.item.content)
      // console.log(row.item.title)
      if (type.target.ariaColIndex !== '7') {
        this.faqDetailRow = record.no
        this.faqDetailCategory = record.category
        this.faqDetailTitle = record.title
        this.faqDetailContent = record.content
        this.faqDetailDisabled = true
        this.$refs['my-modal'].show()
      }
    },
  },
}

</script>

<style scoped>
</style>
