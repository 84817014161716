<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        게임 유형설정
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
      </h4>
      <hr>
      <div class="m-1">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col
            cols="9"
            md="6"
            sm="12"
            class="mb-sm-1 d-flex justify-content-between align-items-center"
          >
            <div class="d-inline-flex">
              <b-form-group class="mb-0">
                <b-form-checkbox
                  v-model="filterByUseMarket"
                  inline
                  @change="fetchData"
                >
                  <div class="font-small-3">
                    사용중인 유형만 보기
                  </div>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="filterByMenuPrematch"
                  inline
                  @change="fetchData('prematch')"
                >
                  <div class="font-small-3">
                    프리매치만 보기
                  </div>
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="filterByMenuInplay"
                  inline
                  @change="fetchData('inplay')"
                >
                  <div class="font-small-3">
                    인플레이만 보기
                  </div>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '1' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('soccer', '1')"
            >
              축구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '2' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('basketball', '2')"
            >
              농구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '3' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('baseball', '3')"
            >
              야구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '4' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('tennis', '4')"
            >
              테니스
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '5' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('volleyball', '5')"
            >
              배구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '6' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('football', '6')"
            >
              풋볼
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '7' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('hockey', '7')"
            >
              하키
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '8' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('esports', '8')"
            >
              E스포츠
            </b-button>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        striped
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchGameSetOddsMarket"
        :fields="fields"
        :filter="filter"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #cell(no)="data">
          <div
            class=""
            style="min-width: 1rem"
          >
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(type)="data">
          <div
            class=""
            style="min-width: 5rem"
          >
            {{ data.item.type }}
          </div>
        </template>
        <template #cell(menuId)="data">
          <div
            class=""
            style="min-width: 4rem"
          >
            {{ data.item.menuId }}
          </div>
        </template>
        <template #cell(menuName)="data">
          <div
            class=""
            style="min-width: 5rem"
          >
            {{ categoryText(data.item.menuName) }}
          </div>
        </template>
        <template #cell(marketName)="data">
          <div
            class=""
            style="text-align: center; min-width: 17rem"
          >
            {{ data.item.marketName }}
          </div>
        </template>
        <template #cell(marketNameCustom)="data">
          <div
            class=""
            style="text-align: center; min-width: 17rem"
          >
            {{ data.item.marketNameCustom }}
          </div>
        </template>
        <!--        <template #cell(useYn)="data">-->
        <!--          <b-form-select-->
        <!--            id="useYn"-->
        <!--            :value="data.item.useYn"-->
        <!--            style="text-align: center; color:rgb(229,166,48); min-width: 5rem; max-width: 5rem"-->
        <!--            size="sm"-->
        <!--            class="center"-->
        <!--            :options="options1"-->
        <!--            @change="updateParam('updateGameSetOddsMarket', 'fetchGameSetOddsMarket','useYn', $event, data.item)"-->
        <!--          />-->
        <!--        </template>-->
        <template #cell(useYn)="data">
          <div
            size="sm"
            style=""
          >
            <b-form-checkbox
              :checked="data.item.useYn === 'Y'"
              class="custom-control-primary d-inline"
              name="check-button"
              switch
              @change="updateUseYn($event, data.item)"
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-col
        id="basic1-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="w-auto content-right"
          size="sm"
          @click="submit('updateGameSetOddsMarket')"
        >
          저장
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormCheckbox,
  BButton,
  BBadge,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BFormSelect,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_ODDS_MARKET } from '@/store/game/action'
import { UPDATE_GAME_SET_ODDS_MARKET } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabItem',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BBadge,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      selectSports: 'soccer',
      selectedButton: '1',
      perPage: 100,
      pageOptions: [50, 100, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      filterByUseMarket: true,
      filterByMenuPrematch: false,
      filterByMenuInplay: false,
      options1: [
        { value: 'N', text: 'N' },
        { value: 'Y', text: 'Y' },
      ],
      option2: [
        { value: 'Sports-Prematch', text: '프리매치' },
        { value: 'Sports-Special', text: '스페셜' },
        { value: 'Sports-Inplay', text: '인플레이' },
      ],
      fields: [
        {
          key: 'no', sortable: false, label: 'No.',
        },
        // {
        //   key: 'site', sortable: false, label: '사이트',
        // },
        {
          key: 'menuName', sortable: false, label: '메뉴명',
        },
        {
          key: 'itemName', sortable: false, label: '종목명',
        },
        {
          key: 'marketId', sortable: false, label: 'CODE',
        },
        {
          key: 'marketName', sortable: false, label: '유형명',
        },
        {
          key: 'marketNameCustom', sortable: false, label: '유형명(한글)',
        },
        {
          key: 'useYn', sortable: false, label: '사용여부',
        },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...gameStore.mapGetters({
      fetchGameSetOddsMarket: 'fetchGameSetOddsMarket',
      updateGameSetOddsMarket: 'updateGameSetOddsMarket',
    }),
    categoryText() {
      return value => {
        const option = this.option2.find(option => option.value === value)
        return option ? option.text : value
      }
    },
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('siteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  created() {
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetOddsMarket: FETCH_GAME_SET_ODDS_MARKET,
      $updateGameSetOddsMarket: UPDATE_GAME_SET_ODDS_MARKET,
    }),
    updateParam(t, f, e, v, i) {
      const events = {
        t, f, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateManyMarketParam', events)
    },
    async menuSelect(sports, index) {
      this.selectSports = sports
      this.selectedButton = index
      await this.fetchData()
    },
    async fetchData(type) {
      const mySite = this.site
      if (type === 'prematch') {
        this.filterByMenuInplay = false
      } else if (type === 'inplay') {
        this.filterByMenuPrematch = false
      }
      const menuNameSelected = (this.filterByMenuPrematch ? 'Sports-Prematch' : '') + (this.filterByMenuInplay ? 'Sports-Inplay' : '')
      await this.$fetchGameSetOddsMarket({
        site: mySite,
        sports: this.selectSports,
        useYn: this.filterByUseMarket,
        menuName: menuNameSelected,
      })
      this.totalRows = this.fetchGameSetOddsMarket.length
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSetOddsMarket') {
          await this.$updateGameSetOddsMarket({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateUseYn(event, item) {
      // Get the new value from event
      // console.log(item)
      const newValue = event === true ? 'Y' : 'N'
      this.$set(item, 'useYn', newValue)
      // console.log(newValue)
      // console.log(item.useYn)
      this.updateParam('updateGameSetOddsMarket', 'fetchGameSetOddsMarket', 'useYn', newValue, item)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
