<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- 경기 관리 (발매)-->
    <b-card>
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        경기 관리 (발매)
      </h4>
      <div class="m-2">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="3"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
          >
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '1' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('soccer', '1')"
            >
              축구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '2' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('basketball', '2')"
            >
              농구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '3' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('baseball', '3')"
            >
              야구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '4' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('tennis', '4')"
            >
              테니스
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '5' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('volleyball', '5')"
            >
              배구
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '6' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('football', '6')"
            >
              풋볼
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '7' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('hockey', '7')"
            >
              하키
            </b-button>
            <b-button
              size="sm"
              class=""
              style="margin-right: 5px"
              :variant="selectedButton === '8' ? 'primary' : 'outline-secondary'"
              @click="menuSelect('esports', '8')"
            >
              E스포츠
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!-- Table -->
      <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="transformedData"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler"
      >
        <template
          #cell(index)="data"
          size="sm"
          style="text-align: center; min-width: 2rem;"
        >
          {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
        </template>
        <template #cell(sport)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem;"
          >
            {{ data.item.sport }}
          </div>
        </template>
        <template #cell(gameId)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem;"
          >
            {{ data.item.gameId }}
          </div>
        </template>
        <template #cell(leagueId)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem;"
          >
            {{ data.item.leagueId }}
          </div>
        </template>
        <template #cell(leagueName)="data">
          <div
            v-if="data.item.leagueNameCustom"
            class="text-truncate"
            style="text-align: left; max-width: 9rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.leagueNameCustom"
          >
            {{ data.item.leagueNameCustom }}
          </div>
          <div
            v-else
            class="text-truncate"
            style="text-align: left; max-width: 9em;"
            v-b-tooltip.hover.bottom.v-danger="data.item.leagueName"
          >
            {{ data.item.leagueName }}
          </div>
        </template>
        <template #cell(startDate)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 9rem;"
          >
            {{ `${data.item.startDate}  ${data.item.startTime}` }}
          </div>
        </template>
        <template #cell(oddsType)="data">
          <div
            size="sm"
            style="text-align: center; min-width: 4rem;"
          >
            {{ oddsTypeText(data.item.oddsType) }}
          </div>
        </template>
        <template #cell(homeName)="data">
          <div
            v-if="data.item.homeNameCustom"
            size="sm"
            class="text-truncate"
            style="text-align: right; float: right; max-width: 9rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.homeNameCustom"
          >
            {{ data.item.homeNameCustom }}
          </div>
          <div
            v-else
            size="sm"
            class="text-truncate"
            style="text-align: right; float: right; max-width: 9rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.homeName"
          >
            {{ data.item.homeName }}
          </div>
        </template>
        <template #cell(awayName)="data">
          <div
            v-if="data.item.awayNameCustom"
            size="sm"
            class="text-truncate"
            style="text-align: left; float: left; max-width: 9rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.awayNameCustom"
          >
            {{ data.item.awayNameCustom }}
          </div>
          <div
            v-else
            size="sm"
            class="text-truncate"
            style="text-align: left; float: left; max-width: 9rem;"
            v-b-tooltip.hover.bottom.v-danger="data.item.awayName"
          >
            {{ data.item.awayName }}
          </div>
        </template>
        <template #cell(Home)="data">
          <div style="text-align: center; max-width: 4em;">
            <b-form-input
              style="color:yellow; font-size: small; height: 25px; width: 70px; text-align: center;"
              :value="getHomeValue(data.item)"
              @input="updateValue('Home', $event, data.item)"
            />
          </div>
        </template>
        <template #cell(Draw)="data">
          <div style="text-align: center; max-width: 4em;">
            <b-form-input
              style="color:yellow; font-size: small; height: 25px; width: 70px; text-align: center;"
              :value="getDrawValue(data.item)"
              @input="updateValue('Draw', $event, data.item)"
            />
          </div>
        </template>
        <template #cell(Away)="data">
          <div style="text-align: center; max-width: 4em;">
            <b-form-input
              style="color:yellow; font-size: small; height: 25px; width: 70px; text-align: center;"
              :value="getAwayValue(data.item)"
              @input="updateValue('Away', $event, data.item)"
            />
          </div>
        </template>
        <template #cell(isStopAPI)="data">
          <div
            size="sm"
            style=""
          >
            <b-form-checkbox
              :checked="data.item.isStopAPI === 'Y'"
              class="custom-control-primary d-inline"
              name="check-button"
              @change="updateValue('isStopAPI', $event, data.item)"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
        <template #cell(isHideAPI)="data">
          <div
            size="sm"
            style=""
          >
            <b-form-checkbox
              :checked="data.item.isHideAPI === 'Y'"
              class="custom-control-primary d-inline"
              name="check-button"
              @change="updateValue('isHideAPI', $event, data.item)"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
        <template #cell(isClosedAPI)="data">
          <div
            size="sm"
            style=""
          >
            <b-form-checkbox
              :checked="data.item.isClosedAPI === 'Y'"
              class="custom-control-primary d-inline"
              name="check-button"
              @change="updateValue('isClosedAPI', $event, data.item)"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
        <template #cell(edit)="data">
          <div class="d-flex justify-content-center" style="">
            <b-button
              class=""
              size="sm"
              variant="outline-success"
              type="submit"
              style="margin-right: 10px; width: 55px"
              @click="submit('upsertGameSetMatchBettingCustom', data.item)"
            >
              적용
            </b-button>
            <b-button
              class=""
              size="sm"
              variant="outline-secondary"
              type="submit"
              style=" width: 55px"
              @click="submit('upsertGameSetMatchBettingCustomCancel', data.item)"
            >
              취소
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BFormGroup,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckboxGroup,
  BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_MATCH_BETTING_CUSTOM } from '@/store/game/action'
import { UPSERT_GAME_SET_MATCH_BETTING_CUSTOM } from '@/store/game/mutation'

import vSelect from 'vue-select'
import router from '@/router'
import Swal from "sweetalert2";

const gameStore = createNamespacedHelpers('gameStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTable,
    BPagination,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    VBTooltip,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      connectedUsers: [],
      selectedButton: '1',
      sports: 'soccer',
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Asian Handicap (Games)', text: '핸디캡' },
        { value: 'Maps Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '오버언더' },
        { value: 'Over/Under', text: '오버언더' },
        { value: 'Over/Under by Games in Match', text: '오버언더' },
        { value: 'Total Maps', text: '오버언더' },
      ],
      socket: null,
      perPage: 50,
      pageOptions: [50, 100, 200],
      sortBy: '@id',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      fields: [
        { key: 'index', sortable: false, label: 'No.' },
        { key: 'gameId', sortable: false, label: 'GAME ID' },
        { key: 'startDate', sortable: false, label: '경기시간' },
        // { key: 'leagueId', sortable: false, label: '리그 ID' },
        { key: 'leagueName', sortable: false, label: '리그' },
        { key: 'homeName', sortable: false, label: '홈' },
        { key: 'Home', label: '' },
        { key: 'Draw', label: '기준' },
        { key: 'Away', label: '' },
        { key: 'awayName', sortable: false, label: '원정' },
        { key: 'oddsType', label: '유형' },
        { key: 'isStopAPI', sortable: false, label: 'API 정지' },
        { key: 'isHideAPI', sortable: false, label: '비노출' },
        { key: 'isClosedAPI', sortable: false, label: '마감처리' },
        { key: 'edit', sortable: false, label: '설정' },
      ],
      orgData: [],
      transformedData: [],
      filterByToday: true,
      isLoading: false,
      updateData: [],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...gameStore.mapGetters({
      fetchGameSetMatchBettingCustom: 'fetchGameSetMatchBettingCustom',
      upsertGameSetMatchBettingCustom: 'upsertGameSetMatchBettingCustom',
    }),
    oddsTypeText() {
      return value => {
        const option = this.option3.find(option => option.value === value)
        return option ? option.text : value
      }
    },
  },
  created() {
  },
  watch: {
  },
  mounted() {
    this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetMatchBettingCustom: FETCH_GAME_SET_MATCH_BETTING_CUSTOM,
      $upsertGameSetMatchBettingCustom: UPSERT_GAME_SET_MATCH_BETTING_CUSTOM,
    }),
    async fetchData() {
      this.transformedData = []
      await this.$socket.emit('getPrematchFirst', {
        user: this.siteSelected,
        userid: this.userData.userid,
        sports: this.sports,
      }, async data => {
        try {
          if (data) {
            const mySite = this.siteSelected
            await this.$fetchGameSetMatchBettingCustom({
              site: mySite,
              sports: this.sports,
            })

            this.orgData = JSON.parse(data)
            await this.fetchGames()
          } else {
            console.error('데이터를 받지 못했습니다')
          }
        } catch (error) {
          console.error('데이터 파싱 오류: ', error)
        }
      })
    },
    async menuSelect(sports, index) {
      this.sports = sports
      this.selectedButton = index
      this.updateData = {}
      await this.fetchData(sports)
    },
    async fetchGames() {
      // 배열 해체 (Array Destructuring)
      [this.leagues, this.games] = this.orgData
      this.isLoading = false

      this.transformedData = this.orgData[1].flatMap(event => event.odds.map(odd => {
        const participants = odd.participants.reduce((acc, participant) => {
          acc[participant.name] = {
            value: participant.value_eu,
            handicap: participant.handicap || '',
            name: participant.name || '',
          }
          return acc
        }, {})

        return {
          sport: event.sport,
          gameId: event.eventId,
          leagueId: event.leagueId,
          leagueName: event.leagueName,
          leagueNameCustom: event.leagueNameCustom,
          homeName: event.homeName,
          homeNameCustom: event.homeNameCustom,
          awayName: event.awayName,
          awayNameCustom: event.awayNameCustom,
          startDate: event.startDate,
          startTime: event.startTime,
          oddsType: odd.name,
          oddsId: odd.id,
          ...participants,
        }
      })).filter(item => item) // 필터링을 통해 undefined 항목 제거

      // 기존 데이터가 정리된 후에 fetchGameSetMatchBettingCustom 데이터 병합
      this.transformedData.forEach(item => {
        const customData = this.fetchGameSetMatchBettingCustom.find(data =>
            data.site === this.siteSelected &&
            data.gameId === item.gameId &&
            data.menuName === 'Sports-Prematch' &&
            data.itemName === this.sports &&
            data.oddsTypeId === item.oddsId,
        )
        if (customData) {
          const shouldUpdateValues = ['Y', 'Y', 'Y'].includes(customData.isStopAPI) ||
              ['Y', 'Y', 'Y'].includes(customData.isHideAPI) ||
              ['Y', 'Y', 'Y'].includes(customData.isClosedAPI);

          if (shouldUpdateValues) {
            if (item.Home) {
              item.Home.value = customData.oddsAddHome
            } else if (item.Over) {
              item.Over.value = customData.oddsAddHome
            }
            if (item.Away) {
              item.Away.value = customData.oddsAddAway
            } else if (item.Under) {
              item.Under.value = customData.oddsAddAway
            }
            if (item.Draw) {
              item.Draw.value = customData.oddsAddDraw
            } else if (item.Home) {
              item.Home.handicap = customData.handicap
            } else if (item.Over) {
              item.Over.handicap = customData.handicap
            }
            item.isStopAPI = customData.isStopAPI
            item.isHideAPI = customData.isHideAPI
            item.isClosedAPI = customData.isClosedAPI
          }
        }
      })
      this.totalRows = this.transformedData.length
      // console.log('data : ', this.transformedData)
    },
    async submit(target, rowData) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target, rowData)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, rowData) {
      try {
        if (target === 'upsertGameSetMatchBettingCustom') {
          // Find matching updateData object
          const matchingData = this.updateData.find(item => item.gameId === rowData.gameId && item.oddsTypeId === rowData.oddsId)
          console.log('matchingData :::', matchingData)
          if (matchingData) {
            await this.$upsertGameSetMatchBettingCustom({
              ...matchingData,
            })
          }
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onRowSelected(items) {
      this.selected = items
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    rowClickHandler(record) {
    },
    getHomeValue(item) {
      return item.Home ? item.Home.value : item.Over.value
    },
    getDrawValue(item) {
      return item.Draw ? item.Draw.value : (item.Home ? item.Home.handicap : item.Over.handicap)
    },
    getAwayValue(item) {
      return item.Away ? item.Away.value : item.Under.value
    },
    updateValue(target, newValue, data) {
      // 각 필드의 초기값 설정
      const mySite = this.siteSelected
      let oddsAddHome
      let oddsAddDraw
      let oddsAddAway
      let handicap
      let isStopAPI
      let isHideAPI
      let isClosedAPI

      const existingDataIdx = this.updateData.findIndex(item =>
          item.site === mySite &&
          item.menuName === 'Sports-Prematch' &&
          item.gameId === data.gameId &&
          item.oddsTypeId === data.oddsId)
      if (existingDataIdx !== -1) {
        const existingData = this.updateData[existingDataIdx]
        oddsAddHome = existingData.oddsAddHome
        oddsAddDraw = existingData.oddsAddDraw
        oddsAddAway = existingData.oddsAddAway
        handicap = existingData.handicap
        isStopAPI = existingData.isStopAPI
        isHideAPI = existingData.isHideAPI
        isClosedAPI = existingData.isClosedAPI
      } else {
        oddsAddHome = data.Home ? data.Home.value : (data.Over ? data.Over.value : '')
        oddsAddDraw = data.Draw ? data.Draw.value : ''
        oddsAddAway = data.Away ? data.Away.value : (data.Under ? data.Under.value : '')
        handicap = (data.Home && data.Home.handicap) ? data.Home.handicap : ((data.Over && data.Over.handicap) ? data.Over.handicap : '')
        isStopAPI = data.isStopAPI
        isHideAPI = data.isHideAPI
        isClosedAPI = data.isClosedAPI
      }

      let oddsChanged = false

      if (target === 'Home' && oddsAddHome !== newValue) {
        oddsAddHome = newValue
        oddsChanged = true
      }
      if (target === 'Away' && oddsAddAway !== newValue) {
        oddsAddAway = newValue
        oddsChanged = true
      }
      if (target === 'Draw') {
        if (data.Home && data.Home.handicap && handicap !== newValue) {
          handicap = newValue
          oddsChanged = true
        } else if (data.Over && data.Over.handicap && handicap !== newValue) {
          handicap = newValue
          oddsChanged = true
        } else if (oddsAddDraw !== newValue) {
          oddsAddDraw = newValue
          oddsChanged = true
        }
      }
      if (target === 'isStopAPI') { isStopAPI = newValue === true ? 'Y' : 'N' }
      if (target === 'isHideAPI') { isHideAPI = newValue === true ? 'Y' : 'N' }
      if (target === 'isClosedAPI') { isClosedAPI = newValue === true ? 'Y' : 'N' }

      // 만약 odds가 변경되었고 isStopAPI가 undefined이면 isStopAPI를 'Y'로 설정
      if (oddsChanged && (isStopAPI === undefined)) {
        isStopAPI = 'Y'
      }

      // 데이터 구성
      const updatedData = {
        site: mySite, // 사이트 이름
        menuName: 'Sports-Prematch', // 메뉴 이름 예시
        itemName: data.sport, // 스포츠 종목명
        gameId: data.gameId, // 게임 ID
        gameDate: `${data.startDate} ${data.startTime}`, // 게임 날짜 및 시간
        oddsTypeId: data.oddsId, // 배당 유형 ID
        oddsTypeName: data.oddsType, // 배당 유형 이름
        leagueName: data.leagueName, // 리그 이름
        homeTeamName: data.homeName, // 홈 팀 이름
        awayTeamName: data.awayName, // 원정 팀 이름
        oddsAddHome: oddsAddHome, // 홈 팀 배당 추가 값
        oddsAddDraw: oddsAddDraw, // 무승부 배당 추가 값
        oddsAddAway: oddsAddAway, // 원정 팀 배당 추가 값
        handicap: handicap, // 핸디캡
        isStopAPI: isStopAPI || 'N', // API 중지 여부 (기본값 'N')
        isHideAPI: isHideAPI || 'N', // API 숨김 여부 (기본값 'N')
        isClosedAPI: isClosedAPI || 'N', // API 닫힘 여부 (기본값 'N')
      }

      if (existingDataIdx !== -1) {
        // 기존 데이터를 업데이트
        this.updateData.splice(existingDataIdx, 1, updatedData)
      } else {
        // 새로운 데이터를 추가
        this.updateData.push(updatedData)
      }
      // console.log(this.updateData)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
