<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="font-small-3"
            label="기준 사이트"
            label-for="site-select"
            style="max-width: 500px"
          >
            <b-form-select
              id="site-select"
              v-model="siteSelected"
              :options="authSite"
              class="font-small-3"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        카지노 벤더 설정
        <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
        >
          {{ totalRows }}건
        </b-badge>
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <div class="m-1">
        <!-- Per Page -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="3" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="9" md="6" sm="12" class="mb-sm-1 d-flex justify-content-between align-items-center">
            <div class="d-inline-flex">
              <b-form-group class="mb-0">
                <b-form-checkbox
                  v-model="filterByUseVendor"
                  inline
                  @change="fetchData"
                >
                  <div class="font-small-3">
                    사용중인 유형만 보기
                  </div>
                </b-form-checkbox>
              </b-form-group>
            </div>
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="6" class="ml-auto">
            <b-form-group class="mb-0">
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive
        show-empty
        hover
        small
        striped
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchGameSetVendor"
        :fields="fields"
        :filter="filter"
        style="text-align: center !important; font-size: small"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :current-page="currentPage"
        @filtered="onFiltered"
      >
        <template #cell(index)="data">
          <div
            class=""
            style="min-width: 1rem"
          >
            {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
          </div>
        </template>
        <template #cell(category)="data">
          <div
            class="text-info"
            style="min-width: 5rem"
          >
            {{ changeText(data.item.category) }}
          </div>
        </template>
        <template #cell(vendorName)="data">
          <div
            class=""
            style="min-width: 4rem"
          >
            {{ data.item.vendorName }}
          </div>
        </template>
        <template #cell(vendorNameCustom)="data">
          <div
            class=""
            style="min-width: 5rem"
          >
            {{ data.item.vendorNameCustom }}
          </div>
        </template>
        <template #cell(useYn)="data">
          <div
            size="sm"
            style=""
          >
            <b-form-checkbox
              :checked="data.item.useYn === 'Y'"
              class="custom-control-primary d-inline"
              name="check-button"
              @change="updateUseYn($event, data.item)"
              switch
            >
              <span class="switch-icon-left">
                <feather-icon icon="CheckIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="XIcon" />
              </span>
            </b-form-checkbox>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-col
        id="basic1-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="w-auto content-right"
          size="sm"
          @click="submit('updateGameSetVendor')"
        >
          저장
        </b-button>
      </b-col>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormCheckbox,
  BButton,
  BBadge,
  BTable,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BFormSelect,
  BFormGroup,
  BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import {FETCH_GAME_SET_VENDOR} from '@/store/game/action'
import {UPDATE_GAME_SET_VENDOR} from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabCasinoSoltUseYn',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BButton,
    BBadge,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  // props: {
  //   site: {
  //     type: String,
  //     required: true,
  //   },
  // },
  data() {
    return {
      perPage: 100,
      pageOptions: [50, 100, 500],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      currentPage: 1,
      totalRows: 1,
      filterByUseVendor: true,
      option1: [
        { value: 'card', text: '카드' },
        { value: 'slot', text: '슬롯' },
      ],
      fields: [
        {
          key: 'index', sortable: false, label: 'No.',
        },
        // {
        //   key: 'site', sortable: false, label: '사이트',
        // },
        {
          key: 'category', sortable: false, label: '카테고리',
        },
        {
          key: 'vendorName', sortable: false, label: '벤더명',
        },
        {
          key: 'vendorNameCustom', sortable: false, label: '벤더명(커스텀)',
        },
        {
          key: 'useYn', sortable: false, label: '사용여부',
        },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected: {
      get() {
        return this.$store.state.rootStore.siteSelected
      },
      set(value) {
        this.$store.commit('rootStore/SET_SITE_SELECTED', value)
        this.fetchData()
      },
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...gameStore.mapGetters({
      fetchGameSetVendor: 'fetchGameSetVendor',
      updateGameSetVendor: 'updateGameSetVendor',
    }),
    // categoryText() {
    //   return value => {
    //     const option = this.option2.find(option => option.value === value)
    //     return option ? option.text : value
    //   }
    // },
  },
  created() {
  },
  watch: {

  },
  mounted() {
    this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetVendor: FETCH_GAME_SET_VENDOR,
      $updateGameSetVendor: UPDATE_GAME_SET_VENDOR,
    }),
    updateParam(t, f, e, v, i) {
      const events = {
        t, f, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateManyVendorParam', events)
    },
    // async menuSelect(sports, index) {
    //   this.selectSports = sports
    //   this.selectedButton = index
    //   await this.fetchData()
    // },
    async fetchData() {
      const mySite = this.siteSelected
      console.log('mySite:::', mySite)
      console.log('filterByUseVendor:::', this.filterByUseVendor)
      await this.$fetchGameSetVendor({
        site: mySite,
        useYn: this.filterByUseVendor,
      })
      this.totalRows = this.fetchGameSetVendor.length
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.siteSelected
      try {
        if (target === 'updateGameSetVendor') {
          await this.$updateGameSetVendor({
            site: mySite,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    updateUseYn(event, item) {
      // Get the new value from event
      // console.log(item)
      const newValue = event === true ? 'Y' : 'N'
      this.$set(item, 'useYn', newValue)
      // console.log(newValue)
      // console.log(item.useYn)
      this.updateParam('updateGameSetVendor', 'fetchGameSetVendor', 'useYn', newValue, item)
    },
    changeText(text) {
      const option = this.option1.find(option => option.value === text)
      return option ? option.text : text
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
