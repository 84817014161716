<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      @shown="btnModal('show')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          총판 출금내역
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.prevent="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>

        <div>
          <!-- Table Fitler -->
          <div class="mb-2">
            <!-- Search Condition -->
            <b-row>
              <b-col sm="3">
                <b-form-group
                  class="font-small-3 mt-lg-1"
                  label="상태"
                  label-for="message-select"
                  label-align-sm="2"
                  content-cols-sm="6"
                >
                  <b-form-select
                    id="messages-site"
                    ref="messages-site"
                    v-model="selected1"
                    :options="options1"
                    class="font-small-3"
                    size="sm"
                  >
                    <template
                      v-if="true"
                      #first
                    >
                      <b-form-select-option :value="null">
                        <!-- 선택 -->
                        전체
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                offset-sm="3"
                sm="6"
              >
                <b-form-group
                  class="font-small-3"
                  label-align-sm="3"
                  label="발신일자"
                  label-for="message-period"
                >
                  <b-input-group
                    id="message-period"
                    class="calendar-size font-small-3"
                  >
                    <b-form-datepicker
                      v-model="periodFrom"
                      class=""
                      boundary="window"
                      locale="ko"
                      placeholder="시작일"
                      aria-controls="example-input"
                      size="sm"
                      :max="periodTo"
                    />
                    <div class="pl-1 pr-1">
                      ~
                    </div>
                    <b-form-datepicker
                      v-model="periodTo"
                      class=""
                      boundary="window"
                      locale="ko"
                      placeholder="종료일"
                      aria-controls="example-input"
                      size="sm"
                      :min="periodFrom"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="3">
                <b-form-group
                  class="font-small-3"
                  label="show"
                  label-for="message-select"
                  label-align-sm="2"
                  content-cols-sm="6"
                >
                  <b-form-select
                    v-model="perPage"
                    :options="perPageOptions"
                    size="sm"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>
              <b-col
                offset-sm="3"
                sm="6"
              >
                <b-form-group class="mb-0 pr-1">
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="searchFilter"
                      type="search"
                      placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!searchFilter"
                        @click="searchFilter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!-- table content -->
          <div>
            <b-table
              ref="messagesSendHistoyTable"
              responsive
              show-empty
              hover
              empty-text="No matching records found"
              style="text-align: center; font-size: small"
              :items="paginatedItems"
              :fields="fields"
            >
              <template #cell(idx)="data">
                <div
                  size="sm"
                  style="text-align: center; min-width: 2rem"
                >
                  {{ data.item.idx }}
                </div>
              </template>
              <template #cell(requestDate)="data">
                <div
                  size="sm"
                  style="text-align: center; min-width: 9rem"
                >
                  {{ data.item.requestDate | formatDate('fmt4') }}
                </div>
              </template>
              <template #cell(requestType)="data">
                <div
                  size="sm"
                  style="text-align: center; min-width: 2rem"
                >
                  {{ data.item.requestType }}
                </div>
              </template>
              <template #cell(requestCash)="data">
                <div
                  size="sm"
                  style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
                >
                  {{ Number(data.item.requestCash).toLocaleString() }}
                </div>
              </template>
              <template #cell(requestName)="data">
                <div
                  size="sm"
                  style="text-align: center; min-width: 4rem"
                >
                  {{ data.item.requestName }}
                </div>
              </template>
              <template #cell(accountBank)="data">
                <div
                  size="sm"
                  style="text-align: center; min-width: 4rem; color:rgb(196,186,138)"
                >
                  {{ data.item.accountBank }}
                </div>
              </template>
              <template #cell(accountNumber)="data">
                <div
                  size="sm"
                  style="text-align: center; min-width: 10rem; color:rgb(196,186,138)"
                >
                  {{ data.item.accountNumber }}
                </div>
              </template>
              <template #cell(confirmAt)="data">
                <div
                  size="sm"
                  style="{ textAlign: 'center', minWidth: '9rem' }"
                >
                  {{ data.item.confirmAt | formatDate('fmt4') }}
                </div>
              </template>
              <template #cell(confirmed)="data">
                <div
                  size="sm"
                  :style="{ ...cashOutConfirmedVariant(data.item.confirmed).style,
                            textAlign: 'center',
                            minWidth: '5rem' }"
                >
                  {{ cashOutConfirmedVariant(data.item.confirmed).label }}
                </div>
              </template>
            </b-table>
          </div>
          <!-- table footer -->
          <div>
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-left justify-content-left justify-content-sm-start pl-4"
                style="margin-bottom: 0.5rem;"
              >
                <span
                  class="font-small-2"
                >{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }}
                </span>
              </b-col>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>

        <b-row
          v-if="false"
        >
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              size="sm"
              class="float-right"
              @click.prevent="btnSave"
            >
              저장
            </b-button>
            <b-button
              ref="reset"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="reset"
              variant="primary"
              size="sm"
              class="float-right mr-2"
              @click.prevent="btnModal('close')"
            >
              취소
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <div>
        <!-- <p>fetchRequestAlarmsBranchCashoutFinish: {{ fetchRequestAlarmsBranchCashoutFinish.length }} </p> -->
        <!-- <p>paginatedItems: {{ paginatedItems.length }} </p> -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard, BRow, BCol, BButton,
  BForm, BFormGroup,
  BFormInput, BInputGroupAppend, BInputGroup,
  BFormSelect, BFormSelectOption,
  BTable, BPagination,
  BFormDatepicker,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH } from '@/store/notice/action'

import { createNamespacedHelpers } from 'vuex'
import * as moment from 'moment-timezone'

const noticeStore = createNamespacedHelpers('noticeStore')

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
// const fmt2 = 'YYYYMMDD'
// const fmt3 = 'YYYY-MM-DD'

export default {
  name: 'BranchCashOutBalance',
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormSelectOption,
    BTable,
    BPagination,
    BFormDatepicker,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  filters: {
    truncateText(value, maxLength) {
      if (!value) return
      if (value.length > maxLength) {
        return `${value.slice(0, maxLength)}...`
      }
      return value
    },
    formatDate(value, formatType) {
      // value를 moment 객체로 변환
      const dateObject = moment(value)

      // 포맷 유형에 대한 리터럴 객체
      const formats = {
        fmt1: 'YYYYMMDD',
        fmt2: 'YYYY-MM-DD',
        fmt3: 'YYYY-MM-DD HH:mm',
        fmt4: 'YY.MM.DD HH:mm',
      }

      // 날짜를 원하는 형식으로 포맷
      const formattedDate = dateObject.format(formats[formatType] || formats.fmt1)
      return formattedDate
    },
    formatAmount(value) {
      // 금액을 형식화하는 커스텀 필터
      // type1: value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' 원'; // 단위(천)표시 / 소수점 2자리 / "원"을 추가
      // type2: value.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // 단위(천)표시
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      // userIp: localStorage.getItem('ip'),

      selectConfirmed: null,

      // TableFilter
      selected1: null,
      options1: [
        { value: 'CONFIRMED', text: '승인완료' },
        { value: 'REJECT', text: '출금부결' },
        { value: 'CANCELED', text: '출금취소' },
      ],
      searchFilter: '',
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),

      // TableOption
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1, // 현재 페이지
      perPage: 10, // 페이지 당 표시할 항목 수
      pageFrom: 0,
      pageTo: 0,
      pageOf: 0,
      totalRows: 0, // 총 메시지 수 (서버에서 얻을 수도 있습니다)

      // Tablefields
      fields: [
        { key: 'idx', sortable: false, label: 'No.', thClass: 'col1' },
        { key: 'requestDate', sortable: false, label: '신청일시', thClass: 'col3' },
        { key: 'requestType', sortable: false, label: '타입', thClass: 'col3' },
        { key: 'requestCash', sortable: false, label: '신청금액', thClass: 'col3' },
        { key: 'requestName', sortable: false, label: '입금자 명', thClass: 'col3' },
        { key: 'accountBank', sortable: false, label: '입금 계좌', thClass: 'col3' },
        { key: 'accountNumber', sortable: false, label: '계좌 번호', thClass: 'col3' },
        { key: 'confirmAt', sortable: false, label: '승인일시', thClass: 'col3' },
        { key: 'confirmed', sortable: false, label: '상태', thClass: 'col6' },
      ],

    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...noticeStore.mapGetters({
      fetchRequestAlarmsBranchCashoutFinish: 'fetchRequestAlarmsBranchCashoutFinish',
    }),
    // 검색필터된 Items
    filteredItems() {
      let filteredData = this.fetchRequestAlarmsBranchCashoutFinish
      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }
      return filteredData
    },
    // 검색필터된 Items => 페이징처리된 Item
    paginatedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      const paginatedData = this.filteredItems.slice(start, end)

      this.totalRows = this.filteredItems.length
      this.pageFrom = start + 1
      this.pageTo = start + paginatedData.length
      this.pageOf = this.totalRows

      return paginatedData
    },

  },
  watch: {
    selected1() {
      this.fetchData()
    },
    periodFrom() {
      this.fetchData()
    },
    periodTo() {
      this.fetchData()
    },

  },
  created() {
    // console.log(this.componentName, 'created()')
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    this.fetchData()
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsBranchCashoutFinish: FETCH_REQUEST_ALARMS_BRANCH_CASHOUT_FINISH,
    }),
    init() {
      console.log(this.componentName, 'init()')
    },

    async fetchData() {
      await this.$fetchRequestAlarmsBranchCashoutFinish({
        site: this.site,
        requestId: this.userData.userid,
        ...(this.selected1 !== null && { confirmed: this.selected1 }),
        periodFrom: this.periodFrom,
        periodTo: this.periodTo,
      })
    },

    // btnSave
    async btnSave() {
      console.log(this.componentName, 'btnSave()')
    },
    btnReset() {
      console.log(this.componentName, 'btnReset()')
      this.init()
    },

    // Modal
    btnModal(target) {
      if (target === 'show') {
        console.log(this.componentName, 'btnModal()', target)
        this.fetchData()
      }
      if (target === 'close') {
        console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
      }
    },

    // fnc
    /* Table Variant */
    cashOutConfirmedVariant(data) {
      return {
        CONFIRMED: { value: 'CONFIRMED', label: '출금승인', style: { color: '#28c76f' } },
        REJECT: { value: 'REJECT', label: '출금부결', style: { color: 'red' } },
        CANCELED: { value: 'CANCELED', label: '출금취소', style: { color: 'yellow' } },
      }[data]
    },
  },

}
</script>

<style>
  .modal-dialog {
    max-width: 90rem;
  }
</style>
