<template>
  <div>
    <b-card style="margin-bottom: -1rem">
      <h4 class="">
        <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
        />
        게임 메뉴설정
        <b-badge
          variant="primary"
          style="font-size: xx-small"
        >
          DB 연계
        </b-badge>
      </h4>
      <hr>
      <b-table
        responsive
        show-empty
        small
        empty-text="No matching records found"
        :items="fetchGameSetMenu"
        :fields="fields"
        style="text-align: center !important; font-size: small"
      >
        <template #cell(index)="data">
          <div
            class=""
            style="min-width: 1rem"
          >
            {{ data.index + 1 }}
          </div>
        </template>
        <template #cell(id)="data">
          <div
            class=""
            style="min-width: 4rem"
          >
            {{ data.item.id }}
          </div>
        </template>
        <template #cell(type)="data">
          <div
            class=""
            style="min-width: 5rem"
          >
            {{ data.item.type }}
          </div>
        </template>
        <template #cell(name)="data">
          <div
            class="text-info"
            style="text-align: left; min-width: 5rem"
          >
            {{ data.item.name }}
          </div>
        </template>
        <template #cell(nameCustom)="data">
          <b-form-input
            :value="data.item.nameCustom"
            style="text-align: left; min-width: 7rem; height: 2rem; color:rgb(229,166,48); font-size: small"
            @change="updateParam('fetchGameSetMenu' ,'nameCustom', $event, data.index)"
          >
          </b-form-input>
        </template>
        <template #cell(minBet)="data">
          <b-form-input
            id="minBet"
            :disabled="data.item.type !== 'sports'"
            :value="data.item.minBet | formatNumber"
            type="text"
            size="sm"
            class="center"
            style="text-align: right; color:rgb(229,166,48); min-width: 6rem"
            @change="updateParam('fetchGameSetMenu','minBet', $event, data.index)"
          />
        </template>
        <template #cell(maxBet)="data">
          <b-form-input
            id="maxBet"
            :disabled="data.item.type !== 'sports'"
            :value="data.item.maxBet| formatNumber"
            type="text"
            size="sm"
            class="center"
            style="text-align: right; color:rgb(229,166,48); min-width: 6rem"
            @change="updateParam('fetchGameSetMenu','maxBet', $event, data.index)"
          />
        </template>
        <template #cell(maxWin)="data">
          <b-form-input
            id="maxWin"
            :disabled="data.item.type !== 'sports'"
            :value="data.item.maxWin| formatNumber"
            type="text"
            size="sm"
            class="center"
            style="text-align: right; color:rgb(229,166,48); min-width: 6rem"
            @change="updateParam('fetchGameSetMenu','maxWin', $event, data.index)"
          />
        </template>
        <template #cell(maxFold)="data">
          <div class="maxFold d-inline-flex">
            <b-form-input
              id="foldFrom"
              :disabled="data.item.type !== 'sports'"
              :value="data.item.foldFrom"
              type="number"
              size="sm"
              class="center"
              style="text-align: right; color:rgb(229,166,48); width: 4rem"
              @change="updateParam('fetchGameSetMenu','foldFrom', $event, data.index)"
            />
            <div class="pl-1 pr-1" style="align-self: center"> ~ </div>
            <b-form-input
              id="foldTo"
              :disabled="data.item.type !== 'sports'"
              :value="data.item.foldTo"
              type="number"
              size="sm"
              class="center"
              style="text-align: right; color:rgb(229,166,48); width: 4rem"
              @change="updateParam('fetchGameSetMenu','foldTo', $event, data.index)"
            />
          </div>
        </template>
        <template #cell(useYn)="data">
          <b-form-select
            id="useYn"
            :disabled="data.item.type !== 'sports'"
            :value="data.item.useYn"
            style="text-align: center; color:rgb(229,166,48); min-width: 8rem"
            size="sm"
            class="center"
            :options="options1"
            @change="updateParam('fetchGameSetMenu','useYn', $event, data.index)"
          />
        </template>
        <template #cell(menuUseYn)="data">
          <b-form-select
            id="menuUseYn"
            :value="data.item.menuUseYn"
            style="text-align: center; color:rgb(229,166,48); min-width: 7rem"
            size="sm"
            class="center"
            :options="options1"
            @change="updateParam('fetchGameSetMenu','menuUseYn', $event, data.index)"
          />
        </template>
      </b-table>
      <b-col
        id="save-btn"
        class="mt-1"
        cols="12"
      >
        <b-button
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="w-auto content-right"
          size="sm"
          @click="submit('updateGameSetMenu')"
        >
          저장
        </b-button>
      </b-col>
<!--      {{ site }}-->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BButton,
  BBadge,
  BTable,
  BCol,
  BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_GAME_SET_MENU } from '@/store/game/action'
import { UPDATE_GAME_SET_MENU } from '@/store/game/mutation'

const gameStore = createNamespacedHelpers('gameStore')

export default {
  name: 'GameSettingsTabMenu',
  components: {
    BCard,
    BFormInput,
    BButton,
    BBadge,
    BTable,
    BCol,
    BFormSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    site: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      options1: [
        { value: 'N', text: 'N' },
        { value: 'Y', text: 'Y' },
      ],
      fields: [
        {
          key: 'index', sortable: false, label: 'No.',
        },
        {
          key: 'id', sortable: false, label: 'Code',
        },
        {
          key: 'type', sortable: false, label: '타입',
        },
        {
          key: 'name', sortable: false, label: '메뉴명',
        },
        {
          key: 'nameCustom', sortable: false, label: '메뉴명(변경)',
        },
        {
          key: 'minBet', sortable: false, label: '최소배팅금',
        },
        {
          key: 'maxBet', sortable: false, label: '최대배팅금',
        },
        {
          key: 'maxWin', sortable: false, label: '최대당첨금',
        },
        {
          key: 'maxFold', sortable: false, label: '배팅가능폴더',
        },
        {
          key: 'useYn', sortable: false, label: '금액,폴더 사용여부',
        },
        {
          key: 'menuUseYn', sortable: false, label: '메뉴 사용여부',
        },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...gameStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
      updateGameSetMenu: 'updateGameSetMenu',
    }),
  },
  created() {
  },
  watch: {
    site: {
      handler(event) {
        this.fetchData()
        // localStorage.setItem('siteSelected', event)
        // this.$store.commit('footerStore/setSelectedSiteIndex', this.authSite.indexOf(event))
      },
      immediate: false,
      deep: false,
    },
  },
  mounted() {
    // this.fetchData()
  },
  setup() {
  },
  methods: {
    ...gameStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
      $updateGameSetMenu: UPDATE_GAME_SET_MENU,
    }),
    updateParam(t, e, v, i) {
      // console.log(t)
      // console.log(f)
      // console.log(e)
      // console.log(v)
      // console.log(i)
      const events = {
        t, e, v, i,
      }
      // store에 param을 update 한다
      this.$store.commit('gameStore/updateManyParam', events)
    },
    async fetchData() {
      const mySite = this.site
      await this.$fetchGameSetMenu({
        site: mySite,
      })
      // console.log(this.fetchGameSetMenu)
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.site
      try {
        if (target === 'updateGameSetMenu') {
          await this.$updateGameSetMenu({
            site: mySite,
            userid: this.userData.userid,
            userIp: this.userIp,
            // store의 updated 된 param을 사용한다.
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          this.fetchData()
        })
      }
    },
    formatNumber(value) {
      return Number(value).toLocaleString()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
