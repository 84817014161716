<template xmlns="http://www.w3.org/1999/html">

  <b-card>
    <span class="pl-1 row justify-content-between">
      <h3 class="">팝업 설정</h3>
    </span>
    <hr class="pb-1">
    <b-form @submit.prevent>
      <b-row>
        <b-col
          cols="1"
          style="padding-top: 0.3rem; padding-bottom: 1.5rem"
        >
          <b-card-text
            class="font-small-3"
            style="color: #d0d2d6"
          >
            사용여부
          </b-card-text>
        </b-col>
        <b-col style="padding-top: 0.3rem">
          <b-form-radio-group
            id="event-active3"
            v-model="detailPopupActive"
            value-field="value"
            text-field="text"
            :options="options1"
          />
        </b-col>
        <b-col cols="6" />
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="제목"
            label-for="popup-title"
            label-cols-md="2"
          >
            <b-form-input
              id="popup-title"
              v-model="detailPopupTitle"
              class="font-small-3"
              size="sm"
              placeholder="제목"
              style="color:rgb(229,166,48)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6" />
        <!-- datepicker -->
        <b-col cols="6">
          <b-form-group
            class="font-small-3"
            label="게시기간"
            label-for="event-period"
            label-cols-md="2"
          >
            <b-input-group
              class="calendar-size font-small-3"
              style="padding-top: 0.5rem"
            >
              <b-form-input
                id="event-fisrst-period-from"
                v-model="detailPopupEventFirstPeriodFrom"
                type="text"
                placeholder="시작일"
                autocomplete="off"
                show-decade-nav
                size="sm"
                style="color:rgb(229,166,48)"
              />
              <b-form-datepicker
                v-model="detailPopupEventFirstPeriodFrom"
                show-decade-nav
                button-only
                right
                locale="ko"
                aria-controls="example-input"
                size="sm"
                button-variant="outline-primary"
                style="height: 30px; padding-right: 10px"
                @context="onContext"
              />
              <b-form-input
                id="event-first-period-to"
                v-model="detailPopupEventFirstPeriodTo"
                type="text"
                placeholder="종료일"
                autocomplete="off"
                show-decade-nav
                size="sm"
                style="color:rgb(229,166,48)"
              />
              <b-form-datepicker
                v-model="detailPopupEventFirstPeriodTo"
                show-decade-nav
                button-only
                right
                locale="ko"
                aria-controls="example-input"
                size="sm"
                button-variant="outline-primary"
                style="height: 30px;"
                @context="onContext"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--        <b-col cols="5">-->
        <!--          <b-form-group-->
        <!--            class="font-small-3"-->
        <!--            label="팝업크기(px)"-->
        <!--            label-for="popup-size"-->
        <!--            label-cols-md="4"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="popup-size-width"-->
        <!--              v-model="detailPopupSizeWidth"-->
        <!--              class="font-small-3"-->
        <!--              size="sm"-->
        <!--              placeholder="팝업크기"-->
        <!--              style="color:rgb(229,166,48);"-->
        <!--            />-->
        <!--            <b-form-input-->
        <!--              id="popup-size-height"-->
        <!--              v-model="detailPopupSizeHeight"-->
        <!--              class="font-small-3"-->
        <!--              size="sm"-->
        <!--              placeholder="팝업크기"-->
        <!--              style="color:rgb(229,166,48); margin-top: 0.5rem"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
      </b-row>
    </b-form>
    <div class="mt-2">
      <quill-editor
        v-model="detailPopupContent"
        :options="editorOption"
      />
    </div>
    <hr>
    <div class="row">
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="float-right"
          @click="validationForm('upsertSetNoticePopup')"
        >
          저장
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          size="sm"
          class="float-right mr-1"
          :to="{ name: 'popup-settings' }"
        >
          취소
        </b-button>
      </b-col>
    </div>
  </b-card>

</template>

<script>
import {
  BCard,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { UPSERT_SET_NOTICE_POPUP } from '@/store/notice/mutation'
import { createNamespacedHelpers } from 'vuex'
import router from '@/router'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BRow,
    BCol,
    BFormDatepicker,
    BFormRadioGroup,
    BCardText,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      options1: [
        { value: 'true', text: '사용' },
        { value: 'false', text: '중지' },
      ],
      info: {
        // 팝업설정
        popupActive: 'Y',
        popupTitle: '메인공지 팝업 1차 설정',
        eventFirstPeriodFrom: '2022-09-17',
        eventFirstPeriodTo: '2022-09-22',
        popupSizeWidth: 500,
        popupSizeHeight: 600,
      },
      detailPopupActive: 'true',
      detailPopupTitle: '',
      detailPopupEventFirstPeriodFrom: '',
      detailPopupEventFirstPeriodTo: '',
      detailPopupContent: '',
      editorOption: {
        // debug: 'info',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // <strong>, <em>, <u>, <s>
            ['blockquote', 'code-block'], // <blockquote>, <pre class="ql-syntax" spellcheck="false">
            [{ header: 1 }, { header: 2 }], // <h1>, <h2>
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // <sub>, <sup>
            [{ indent: '-1' }, { indent: '+1' }], // class제어
            [{ direction: 'rtl' }], // class 제어
            [{ size: ['small', false, 'large', 'huge'] }], // class 제어 - html로 되도록 확인
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // <h1>, <h2>, <h3>, <h4>, <h5>, <h6>, normal
            [{ font: [] }], // 글꼴 class로 제어
            [{ color: [] }, { background: [] }], // style="color: rgb(230, 0, 0);", style="background-color: rgb(230, 0, 0);"
            [{ align: [] }], // class
            // ["clean"],
            ['link', 'image', 'video'],
          ],
        },
        placeholder: '글을 입력해 주세요...',
        readOnly: true,
        theme: 'snow',
      },
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
  },
  methods: {
    // methods에는 mapAction, mapMutate를 선언한다
    ...noticeStore.mapActions({
      $upsertSetNoticePopup: UPSERT_SET_NOTICE_POPUP,
    }),
    validationForm(target) {
      if (this.detailPopupTitle === '') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '제목을 입력해주세요',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        this.submit(target)
      }
    },
    async submit(target) {
      await Swal.fire({
        title: '변경내용을 저장하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.confirmYes(target)
        }
      })
    },
    // confirm Yes
    async confirmYes(target) {
      const mySite = this.$route.params.siteSelected
      const adminId = this.userData.userid
      try {
        if (target === 'upsertSetNoticePopup') {
          await this.$upsertSetNoticePopup({
            site: mySite,
            title: this.detailPopupTitle,
            content: this.detailPopupContent,
            activeFrom: this.detailPopupEventFirstPeriodFrom,
            activeTo: this.detailPopupEventFirstPeriodTo,
            active: this.detailPopupActive,
            enrollId: adminId,
            userIp: this.userIp,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          router.replace('/boards/popup-settings')
        })
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formattedFrom = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selectedFrom = ctx.selectedYMD
      // The date formatted in the locale, or the `label-no - date - selected` string
      this.formattedTo = ctx.selectedFormatted
      // The following will be an empty string until a valid date is entered
      this.selectedTo = ctx.selectedYMD
    },
  },
}
</script>

<style scoped>
.ql-toolbar {
  background-color: #e5e2e2 !important;
}
.select-size {
  width: 10rem;
}
.calendar-size {
  width: 21rem;
}
</style>
