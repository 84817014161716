<template>
  <div>
    <b-modal
      id="UserViewUserMessages"
      no-close-on-backdrop
      hide-footer
      centered
      @shown="modalOpen"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          쪽지 발송
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="modalClose"
          />
        </div>
      </template>

      <b-card no-body>
        <validation-observer ref="simpleRules">
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="사이트"
                  label-for="messages-site"
                  label-cols-md="4"
                  label-align="left"
                >  <validation-provider
                  #default="{ errors }"
                  rules="required"
                  mode="passive"
                  name="사이트"
                >
                  <b-form-select
                    v-if="authSite"
                    id="messages-site"
                    ref="messages-site"
                    v-model="siteSelected"
                    :options="authSite"
                    class="font-small-3"
                    size="sm"
                    style="color:rgb(229,166,48) !important; opacity: 0.7;"
                    disabled
                  >
                    <template
                      v-if="false"
                      #first
                    >
                      <b-form-select-option :value="null">
                        선택
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                  <small class="text-danger"> {{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="받는 회원 "
                  label-for="messages-receiver"
                  label-cols-md="4"
                  label-align="left"
                >  <validation-provider
                  #default="{ errors }"
                  rules="required"
                  mode="passive"
                  name="받는 회원"
                >
                  <div>
                    <b-input-group>
                      <b-form-input
                        id="messages-receiver"
                        v-model.lazy="messages.receivers[0]"
                        placeholder="클릭하여 유저검색"
                        class="cursor-pointer font-small-3 custom-placeholder-color"
                        readonly
                        style="color:rgb(229,166,48) !important; opacity: 0.7;"
                      />
                    </b-input-group>
                  </div>
                  <small class="text-danger"> {{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="쪽지 제목"
                  label-for="messages-title"
                  label-cols-md="4"
                  label-align="left"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|between:5,100"
                    mode="passive"
                    name="쪽지 제목"
                  >
                    <b-form-input
                      id="messages-title"
                      v-model.lazy="messages.title"
                      class="font-small-3"
                      size="sm"
                      style="color:rgb(229,166,48)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group
                  class="font-small-3"
                  label="쪽지 내용"
                  label-for="messages-message"
                  label-cols-md="4"
                  label-align="left"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|between:5,200"
                    mode="passive"
                    name="쪽지내용"
                  >
                    <b-form-textarea
                      id="messages-message"
                      v-model="messages.message"
                      class="font-small-3"
                      size="sm"
                      placeholder=""
                      style="color:rgb(229,166,48)"
                      rows="5"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger"> {{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="mt-1"
                cols="12"
              >
                <b-button
                  ref="submit"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  size="sm"
                  class="float-right"
                  @click.prevent="submit"
                >
                  발송
                </b-button>
                <b-button
                  ref="reset"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="reset"
                  variant="primary"
                  size="sm"
                  class="float-right mr-2"
                  @click.prevent="modalClose"
                >
                  취소
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BButton, BFormInput, BInputGroup, BFormSelect, BFormSelectOption, BFormTextarea, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import {
  ValidationProvider, ValidationObserver, localize, extend,
} from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'

import {
  CREATE_MESSAGES,
} from '@/store/messages/mutation'
import { createNamespacedHelpers } from 'vuex'

const messagesStore = createNamespacedHelpers('messagesStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BModal,

    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  props: ['user'],
  data() {
    return {
      // data
      messages: {
        site: '',
        title: '',
        message: '',
        senderId: 'admin',
        // senderIp: localStorage.getItem('ip'),
        senderIp: this.userIp,
        receivers: [],
      },

      // validations
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,

    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
  },
  methods: {
    ...messagesStore.mapActions({
      $createMessages: CREATE_MESSAGES,
    }),
    async submit() {
      // console.log('createMessages', this.messages)
      // validation
      if (!await this.validationForm()) return false

      const result = await this.swalShow('confirm')
      if (result.isConfirmed) {
        try {
          await this.$createMessages({
            messages: {
              ...this.messages,
            },
          })
          this.swalShow('success')
            .then(() => {
              this.modalClose()
            })
        } catch (e) {
          console.log('[Messages] [VUE] [Methods] [Swal] ', e.Messages)
          this.swalShow('error', e.Messages)
        }
      }
    },
    // Validate
    async validationForm() {
      // between 규칙 재정의
      extend('between', {
        validate(value, { min, max }) {
          // value의 길이가 min과 max 사이인지 확인
          return value.length >= min && value.length <= max
        },
        params: ['min', 'max'], // 규칙에 필요한 매개변수
        // Messages: '값은 {min}과 {max} 사이의 길이여야 합니다.' // 오류 메시지
      })
      // messages 규칙 재정의
      localize({
        en: {
          messages: {
            required: '"{_field_}" 값은 필수 항목입니다.',
            between: '"{_field_}" 값은 {min}과 {max} 사이여야 합니다.',
            length: '"{_field_}" 값은 {length}자리여야 합니다.',
            // ... 다른 메시지 추가 가능
          },
        },
      })

      const res = await this.$refs.simpleRules.validate()
      return res
    },
    // Swal
    swalShow(type) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        delete: {
          title: '삭제하시겠습니까?<br>삭제하시면 복구할 수 없습니다!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 message가 있다면 해당 설정을 덮어씁니다.
      // if (Messages) config.text = Messages;

      // Swal을 실행합니다.
      return Swal.fire(config)
    },
    // Modal
    modalOpen() {
      console.log('showModal')

      this.messages.site = this.user.site
      this.messages.receivers = [this.user.userid]
    },
    modalClose() {
      console.log('closeModal')
      this.messages.title = ''
      this.messages.message = ''
      this.$bvModal.hide('UserViewUserMessages')
    },
  },
}
</script>

<style>
.custom-placeholder-color::placeholder {
  color: rgb(229, 166, 48) !important;
}
</style>
