import { ref, watch, computed, onMounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

export default function useUsersList() {
  // console.log('1. useUsersList')

  /* Table Set */
  const refUserListTable = ref(null)

  /* TableOption */
  const perPageOptions = [20, 50, 100]
  const currentPage = ref(1) // 현재 페이지
  const perPage = ref(20) // 페이지 당 표시할 항목 수
  const pageFrom = ref(0)
  const pageTo = ref(0)
  const pageOf = ref(0)
  const totalRows = ref(0) // 총 메시지 수 (서버에서 얻을 수도 있습니다)
  const searchFilter = ref(null)

  /* Search Filter */
  // const siteFilter = ref(localStorage.getItem('siteSelected') || null)
  const siteFilter = ref(store.getters['rootStore/siteSelected'] || null)
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const rankFilter = ref(null)

  /* Select Options */
  // const siteOptions = computed(() => localStorage.getItem('authSite').split(',').map(site => ({
  //   text: site,
  //   value: site,
  // })))
  const siteOptions = computed(() => store.getters['rootStore/authSite'])

  const rankOptions = computed(() => store.getters['settingsStore/fetchSetRank']
    .map(rank => ({
      text: `${rank.rankOrder} :: ${rank.rankName}`,
      value: rank.rankOrder,
    }))
    .concat({ text: '전체', value: null, selected: true })
    .reverse())

  const roleOptions = [
    { text: '전체', value: null, selected: true },
    { text: '어드민(Admin)', value: 'admin' },
    { text: '총판(Branch)', value: 'branch' },
    { text: '회원(User)', value: 'user' },
  ]

  const statusOptions = [
    { text: '전체', value: null },
    { text: '정상회원', value: 'active' },
    { text: '휴먼회원', value: 'human' },
    { text: '차단회원', value: 'block' },
  ]

  // 검색필터된 Items
  const filteredItems = computed(() => {
    // console.log('2. filteredItems computed')
    let filteredData = store.getters['userStore/fetchUsers']

    if (searchFilter.value) {
      filteredData = filteredData.filter(item => Object.values(item).some(val => (typeof val === 'string' && val.toLowerCase().includes(searchFilter.value.toLowerCase()))
        || (typeof val === 'number' && val === searchFilter.value)))
    }

    if (roleFilter.value) {
      filteredData = filteredData.filter(item => roleFilter.value.includes(item.role))
    }

    if (rankFilter.value !== undefined && rankFilter.value !== null) { // 숫자
      filteredData = filteredData.filter(item => rankFilter.value === item.rank)
    }

    if (statusFilter.value) {
      filteredData = filteredData.filter(item => statusFilter.value.includes(item.status))
    }

    // role{ godfather: 0, admin: 1, branch: 2, user: 3 } 기준으로 정렬
    filteredData.sort((a, b) => {
      const order = { godfather: 0, admin: 1, branch: 2, user: 3 }

      const currentUserId = store.getters['rootStore/userData'].userid

      // 자신인 경우 최우선으로 배치
      if (b.userid === currentUserId) return -1
      if (a.userid === currentUserId) return 1

      return order[b.role] - order[a.role] // 내림차순
    })

    return filteredData
  })

  // 검색필터된 Items => 페이징처리된 Items
  const paginatedItems = computed(() => {
    // console.log('2. paginatedItems computed')

    const totalFilteredItems = filteredItems.value.length
    const start = totalFilteredItems - (currentPage.value * perPage.value)
    const end = start + perPage.value

    const paginatedData = filteredItems.value.slice(Math.max(0, start), end).reverse()

    totalRows.value = totalFilteredItems
    pageFrom.value = (currentPage.value - 1) * perPage.value + 1 // 현재 페이지의 첫 번째 항목
    pageTo.value = Math.min(totalRows.value, currentPage.value * perPage.value) // 현재 페이지의 마지막 항목 또는 전체 항목 수 중 작은 값
    pageOf.value = totalRows.value

    return paginatedData
  })

  /* Methods */
  const fetchData = async () => {
    await store.dispatch('userStore/FETCH_USERS', {
      site: siteFilter.value,

      userid: store.getters['rootStore/userData'].userid,
      // userid: JSON.parse(localStorage.getItem('userData')).userid,
    })
  }

  const fetchSetRank = async () => {
    // console.log('4. fetchSetRank', siteFilter.value)
    await store.dispatch('settingsStore/FETCH_SET_RANK', { site: siteFilter.value })
  }

  const rowClicked = async item => {
    // 기존 창에서 열기
    // localStorage.setItem('userView', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }))
    // router.push({ name: 'apps-user-view' })
    // 새로운 창에서 열기
    // localStorage.setItem('userView', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }))
    // this.$store.commit('rootStore/SET_USER_VIEW', JSON.stringify({ userid: item.userid, site: item.site, nickname: item.nickname }), { root: true })

    // URL 쿼리 파라미터로 데이터를 전달
    const queryParams = {
      userid: item.userid,
      site: item.site,
      nickname: item.nickname,
    }
    const url = router.resolve({
      name: 'apps-user-view',
      query: queryParams,
    }).href
    // const url = router.resolve({ name: 'apps-user-view' }).href
    // 새로 고유한 이름을 가진 팝업 창을 열기 위해 현재 시간 사용
    const popupName = `userViewPopup_${new Date().getTime()}`
    const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
    window.open(url, popupName, popupOptions) // 매번 다른 이름으로 팝업 창 열기
  }

  // UI
  const resolveUserRoleVariant = role => {
    if (role === 'user') return 'secondary'
    if (role === 'branch') return 'warning'
    if (role === 'admin') return 'primary'
    if (role === 'godfather') return 'danger'
    return 'secondary'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'additional') return 'info'
    if (status === 'await') return 'info'
    if (status === 'active') return 'success'
    if (status === 'sleep') return 'primary'
    if (status === 'block') return 'primary'
  }

  const convertUserStatus = status => {
    if (status === 'apply') return '1차가입'
    if (status === 'additional') return '2차가입'
    if (status === 'await') return '최종대기'
    if (status === 'active') return '정상'
    if (status === 'sleep') return '휴먼'
    if (status === 'block') return '차단'
  }

  watch(siteFilter, async newValue => {
    // console.log('5. watch', siteFilter.value)
    // 선택된 사이트 필터 값이 변경될 때 호출되는 로직
    store.commit('rootStore/SET_SITE_SELECTED', newValue)
    // localStorage.setItem('siteSelected', newValue)
    // await store.commit('footerStore/setSelectedSiteIndex', localStorage.getItem('authSite').split(',').indexOf(newValue))
    await fetchData()
  })

  onMounted(async () => {
    // console.log('3. onMounted')
    await fetchSetRank()
    await fetchData()
  })

  return {

    /* Table Set */
    refUserListTable,
    rowClicked,

    /* TableOption */
    perPageOptions,
    currentPage,
    perPage,
    pageFrom,
    pageTo,
    pageOf,
    totalRows,
    searchFilter,

    /* Search Filter */
    siteFilter,
    roleFilter,
    statusFilter,
    rankFilter,

    /* Select Options */
    siteOptions,
    roleOptions,
    statusOptions,
    rankOptions,

    /* fetchData */
    filteredItems,
    paginatedItems,

    // UI
    resolveUserRoleVariant,
    resolveUserStatusVariant,
    convertUserStatus,

  }
}
