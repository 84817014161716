export const FETCH_MESSAGES_USER_FROM_ADMIN = 'FETCH_MESSAGES_USER_FROM_ADMIN'
export const FETCH_MESSAGES_USER_TO_ADMIN = 'FETCH_MESSAGES_USER_TO_ADMIN'
export const FETCH_MESSAGES_ADMIN_FROM_USER = 'FETCH_MESSAGES_ADMIN_FROM_USER'
export const FETCH_MESSAGES_ADMIN_TO_USER = 'FETCH_MESSAGES_ADMIN_TO_USER'
export const FETCH_MESSAGES_ADMIN_FROM_MASTER = 'FETCH_MESSAGES_ADMIN_FROM_MASTER'
export const FETCH_MESSAGES_ADMIN_TO_MASTER = 'FETCH_MESSAGES_ADMIN_TO_MASTER'
export const FETCH_MESSAGES_MASTER_FROM_ADMIN = 'FETCH_MESSAGES_MASTER_FROM_ADMIN'
export const FETCH_MESSAGES_MASTER_TO_ADMIN = 'FETCH_MESSAGES_MASTER_TO_ADMIN'
export const FETCH_MESSAGES_CNT = 'FETCH_MESSAGES_CNT'
export const FETCH_MESSAGES_SEND_FORM_SEARCH_USERS = 'FETCH_MESSAGES_SEND_FORM_SEARCH_USERS'
