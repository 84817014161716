<template>
  <div>
    <b-card>
      <b-tabs @input="handleTabChange">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          :title="tab.title"
        >
          <hr>
          <component
            :is="tab.component"
            v-if="tab.isLoaded"
            :title="tab.title"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue'

import UserTabUserExchange from '@/views/apps/user/users-view/users-tab/UserTabUserExchange.vue'
import UserTabUserDeposite from '@/views/apps/user/users-view/users-tab/UserTabUserDeposite.vue'
import UserTabBetHistory from '@/views/apps/user/users-view/users-tab/UserTabBetHistory.vue'
import UserTabBetHistoryCasino from '@/views/apps/user/users-view/users-tab/UserTabBetHistoryCasino.vue'
import UserTabPointHistory from '@/views/apps/user/users-view/users-tab/UserTabPointHistory.vue'
import UserTabUserSigninLog from '@/views/apps/user/users-view/users-tab/UserTabUserSigninLog.vue'
import UserTabUserUpdateLogs from '@/views/apps/user/users-view/users-tab/UserTabUserUpdateLogs.vue'
import UserTabUserMessages from '@/views/apps/user/users-view/users-tab/UserTabUserMessages.vue'
import ZUserTabTemp from '@/views/apps/user/users-view/users-tab/ZUserTabTemp.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,

    UserTabBetHistory, /* 배팅내역 */
    UserTabUserMessages, /* 쪽지함 */
    UserTabUserSigninLog, /* 접속로그 */
  },
  data() {
    return {
      tabs: [
        { title: '충전내역', isLoaded: false, component: UserTabUserDeposite },
        { title: '환전내역', isLoaded: false, component: UserTabUserExchange },
        { title: '배팅내역(스포츠)', isLoaded: false, component: UserTabBetHistory },
        { title: '배팅내역(카지노)', isLoaded: false, component: UserTabBetHistoryCasino },
        { title: '포인트내역', isLoaded: false, component: UserTabPointHistory },
        { title: '변경내역', isLoaded: false, component: UserTabUserUpdateLogs },
        { title: '접속로그', isLoaded: false, component: UserTabUserSigninLog },
        { title: '쪽지함', isLoaded: false, component: UserTabUserMessages },
        { title: '정산관리', isLoaded: false, component: ZUserTabTemp },
      ],
      activeTabIndex: 0, // Store the active tab index
    }
  },
  methods: {
    // handleTabChange(activeTabIndex) {
    //   const activeTab = this.tabs[activeTabIndex]
    //   if (activeTab && !activeTab.isLoaded) {
    //     activeTab.isLoaded = true
    //   }
    // },
    handleTabChange(activeTabIndex) {
      // 이전 탭의 isLoaded 값을 false로 설정
      this.tabs[this.activeTabIndex].isLoaded = false
      // 새로 선택된 탭의 isLoaded 값을 true로 설정
      this.tabs[activeTabIndex].isLoaded = true
      // 현재 활성화된 탭의 인덱스 업데이트
      this.activeTabIndex = activeTabIndex
    },
  },
}
</script>

<style>
</style>
