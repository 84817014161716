<template>
  <div>
    <b-modal
      :id="componentName"
      no-close-on-backdrop
      hide-footer
      centered
      size="lg"
      @shown="btnModal('show')"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">
          롤링금 산정 내역
        </h5>
        <div class="modal-actions">
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click.prevent="btnModal('close')"
          />
        </div>
      </template>

      <!-- Modal Body -->
      <b-card no-body>

        <div>
          <!-- Table Fitler -->
          <div class="mb-2">
            <!-- Search Condition -->
            <b-row>
              <b-col sm="3">
                <b-form-group
                  v-if="false"
                  class="font-small-3 mt-lg-1"
                  label="적용일시"
                  label-for="issueDate"
                  label-align-sm="2"
                  content-cols-sm="6"
                >
                  <b-form-select
                    id="issueDate"
                    ref="issueDate"
                    v-model="selectedIssueDate"
                    :options="issueDateOptions"
                    class="font-small-3"
                    style="color:rgb(229,166,48)"
                  >
                    <template #first>
                      <option
                        value=""
                        style="color: white;"
                      >
                        == 전체 ==
                      </option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                offset-sm="3"
                sm="6"
              >
                <b-form-group
                  v-if="false"
                  class="font-small-3 mt-lg-1"
                  label="검색"
                  label-for="filterInput"
                  label-align-sm="2"
                  content-cols-sm="10"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="searchFilter"
                      type="search"
                      placeholder=""
                      style="color: #99dff1"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!searchFilter"
                        @click="searchFilter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!-- table content -->
          <div>
            <b-table
              ref="messagesSendHistoyTable"
              responsive
              show-empty
              small
              empty-text="No matching records found"
              style="text-align: center; font-size: small"
              :items="filteredItems"
              :fields="fields"
            >
              <!-- Table Body -->
              <template #cell(index)="data">
                <span v-if="data.item.seq === 0">
                  {{ Math.floor(data.index / 2) + 1 }}
                </span>
              </template>
              <template #cell(baseDate)="data">
                <span v-if="data.item.seq === 0">
                  {{ data.item.baseDate }}
                </span>
              </template>

              <template #cell(user)="data">
                <div class="d-flex align-items-center">
                  <div
                    class="d-flex align-items-center justify-content-end flex-shrink-0"
                    style="width: 30%; margin-right: 5px;"
                  >
                    <b-badge
                      v-if="data.item.seq === 0"
                      :variant="`light-primary`"
                      class="me-2"
                    >
                      상위
                    </b-badge>
                  </div>
                  <div
                    class="d-flex align-items-center"
                    style="width: 70%;"
                  >
                    {{ data.item.userid }}
                  </div>
                </div>
              </template>

              <template #cell(gameType)="data">
                <span v-if="data.item.seq === 0" />
                <span v-else>
                  {{ data.item.gameType }}
                </span>
              </template>
              <template #cell(gameDetail)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span v-if="data.item.seq === 0" />
                  <span v-else>
                    {{ data.item.gameDetail }}
                  </span>
                </div>
              </template>
              <template #cell(betDate)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span v-if="data.item.seq === 0" />
                  <span v-else>
                    {{ data.item.betDate }}
                  </span>
                </div>
              </template>
              <template #cell(issueDate)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span>
                    {{ data.item.issueDate }}
                  </span>
                </div>
              </template>
              <template #cell(bettingAmount)="data">
                <div class="d-flex nowrap-text">
                  <span
                    v-if="data.item.seq === 0"
                    class="text-center w-100"
                  >
                    -
                  </span>
                  <span
                    v-else
                    class="text-right w-100"
                    style="color:rgb(196,186,138);"
                  >
                    {{ (data.item.bettingAmount).toLocaleString() }}
                  </span>
                </div>
              </template>
              <template #cell(rate)="data">
                <div class="nowrap-text">
                  <span style="color:rgb(196,186,138); min-width: 6rem">{{ data.item.rate }} </span>
                </div>
              </template>
              <template #cell(rollingAmount)="data">
                <div class="d-flex nowrap-text">
                  <span
                    class="text-right w-100"
                    style="color:rgb(196,186,138);"
                  >
                    {{ (data.item.rollingAmount).toLocaleString() }}
                  </span>
                </div>
              </template>
              <template #cell(formula)="data">
                <div
                  style="min-width: 10rem;"
                >
                  <span>
                    {{ data.item.formula }}
                  </span>
                </div>
              </template>

              <template #cell(user1)="data">
                <div
                  :style="{
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: `${(data.item.level || 0) * 20}px`,
                  }"
                >
                  <feather-icon
                    v-if="data.item.role === 'branch'"
                    style="width: 20px; text-align: center;"
                    icon="ChevronDownIcon"
                    size="14"
                  />

                  <!-- 사용자 정보 -->
                  <b-avatar
                    size="24"
                    :text="avatarText(data.item.role)"
                    :variant="resolveUserRoleVariant(data.item.role)"
                    :style="{ marginRight: '10px' }"
                  />
                  <div
                    :style="{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }"
                  >
                    <div :style="{ marginBottom: '0', color: 'green' }">
                      {{ data.item.userid }}
                    </div>
                    <div
                      v-if="false"
                      :style="{ marginBottom: '0' }"
                    >
                      {{ data.item.nickname }}
                    </div>
                  </div>
                </div>
              </template>

              <template #cell(sportBettingAmount)="data">
                {{ (data.item.sport.bettingAmount).toLocaleString() }}
              </template>
              <template #cell(sportRollingAmount)="data">
                {{ (data.item.sport.rollingAmount).toLocaleString() }}
              </template>
              <template #cell(sportRollingFormula)="data">
                <div
                  v-if="data.item.sportBettingAmount > 0"
                  style="text-align: center; minWidth: 21rem; padding: 0.5rem"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td style="border-top: 0px; padding: 10px 10px 5px 10px">
                        <strong>상위</strong>
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.parentFormula }}
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.parentRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: 0px; padding: 10px 10px 5px 10px">
                        <strong>본인</strong>
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.selfFormula }}
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.sportRollingFormula.selfRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <template #cell(casinoBettingAmount)="data">
                {{ (data.item.casino.bettingAmount).toLocaleString() }}
              </template>
              <template #cell(casinoRollingAmount)="data">
                {{ (data.item.casino.rollingAmount).toLocaleString() }}
              </template>
              <template #cell(casinoRollingFormula)="data">
                <div
                  v-if="data.item.casinoBettingAmount > 0"
                  style="text-align: center; minWidth: 21rem; padding: 0.5rem"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td style="border-top: 0px; padding: 10px 10px 5px 10px">
                        <strong>상위</strong>
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.parentFormula }}
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.parentRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: 0px; padding: 10px 10px 5px 10px">
                        <strong>본인</strong>
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.selfFormula }}
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.casinoRollingFormula.selfRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <template #cell(slotBettingAmount)="data">
                {{ (data.item.slot.bettingAmount).toLocaleString() }}
              </template>
              <template #cell(slotRollingAmount)="data">
                {{ (data.item.slot.rollingAmount).toLocaleString() }}
              </template>
              <template #cell(slotRollingFormula)="data">
                <div
                  v-if="data.item.slotBettingAmount > 0"
                  style="text-align: center; minWidth: 21rem; padding: 0.5rem"
                >
                  <table style="width: 100%;">
                    <tr>
                      <td style="border-top: 0px; padding: 10px 10px 5px 10px">
                        <strong>상위</strong>
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.parentFormula }}
                      </td>
                      <td style="border-top: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.parentRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                    <tr>
                      <td style="border-bottom: 0px; padding: 10px 10px 5px 10px">
                        <strong>본인</strong>
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.selfFormula }}
                      </td>
                      <td style="border-bottom: 0px; border-left: 1px solid #000; padding: 10px 10px 5px 10px">
                        {{ data.item.slotRollingFormula.selfRollingAmount.toLocaleString() }}
                      </td>
                    </tr>
                  </table>
                </div>
              </template>

              <!-- Table Footer -->
              <template
                slot="bottom-row"
              >
                <td
                  colspan="7"
                  class="text-center"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  Total
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('bettingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getRateStatus() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                >
                  {{ getTotal('rollingAmount').toLocaleString() }}
                </td>
                <td
                  class="text-right"
                  style="background-color: #24233a; color:rgb(196,186,138)"
                />
              </template>
            </b-table>
          </div>
        </div>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BCard, BRow, BCol, BButton,
  BFormGroup,
  BFormInput, BInputGroupAppend, BInputGroup,
  BFormSelect,
  BTable,
  VBTooltip,
  BBadge, BMedia, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')

export default {
  name: 'BranchCalculateDailyUsersRolling',
  components: {
    BModal,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BTable,
    BBadge,
    BMedia,
    BAvatar,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    fetchBalanceUserRolling: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // Sample Data
      currentURL: window.location.href,
      componentName: this.$options.name,
      avatarText,
      // TableFilter
      selectedIssueDate: '',
      searchFilter: '',

      // Tablefields
      fields: [
        { key: 'index', label: 'No.', thClass: '' },
        { key: 'baseDate', label: '기준일자', thClass: '' },
        { key: 'user', label: '유저', thClass: '' },
        { key: 'gameType', label: '게임', thClass: '' },
        { key: 'gameDetail', label: '게임상세', thClass: '' },
        { key: 'betDate', label: '베팅일자', thClass: '' },
        { key: 'issueDate', label: '정산일자', thClass: '' },
        { key: 'bettingAmount', label: '배팅금', thClass: '' },
        { key: 'rate', label: '요율', thClass: '', tdClass: 'text-center' },
        { key: 'rollingAmount', label: '롤링금', thClass: '' },
        { key: 'formula', label: '계산식', thClass: '', tdClass: 'text-left' },
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    filteredItems() {
      let filteredData = this.fetchBalanceUserRolling

      if (this.searchFilter) {
        filteredData = filteredData.filter(item => Object.values(item).some(
          val => val && val.toString().toLowerCase().includes(this.searchFilter.toLowerCase()),
        ))
      }

      return filteredData
    },

  },
  methods: {
    // Modal
    btnModal(target) {
      if (target === 'close') {
        // console.log(this.componentName, 'btnModal()', target)
        this.$bvModal.hide(this.componentName)
      }
    },

    // fnc
    /* Table Variant */
    rowClass(item, type) {
      // baseDate 값에 따라 다른 클래스를 반환
      if (type === 'row') {
        // issueDate에서 시간 부분만 추출 (HH:mm:ss)
        const time = item.issueDate.split(' ')[1]

        // 분(minute) 부분을 추출
        const minutes = parseInt(time.split(':')[1], 10)

        // 분을 5로 나누었을 때 나머지가 짝수면 table-bright', 홀수면 'table-dark'' 클래스를 적용
        return (minutes / 5) % 2 === 0 ? 'table-bright' : 'table-dark'
      }
    },

    /* [formatter] */
    formatTime(value) {
      return moment(value, moment.ISO_8601, true).isValid() ? moment(value).format('HH시 mm분') : value
    },
    formatNumberWithCommas(value) {
      if (value == null || Number.isNaN(Number(value))) {
        return value
      }

      return parseInt(value, 10).toLocaleString()
    },
    getTotal(field) {
      return this.filteredItems.reduce((acc, item) => acc + item[field], 0)
    },
    getRateStatus() {
      // 1. filteredItems에서 rate 값을 추출하고 0을 제외
      const rates = this.filteredItems
        .map(item => item.rate)
        .filter(rate => rate !== 0)

      // 2. 중복 제거
      const uniqueRates = [...new Set(rates)]

      // 3. uniqueRates의 길이에 따라 결과 반환
      if (uniqueRates.length === 1) {
        return uniqueRates[0] // 값이 1개일 때 해당 값 반환
      } if (uniqueRates.length > 1) {
        return '변동' // 값이 2개 이상일 때 '변동' 반환
      }
      return '없음' // 값이 없을 때 처리 (필요 시)
    },
    _getTotal(category, field) {
    // category: sport, casino, slot
    // field: bettingAmount or rollingAmount
      return this.filteredItems.reduce((acc, item) => {
        if (item[category] && item[category][field] !== undefined) {
          return acc + item[category][field]
        }
        return acc
      }, 0)
    },
  },

}
</script>

<style>
  .modal-dialog {
    max-width: 1200px;
  }
</style>
