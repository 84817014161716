<template>
  <div>
    <b-card>
      <div>
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            총판 신규등록 (S, A)
          </h4>
        </span>
        <hr class="pb-1">
      </div>
      <b-form class="pb-2">
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="사이트"
              label-for="branch-site"
            >
              <b-form-select
                id="branch-site"
                ref="branch-site"
                v-model="enrollForm.authSite"
                :options="authSite"
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); "
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="아이디"
              label-for="user-userid"
            >
              <b-input-group
                @click="serchUserShow"
              >
                <b-form-input
                  id="user-userid"
                  v-model="enrollForm.userid"
                  placeholder="클릭하여 유저검색"
                  class="cursor-pointer font-small-3"
                  style="color:rgb(229,166,48)"
                  readonly
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="false"
            cols="12"
            md="4"
          >
            <b-form-group
              label="비밀번호"
              label-for="enrollForm-password1"
            >
              <b-form-input
                id="enrollForm-password1"
                class="font-small-3"
                placeholder=""
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="false"
            cols="12"
            md="4"
          >
            <b-form-group
              label="비밀번호확인"
              label-for="enrollForm-password2"
            >
              <b-form-input
                id="enrollForm-password2"
                v-model="enrollForm.password"
                class="font-small-3"
                placeholder=""
                style="color:rgb(229,166,48)"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="상위/총판"
              label-for="enrollForm-recommendNickname"
            >
              <b-form-select
                id="enrollForm-recommendNickname"
                v-model="enrollForm.recommendNickname"
                :options="optionsBranch"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              >
                <template #first>
                  <option
                    disabled
                    value="null"
                    style="color: white;"
                  >
                    == 선택 ==
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="닉네임"
              label-for="enrollForm-nickname"
            >
              <b-form-input
                id="enrollForm-nickname"
                v-model="enrollForm.nickname"
                placeholder="닉네임"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                :readonly="!enrollForm.userid"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="메일"
              label-for="enrollForm-email"
            >
              <b-form-input
                id="enrollForm-email"
                v-model="enrollForm.email"
                placeholder="ex) example@example.com"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                :readonly="!enrollForm.userid"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="전화번호"
              label-for="enrollForm-mobile"
            >
              <b-form-input
                id="enrollForm-mobile"
                v-model="enrollForm.mobile"
                class="font-small-3"
                placeholder="ex) 123-456-789"
                style="color:rgb(229,166,48)"
                :readonly="!enrollForm.userid"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="이름"
              label-for="enrollForm-username"
            >
              <b-form-input
                v-if="true"
                id="enrollForm-username"
                v-model="enrollForm.username"
                class="font-small-3"
                placeholder="ex) 홍길동"
                style="color:rgb(229,166,48)"
                :readonly="!enrollForm.userid"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="계좌은행"
              label-for="enrollForm-userAccountBank"
            >
              <b-form-input
                id="enrollForm-userAccountBank"
                v-model="enrollForm.userAccountInfo.userAccountBank"
                class="font-small-3"
                :placeholder="`ex) ${siteSelected} 은행`"
                style="color:rgb(229,166,48)"
                :readonly="!enrollForm.userid"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="계좌번호"
              label-for="enrollForm-userAccountNumber"
            >
              <b-form-input
                id="enrollForm-userAccountNumber"
                v-model="enrollForm.userAccountInfo.userAccountNumber"
                class="font-small-3"
                placeholder="ex) 123-456-789"
                style="color:rgb(229,166,48)"
                :readonly="!enrollForm.userid"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="권한"
              label-for="enrollForm-role"
            >
              <b-form-select
                id="enrollForm-role"
                v-model="enrollForm.role"
                :options="options1"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                disabled
              >
                <template #first>
                  <option
                    disabled
                    value="null"
                    style="color: white;"
                  >
                    == 선택 ==
                  </option>
                </template>
              </b-form-select>

              <div
                v-if="options1.value === 'admin'"
                class="text-primary font-small-3"
              >
                # 권한을 "어드민"으로 설정할 경우 해당 총판은 관리자 권한을 사용할 수 있습니다 (타입 S 총판)
              </div>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="랭크"
              label-for="enrollForm-rank"
            >
              <b-form-select
                id="enrollForm-rank"
                v-model="enrollForm.rank"
                :options="optionsSetRank"
                class="font-small-3"
                style="color:rgb(229,166,48)"
              >
                <template #first>
                  <option
                    disabled
                    value="null"
                    style="color: white;"
                  >
                    == 선택 ==
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="상태"
              label-for="enrollForm-status"
            >
              <b-form-select
                id="enrollForm-status"
                v-model="enrollForm.status"
                :options="options2"
                class="font-small-3"
                style="color:rgb(229,166,48)"
                :disabled="!enrollForm.userid"
              >
                <template #first>
                  <option
                    disabled
                    value="null"
                    style="color: white;"
                  >
                    == 선택 ==
                  </option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <div>
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            총판 추천 코드 생성
          </h4>
        </span>
        <hr class="pb-1">
        <b-form class="pb-2">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="추천코드"
                label-for="enrollForm-recommendCode"
              >
                <div class="d-flex align-items-center">
                  <b-form-input
                    id="enrollForm-recommendCode"
                    v-model="enrollForm.recommendCode"
                    placeholder="ex) SUNDAY03"
                    class="font-small-3"
                    style="color:rgb(229,166,48)"
                    :readonly="!enrollForm.userid"
                  />
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    style="margin-left: 1rem; min-width: 5rem; height: 2.5rem"
                    :disabled="!enrollForm.userid"
                    @click="generateRecommendCode"
                  >
                    생성
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div>
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            총판 계좌 설정
          </h4>
        </span>
        <hr class="pb-1">
        <b-form class="pb-2">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="계좌은행"
                label-for="enrollForm-adminAccountBank"
              >
                <b-form-input
                  id="enrollForm-adminAccountBank"
                  v-model="enrollForm.branchAccountInfo.adminAccountBank"
                  class="font-small-3"
                  :placeholder="`ex) ${siteSelected} 은행`"
                  style="color:rgb(229,166,48)"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="계좌번호"
                label-for="enrollForm-adminAccountNumber"
              >
                <b-form-input
                  id="enrollForm-adminAccountNumber"
                  v-model="enrollForm.branchAccountInfo.adminAccountNumber"
                  class="font-small-3"
                  placeholder="ex)123-456-789"
                  style="color:rgb(229,166,48)"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="예금주"
                label-for="enrollForm-adminAccountName"
              >
                <b-form-input
                  v-if="true"
                  id="enrollForm-adminAccountName"
                  v-model="enrollForm.branchAccountInfo.adminAccountName"
                  class="font-small-3"
                  placeholder="홍길동"
                  style="color:rgb(229,166,48)"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div>
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            총판 출금 설정
          </h4>
        </span>
        <hr class="pb-1">
        <b-form class="pb-2">
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="출금주기"
                label-for="enrollForm-period"
              >
                <b-form-select
                  id="enrollForm-period"
                  v-model="selectedPeriod"
                  :options="optionsPeriod"
                  class="font-small-3"
                  style="color:rgb(229,166,48)"
                  @change="handlePeriod"
                >
                  <template #first>
                    <option
                      disabled
                      value="null"
                      style="color: white;"
                    >
                      == 선택 ==
                    </option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="isShowDays"
              cols="12"
              md="4"
            >
              <b-form-group
                label="선택: 요일"
                label-for="user-period-every-week"
              >
                <b-form-radio-group
                  id="user-period-every-week"
                  v-model="selectedDays"
                  :options="optionsDays"
                  @change="handleCronSchedule"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="isShowCustomDate"
              cols="12"
              md="4"
            >
              <b-form-group
                label="선택: 특정일"
                label-for="user-period-custom-day"
              >
                <b-form-input
                  id="user-period-custom-day"
                  v-model="customDate"
                  class="font-small-3"
                  placeholder="1-25일 중 선택바랍니다."
                  style="color:rgb(229,166,48)"
                  type="number"
                  @input="handleCronSchedule"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div
        v-if="enrollForm.userid"
      >
        <span
          class="pl-1 row justify-content-between"
        >
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            비율 산정
          </h4>
        </span>
        <hr class="pb-1">
        <b-row class="pb-2">
          <b-col>
            <branch-rate
              ref="BranchReate"
              :enroll-user="enrollForm"
              @validate-rate="handleValidateRate"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            루징 비율 설정 [총판]
          </h4>
        </span>
        <hr class="pb-1">
        <b-form class="pb-2">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="font-small-3 text-success mb-1">
                루징금은 [총판 충전금 - 총판 환전금]x루징비율% 으로 1일 1회(00~01시) 산출되며, 지급은 [총판 정산관리] 메뉴에서 관리합니다.
                루징비율이 수정될 경우 지급시점(최근 정산) 이후 산출분부터(과거포함) 반영됩니다.
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="총판 정산비율(%)"
                label-for="enrollForm-rate"
              >
                <template #label>
                  총판 정산비율(%)
                  <span>
<!--                    <b-badge-->
<!--                      v-if="enrollForm.rate"-->
<!--                      size="sm"-->
<!--                      :variant="validateRate.isUpperRate ? 'success' : 'primary'"-->
<!--                    >-->
<!--                      {{ validateRate.isUpperRate ? '적합' : '부적합' }}-->
<!--                    </b-badge>-->
                  </span>
                </template>
                <b-form-input
                  id="enrollForm-rate"
                  v-model="enrollForm.rate"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  inputmode="numeric"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div>
        <span class="pl-1 row justify-content-between">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            롤링 비율 설정 [스포츠/카지노/슬롯]
          </h4>
        </span>
        <hr class="pb-1">
        <b-form class="pb-2">
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="font-small-3 text-success mb-1">
                설명: 내용을 추가해주세요.
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="스포츠 롤링 비율(%)"
                label-for="enrollForm-rateSport"
              >
                <template #label>
                  스포츠 롤링 비율(%)
                  <span>
<!--                    <b-badge-->
<!--                      v-if="enrollForm.rate"-->
<!--                      size="sm"-->
<!--                      :variant="validateRate.isUpperRateSportRolling ? 'success' : 'primary'"-->
<!--                    >-->
<!--                      {{ validateRate.isUpperRateSportRolling ? '적합' : '부적합' }}-->
<!--                    </b-badge>-->
                  </span>
                </template>
                <b-form-input
                  id="enrollForm-rateSport"
                  v-model="enrollForm.rateSportRolling"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  inputmode="numeric"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="카지노 롤링 비율(%)"
                label-for="enrollForm-rateCasino"
              >
                <template #label>
                  카지노 롤링 비율(%)
                  <span>
<!--                    <b-badge-->
<!--                      v-if="enrollForm.rate"-->
<!--                      size="sm"-->
<!--                      :variant="validateRate.isUpperRateCasinoRolling ? 'success' : 'primary'"-->
<!--                    >-->
<!--                      {{ validateRate.isUpperRateCasinoRolling ? '적합' : '부적합' }}-->
<!--                    </b-badge>-->
                  </span>
                </template>
                <b-form-input
                  id="enrollForm-rateCasino"
                  v-model="enrollForm.rateCasinoRolling"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  inputmode="numeric"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <b-form-group
                label="슬롯 롤링 비율(%)"
                label-for="enrollForm-rateSlots"
              >
                <template #label>
                  슬롯 롤링 비율(%)
                  <span>
<!--                    <b-badge-->
<!--                      v-if="enrollForm.rate"-->
<!--                      size="sm"-->
<!--                      :variant="validateRate.isUpperRateSlotRolling ? 'success' : 'primary'"-->
<!--                    >-->
<!--                      {{ validateRate.isUpperRateSlotRolling ? '적합' : '부적합' }}-->
<!--                    </b-badge>-->
                  </span>
                </template>
                <b-form-input
                  id="enrollForm-rateSlots"
                  v-model="enrollForm.rateSlotRolling"
                  class="font-small-3"
                  placeholder="ex) 0.00"
                  style="color:rgb(229,166,48)"
                  type="number"
                  inputmode="numeric"
                  :readonly="!enrollForm.userid"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>

      <div
        class="row"
      >
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="btnSave"
          >
            저장
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            class="float-right mr-1"
            @click="btnCancel"
          >
            취소
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            class="float-right mr-1"
            @click="btnReset"
          >
            초기화
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="float-right mr-1"
            @click="btnList"
          >
            목록
          </b-button>

        </b-col>
      </div>
    </b-card>
    <!-- Modal Form -->
    <branch-search-user
      ref="BranchSearchUser"
      @selected-row="searchUserSelected"
    />
  </div>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BInputGroup, BInputGroupAppend, BFormGroup, BFormInput, BForm, BFormSelect, BFormRadioGroup, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_BRANCH, FETCH_BRANCH_SELECT_OPTIONS, FETCH_BRANCH_RECOMMEND_CODE } from '@/store/branch/action'
import { UPSERT_BRANCH } from '@/store/branch/mutation'
import { FETCH_SET_RANK } from '@/store/settings/action'

import Swal from 'sweetalert2'

import BranchRate from './BranchRate.vue'
import BranchSearchUser from './BranchSearchUser.vue'

const branchStore = createNamespacedHelpers('branchStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  name: 'BranchCreate',
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    BFormRadioGroup,
    BBadge,

    BranchRate,
    BranchSearchUser,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      currentURL: window.location.href,
      componentName: this.$options.name,

      enrollForm: {
        site: '',
        userid: '',
        username: '',
        nickname: '',
        recommendNickname: '',
        recommendCode: '',
        email: '',
        mobile: '',
        rank: null,
        role: null,
        status: null,
        rate: null,
        authSite: '',
        rateSportRolling: null,
        rateCasinoRolling: null,
        rateSlotRolling: null,
        withdrawableDay: null,
        userAccountInfo: {
          userAccountBank: '',
          userAccountNumber: '',
          // userAccountName: '',
        },
        branchAccountInfo: {
          adminAccountBank: '',
          adminAccountNumber: '',
          adminAccountName: '',
        },
        period: null,
      },
      selectedItem: {},
      validateRate: {},

      options1: [
        { value: 'godfather', text: 'godfather' },
        { value: 'branch', text: '총판' },
        { value: 'admin', text: '어드민' },
        { value: 'user', text: '유저' },
      ],
      options2: [
        { value: 'active', text: '정상' },
        { value: 'sleep', text: '휴면' },
        { value: 'block', text: '차단' },
      ],

      // 출금 주기 설정
      selectedPeriod: null,
      selectedDays: null,
      customDate: '',
      isShowDays: false, // 요일 선택
      isShowCustomDate: false, // 특정일 선택
      optionsPeriod: [
        { value: 'DAILY', text: '매일' },
        { value: 'WEEKLY', text: '매주' },
        // { value: 'BIWEEKLY', text: '격주' },
        { value: 'LASTDAY', text: '말일' },
        { value: 'CUSTOMDATE', text: '특정일' },
      ],
      optionsDays: [
        { value: 0, text: '일' }, // 'Sun'
        { value: 1, text: '월' }, // 'Mon'
        { value: 2, text: '화' }, // 'Tue'
        { value: 3, text: '수' }, // 'Wed'
        { value: 4, text: '목' }, // 'Thu'
        { value: 5, text: '금' }, // 'Fri'
        { value: 6, text: '토' }, // 'Sat'
      ],
    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...branchStore.mapGetters({
      fetchBranch: 'fetchBranch',
      fetchBranchSelectOptions: 'fetchBranchSelectOptions',
      // fetchBranchRecommendCode: 'fetchBranchRecommendCode',
    }),
    ...settingsStore.mapGetters({
      fetchSetRank: 'fetchSetRank',
    }),
    optionsBranch() {
      const resultData = this.fetchBranchSelectOptions
        .reduce((acc, option) => {
          if (option.role === 'branch' && option.nickname !== this.enrollForm.nickname) {
            acc.push({
              text: option.status === 'active'
                ? `[${option.role}] ${option.nickname}`
                : `[${option.role}] ${option.nickname} (${option.status})`,
              value: option.nickname,
            })
          }
          return acc
        }, this.userData.role === 'admin' ? [{
          text: `[site] ${this.siteSelected}`,
          value: this.siteSelected,
        }] : [])
      return resultData
    },
    optionsSetRank() {
      return this.fetchSetRank.map(rank => ({
        text: `${rank.rankOrder} :: ${rank.rankName}`,
        value: rank.rankOrder,
      })).reverse()
    },
  },
  watch: {
    selectedItem: {
      handler(newVal) {
        this.btnReset()
        if (newVal.type) {
          // console.log(newVal.type, 'fetchData()')
          this.fetchData()
        } else {
          // console.log(newVal.type, 'enrollUser()')
          this.enrollUser()
        }
      },
      deep: true, // 객체 내부의 변화까지 감지
    },
    customDate(newValue) {
      const value = Number(newValue)

      if (value < 1 || value > 25) {
        this.customDate = ''
      }
    },
  },
  created() {
    // console.log(this.componentName, 'created()')
    this.btnReset()
    this.init()
  },
  mounted() {
    // console.log(this.componentName, 'mounted()')
    // console.log(this.componentName, 'Current route:', this.$route)
    // console.log(this.componentName, 'Current route name:', this.$route.name)
    // console.log(this.componentName, 'Current route params:', this.$route.params)

    // const { site, userid } = this.$route.params
    // console.log(this.componentName, 'Received userId:', site, userid)
  },
  beforeRouteEnter(to, from, next) {
    // console.log('From:', from.name) // 이전 페이지의 경로
    // console.log('To:', to.name) // 현재 페이지의 경로
    // next()
    next(vm => {
      if (from.name === 'branch-list') {
        const { site, userid } = to.params || {}
        if (site && userid) {
          Object.assign(vm, { selectedItem: {
            site,
            userid,
            type: 2,
          } })
        } else {
          vm.serchUserShow()
        }
      } else if (from.name === 'apps-users-edit') {
        vm.serchUserShow()
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.btnReset()
    this.selectedItem = {}
    next() // 라우팅을 계속 진행합니다.
  },
  beforeDestroy() {
    this.btnReset()
    this.selectedItem = {}
  },
  methods: {
    ...branchStore.mapActions({
      $fetchBranch: FETCH_BRANCH,
      $fetchBranchSelectOptions: FETCH_BRANCH_SELECT_OPTIONS,
      $fetchBranchRecommendCode: FETCH_BRANCH_RECOMMEND_CODE,
      $upsertBranch: UPSERT_BRANCH,
    }),
    ...settingsStore.mapActions({
      $fetchSetRank: FETCH_SET_RANK,
    }),
    async init() {
      await this.$fetchSetRank({
        site: this.siteSelected,
      })
      await this.$fetchBranchSelectOptions({
        site: this.siteSelected,
        userid: this.userData.userid,
      })

      // const { site, userid } = this.$route.params || {}
      // if (!site || !userid) {
      //   this.serchUserShow()
      // } else {
      //   this.selectedItem = {
      //     site,
      //     userid,
      //     type: 2,
      //   }
      // }
    },
    async fetchData() {
      await this.$fetchBranch({
        site: this.selectedItem.site,
        userid: this.selectedItem.userid,
      })
      this.enrollUser()
    },
    enrollUser() {
      this.btnReset()

      // 총판 출금 설정
      const setWithdrawableDay = cron => {
        if (!cron) return

        const [, , dayOfMonth, months, dayOfWeek] = cron.split(' ')

        if (dayOfMonth === 'L') {
          // 말일 처리
          this.selectedPeriod = 'LASTDAY'
        } else if (dayOfMonth === '*' && months === '*' && dayOfWeek === '*') {
          // 매일 처리
          this.selectedPeriod = 'DAILY'
        } else if (dayOfMonth === '*' && months === '*' && dayOfWeek !== '*' && !Number.isNaN(Number(dayOfWeek))) {
          // 매주 처리
          this.selectedPeriod = 'WEEKLY'
          this.selectedDays = Number(dayOfWeek) // 요일 설정
        } else if (dayOfMonth !== '*' && !Number.isNaN(Number(dayOfMonth)) && months === '*' && dayOfWeek === '*') {
          // 특정일 처리
          this.selectedPeriod = 'CUSTOMDATE'
          this.customDate = dayOfMonth // 특정일 설정
        }
        this.isShowDays = ['BIWEEKLY', 'WEEKLY'].includes(this.selectedPeriod) // 격주 또는 매주 선택 시 요일 선택 표시
        this.isShowCustomDate = ['CUSTOMDATE'].includes(this.selectedPeriod) // 특정일 선택 여부
        this.handleCronSchedule() // 크론표현식 만들기
      }

      console.log('this.selectedItem.type', this.selectedItem.type)
      if (this.selectedItem.type) {
        this.enrollForm = { ...JSON.parse(JSON.stringify(this.fetchBranch)) }
        this.enrollForm.site = 'ADMIN'
        this.enrollForm.role = 'branch'
        this.enrollForm.authSite = this.siteSelected
        setWithdrawableDay(this.fetchBranch.withdrawableDay)
      } else {
        this.enrollForm.site = 'ADMIN'
        this.enrollForm.userid = this.selectedItem.userid
        this.enrollForm.role = 'branch'
        this.enrollForm.authSite = this.siteSelected
        this.enrollForm.recommendNickname = this.userData.role === 'branch' ? this.userData.nickname : this.siteSelected
        this.enrollForm.rate = 0
        this.enrollForm.rateSportRolling = 0
        this.enrollForm.rateCasinoRolling = 0
        this.enrollForm.rateSlotRolling = 0
      }
    },
    async generateRecommendCode() {
      // const fetchBranchRecommendCode = await this.$fetchBranchRecommendCode()
      // this.enrollForm.recommendCode = fetchBranchRecommendCode
      this.$fetchBranchRecommendCode()
        .then(data => {
          console.log('fetchBranchRecommendCode', data)
          this.$set(this.enrollForm, 'recommendCode', data)
        })
        .catch(error => {
          console.error('Error generating recommend code:', error)
        })
    },
    handlePeriod() {
      this.isShowDays = ['BIWEEKLY', 'WEEKLY'].includes(this.selectedPeriod) // 격주 또는 매주 선택 시 요일 선택 표시
      this.isShowCustomDate = ['CUSTOMDATE'].includes(this.selectedPeriod) // 특정일 선택 여부
      this.selectedDays = null // 요일 초기화
      this.customDate = '' // 특정일 초기화
      this.handleCronSchedule() // 크론표현식 만들기
    },
    handleCronSchedule() {
      // 크론 표현식 조합
      let cronSchedule
      switch (this.selectedPeriod) {
        case 'DAILY':
          cronSchedule = '40 0 * * *' // 매일
          break
        case 'LASTDAY':
          cronSchedule = '40 0 L * *' // 매월 마지막 날
          break
        case 'WEEKLY':
          cronSchedule = `40 0 * * ${this.selectedDays}`
          break
        case 'CUSTOMDATE':
          cronSchedule = `40 0 ${this.customDate} * *` // 특정일
          break
        default:
          cronSchedule = null
          break
      }

      // 크론 표현식 유효성 검사
      const isValidCronExpression = cron => {
        if (!cron) return

        const [minutes, hours, dayOfMonth, months, dayOfWeek] = cron.split(' ')

        // 개별 유효성 검사 함수 (예시)
        const isValidMinute = min => min === '*' || (min >= 0 && min <= 59)
        const isValidHour = hour => hour === '*' || (hour >= 0 && hour <= 23)
        const isValidDayOfMonth = day => day === 'L' || day === '*' || (day >= 1 && day <= 25)
        const isValidMonth = month => month === '*' || (month >= 1 && month <= 12)
        const isValidDayOfWeek = day => day === '*' || (day >= 0 && day <= 7)

        console.log(isValidMinute(minutes), isValidHour(hours), isValidDayOfMonth(dayOfMonth), isValidMonth(months), isValidDayOfWeek(dayOfWeek))
        return isValidMinute(minutes)
           && isValidHour(hours)
           && isValidDayOfMonth(dayOfMonth)
           && isValidMonth(months)
           && isValidDayOfWeek(dayOfWeek)
      }

      if (isValidCronExpression(cronSchedule)) {
        // console.log('유효한 크론 표현식:', cronSchedule)
        this.enrollForm.withdrawableDay = cronSchedule
      } else {
        // console.log('유효하지 않은 크론 표현식입니다.', cronSchedule)
        this.enrollForm.withdrawableDay = ''
      }
    },
    handleValidateRate(data) {
      this.validateRate = data
    },
    serchUserShow() {
      // this.$refs.BranchSearchUser.$bvModal.show('BranchSearchUser')
      this.$nextTick(() => {
        if (this.$refs.BranchSearchUser) {
          this.$refs.BranchSearchUser.$bvModal.show('BranchSearchUser')
          // this.$refs.BranchSearchUser.$bvModal.show()
        } else {
          console.log('BranchSearchUser ref is not defined')
        }
      })
    },
    searchUserSelected(data) {
      this.selectedItem = data
    },
    async btnSave() {
      let isValid = true
      let validationMessage = ''
      const missingFields = []

      const requiredFields = [
        'site', 'userid', 'username', 'nickname', 'recommendNickname',
        'email', 'mobile',
        'rank', 'role', 'status',
        'rate', 'rateSportRolling', 'rateCasinoRolling', 'rateSlotRolling',
      ]
      const accountFields = [
        'userAccountInfo.userAccountBank', 'userAccountInfo.userAccountNumber',
        //  'userAccountInfo.userAccountName',
        'branchAccountInfo.adminAccountBank', 'branchAccountInfo.adminAccountNumber', 'branchAccountInfo.adminAccountName',
      ]

      // 기본 필드 체크
      requiredFields.forEach(field => {
        if (this.enrollForm[field] === undefined || this.enrollForm[field] === null || this.enrollForm[field] === '') {
          isValid = false
          missingFields.push(field)
        }
      })

      // 계좌 정보 필드 체크
      accountFields.forEach(fieldPath => {
        const fieldParts = fieldPath.split('.')
        let fieldValue = this.enrollForm

        fieldParts.forEach(part => {
          if (fieldValue) {
            fieldValue = fieldValue[part]
          }
        })

        if (!fieldValue) {
          isValid = false
          missingFields.push(fieldPath)
        }
      })

      // validateRate.result 체크
      // if (!(this.validateRate.isUpperRate
      // && this.validateRate.isUpperRateSportRolling
      // && this.validateRate.isUpperRateCasinoRolling
      // && this.validateRate.isUpperRateSlotRolling)) {
      //   isValid = false
      //   missingFields.push('validateRate')
      // }

      // 결과 메시지 생성
      if (!isValid) {
        validationMessage = `The following fields are missing or invalid: ${missingFields.join(', ')}`
      } else {
        validationMessage = 'All fields are valid.'
      }
      console.log(validationMessage)

      // 저장 로직
      // console.log(isValid)
      if (isValid) {
        await this.fncShowSwal('confirm')
          .then(async result => {
            if (result.isConfirmed) {
              try {
                await this.$upsertBranch(this.enrollForm)
              } catch {
                console.error()
              } finally {
                await this.fncShowSwal('success')
                await this.fetchData()
              }
            }
          })
      } else {
        this.fncShowSwal('valid')
      }
    },
    btnCancel() {
      this.fncShowSwal('cancel')
        .then(result => {
          if (result.isConfirmed) {
            this.btnReset()
            this.$router.go(-1)
          }
        })
    },
    btnReset() {
      this.enrollForm = {
        site: 'ADMIN',
        userid: this.selectedItem.userid,
        username: '',
        nickname: '',
        recommendNickname: this.siteSelected || null,
        recommendCode: '',
        email: '',
        mobile: '',
        rank: null,
        role: null,
        status: null,
        rate: null,
        authSite: this.siteSelected,
        rateSportRolling: null,
        rateCasinoRolling: null,
        rateSlotRolling: null,
        withdrawableDay: null,
        userAccountInfo: {
          userAccountBank: '',
          userAccountNumber: '',
          userAccountName: '',
        },
        branchAccountInfo: {
          adminAccountBank: '',
          adminAccountNumber: '',
          adminAccountName: '',
        },
      }
    },
    btnList() {
      // this.$router.push({ name: 'branch-list' }).catch(() => {})
      this.$router.go(-1)
    },

    // Swal
    fncShowSwal(type, title = null, text = null) {
      const config = {
        confirm: {
          title: '변경내용을 저장하시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        valid: {
          title: '저장 할 수 없습니다. \n 입력 정보를 확인 바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        check: {
          title: '변경된 내용이 없습니다. \n 다시 확인바랍니다.',
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        cancel: {
          title: '변경된 내용이 저장되지 않습니다. 정말 나가시겠습니까?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        },
        success: {
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
        error: {
          title: '저장 실패',
          icon: 'error',
          text: '시스템 관리자에게 문의바랍니다.',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        },
      }[type]

      // 추가적으로 전달된 title 있다면 해당 설정을 덮어씁니다.
      if (title) config.title = title

      // 추가적으로 전달된 text 있다면 해당 설정을 덮어씁니다.
      if (text === null) delete config.text

      // Swal을 실행합니다.
      return Swal.fire(config)
    },
  },
}
</script>

<style>

</style>
