<template>
  <b-card>
    <div>
      <b-tabs
        content-class="mt-3"
      >

        <b-tab
          title="대기함"
          active
        >
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            신규 회원
            <b-badge
              variant="light-primary"
              style="font-size: small; margin-left: 5px; margin-right: 3px"
              class="badge-glow"
            >
              {{ totalRows }}건
            </b-badge>
            <b-badge
              variant="light-info"
              style="font-size: small; margin-right: 3px"
            >
              실시간
            </b-badge>
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
          <hr>
          <div class="ml-2 mr-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1"
              >
                <label style="width: 41px">상태</label>
                <b-form-select
                  id="site-select"
                  v-model="fetchSelected1"
                  :options="option1"
                  class="divSelecter font-small-3"
                  size="sm"
                />
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="mb-1"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="ml-2 mr-2 mb-1">
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="11"
                md="11"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <label>Show</label>
                <b-form-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  size="sm"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
            </b-row>
          </div>
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :items="fetchRequestAlarmsSignup"
            :fields="fields1"
            :filter="filter"
            :filter-included-fields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            style="text-align: center; font-size: small"
            :current-page="currentPage"
            @filtered="onFiltered"
          >
            <template #cell(selected)="data">
              <b-form-checkbox
                v-if="fetchSelected1 === 'REQUEST'"
                v-model="selectedGroupIds"
                :value="data.item.paymentId"
                @change="updateTotalSum(data.item.requestCash, data.item.paymentId)"
              />
            </template>
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; max-width: 1rem"
            >
              {{ totalRows - (perPage * (currentPage - 1) + data.index) }}
            </template>
            <template #cell(site)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.site }}
              </div>
            </template>
            <!-- Column: recommandNickname -->
            <template #cell(recommendNickname)="data">
              <div
                class="text-center"
                style="color:rgb(70,177,112); min-width: 7rem"
              >
                {{ data.item.recommendNickname }}
              </div>
            </template>
            <template #cell(requestId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
              >
                {{ data.item.requestId }}({{ data.item.requestNickname }})
              </div>
            </template>
            <template #cell(requestDate)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem;"
              >
                {{ data.item.requestDate ? data.item.requestDate.replace(/^(\d{2})(\d{2}).(\d{2}).(\d{2}) (\d{2}:\d{2}):\d{2}$/, "$2.$3.$4 $5") : '' }}
              </div>
            </template>
            <template #cell(mobile)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem;"
              >
                {{ data.item.mobile }}
              </div>
            </template>
            <template #cell(requestRank)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.requestRank }}
              </div>
            </template>
            <template #cell(requestType)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.requestType }}
              </div>
            </template>
            <template #cell(requestIp)="data">
              <div
                size="sm"
                style="text-align: center;"
                v-b-tooltip.hover.bottom.v-danger="data.item.requestIp"
              >
                {{ truncateText(data.item.requestIp, 15) }}
              </div>
            </template>
            <template #cell(requestName)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.requestName }}
              </div>
            </template>
            <template #cell(accountBank)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem; color:rgb(196,186,138)"
              >
                {{ data.item.accountBank }}
              </div>
            </template>
            <template #cell(accountNumber)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 10rem; color:rgb(196,186,138)"
              >
                {{ data.item.accountNumber }}
              </div>
            </template>
            <template #cell(signin)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem;"
              >
                {{ data.item.signin }}
              </div>
            </template>
            <template #cell(category)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 6rem;"
                class="text-success"
              >
                {{ data.item.category }}
              </div>
            </template>
            <template #cell(confirmed)="data">
              <div
                v-if="data.item.confirmed === 'REQUEST' || data.item.confirmed === 'AWAIT'"
                size="sm"
                style="text-align: center; min-width: 4rem;"
                class="text-success"
              >
                가입 요청
              </div>
            </template>
            <template
              #cell(edit)="data"
            >
              <div
                class="editClass"
                @click.stop
              >
                <span class="d-inline-flex">
                  <div
                    v-if="data.item.confirmed === 'REQUEST'"
                    style="min-width: 9rem;"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="mr-1"
                      size="sm"
                      variant="outline-success"
                      style="font-size: smaller"
                      @click="submit('updateUserSignupConfirm', data.item)"
                    >
                      승인
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="outline-primary"
                      style="font-size: smaller;"
                      @click="submit('updateUserSignupReject', data.item)"
                    >
                      부결
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="editClass"
                    style="color: gray; min-width: 3rem;"
                  >
                    -
                  </div>
                </span>
              </div>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>

        <b-tab title="완료함">
          <h4 class="">
            <feather-icon
              icon="BoxIcon"
              style="width: 18px; height: 18px"
            />
            신규 회원 처리 완료함
            <b-badge
              variant="light-primary"
              style="font-size: small; margin-left: 5px; margin-right: 3px"
              class="badge-glow"
            >
              {{ totalRowsFinish }}건
            </b-badge>
            <b-badge
              variant="light-info"
              style="font-size: small; margin-right: 3px"
            >
              실시간
            </b-badge>
            <b-badge
              variant="primary"
              style="font-size: xx-small"
            >
              DB 연계
            </b-badge>
          </h4>
          <hr>

          <div class="ml-2 mr-2">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1"
              >
                <label style="width: 41px">상태</label>
                <b-form-select
                  id="site-select"
                  v-model="fetchSelected2"
                  :options="option2"
                  class="divSelecter font-small-3"
                  size="sm"
                />
              </b-col>
              <!-- Search -->
              <b-col
                cols="12"
                md="6"
              >
                <b-form-group
                  class="mb-1"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                      >
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="ml-2 mr-2">
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="6"
                md="6"
                class="col-sm-12"
              >
                <label>Show</label>
                <b-form-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="pageOptions"
                  size="sm"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
              </b-col>
              <b-col
                cols="5"
                lg="6"
                md="5"
                sm="12"
                class=""
              >
                <b-form-group
                  class="font-small-3"
                  label="승인일자"
                  label-for="period"
                  label-cols-md="1"
                >
                  <b-input-group
                    name="period"
                    class="calendar-size font-small-3"
                  >
                    <b-form-datepicker
                      v-model="periodFromFinish"
                      class=""
                      boundary="window"
                      locale="ko"
                      placeholder="시작일"
                      aria-controls="example-input"
                      size="sm"
                      :max="periodToFinish"
                    />
                    <div class="pl-1 pr-1">
                      ~
                    </div>
                    <b-form-datepicker
                      v-model="periodToFinish"
                      class=""
                      boundary="window"
                      locale="ko"
                      placeholder="종료일"
                      aria-controls="example-input"
                      size="sm"
                      :min="periodFromFinish"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <!-- Table -->
          <b-table
            responsive
            show-empty
            hover
            small
            empty-text="No matching records found"
            :per-page="perPage"
            :items="fetchRequestAlarmsSignupFinish"
            :fields="fields2"
            :filter="filter"
            :filter-included-fields="filterOn"
            style="text-align: center; font-size: small"
            :sort-by.sync="sortByFinish"
            :sort-desc.sync="sortDescFinish"
            :sort-direction="sortDirectionFinish"
            :current-page="currentPageFinish"
            @filtered="onFiltered"
          >
            <template
              #cell(index)="data"
              size="sm"
              style="text-align: center; min-width: 3rem"
            >
              {{ totalRowsFinish - (perPage * (currentPageFinish - 1) + data.index) }}
            </template>
            <template #cell(site)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.site }}
              </div>
            </template>
            <template #cell(requestId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem; text-decoration: underline; text-underline-offset: 5px;"
                @click="userClicked(data)"
              >
                {{ data.item.requestId }}({{ data.item.requestNickname }})
              </div>
            </template>
            <template #cell(requestDate)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem"
              >
                {{ data.item.requestDate ? data.item.requestDate.replace(/^(\d{2})(\d{2}).(\d{2}).(\d{2}) (\d{2}:\d{2}):\d{2}$/, "$2.$3.$4 $5") : '' }}
              </div>
            </template>
            <template #cell(requestRank)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.requestRank }}
              </div>
            </template>
            <template #cell(requestType)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 2rem"
              >
                {{ data.item.requestType }}
              </div>
            </template>
            <template #cell(requestIp)="data">
              <div
                size="sm"
                style="text-align: center;"
                v-b-tooltip.hover.bottom.v-danger="data.item.requestIp"
              >
                {{ truncateText(data.item.requestIp, 15) }}
              </div>
            </template>
            <template #cell(requestCash)="data">
              <div
                size="sm"
                style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
              >
                {{ Number(data.item.requestCash).toLocaleString() }}
              </div>
            </template>
            <template #cell(requestName)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem"
              >
                {{ data.item.requestName }}
              </div>
            </template>
            <template #cell(accountBank)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 4rem; color:rgb(196,186,138)"
              >
                {{ data.item.accountBank }}
              </div>
            </template>
            <template #cell(accountNumber)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 10rem; color:rgb(196,186,138)"
              >
                {{ data.item.accountNumber }}
              </div>
            </template>
            <template #cell(category)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 6rem;"
                class="text-success"
              >
                {{ data.item.category }}
              </div>
            </template>
            <template #cell(confirmId)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 5rem"
                class="text-success"
              >
                {{ data.item.confirmId }}
              </div>
            </template>
            <template #cell(confirmIp)="data">
              <div
                class="text-success"
                style="font-size: small;"
                v-b-tooltip.hover.bottom.v-danger="data.item.confirmIp"
              >
                {{ truncateText(data.item.confirmIp, 15) }}
              </div>
            </template>
            <template #cell(confirmAt)="data">
              <div
                size="sm"
                style="text-align: center; min-width: 7rem;"
                class="text-success"
              >
                {{ data.item.confirmAt ? data.item.confirmAt.replace(/^(\d{2})(\d{2}).(\d{2}).(\d{2}) (\d{2}:\d{2}):\d{2}$/, "$2.$3.$4 $5") : '' }}
              </div>
            </template>
            <template #cell(confirmed)="data">
              <div
                v-if="data.item.confirmed === 'CONFIRMED'"
                size="sm"
                style="text-align: center; min-width: 3rem;"
                class="text-success"
              >
                승인
              </div>
              <div
                v-if="data.item.confirmed === 'REJECT'"
                size="sm"
                style="text-align: center; min-width: 3rem; color: red"
              >
                부결
              </div>
            </template>
            <template #cell(edit)="data">
              <div
                v-if="data.item.confirmed === 'CONFIRMED' && todayChk(data.item.confirmAt)"
                style="min-width: 4rem;"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  class=""
                  size="sm"
                  variant="outline-secondary"
                  style="font-size: smaller"
                  @click="submit('updateManyExchangeCancel', [data.item.paymentId, data.item.confirmAt])"
                >
                  취소
                </b-button>
              </div>
              <div
                v-else
                class="editClass"
                style="color: gray; min-width: 3rem;"
              >
                -
              </div>
            </template>
            <template #row-details="row">
              <b-card
                class="p-0"
                style="background-color: #212026;"
              >
              </b-card>
            </template>
          </b-table>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPageFinish"
                  :total-rows="totalRowsFinish"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-card>

</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'
import { createNamespacedHelpers } from 'vuex'
import {
  FETCH_REQUEST_ALARMS_SIGNUP,
  FETCH_REQUEST_ALARMS_SIGNUP_FINISH,
} from '@/store/notice/action'
import {
  UPDATE_USER_SIGNUP_CONFIRM,
  UPDATE_USER_SIGNUP_REJECT,
} from '@/store/notice/mutation'
import * as moment from 'moment-timezone'
import router from "@/router";

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm:ss'
const fmt2 = 'YYYYMMDD'

const noticeStore = createNamespacedHelpers('noticeStore')

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    // RequestAlarmsDetailResult,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      fetchSelected2: '',
      option1: [
        { value: 'REQUEST', text: '가입요청' },
      ],
      option2: [
        { value: 'CONFIRMED', text: '승인완료' },
        { value: 'REJECT', text: '가입부결' },
      ],
      perPage: 30,
      pageOptions: [10, 30, 50],
      totalRowsFinish: 1,
      totalRows: 1,
      notReadCnt: 0,
      currentPage: 1,
      currentPageFinish: 1,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      sortByFinish: 'idx',
      sortDescFinish: true,
      sortDirectionFinish: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      check1: 'false',
      isHidden1: true,
      selectedRecord: null, // 선택된 record를 저장하기 위한 변수
      isExpandChecked1: false,
      isExpandChecked2: false,
      periodFromFinish: moment(new Date()).startOf('month').format(fmt1),
      periodToFinish: moment(new Date()).endOf('month').format(fmt1),
      selectedGroupIds: [],
      selectedCash: 0,
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'recommendNickname', sortable: false, label: '상위', thClass: 'col5',
        },
        {
          key: 'requestId', sortable: false, label: '아이디(닉네임)', thClass: 'col5',
        },
        {
          key: 'requestDate', sortable: false, label: '신청 날짜', thClass: 'col3',
        },
        {
          key: 'mobile', sortable: false, label: '전번', thClass: 'col3',
        },
        {
          key: 'requestName', sortable: false, label: '이름', thClass: 'col5',
        },
        {
          key: 'accountBank', sortable: false, label: '은행', thClass: 'col3',
        },
        {
          key: 'accountNumber', sortable: false, label: '계좌 번호', thClass: 'col3',
        },
        {
          key: 'signin', sortable: false, label: '플랫폼', thClass: 'col3',
        },
        {
          key: 'requestIp', sortable: false, label: 'IP', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '진행', thClass: 'col1',
        },
        {
          key: 'confirmed', sortable: false, label: '상태', thClass: 'col6',
        },
        {
          key: 'edit', sortable: false, label: '처리', thClass: 'col10',
        },
      ],
      fields2: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'site', sortable: false, label: '사이트', thClass: 'col1',
        },
        {
          key: 'recommendNickname', sortable: false, label: '상위', thClass: 'col5',
        },
        {
          key: 'requestId', sortable: false, label: '아이디(닉네임)', thClass: 'col5',
        },
        {
          key: 'requestDate', sortable: false, label: '신청 날짜', thClass: 'col3',
        },
        {
          key: 'requestName', sortable: false, label: '이름', thClass: 'col5',
        },
        {
          key: 'mobile', sortable: false, label: '전화번호', thClass: 'col3',
        },
        {
          key: 'accountBank', sortable: false, label: '은행', thClass: 'col3',
        },
        {
          key: 'accountNumber', sortable: false, label: '계좌 번호', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '진행', thClass: 'col1',
        },
        {
          key: 'confirmId', sortable: false, label: '승인아이디', thClass: 'col3',
        },
        {
          key: 'confirmIp', sortable: false, label: '승인Ip', thClass: 'col3',
        },
        {
          key: 'confirmAt', sortable: false, label: '승인 일시', thClass: 'col3',
        },
        {
          key: 'confirmed', sortable: false, label: '상태', thClass: 'col6',
        },
      ],
    }
  },
  watch: {
    isExpandChecked1(newVal) {
      this.fetchRequestAlarmsExchange.forEach(item => {
        this.$set(item, '_showDetails', newVal)
        this.$set(item, '_detailsData', item) // 각 항목의 상세 데이터를 설정
      })
    },
    isExpandChecked2(newVal) {
      this.fetchRequestAlarmsExchangeFinish.forEach(item => {
        this.$set(item, '_showDetails', newVal)
        this.$set(item, '_detailsData', item) // 각 항목의 상세 데이터를 설정
      })
    },
    fetchSelected1(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected1 = newVal
      this.fetchData1(newVal)
    },
    fetchSelected2(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected2 = newVal
      this.fetchData2(newVal)
    },
    periodFromFinish() {
      this.fetchData2(this.fetchSelected2)
    },
    periodToFinish() {
      this.fetchData2(this.fetchSelected2)
    },
  },
  created() {
    this.init()
    this.$socket.on('fetchRequestAlarmsSignup', data => {
      console.log('fetchRequestAlarmsSignup: ', data)
      this.$store.dispatch('noticeStore/socketFetchRequestAlarmsSignup', data)
    })
    this.$socket.on('fetchRequestAlarmsSignupFinish', data => {
      console.log('fetchRequestAlarmsSignupFinish: ', data)
      this.$store.dispatch('noticeStore/socketFetchRequestAlarmsSignupFinish', data)
    })
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    userIp() {
      return this.$store.getters['rootStore/ip']
    },
    ...noticeStore.mapGetters({
      fetchRequestAlarmsSignup: 'fetchRequestAlarmsSignup',
      fetchRequestAlarmsSignupFinish: 'fetchRequestAlarmsSignupFinish',
    }),
  },
  mounted() {
    this.fetchSelected1 = 'REQUEST'
    this.fetchSelected2 = 'CONFIRMED'
    // this.fetchData1('REQUEST')
    // this.fetchData2('CONFIRMED')
  },
  methods: {
    ...noticeStore.mapActions({
      $fetchRequestAlarmsSignup: FETCH_REQUEST_ALARMS_SIGNUP,
      $fetchRequestAlarmsSignupFinish: FETCH_REQUEST_ALARMS_SIGNUP_FINISH,
      $updateUserSignupConfirm: UPDATE_USER_SIGNUP_CONFIRM,
      $updateUserSignupReject: UPDATE_USER_SIGNUP_REJECT,
    }),
    init() {
    },
    async fetchData1(fetchSelected) {
      // const mySite = this.userData.site
      await this.$fetchRequestAlarmsSignup({
        site: this.authSite,
        confirmed: fetchSelected,
      })
      this.totalRows = this.fetchRequestAlarmsSignup.length
    },
    async fetchData2(fetchSelected) {
      const periodFrom = this.periodFromFinish ? `${moment(this.periodFromFinish).format('YYYY-MM-DD')} 00:00:00` : ''
      const periodTo = this.periodToFinish ? `${moment(this.periodToFinish).format('YYYY-MM-DD')} 23:59:59` : ''

      await this.$fetchRequestAlarmsSignupFinish({
        site: this.authSite,
        confirmed: fetchSelected,
        periodFrom,
        periodTo,
      })
      this.totalRowsFinish = this.fetchRequestAlarmsSignupFinish.length
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      } else {
        return text.substring(0, maxLength) + '...'
      }
    },
    // submit
    async submit(target, data) {
      await Swal.fire({
        title: '변경 내용을 저장 하시겠습니까?',
        // text: Number(this.desiredBet).toLocaleString(),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      const shouldProceed = true
      try {
        if (target === 'updateUserSignupConfirm') {
          console.log('data:::', data)
          await this.$updateUserSignupConfirm({
            rowData: data,
            userid: this.userData.userid,
            ip: this.userIp,
          })
        } else if (target === 'updateUserSignupReject') {
          // console.log('data:::', data)
          // console.log('this.userData.userid:::', this.userData.userid)
          // console.log('this.userIp:::', this.userIp)
          await this.$updateUserSignupReject({
            rowData: data,
            userid: this.userData.userid,
            ip: this.userIp,
          })
        }
      } catch (error) {
        // console.error(error)
        Swal.fire({
          title: error,
          icon: 'warning',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      } finally {
        if (shouldProceed) {
          Swal.fire({
            title: '저장 완료',
            icon: 'success',
            timer: 5000,
            background: 'rgb(3,11,37)',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
          }).then(result => {
            // const args = { site: mySite }
            // this.$socket.emit('requestAlarms', args)
            this.fetchData1('REQUEST')
            this.fetchData2('CONFIRMED')
          })
        }
      }
    },
    async rowClickHandler1(record, index, type) {
      console.log(type.target)
      if (type.target.ariaColIndex !== '1') {
        this.$set(record, '_showDetails', !record._showDetails) // _showDetails 설정
        this.$set(record, '_detailsData', record) // 각 항목의 상세 데이터를 설정
      }
    },
    rowClickHandler2(record, index) {
      // console.log('rowClickHandler2 : ', record)
      // console.log(index)
      // console.log(type.srcElement.parentElement.ariaColIndex)
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
      this.totalRowsFinish = filteredItems.length
      this.currentPageFinish = 1
    },
    // Sleep function
    async sleep(ms) {
      const wakeUpTime = Date.now() + ms
      while (Date.now() < wakeUpTime) {}
    },
    getTotal(field) {
      return this.fetchRequestAlarmsSignup.reduce((acc, item) => acc + item[field], 0)
    },
    getTotalFinish(field) {
      return this.fetchRequestAlarmsSignupFinish.reduce((acc, item) => acc + item[field], 0)
    },
    refreshParent() {
      // console.log(1111111111111)
      this.fetchData1('YET')
    },
    updateTotalSum(totalHitCash, groupId) {
      if (this.selectedGroupIds.includes(groupId)) {
        // groupId가 selectedGroupIds 배열에 있으면 체크된 상태
        this.selectedCash += totalHitCash
      } else {
        // 그렇지 않으면 체크 해제된 상태
        this.selectedCash -= totalHitCash
      }
    },
    todayChk(confirmDate) {
      const today = moment().format('YYYY-MM-DD')
      const inputDate = moment(confirmDate).format('YYYY-MM-DD')

      return today === inputDate
    },
    userClicked(data) {
      // localStorage.setItem('userView', JSON.stringify({ userid: data.item.requestId, site: data.item.site, nickname: data.item.requestNickname }))
      // this.$store.commit('rootStore/SET_USER_VIEW', JSON.stringify({ userid: data.item.requestId, site: data.item.site, nickname: data.item.requestNickname }))

      // URL 쿼리 파라미터로 데이터를 전달
      const queryParams = {
        userid: data.item.requestId,
        site: data.item.site,
        nickname: data.item.requestNickname,
      }
      const url = router.resolve({
        name: 'apps-user-view',
        query: queryParams,
      }).href
      // const url = router.resolve({ name: 'apps-user-view' }).href
      // window.open(url, '_blank')
      const popupName = `userViewPopup_${new Date().getTime()}`
      const popupOptions = 'width=1800,height=1250,scrollbars=yes,resizable=yes' // 원하는 옵션 설정
      window.open(url, popupName, popupOptions)
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
