<template>
  <div>
    <!-- Filters -->
    <b-card
      no-body
      class="p-1"
    >
      <b-row>
        <b-col cols="3">
          <b-form-group
            label="사이트"
            label-for="filter-site"
            label-align="left"
            class="font-small-3"
          >
            <b-form-select
              v-if="siteOptions"
              id="filter-site"
              ref="filter-site"
              v-model="siteFilter"
              :options="siteOptions"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); "
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="권한"
            label-for="filter-Role"
            label-align="left"
            class="font-small-3"
          >
            <b-form-select
              v-if="roleOptions"
              id="filter-Role"
              v-model="roleFilter"
              :options="roleOptions"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); "
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="랭크"
            label-for="filter-Rank"
            label-align="left"
            class="font-small-3"
          >
            <b-input-group class="input-group-merge">
              <b-form-select
                v-if="rankOptions"
                id="filter-Rank"
                v-model="rankFilter"
                :options="rankOptions"
                class="font-small-3"
                size="sm"
                style="color:rgb(229,166,48); "
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="상태"
            label-for="filter-Status"
            label-align="left"
            class="font-small-3"
          >
            <b-form-select
              id="filter-Status"
              v-model="statusFilter"
              :options="statusOptions"
              class="font-small-3"
              size="sm"
              style="color:rgb(229,166,48); "
            />
          </b-form-group>
        </b-col>
        <b-col>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=""
              variant="outline-info"
              style="float: right; margin-right: 0.5rem"
              size="sm"
              type="submit"
              @click="excelDataDownload"
            >
              엑셀 추출
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=""
              variant="outline-info"
              style="float: right; margin-right: 0.5rem"
              size="sm"
              type="submit"
              @click="openModal"
            >
              엑셀 업로드
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class=""
              variant="outline-info"
              style="float: right; margin-right: 0.5rem"
              size="sm"
              type="submit"
              @click="excelFormatDownload"
            >
              엑셀 양식
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >
      <div>
        <b-row class="m-1">
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <b-form-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              size="sm"
              class="per-page-selector d-inline-block mx-50"
              style="width: 100px;"
            />
          </b-col>
          <!-- Search -->
          <b-col>
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="searchFilter"
                type="search"
                placeholder="검색: 상위/추천, 닉네임/이름, 아이디, 전화번호 ..."
                style="color: #99dff1"
              />
              <b-input-group-append>
                <b-button
                  :disabled="!searchFilter"
                  @click="searchFilter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table
              ref="refUserListTable"
              primary-key="id"
              hover
              small
              responsive
              show-empty
              empty-text="No matching records found"
              :items="paginatedItems"
              :fields="tableColumns"
              class="position-relative small"
              style="text-align: center !important;"
              @row-clicked="rowClicked"
              @sort-changed="handleSortChanged"
            >
              <!-- Column: No -->
              <template #cell(No.)="data">
                {{ (currentPage - 1) * perPage + data.index + 1 }}
              </template>
              <template #cell(recommendNickname)="data">
                <div style="text-align: left; min-width: 8rem">
                  <b-badge
                    :variant="`light-primary`"
                    style=""
                    class=""
                  >
                    상위
                  </b-badge>
                  {{ data.item.recommendNickname === siteSelected ? '본사' : data.item.recommendNickname }}
                  <div v-if="data.item.recommendUserNickname" style="text-align: left;">
                    <b-badge
                      :variant="`light-warning`"
                      style=""
                      class=""
                    >
                      추천
                    </b-badge>
                    {{ data.item.recommendUserNickname }}
                  </div>
                </div>
              </template>
              <!-- Column: Role -->
              <template #cell(role)="data">
                <div style="min-width: 3rem">
                  <b-media class="align-content-center">
                    <template>
                      <b-avatar
                        size="24"
                        :src="data.item.role"
                        :text="avatarText(data.item.role)"
                        :variant="`${resolveUserRoleVariant(data.item.role)}`"
                      />
                    </template>
                  </b-media>
                </div>
              </template>
              <!-- Column: Userid / nickname -->
              <template #cell(userid)="data">
                <div
                  class="text-success font-weight-bold d-block text-nowrap"
                  style="text-align: left !important; min-width: 7rem; text-decoration: underline; text-underline-offset: 5px;"
                >
                  {{ data.item.userid }}
                </div>
                <!--                <div-->
                <!--                  class=""-->
                <!--                  style="text-align: left !important; min-width: 7rem; text-underline-offset: 5px;"-->
                <!--                >-->
                <!--                  {{ data.item.nickname }}-->
                <!--                </div>-->
              </template>
              <template #cell(nickname)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: left !important; min-width: 7rem;"
                >
                  {{ data.item.nickname }}
                </div>
              </template>
              <template #cell(username)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: left !important; min-width: 5rem;"
                >
                  {{ data.item.username }}
                </div>
              </template>
              <template #cell(accountBank)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: left !important; min-width: 5rem;"
                >
                  {{ data.item.accountBank }}
                </div>
              </template>
              <template #cell(accountNumber)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: left !important; min-width: 8rem;"
                >
                  {{ data.item.accountNumber }}
                </div>
              </template>
              <!-- Column: mobile -->
              <template #cell(mobile)="data">
                <div
                  class="text-center"
                  style="min-width: 8rem"
                >
                  {{ data.item.mobile }}
                </div>
              </template>
              <!-- Column: rank -->
              <template #cell(rank)="data">
                <div style="min-width: 5rem">
                  <b-avatar
                    size="28"
                    style="text-align: center; min-width: 2.1rem"
                    :src="`https://agress-assets.s3.ap-northeast-2.amazonaws.com/img_rank/` + siteFilter + `_` + data.item.rank + `.png`"
                  />
                  <span class="pl-1">Lv.{{ data.item.rank }}</span>
                </div>
              </template>
              <!-- Column: cash -->
              <template #cell(cash)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.cash).toLocaleString() }}
                </div>
              </template>
              <template #cell(point)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.point).toLocaleString() }}
                </div>
              </template>
              <!-- Column: betTotal -->
              <template #cell(betTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.sportBettingAmount + data.item.casinoBettingAmount + data.item.slotBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(sportBettingAmount)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.sportBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(casinoBettingAmount)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.casinoBettingAmount).toLocaleString() }}
                </div>
              </template>
              <template #cell(slotBettingAmount)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 6rem"
                >
                  {{ Number(data.item.slotBettingAmount).toLocaleString() }}
                </div>
              </template>
              <!-- Column: depositTotal -->
              <template #cell(depositTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.depositTotal).toLocaleString() }}
                </div>
              </template>
              <!-- Column: exchangeTotal -->
              <template #cell(exchangeTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.exchangeTotal).toLocaleString() }}
                </div>
              </template>
              <template #cell(depositExchangeTotal)="data">
                <div
                  class="text-right"
                  style="color:rgb(196,186,138); min-width: 5rem"
                >
                  {{ Number(data.item.depositTotal - data.item.exchangeTotal).toLocaleString() }}
                </div>
              </template>
              <!-- Column: enrollDate -->
              <template #cell(enrollDate)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: left !important;"
                >
                  {{ data.item.enrollDate | formatDateMinute }}
                </div>
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.enrollIp"
                  class="font-weight-bold"
                  style="text-align: left; min-width: 6rem; color: #6f7678"
                >
                  {{ data.item.enrollIp ? data.item.enrollIp.substring(0, 14) : '0:0:0:0/0' }}
                </div>
              </template>
              <template #cell(signinLastDate)="data">
                <div
                  class="font-weight-bold d-block text-nowrap"
                  style="text-align: left !important"
                >
                  {{ data.item.signinLastDate | formatDateMinute }}
                </div>
                <div
                  v-b-tooltip.hover.bottom.v-danger="data.item.signinLastIp"
                  class="font-weight-bold"
                  style="text-align: left; min-width: 6rem; color: #6f7678"
                >
                  {{ data.item.signinLastIp ? data.item.signinLastIp.substring(0, 14) : '0:0:0:0/0' }}
                </div>
              </template>
              <template #cell(signinLastPlatform)="data">
<!--                <div style="min-width: 1rem">-->
<!--                  <img-->
<!--                    class="flag"-->
<!--                    style="width: 20px;"-->
<!--                    :src="data.item.signinLastFlag"-->
<!--                  >-->
<!--                </div>-->
<!--                <div-->
<!--                  class=""-->
<!--                  style=""-->
<!--                >-->
<!--                  {{ data.item.signinLastCountry }}-->
<!--                </div>-->
<!--                <div-->
<!--                  class=""-->
<!--                  style=""-->
<!--                >-->
<!--                  {{ data.item.signinLastPlatform }}-->
<!--                </div>-->
                <div style="display: flex; align-items: center; gap: 0.5rem;">
                  <!-- 국기 및 국가명 -->
                  <span
                    style="font-size: 1.5rem; cursor: pointer;"
                    v-b-tooltip.hover.bottom.v-danger="data.item.signinLastCountry"
                  >
                    <img
                      v-if="data.item.signinLastFlag"
                      class="flag"
                      style="width: 20px;"
                      :src="data.item.signinLastFlag"
                    >
                    <template v-else>❓</template> <!-- 알 수 없는 국가 -->
                  </span>
                  <span style="font-size: 1.5rem;"
                        v-b-tooltip.hover.bottom.v-danger="data.item.signinLastPlatform"
                  >
                    <template v-if="data.item.signinLastPlatform === 'desktop'">💻</template>
                    <template v-else-if="data.item.signinLastPlatform === 'tablet'">📟</template>
                    <template v-else-if="data.item.signinLastPlatform === 'phone'">📱</template>
                    <template v-else>❓</template> <!-- 알 수 없는 플랫폼 -->
                  </span>
                </div>
              </template>
              <template #cell(signinTotal)="data">
                <div
                  class="text-info"
                  style="text-align: center !important; min-width: 3rem"
                >
                  {{ data.item.signinTotal }}
                </div>
              </template>
              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
                  class="text-capitalize"
                >
                  <!-- {{ data.item.status }} -->
                  {{ convertUserStatus(data.item.status) }}
                </b-badge>
              </template>
              <template #cell(alias)="data">
                <b-badge
                  pill
                  :variant="`light-danger`"
                  style="color: white"
                  class="text-capitalize"
                >
                  {{ data.item.alias }}
                </b-badge>
              </template>
              <template #cell(designated)="data">
                <b-badge
                  pill
                  :variant="`light-info`"
                  style="color: white"
                  class="text-capitalize"
                  v-b-tooltip.hover.bottom.v-info
                  :title="`${data.item.adminAccountBank || ''} ${data.item.adminAccountName || ''} ${data.item.adminAccountNumber || ''}`.trim()"
                >
                  {{ data.item.adminAccountBank ? '개별계좌' : '' }}
                </b-badge>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="mx-2 mb-2">
              <b-row>
                <!-- Table Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-left justify-content-left justify-content-sm-start"
                  style="margin-bottom: 0.5rem;"
                >
                  <span class="font-small-2">{{ pageFrom }}~{{ pageTo }} / 총 {{ pageOf }} </span>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  class="d-flex align-items-center justify-content-center justify-content-sm-center"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      ref="uploadUserModal"
      small
      no-close-on-backdrop
      title="회원 엑셀 업로드"
      centered
      button-size="sm"
      cancel-title="취소"
      ok-title="저장"
      @ok="excelUpload()"
      @cancel="closeModal()"
    >
      <!-- Accept specific image formats by extension -->
      <label class="mt-1">엑셀 양식을 다운로드하여 작업후 업로드 해주시기 바랍니다</label>
      <b-form-file
        id="extension"
        v-model="uploadFile"
        accept=".xlsx"
        @change="checkFileSize"
      />
      <div
        class="text-dark small mb-1"
      >
        파일 크기: {{ fileSizeString }}
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol,
  BFormGroup,
  BFormInput, BInputGroup, BInputGroupAppend,
  BFormSelect,
  BButton,
  BTable, BPagination,
  BBadge, BMedia, BAvatar,
  BFormFile,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText, formatDate } from '@core/utils/filter'

import * as moment from 'moment-timezone'

import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'

import { createNamespacedHelpers } from 'vuex'
import { UPLOAD_EXCEL_USERS } from '@/store/users/mutation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useUsersList from './useUsersList'

moment().tz('Asia/Seoul')
const userStore = createNamespacedHelpers('userStore')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BButton,
    BTable,
    BPagination,
    BBadge,
    BMedia,
    BAvatar,
    BFormFile,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      uploadFile: null,
      fileSizeString: '',
      header: [
        'No.', '권한', '아이디', '닉네임', '이름',
        '상위', '등급', '상태', '출금계좌은행', '출금계좌번호',
        '입금계좌은행', '입금계좌번호', '입금계좌예금주', '전화번호', '패스워드',
        '캐쉬', '포인트', '환전패스워드', '스포츠롤링비율', '카지노롤링비율',
        '슬롯롤링비율', '가입일', '가입IP', '접속', '메모',
      ],
    }
  },
  setup() {
    // Table Handlers
    const tableColumns = [
      { key: 'No.', sortable: false, label: 'No.' },
      { key: 'recommendNickname', sortable: true, label: '상위' },
      { key: 'role', sortable: true, label: '권한' },
      { key: 'userid', sortable: true, label: '아이디' },
      { key: 'nickname', sortable: true, label: '닉네임' },
      { key: 'username', sortable: true, label: '이름' },
      { key: 'rank', sortable: true, label: '등급' },
      { key: 'status', sortable: true, label: '상태' },
      { key: 'alias', sortable: true, label: '별칭' },
      { key: 'accountBank', sortable: true, label: '출금은행' },
      { key: 'accountNumber', sortable: true, label: '출금계좌' },
      { key: 'designated', sortable: true, label: '입금' },
      // { key: 'adminAccountBank', sortable: false, label: '입금계좌은행' },
      // { key: 'adminAccountNumber', sortable: false, label: '입금계좌번호' },
      // { key: 'adminAccountName', sortable: false, label: '입금계좌예금주' },
      { key: 'mobile', sortable: true, label: '전화번호' },
      // { key: 'password', sortable: false, label: '패스워드' },
      { key: 'cash', sortable: true, label: '통합캐쉬' },
      { key: 'point', sortable: true, label: '포인트' },
      { key: 'betTotal', sortable: true, label: '배팅합계' },
      { key: 'depositTotal', sortable: true, label: '충전합계' },
      { key: 'exchangeTotal', sortable: true, label: '환전합계' },
      // { key: 'outcomePassword', sortable: false, label: '환전패스워드' },
      // { key: 'rateSportRolling', sortable: false, label: '스포츠롤링' },
      // { key: 'rateCasinoRolling', sortable: false, label: '카지노롤링' },
      // { key: 'rateSlotRolling', sortable: false, label: '슬롯롤링' },
      { key: 'enrollDate', sortable: true, label: '가입일/IP' },
      // { key: 'enrollIp', sortable: true, label: '가입IP' },
      { key: 'signinLastDate', sortable: true, label: '최근접속/IP' },
      // { key: 'signinLastIp', sortable: true, label: '가입IP' },
      { key: 'signinLastPlatform', sortable: true, label: '최근플랫폼' },
      { key: 'signinTotal', sortable: true, label: '누적접속' },
      // { key: 'memo', sortable: false, label: '메모' },
    ]

    const {

      /* Table Set */
      refUserListTable,
      rowClicked,

      /* TableOption */
      perPageOptions,
      currentPage,
      perPage,
      pageFrom,
      pageTo,
      pageOf,
      totalRows,
      searchFilter,

      /* Search Filter */
      siteFilter,
      roleFilter,
      statusFilter,
      rankFilter,

      /* Select Options */
      siteOptions,
      roleOptions,
      statusOptions,
      rankOptions,

      /* fetchData */
      filteredItems,
      paginatedItems,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      convertUserStatus,

    } = useUsersList()

    return {
      /* Table Set */
      refUserListTable,
      rowClicked,

      /* TableOption */
      perPageOptions,
      currentPage,
      perPage,
      pageFrom,
      pageTo,
      pageOf,
      totalRows,
      searchFilter,

      /* Search Filter */
      siteFilter,
      roleFilter,
      statusFilter,
      rankFilter,

      /* Select Options */
      siteOptions,
      roleOptions,
      statusOptions,
      rankOptions,

      /* fetchData */
      filteredItems,
      paginatedItems,
      tableColumns,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,
      convertUserStatus,

      avatarText,
      formatDate,

    }
  },
  computed: {
    authSite() {
      return this.$store.getters['rootStore/authSite']
    },
    siteSelected() {
      return this.$store.getters['rootStore/siteSelected']
    },
    userData() {
      return this.$store.getters['rootStore/userData']
    },
    ...userStore.mapGetters({
      uploadExcelUsers: 'uploadExcelUsers',
    }),
  },
  methods: {
    ...userStore.mapActions({
      $uploadExcelUsers: UPLOAD_EXCEL_USERS,
    }),
    excelFormatDownload() {
      // 파일 다운로드
      const formatUrl = 'https://agress-assets.s3.ap-northeast-2.amazonaws.com/format_userupload/userlist_upload_format_v1.0.xlsx'
      const link = document.createElement('a')
      link.href = formatUrl
      link.download = 'userlist_upload_format_v1.0.xlsx' // 저장될 파일 이름
      link.click()
    },
    openModal() {
      this.$refs.uploadUserModal.show()
    },
    closeModal() {
      this.fileSizeString = ''
    },
    formatFileSize(size) {
      if (size < 1024) {
        return `${size} bytes`
      } if (size < 1048576) {
        return `${(size / 1024).toFixed(2)} KB`
      }
      return `${(size / 1048576).toFixed(2)} MB`
    },
    checkFileSize(event) {
      const selectedFile = event.target.files[0]
      if (selectedFile) {
        this.uploadFile = selectedFile
        const fileSize = selectedFile.size
        this.fileSizeString = this.formatFileSize(fileSize)
      }
    },
    handleSortChanged({ sortBy, sortDesc }) {
      console.log('handleSortChanged', sortBy, sortDesc)
    },
    async excelUpload() {
      if (!this.uploadFile) {
        return
      }
      try {
        const workbook = new ExcelJS.Workbook()
        const fileBuffer = await this.uploadFile.arrayBuffer()
        await workbook.xlsx.load(fileBuffer)

        // 첫 번째 워크시트 선택
        const worksheet = workbook.worksheets[0]

        // 헤더 추출 (첫 번째 행)
        this.tableHeaders = worksheet.getRow(1).values.slice(1) // 첫 번째 셀 제외 (Header 추출)

        // 데이터 추출 (1번째와 3번째 행 제외)
        const data = []
        worksheet.eachRow((row, rowIndex) => {
          // 1번째와 3번째 행은 건너뛰기
          if (rowIndex === 1 || rowIndex === 2 || rowIndex === 3) return

          // 첫 번째 셀(No.)을 제외하고 나머지 데이터를 정제하여 추가
          const rowData = row.values.slice(2) // 첫 번째 셀과 첫 번째 열 제외

          // 필요한 데이터가 최소한으로 있는지 확인
          if (rowData.length < this.tableHeaders.length - 1) {
            while (rowData.length < this.tableHeaders.length - 1) {
              rowData.push('') // 누락된 데이터를 공백으로 채움
            }
          }

          data.push(rowData)
        })
        this.extractedData = data.filter(row => row.some(value => value !== '')) // 빈 행 필터링

        // console.log('extractedData ::', this.extractedData)

        // ---------------------------------------
        await this.validateAndUploadExcelUsers()
      } catch (error) {
        this.showToast('Excel을 확인해 주세요.', '', 'top-center')
      }
    },
    async validateAndUploadExcelUsers() {
      const schema = {
        role: { maxLength: 15, type: 'string', required: true },
        userid: { maxLength: 15, type: 'string', required: true },
        nickname: { maxLength: 45, type: 'string', required: true },
        username: { maxLength: 45, type: 'string', required: true },
        recommendNickname: { maxLength: 45, type: 'string', required: true },
        rank: { type: 'number', required: true },
        status: { maxLength: 15, type: 'string', required: true },
        accountBank: { maxLength: 45, type: 'string', required: true },
        accountNumber: { maxLength: 45, type: 'string', required: true },
        adminAccountBank: { maxLength: 45, type: 'string', required: true },
        adminAccountNumber: { maxLength: 45, type: 'string', required: true },
        adminAccountName: { maxLength: 45, type: 'string', required: true },
        mobile: { maxLength: 45, type: 'string', required: false },
        password: { type: 'string', required: false },
        cash: { type: 'number', required: false },
        point: { type: 'number', required: false },
        outcomePassword: { type: 'string', required: false },
        rateSportRolling: { type: 'number', required: false },
        rateCasinoRolling: { type: 'number', required: false },
        rateSlotRolling: { type: 'number', required: false },
        enrollDate: { type: 'string', required: false },
        enrollIp: { maxLength: 45, type: 'string', required: false },
        signinTotal: { type: 'number', required: false },
        memo: { maxLength: 1000, type: 'string', required: false },
      }

      const columnMapping = [
        'role', 'userid', 'nickname', 'username', 'recommendNickname', 'rank', 'status',
        'accountBank', 'accountNumber', 'adminAccountBank', 'adminAccountNumber', 'adminAccountName',
        'mobile', 'password', 'cash', 'point', 'outcomePassword', 'rateSportRolling', 'rateCasinoRolling',
        'rateSlotRolling', 'enrollDate', 'enrollIp', 'signinTotal', 'memo',
      ]

      const headerMapping = Object.keys(schema).reduce((acc, key, index) => {
        acc[key] = this.header[index + 1] || key
        return acc
      }, {})

      const mappedData = this.extractedData.map(row => this.mapRowToData(row, columnMapping, schema))

      for (const [index, user] of mappedData.entries()) {
        if (!this.validateRow(user, schema, headerMapping, index)) return false
      }

      const transformedData = this.transformStatus(mappedData)

      try {
        await this.$uploadExcelUsers({
          site: this.siteSelected,
          usersInput: transformedData,
        })
        return true
      } catch (error) {
        this.showToast('엑셀 사용자 업로드 중 오류가 발생했습니다. 다시 시도해주세요', '', 'top-center')
        return false
      }
    },

    mapRowToData(row, columnMapping, schema) {
      return row.reduce((acc, value, index) => {
        const key = columnMapping[index]
        if (!key) return acc

        if (value === undefined || value === null || value === '') {
          acc[key] = this.getDefaultValue(key, schema)
        } else if (schema[key]?.type === 'string' && typeof value !== 'string') {
          acc[key] = String(value)
        } else if (schema[key]?.type === 'number' && typeof value !== 'number') {
          const numberValue = Number(value)
          acc[key] = Number.isNaN(numberValue) ? 0 : numberValue
        } else {
          acc[key] = value
        }
        return acc
      }, {})
    },

    getDefaultValue(key, schema) {
      if (key === 'recommendNickname') return this.siteSelected
      if (key === 'password' || key === 'outcomePassword') return '1234'
      if (key === 'enrollDate') return new Date().toISOString()
      if (schema[key]?.type === 'string') return ''
      if (schema[key]?.type === 'number') return 0
      return null
    },

    validateRow(data, schema, headerMapping, index) {
      const updatedData = { ...data } // 원본 객체 복사

      for (const [key, rules] of Object.entries(schema)) {
        let value = updatedData[key]
        const headerName = headerMapping[key]

        // 기본값 설정
        if (value === undefined || value === null || value === '') {
          value = this.getDefaultValue(key, schema)
          updatedData[key] = value
        }

        // 유효성 검사
        if (this.isInvalid(value, rules)) {
          this.showToast(this.generateErrorMessage(index, headerName, '유효하지 않은 값입니다'), '', 'top-center')
          return false
        }

        // 특정 필드에 대한 추가 벨리데이션
        if (key === 'role' && !['user', 'branch'].includes(value)) {
          this.showToast(this.generateErrorMessage(index, headerName, '\'user\', \'branch\' 중 하나여야 합니다'), '', 'top-center')
          return false
        }

        if (key === 'rank' && (value < 1 || value > 10)) {
          this.showToast(this.generateErrorMessage(index, headerName, '1에서 10 사이의 값이어야 합니다'), '', 'top-center')
          return false
        }

        if (key === 'status' && !['정상', '휴면', '차단'].includes(value)) {
          this.showToast(this.generateErrorMessage(index, headerName, '\'정상\', \'휴면\', \'차단\' 중 하나여야 합니다'), '', 'top-center')
          return false
        }

        // 최대 길이 검사
        if (rules.maxLength && typeof value === 'string' && value.length > rules.maxLength) {
          this.showToast(this.generateErrorMessage(index, headerName, `길이가 최대 ${rules.maxLength}자를 초과했습니다`), '', 'top-center')
          return false
        }
      }
      return updatedData // 수정된 객체 반환
    },

    generateErrorMessage(index, headerName, message) {
      return `'${index + 1}번째 행의 ${headerName} ${message}'`
    },

    isInvalid(value, rules) {
      // 필수값이 아닌 경우 항상 유효하다고 간주
      if (!rules.required) return false

      if (rules.required && (value === undefined || value === null || value === '')) return true
      if (rules.type === 'number' && !Number.isFinite(value)) return true
      if (rules.type === 'string' && typeof value !== 'string') return true

      return false
    },

    transformStatus(data) {
      const statusMapping = {
        정상: 'active',
        휴면: 'sleep',
        차단: 'block',
      }

      return data.map(item => {
        const transformedStatus = statusMapping[item.status]
        if (!transformedStatus) throw new Error(`Invalid status value: ${item.status}`)
        return {
          ...item,
          status: transformedStatus,
        }
      })
    },
    async excelDataDownload() {
      try {
        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('Data Export')

        // 1. 헤더 추가 (순서에 맞게 컬럼 추가)
        worksheet.addRow(this.header)

        // 2. 헤더 배경색을 회색으로 설정
        const headerRow = worksheet.getRow(1)
        headerRow.eachCell(originalCell => {
          const cell = { ...originalCell } // 복사
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D3D3D3' }, // 회색 (Hex: #D3D3D3)
          }
          cell.font = { bold: true } // 글자 굵게 설정
        })

        // 3. 데이터 추가 (역순)
        const filteredData = this.$store.getters['userStore/fetchUsers']
        filteredData.reverse().forEach((item, index) => {
          // 상태값 변환
          let statusLabel = ''
          if (item.status === 'apply') statusLabel = '1차가입'
          else if (item.status === 'additional') statusLabel = '2차가입'
          else if (item.status === 'await') statusLabel = '최종대기'
          else if (item.status === 'active') statusLabel = '정상'
          else if (item.status === 'sleep') statusLabel = '휴면'
          else if (item.status === 'block') statusLabel = '차단'

          // 번호를 포함한 행 추가
          const row = [
            index + 1, // No.
            item.role || '', // 권한
            item.userid || '', // 아이디
            item.nickname || '', // 닉네임
            item.username || '', // 이름
            item.recommendNickname || '', // 상위
            item.rank || '', // 등급
            statusLabel, // 상태
            item.accountBank || '', // 출금계좌은행
            item.accountNumber || '', // 출금계좌번호
            item.adminAccountBank || '', // 입금계좌은행
            item.adminAccountNumber || '', // 입금계좌번호
            item.adminAccountName || '', // 입금계좌예금주
            item.mobile || '', // 전화번호
            item.password || '', // 패스워드
            item.cash || '', // 통합캐쉬
            item.point || '', // 포인트
            item.outcomePassword || '', // 환전패스워드
            item.rateSportRolling || '', // 스포츠롤링비율
            item.rateCasinoRolling || '', // 카지노롤링비율
            item.rateSlotRolling || '', // 슬롯롤링비율
            item.enrollDate || '', // 가입일
            item.enrollIp || '', // 가입IP
            item.signinTotal || '', // 접속
            item.memo || '', // 메모
          ]
          worksheet.addRow(row)

          // 4. 가입일, 최근접속일을 'YYYY-MM-DD' 형식으로 텍스트로 설정
          const enrollDateCell = worksheet.getCell(`R${index + 2}`) // 가입일
          const signinLastDateCell = worksheet.getCell(`S${index + 2}`) // 최근접속일

          // moment를 사용하여 'Asia/Seoul' 시간대로 변환하고 'YYYY-MM-DD' 형식으로 포맷
          const enrollDateFormatted = item.enrollDate ? moment(item.enrollDate).format('YYYY-MM-DD') : ''
          const signinLastDateFormatted = item.signinLastDate ? moment(item.signinLastDate).format('YYYY-MM-DD') : ''

          enrollDateCell.value = enrollDateFormatted
          signinLastDateCell.value = signinLastDateFormatted

          // 텍스트 형식 지정 (셀이 날짜로 계산되지 않도록)
          enrollDateCell.numFmt = '@'
          signinLastDateCell.numFmt = '@'
        })

        // 5. 스타일 설정
        worksheet.columns.forEach(originalCol => {
          const col = { ...originalCol } // 복사
          col.width = 17
        })
        worksheet.getRow(1).font = { bold: true }

        // 6. 파일 저장
        const buffer = await workbook.xlsx.writeBuffer()
        saveAs(new Blob([buffer]), 'DataExport.xlsx')
      } catch (error) {
        console.error('Excel 다운로드 실패:', error)
      }
    },
    showToast(title, subtitle, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          text: subtitle,
          icon: 'BellIcon',
          variant: 'danger',
        },
      },
      {
        position,
        // timeout: 8000,
      })
    },
  },
}
</script>
